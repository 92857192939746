import moment from "moment"

import {TBasicRebate} from '../../../../fragments/BasicRebate'
import {TDisabilityRebate} from '../../../../fragments/DisabilityRebate'
import {TZtppRebate} from '../../../../fragments/ZtppRebate'
import {TChildrenRebate} from "../../../../fragments/ChildrenRebate"

type Rebate = TBasicRebate | TDisabilityRebate | TZtppRebate

export function calculatePrevYearPeriods<T extends Rebate>(rebates: T[]) {
    return rebates
        .filter(rebate => rebate.settlementRequest && rebate.settlementRequest.type === "MONTHLY_PREV_YEAR")
        .map(rebate => {
            if (!rebate.prevYearPeriods) {
                return []
            }

            return rebate.prevYearPeriods.map(period => ({
                from: moment(period.from),
                to: moment(period.to)
            }))
        })
        .flat()
}

export function calculateChildrenPrevPeriods(rebates: TChildrenRebate[]) {
    return rebates
        .filter(rebate => rebate.settlementRequest && rebate.settlementRequest.type === "MONTHLY_PREV_YEAR")
        .reduce<any>((result, object) => {
            if (!object.children) {
              return result
            }

            object.children.forEach((child) => {
              if (!result[child.tag]) {
                result[child.tag] = []
              }

              result[child.tag].push(child)
            })

            return result
          }, {})
}