import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import classnames from 'classnames'
import React from 'react'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: (props: { noMargin?: boolean }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: props.noMargin ? 0 : theme.spacing(2.5),
    marginBottom: props.noMargin ? 0 : theme.spacing(2.5),
  }),
  chip: {
    marginLeft: theme.spacing(2),
    borderWidth: 0,
    borderRadius: theme.spacing(3),
    textTransform: 'uppercase',
    textAlign: 'center',
    height: 'fit-content',
    padding: theme.spacing(1, 2),
    minWidth: theme.spacing(8),
  },
  chipYes: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  chipNo: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
  },
}))

interface IProps {
  label: string
  value?: boolean | null
  noMargin?: boolean
}

const Question: React.FC<IProps> = (props) => {
  const classes = useStyles({ noMargin: props.noMargin })
  const { t } = useTranslation()
  return (
    <Box className={classes.root}>
      <Typography variant="h4">{props.label}</Typography>
      {Boolean(props.value) ? (
        <Typography
          component="span"
          variant="body2"
          className={classnames(classes.chip, classes.chipYes)}
        >
          {t('common.yes')}
        </Typography>
      ) : (
        <Typography
          component="span"
          variant="body2"
          className={classnames(classes.chip, classes.chipNo)}
        >
          {t('common.no')}
        </Typography>
      )}
    </Box>
  )
}

export default Question
