import {
  IUserBlockAnnualOverrideData,
  TEmployee as TUser,
  TUserBlockAnnualOverrideVariables,
  USER_BLOCK_ANNUAL_OVERRIDE,
} from './gql'
import {Center, IconCircle} from '../../../../components/CommonBox'
import {makeStyles} from '@material-ui/core/styles'
import {Observer} from 'mobx-react'
import {OpravduSmazat} from '../../../../components/icons/KubikIcons'
import {useMutation} from 'react-apollo'
import {useTranslation} from 'react-i18next'
import Dialog from '../../../../components/Dialog'
import DialogActions from '../../../../components/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../../../../components/DialogTitle'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import SubmitButton from '../../../../components/form/SubmitButton'
import Typography from '@material-ui/core/Typography'
import useForm from '../../../../hooks/useForm'
import useUser from '../../../../hooks/useUser'

const useStyles = makeStyles(theme => ({
  errorButton: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
}))

interface IProps {
  open: boolean
  employee: TUser | null
  onClose: () => void
}
const EmployeeBlockAnnualOverrideDialog: React.FC<IProps> = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useUser()
  const { onClose, open, employee } = props
  const { id, blockedAnnualOverride } = employee || {
    id: '',
    blockedAnnualOverride: null,
  }

  const [blockAnnualOverrideEmployee] = useMutation<
    IUserBlockAnnualOverrideData,
    TUserBlockAnnualOverrideVariables
  >(USER_BLOCK_ANNUAL_OVERRIDE, { refetchQueries: ['employees'] })

  const { form } = useForm<TUser>(
    { id },
    {},
    {
      variables: { where: { id } },
      async onSubmit(_, form) {
        try {
          const { data } = await blockAnnualOverrideEmployee({
            variables: {
              ...form.options.variables,
              ...(blockedAnnualOverride && { unblock: true }),
            },
          })
          if (data) {
            onClose()
          }
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" scroll="body">
      <DialogTitle form={form} onClose={onClose}>
        <Center mb={2}>
          <IconCircle fontSize="3rem">
            <OpravduSmazat fontSize="inherit" color="primary" />
          </IconCircle>
        </Center>
        {!!blockedAnnualOverride
          ? `${t('employee.annualOverride', {
              context: 'UNBLOCK',
            })} ${blockedAnnualOverride}`
          : `${t('employee.annualOverride', { context: 'BLOCK' })} ${
              user.data.customer.yearOfAnnualRebates
            }`}
      </DialogTitle>

      <DialogContent>
        <Observer>
          {() => (
            <Grid container direction="column" spacing={1}>
              <Grid sm item>
                <Typography align="center">
                  „{employee && `${employee.firstname} ${employee.lastname}`}“
                </Typography>
              </Grid>
            </Grid>
          )}
        </Observer>
      </DialogContent>

      <DialogActions dividerProps={{ mt: 2 }}>
        <SubmitButton
          classes={{ button: classes.errorButton }}
          form={form}
          label={t(!!blockedAnnualOverride ? 'common.forbid' : 'common.allow')}
        />
      </DialogActions>
    </Dialog>
  )
}

export default EmployeeBlockAnnualOverrideDialog
