import React from 'react'
import {Trans} from "react-i18next"

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import {makeStyles} from '@material-ui/styles'

import {Green} from '../../../../../../../components/CommonBox'
import {Dotaznik, Dulezite, KalendarInput, SlevyGreen, Smazat40} from '../../../../../../../components/icons/KubikIcons'

import useUser from '../../../../../../../hooks/useUser'
import moment from "moment";

export const useStyles = makeStyles({
    item: {
        padding: 0
    },
    list: {
        padding: 0,
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
    },
    text: {
        margin: 0
    },
    icon: {
        margin: 0
    }
})

export const Information: React.FC = () => {
    const { user } = useUser()
    const classes = useStyles()

    const items = [
        {
            icon: <Dulezite color="primary" />,
            text: (
                <Trans i18nKey="annualRebate.text2">
                    <Green>Důležité</Green>Je potřeba <strong>projít všechny kroky formuláře</strong>, aby se žádost poslala na mzdovou účetní.
                </Trans>
            )
        },
        {
            icon: <SlevyGreen />,
            text: (
                <Trans i18nKey="annualRebate.text3">
                    <Green>Obsahuje daňové slevy:</Green>Roční slevy (Hypotéka, Dary,
                    Životní pojištění, Penzijní připojištění, Školkovné,
                    Manžel/Manželka a další) a měsíční slevy za minulý rok
                    (Základní poplatník, Děti, Invalidita a ZTP/P)
                </Trans>
            )
        },
        {
            icon: <KalendarInput color="primary" />,
            text: (
                <Trans
                    i18nKey="annualRebate.text4"
                    tOptions={{
                        annualRebatesYear: moment(user.data.customer.annualRebatesPeriodEnd).year()
                    }}
                >
                    <Green>Termín:</Green>
                    Do 15. února
                </Trans>
            )
        },
        {
            icon: <Dotaznik />,
            text: (
                <Trans i18nKey="annualRebate.text5">
                    <Green>Oficiální název formuláře</Green> je Žádost o roční zúčtování záloh a daňového zvýhodnění
                </Trans>
            )
        },
        {
            icon: <Smazat40 />,
            text: (
                <Trans i18nKey="annualRebate.text6">
                    <Green>Dělám si daně sám:</Green> pokud si
                </Trans>
            )
        }
    ]    

    return (
        <List className={classes.list}>
            {items.map((item, i) => {
                return (
                    <ListItem className={classes.item} key={i} alignItems='flex-start'>
                        <ListItemIcon className={classes.icon}>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText className={classes.text}>
                            {item.text}
                        </ListItemText>
                    </ListItem>
                )
            })}
        </List>
    )
}
