import {
  TPrevEmployerSnapWithHistory,
  TPrevEmployerWithHistory,
  TSettlementRequest,
  isPrevEmployerSnap,
} from './../gql'
import { Bold, Divider, Green } from '../../../../../../components/CommonBox'
import { CommentOfDeniedView } from './CommentOfDenied'
import { Observer, inject } from 'mobx-react'
import {
  StatusSwitcherView,
  StatusView,
  StatusViewText,
} from './StatusSwitcher'
import { Store } from '../Store'
import { styled } from '@material-ui/core/styles'
import { Trans, useTranslation } from 'react-i18next'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import Box from '@material-ui/core/Box'
import FileLink from '../../../../../../components/FileLink'
import Grid from '@material-ui/core/Grid'
import PrevEmployerSnapView from '../../../AnnualRebates/components/PrevEmployerSnapView'
import PrevRebate from '../../../AnnualRebates/components/PrevRebate'
import React from 'react'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import PreviousEmployersSummaryInfoOverview from "./PreviousEmployersSummaryInfoOverview";

const Heading = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  width: 450,
  maxWidth: '100%',
  fontSize: '1.1rem',
  margin: '0 auto',
}))

interface TStylesProps {
  compactScreen?: boolean
  isNotSmallScreen?: boolean
}

const useStyles = makeStyles<Theme, TStylesProps>((theme: Theme) => ({
  container: {
    borderRadius: '5px',
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.15)',
    padding: (props) =>
      props.compactScreen ? theme.spacing(2, 2) : theme.spacing(4, 3),
    margin: (props) =>
      props.compactScreen ? theme.spacing(0) : theme.spacing(0, 3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '100%',
  },
}))

const sum = (text: number | null | undefined) => `${text || '0'} Kč`

const PreviousEmployerForm: React.FC<{
  store?: Store
  propagateDenyStatus?: boolean
  data: TPrevEmployerSnapWithHistory | TPrevEmployerWithHistory
  divider?: boolean
  year: number
  gender: string
}> = inject('store')((props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)

  return (
    <Observer>
      {() => {
        const { data } = props
        const history = isPrevEmployerSnap(data)
          ? data.snapHistory
          : (data.history || []).slice(1)

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const store = props.store!
        const employerData = store.getEmployerData(data.id)

        const createStatusHandler = (status: 'CONFIRMED' | 'DENIED') => () => {
          if (store.status === 'APPLY') {
            store.updateEmployerData(data.id, { status })

            if (status === 'DENIED' && props.propagateDenyStatus) {
              store.denyWithComment(
                (employerData && employerData.commentOfDenied) || '',
                true,
              )
            }
          }
        }

        const handleCommentBlur = (value: string | number) => {
          // return store.denyWithComment(value + '', true)
        }

        const onCommentChange = (commentOfDenied: string) => {
          store.updateEmployerData(data.id, { commentOfDenied })
        }

        const status = (employerData && employerData.status) || 'APPLY'
        const commentOfDenied =
          (employerData && employerData.commentOfDenied) || ''

        return (
          <Box width={740} maxWidth="100%">
            <Grid container spacing={compactScreen ? 2 : 6}>
              {isPrevEmployerSnap(data) ? (
                <Grid item xs={12}>
                  <StatusSwitcherView
                    status={status}
                    {...(store.status === 'APPLY' && {
                      createHandler: createStatusHandler,
                    })}
                  >
                    {t('annualRebatePreviousEmployers.employer')} -{' '}
                    {data.companyName}
                  </StatusSwitcherView>
                  <CommentOfDeniedView
                    status={status}
                    settlementRequestStatus={store.status}
                    commentOfDenied={commentOfDenied}
                    onChange={onCommentChange}
                    onBlur={handleCommentBlur}
                  />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <StatusView status={data.status}>
                    {t('annualRebatePreviousEmployers.employer')} -{' '}
                    {data.companyName}
                  </StatusView>
                  {data.status === 'DENIED' && (
                    <Typography color="error">
                      {data.commentOfDenied}
                    </Typography>
                  )}
                </Grid>
              )}

              {data.deleteFlag &&
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Bold>{data.companyName}</Bold>
                  </Grid>
                  <Grid item xs={5}>
                    <Bold>{t('annualRebatePreviousEmployers.cancelPrevEmployer2')}</Bold>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>{t('common.yes')}</Typography>
                  </Grid>
                </Grid>
              }

              {!data.deleteFlag && <>
                <Grid item xs={12} container justifyContent="space-between">
                  <Bold>{t('annualRebatePreviousEmployers.companyName')}</Bold>
                  <Typography>{data.companyName}</Typography>
                </Grid>

                <Grid item xs={12} container justifyContent="space-between">
                  <Bold>
                    {t('annualRebatePreviousEmployers.workedHere', {
                      context: props.gender,
                      year: props.year,
                    })}
                  </Bold>
                  <Typography>{`${moment(data.dateOfEmployment).format(
                    'DD.MM.YYYY',
                  )} - ${moment(data.dateOfQuit).format(
                    'DD.MM.YYYY',
                  )}`}</Typography>
                </Grid>
              </>}
            </Grid>

            <Divider my={5} />

            {!data.deleteFlag && <>
              <Grid container spacing={compactScreen ? 2 : 6}>
                <Grid item xs={12}>
                  <Typography variant="h3" align="center">
                    <Trans i18nKey="annualRebatePreviousEmployers.fillIn">
                      Vyplňte údaje z řádku na
                      <a
                        href={process.env.PUBLIC_URL + '/documents/vzor_pozp.pdf'}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Potvrzení o zdanitelných příjmech
                      </a>
                    </Trans>
                  </Typography>
                </Grid>

                <Grid item xs={12} container justifyContent="space-between">
                  <Bold flex={isNotSmallScreen ? undefined : '1 0 100%'}>
                    {t('annualRebatePreviousEmployers.totalRevenue')}
                  </Bold>
                  <Typography>{sum(data.totalRevenue)}</Typography>
                </Grid>

                {/* <Grid item xs={12} container justifyContent="space-between">
                  <Bold>
                    {t('annualRebatePreviousEmployers.incomeInsuranceSum')}
                  </Bold>
                  <Typography>{sum(data.incomeInsuranceSum)}</Typography>
                </Grid> */}

                <Grid item xs={12} container justifyContent="space-between">
                  <Bold flex={isNotSmallScreen ? undefined : '1 0 100%'}>
                    {t('annualRebatePreviousEmployers.totalTaxAdvance')}
                  </Bold>
                  <Typography>{sum(data.totalTaxAdvance)}</Typography>
                </Grid>

                <Grid item xs={12} container justifyContent="space-between">
                  <Bold flex={isNotSmallScreen ? undefined : '1 0 100%'}>
                    {t('annualRebatePreviousEmployers.totalMonthlyTaxBonuses')}
                  </Bold>
                  <Typography>{sum(data.totalMonthlyTaxBonuses)}</Typography>
                </Grid>

                <Grid item xs={12} container justifyContent="space-between">
                  <Bold flex={isNotSmallScreen ? undefined : '1 0 100%'}>
                    {t('annualRebatePreviousEmployers.lifeInsuranceContribution')}
                  </Bold>
                  <Typography>{sum(data.lifeInsuranceContribution)}</Typography>
                </Grid>
              </Grid>

              <Divider my={4} />

              <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box display="flex">
                  <Bold>
                    {t('annualRebatePreviousEmployers.confirmationBelow')}
                  </Bold>
                </Box>
                <Divider my={2} width="25%" />
                <Bold mb={1} mt={1} display="block">
                  {t('annualRebatePreviousEmployers.incomeConfirmationFiles')}
                </Bold>
                <Box pl={compactScreen ? 0 : 4}>
                  {data.incomeConfirmationFiles &&
                    data.incomeConfirmationFiles.map((file) => (
                      <FileLink key={file.id} file={file} />
                    ))}
                </Box>
              </Grid>
            </Grid>
            </>}

            {history && history.length > 0 && (
              <ArchiveChanges>
                {history.map((employer) => (
                  <PrevRebate
                    key={employer.id}
                    status={employer.status}
                    settlementRequest={employer.settlementRequest}
                    commentOfDenied={employer.commentOfDenied}
                  >
                    <PrevEmployerSnapView key={employer.id} data={employer} />
                  </PrevRebate>
                ))}
              </ArchiveChanges>
            )}
          </Box>
        )
      }}
    </Observer>
  )
})

const PreviousEmployers: React.FC<{
  data: TSettlementRequest
}> = (props) => {
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const classes = useStyles({ compactScreen, isNotSmallScreen, ...props })

  const { data } = props

  const appliedEmployers = data.prevEmployers || []
  const otherEmployers = (
    (data.annualRebate && data.annualRebate.prevEmployers) ||
    []
  ).filter((emp) => appliedEmployers.every(({ id }) => id !== emp.id))

  return (
    <Box className={classes.container}>
      <Heading variant="h3" align="center">
        <Trans i18nKey="settlementRequest.employersInnerHeading">
          Uživatelovi údaje o předchozím zaměstnání a údaje z
          <Green>Potvrzení o zdanitelných příjmech</Green>
        </Trans>
      </Heading>

      {data.annualRebate && data.annualRebate.prevEmployersSummaryInfo && <PreviousEmployersSummaryInfoOverview summaryInfo={data.annualRebate.prevEmployersSummaryInfo}/>}

      {appliedEmployers.map((employer, idx, arr) => (
        <React.Fragment key={employer.id}>
          <PreviousEmployerForm
            data={employer}
            year={data.year}
            gender={data.employee.gender}
            divider={idx < arr.length - 1}
          />
          {(idx < arr.length - 1 || otherEmployers.length > 0) && (
            <Divider my={6} />
          )}
        </React.Fragment>
      ))}

      {otherEmployers.map((employer, idx, arr) => (
        <React.Fragment key={employer.id}>
          <PreviousEmployerForm
            data={employer}
            year={data.year}
            gender={data.employee.gender}
            divider={idx < arr.length - 1}
          />
          {idx < arr.length - 1 && <Divider my={6} />}
        </React.Fragment>
      ))}
    </Box>
  )
}

export default PreviousEmployers
