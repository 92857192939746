import {apiPost} from '../../../../../utils'
import {Observer} from 'mobx-react'
import {useForm, useTranslation, useUser} from '../../../../../hooks'
import Button from '@material-ui/core/Button'
import DialogActions from '../../../../../components/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import MonthYearPicker from '../../../../../components/form/MonthYearPicker'
import React from 'react'

interface IProps {
  onClose?: () => void
  report?: string
  periodLabel?: string
  periodHelp?: string
}

interface IMonthlyRebatesEnding {
  period: string
}

const OnlyPeriod: React.FC<IProps> = props => {
  const { user } = useUser()
  const { onClose = () => {}, report, periodLabel, periodHelp } = props
  const { t } = useTranslation()
  const { bind, form } = useForm<IMonthlyRebatesEnding>(
    {
      period: moment()
        .subtract(1, 'month')
        .toISOString(),
    },
    {
      period: {
        rule: 'required',
        label: periodLabel || t('reports.dialogFilter', { context: 'PERIOD' }),
        help:
          periodHelp || t('reports.dialogFilter', { context: 'PERIOD_HELP' }),
      },
    },
    {
      onSubmit(formData, form) {
        const parseDate = (date: moment.Moment) => ({
          year: date.year(),
          month: date.month(),
        })
        apiPost({
          path: `api/${user.data.id}/reports/${report}`,
          data: { ...formData, period: parseDate(moment(formData.period)) },
        })
        form.onSuccess(formData)
        onClose()
      },
      submitWholeModel: true,
    },
  )

  return (
    <>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid xs={12} item>
            <Observer>
              {() => (
                <MonthYearPicker
                  {...bind('period')}
                  fullWidth
                  gutterBottom
                  openTo="month"
                  views={['year', 'month']}
                />
              )}
            </Observer>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions gutterBottom>
        <Observer>
          {() => (
            <Button variant="contained" color="primary" onClick={form.submit}>
              {t('common.submit')}
            </Button>
          )}
        </Observer>
      </DialogActions>
    </>
  )
}

export default OnlyPeriod
