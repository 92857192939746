import {ARCHIVE_DETAILS, TArchiveDetails,} from './gql'
import {makeStyles, Theme} from '@material-ui/core/styles'
import {Observer} from 'mobx-react'
import {useQuery} from 'react-apollo'
import {useTranslation} from 'react-i18next'
import {Center, Green} from '../../../../components/CommonBox'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '../../../../components/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../../../../components/DialogTitle'
import React, {useState} from 'react'
import Podpora from "../../../../components/icons/Podpora";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

interface IStylesProps {
}

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) => {
  return {}
})
interface IProps {
  id: string,
  userName: string,
  type: 'ANNUAL' | 'MONTHLY',
  year: number
}


const ArchiveDetailDialog: React.FC<IProps> = (props, context) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [results, setResults] = useState(null)

  const { loading, error, data, refetch} = useQuery<TArchiveDetails>(ARCHIVE_DETAILS, {
    variables: {
      rebateId: props.id,
      year: props.year,
      type: props.type,
    },
    skip: !open,
    fetchPolicy: 'no-cache'
  })

  const renderReason = (reason?: string | null) => {
    switch (reason) {
      case 'NO_NEED':
        return 'není potřeba'
      case 'QUESTIONNAIRE':
        return 'není schválený dotazník nebo v dotazníku zaměstnanec nechce zpracovat formulář'
      case 'SETTLEMENT_REQUEST':
        return 'zatím nebyla schválena ani zamítnuta žádost'
      case 'PREVIOUS_EMPLOYERS':
        return 'nebyli schváleni předchozí zaměstnavatelé'
      case 'ANNUAL_REBATE':
      case 'MONTHLY_REBATE':
        return 'uživatel nezahájil průchod průvodcem'
      default:
        return reason || ''
    }
  }

  return (
    <>
      <Button onClick={ async () => {
        setOpen(true)
        const newData = await refetch({
          rebateId: props.id,
          year: props.year,
          type: props.type,
        })
        setResults(newData.data.archiveDetails)
      }}>
        <Podpora color="primary" />
      </Button>
      <Dialog open={open} onClose={() => {
        setOpen(false)
      }} fullWidth maxWidth="sm">
        <DialogTitle onClose={() => {
          setOpen(false)
        }}>
          <Center mb={2}>
            <Podpora color="primary" transform="scale(1.2 1.2)"/>
          </Center>
          <h3>
            {props.userName}
          </h3>
          <h3>
            {props.type === 'MONTHLY' && ('Měsíční slevy')} 
            {props.type === 'ANNUAL' && ('Roční zúčtování')} {props.year}

          </h3>
        </DialogTitle>

        <DialogContent>
          <Observer>
            {() => {

              return (
                <>
                  {loading && <CircularProgress />}
                  {!loading && <Table>
                    <TableRow>
                      <TableCell>Formulář</TableCell>
                      <TableCell></TableCell>
                      <TableCell>Důvod</TableCell>
                    </TableRow>
                    {props.type === 'MONTHLY' && !!results && <TableRow>
                      <TableCell>Měsíční slevy {props.year}</TableCell>
                      <TableCell>{(results.monthlyFormResult? <Green>ANO</Green> : 'NE')}</TableCell>
                      <TableCell>{renderReason(results.monthlyFormReason)}</TableCell>
                    </TableRow>}
                    {props.type === 'ANNUAL' && !!results && <>
                      <TableRow>
                        <TableCell>Roční zúčtování {props.year}</TableCell>
                        <TableCell>{(results.annualFormResult? <Green>ANO</Green> : 'NE')}</TableCell>
                        <TableCell>{renderReason(results.annualFormReason)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Dodatek RZ {props.year}</TableCell>
                        <TableCell>{(results.additionalFormResult? <Green>ANO</Green> : 'NE')}</TableCell>
                        <TableCell>{renderReason(results.additionalFormReason)}</TableCell>
                      </TableRow>
                    </>}
                  </Table>}
                </>
              )
            }}
          </Observer>
        </DialogContent>
      </Dialog>


    </>
  )
}

export default ArchiveDetailDialog
