import {Box} from '@material-ui/core'
import {IconCircle} from '../../../../../components/CommonBox'
import {useTranslation} from 'react-i18next'
import Dialog from '../../../../../components/Dialog'
import DialogTitle from '../../../../../components/DialogTitle'
import React from 'react'

export interface IDialogFilterProps {
  open: boolean
  onClose: () => void
  title?: string
  TitleIcon?: React.ComponentType<{ fontSize?: 'small' }>
}

const DialogFilter: React.FC<IDialogFilterProps> = props => {
  const { children, onClose, open, title, TitleIcon } = props
  const { t } = useTranslation()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      scroll="body"
      transitionDuration={{ exit: 60 }}
    >
      <DialogTitle onClose={onClose}>
        <Box display="flex" alignItems="center" flexDirection="column" mb={-2}>
          {!!TitleIcon && (
            <IconCircle mb={2} width={100} height={100}>
              <TitleIcon fontSize="small" />
            </IconCircle>
          )}
          <Box>{title || t('reports.dialogFilter')}</Box>
        </Box>
      </DialogTitle>
      {children}
    </Dialog>
  )
}

export default DialogFilter
