import {gql} from 'apollo-boost'
import {NexusGenArgTypes, NexusGenFieldTypes, NexusGenRootTypes,} from 'kubik-server'
import {PRESCHOOL_REBATE_FULL_FRAGMENT} from '../../../../../../fragments/PreschoolRebate'

export type TPreschoolRebate = NexusGenFieldTypes['PreschoolRebate']

export type TPreschool = NexusGenRootTypes['Preschool']
export type TPartialPreschool = TPreschool & { id?: string }
export type TNewPreschool = Pick<TPreschool, Exclude<keyof TPreschool, 'id'>>
export const isNewPreschool = (
  preschool: TPreschool | TNewPreschool,
): preschool is TNewPreschool => {
  return !('id' in preschool)
}
export const isExistingPreschool = (
  preschool: TPreschool | TNewPreschool,
): preschool is TPreschool => {
  return 'id' in preschool
}

export interface IOwnAnnualPreschoolRebateData {
  user: {
    annualRebate: NexusGenFieldTypes['AnnualRebate'] & {
      preschoolRebate: TPreschoolRebate
      preschoolRebates: TPreschoolRebate[]
    }
  }
}
export const OWN_ANNUAL_PRESCHOOL_REBATE = gql`
  query ownAnnualPreschoolRebate {
    user {
      id
      annualRebate {
        id
        year
        preschoolRebate {
          ...PreschoolRebateFull
        }
        preschoolRebates {
          ...PreschoolRebateFull
        }
      }
    }
  }
  ${PRESCHOOL_REBATE_FULL_FRAGMENT}
`

export interface IUpdateOwnAnnualPreschoolRebateData {
  updateOwnAnnualPreschoolRebate: NexusGenFieldTypes['PreschoolRebate']
}
export type TUpdateOwnAnnualPreschoolRebateVariables = NexusGenArgTypes['Mutation']['updateOwnAnnualPreschoolRebate']
export const UPDATE_OWN_ANNUAL_PRESCHOOL_REBATE = gql`
  mutation updateOwnAnnualPreschoolRebate(
    $data: OwnPreschoolRebateUpdateInput!
  ) {
    updateOwnAnnualPreschoolRebate(data: $data) {
      ...PreschoolRebateFull
    }
  }
  ${PRESCHOOL_REBATE_FULL_FRAGMENT}
`

export interface IResetOwnAnnualPreschoolData {
  resetOwnAnnualPreschoolRebate: TPreschoolRebate
}
export const RESET_OWN_ANNUAL_PRESCHOOL_REBATE = gql`
  mutation resetOwnAnnualPreschoolRebate {
    resetOwnAnnualPreschoolRebate {
      ...PreschoolRebateFull
    }
  }
  ${PRESCHOOL_REBATE_FULL_FRAGMENT}
`
