import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {Divider, Green} from '../../../../../../components/CommonBox'
import {RebateConditions, RebateExpandable} from '../../../../../../shared/Rebates/Conditions'

export const UnionRebateConditions: React.FC = () => {
    const { t } = useTranslation()
  
    return (
      <RebateExpandable>
        <Divider maxWidth="100%" width={680} my={4} />

        <Green>{t("common.mainInfo")}</Green>

        <RebateConditions items={[
            <Trans i18nKey={'annualUnionRebate.conditionsDetail01'}>
                Slevu si může uplatnit <strong>každý zaměstnanec</strong>, který <strong>v minulém roce zaplatil členské příspěvky odborovým organizacím</strong>.
            </Trans>,
            <Trans i18nKey={'annualUnionRebate.conditionsDetail02'}>
                Slevu si můžete uplatnit, pokud <strong>jste členem odborové organizace</strong>.
            </Trans>,
             <Trans i18nKey={'annualUnionRebate.conditionsDetail03'}>
                <strong>Maximálně můžete uplatnit částku 3 000 Kč za celý rok</strong> a současně nesmí částka přesáhnout 1,5 % Vašich příjmů.
            </Trans>
        ]} />

        <Green>{t("common.docsInfo")}</Green>

        <RebateConditions items={[
            {
                primaryText: (
                    <Trans i18nKey="annualUnionRebate.docsInfo01">
                        Potvrzení o zaplaceném příspěvku odborové organizaci
                    </Trans>
                ),
                supportText: t("annualUnionRebate.docsInfo01b")
            }
        ]} />
      </RebateExpandable>
    )
}
