import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {Divider, Green} from '../../../../../../components/CommonBox'
import {RebateConditions, RebateExpandable} from '../../../../../../shared/Rebates/Conditions'

export const GiftRebateConditions: React.FC = () => {
    const { t } = useTranslation()
  
    return (
      <RebateExpandable>
        <Divider maxWidth="100%" width={680} my={4} />

        <Green>{t("common.mainInfo")}</Green>

        <RebateConditions items={[
          <Trans i18nKey="annualGiftRebate.conditionsDetail01">
            Slevu si může uplatnit <strong>každý zaměstnanec</strong>, který v minulém roce daroval <strong>finanční dar alespoň v hodnotě 1 000 Kč</strong>nebo <strong>daroval krev, plazmu</strong> či <strong>tělní orgán</strong>.
          </Trans>,
          <Trans i18nKey="annualGiftRebate.conditionsDetail02" tOptions={{ context: "2023" }}>
            Příjemcem daru může být <strong>nezisková organizace, státní složka</strong> (obec, kraj či politická strana), <strong>firmy v ČR pořádající veřejnou sbírku</strong> (na vědu, vzdělání, kulturu, sociální účely, zdravotní účely apod.) a fyzické osoby poskytující veřejné služby (zdravotní, sociální, péče o zvířata apod.).
          </Trans>,
          <Trans i18nKey="annualGiftRebate.conditionsDetail03">
            <strong>Darování krve</strong> či <strong>plazmy</strong> se hodnotí částkou <strong>3 000 Kč</strong>.
          </Trans>,
          <Trans i18nKey="annualGiftRebate.conditionsDetail04">
            <strong>Darování tělního orgánu</strong> či <strong>krvetvorných buněk</strong> se hodnotí částkou <strong>20 000 Kč</strong>.
          </Trans>,
          <Trans i18nKey="annualGiftRebate.conditionsDetail05">
            Můžete <strong>uplatnit více darů</strong> najednou až do výše <strong>15 % Vašeho daňového základu</strong>.
          </Trans>,
          <Trans i18nKey='annualGiftRebate.conditionsDetail07' components={[<strong />]} />
        ]} />

        <Green>{t("common.docsInfo")}</Green>

        <RebateConditions items={[
          {
            primaryText: (
              <Trans i18nKey='annualGiftRebate.docsInfo01'>
                Potvrzení o poskytnutí daru
              </Trans>
            ),
            supportText: t("annualGiftRebate.docsInfo01b")
          }
        ]} />
      </RebateExpandable>
    )
}
