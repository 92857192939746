import {Center, IconCircle} from '../../../../../../components/CommonBox'
import {makeStyles} from '@material-ui/core/styles'
import {Observer} from 'mobx-react'
import {OpravduSmazat} from '../../../../../../components/icons/KubikIcons'
import {CUSTOMER_BLOCK_SUPERADMIN} from './gql'
import {customer_customer} from '../../../../../../generated/customer'
import {customersPage_customers_items,} from '../../../../../../generated/customersPage'
import {
  customerBlockSuperadmin,
  customerBlockSuperadminVariables,
} from '../../../../../../generated/customerBlockSuperadmin'
import {useMutation} from 'react-apollo'
import {useTranslation} from 'react-i18next'
import Dialog from '../../../../../../components/Dialog'
import DialogActions from '../../../../../../components/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../../../../../../components/DialogTitle'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import SubmitButton from '../../../../../../components/form/SubmitButton'
import Typography from '@material-ui/core/Typography'
import useForm from '../../../../../../hooks/useForm'
import useSnackbar from '../../../../../../hooks/useSnackbar'

const useStyles = makeStyles((theme) => ({
  errorButton: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
}))

interface IProps {
  open: boolean
  customer: customersPage_customers_items | null
  onClose: (refetch?: boolean) => void
}
const CustomerBlockDialog: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { addMessage } = useSnackbar()
  const { open, customer } = props
  const id = customer && customer.id
  const blocked = customer && Boolean(customer.blockedAt)

  const onCloseRefetch = () => {
    props.onClose(true)
  }
  const onClose = () => {
    props.onClose()
  }

  const [blockCustomer] = useMutation<
    customerBlockSuperadmin,
    customerBlockSuperadminVariables
  >(CUSTOMER_BLOCK_SUPERADMIN, {
    refetchQueries: ['customer'], // TBD

    onCompleted({ customerBlockSuperadmin }) {
      addMessage(
        t('superadmin.customersPage.blockCustomer', {
          context: !!customerBlockSuperadmin.blockedAt
            ? 'BLOCKED'
            : 'UNBLOCKED',
          name: (customerBlockSuperadmin && customerBlockSuperadmin.name) || '',
        }),
      )
    },
  })

  const { form } = useForm<customer_customer>(
    { id },
    {},
    {
      variables: {
        where: { id },
        value: !blocked,
      },
      async onSubmit(_, form) {
        try {
          const { data } = await blockCustomer({
            variables: form.options.variables,
          })
          if (data) {
            onCloseRefetch()
          }
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" scroll="body">
      <DialogTitle form={form} onClose={onClose}>
        <Center mb={2}>
          <IconCircle fontSize="3rem">
            <OpravduSmazat fontSize="inherit" color="primary" />
          </IconCircle>
        </Center>
        {t(
          !blocked
            ? 'superadmin.customersPage.blockCustomer'
            : 'superadmin.customersPage.unBlockCustomer',
        )}
      </DialogTitle>

      <DialogContent>
        <Observer>
          {() => (
            <Grid container direction="column" spacing={1}>
              <Grid sm item>
                <Typography align="center">
                  „{customer && `${customer.ico} ${customer.name}`}“
                </Typography>
              </Grid>
            </Grid>
          )}
        </Observer>
      </DialogContent>

      <DialogActions dividerProps={{ mt: 1 }}>
        <SubmitButton
          classes={{ button: classes.errorButton }}
          form={form}
          label={t(blocked ? 'common.unblock' : 'common.block')}
        />
      </DialogActions>
    </Dialog>
  )
}

export default CustomerBlockDialog
