import {gql} from 'apollo-boost'
import {NexusGenArgTypes, NexusGenFieldTypes} from 'kubik-server'
import {UNION_REBATE_FULL_FRAGMENT} from '../../../../../../fragments/UnionRebate'

export type TUnionRebate = NexusGenFieldTypes['UnionRebate']

export interface IOwnAnnualUnionRebateData {
  user: {
    annualRebate: NexusGenFieldTypes['AnnualRebate'] & {
      unionRebate: TUnionRebate
      unionRebates: TUnionRebate[]
    }
  }
}
export const OWN_ANNUAL_UNION_REBATE = gql`
  query ownAnnualUnionRebate {
    user {
      id
      annualRebate {
        id
        year
        unionRebate {
          ...UnionRebateFull
        }
        unionRebates {
          ...UnionRebateFull
        }
      }
    }
  }
  ${UNION_REBATE_FULL_FRAGMENT}
`

export interface IUpdateOwnAnnualUnionRebateData {
  updateOwnAnnualUnionRebate: NexusGenFieldTypes['UnionRebate']
}
export type TUpdateOwnAnnualUnionRebateVariables = NexusGenArgTypes['Mutation']['updateOwnAnnualUnionRebate']
export const UPDATE_OWN_ANNUAL_UNION_REBATE = gql`
  mutation updateOwnAnnualUnionRebate($data: OwnUnionRebateUpdateInput!) {
    updateOwnAnnualUnionRebate(data: $data) {
      ...UnionRebateFull
    }
  }
  ${UNION_REBATE_FULL_FRAGMENT}
`

export interface IResetOwnAnnualUnionData {
  resetOwnAnnualUnionRebate: TUnionRebate
}
export const RESET_OWN_ANNUAL_UNION_REBATE = gql`
  mutation resetOwnAnnualUnionRebate {
    resetOwnAnnualUnionRebate {
      ...UnionRebateFull
    }
  }
  ${UNION_REBATE_FULL_FRAGMENT}
`
