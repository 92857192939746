import React, {useMemo, useState} from 'react'
import moment from 'moment'
import {useTranslation} from 'react-i18next'
import {RouteComponentProps} from 'react-router'
import {NexusGenFieldTypes, NexusGenInputs} from 'kubik-server'
import {useQuery} from 'react-apollo'
import {omit} from 'lodash'

import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'
import {makeStyles, Typography} from '@material-ui/core'

import LogDetailsDialog from './LogDetailsDialog'
import {LOGS, LOGS_USERS, TUserLogData, TUserLogVariables} from './gql'

import Datatable from '../../../../components/Datatable'
import ScrollToTop from '../../../../components/ScrollToTop'
import Autocomplete from '../../../../components/form/Autocomplete'
import useDatatableVariables from '../../../../hooks/useDatatableVariables'
import useUser from '../../../../hooks/useUser'
import BackButton from '../../../../components/BackButton'
import {useActionEnum, useRoleEnum} from '../../../../hooks/useEnumList'
import {Bold} from '../../../../components/CommonBox'
import Select from '../../../../components/form/Select'
import Aplikace from '../../../../components/icons/Aplikace'
import Api from '../../../../components/icons/Api'
import {LogsUsers} from '../../../../generated/LogsUsers'
import DatePicker from '../../../../components/form/DatePicker'

const useStyles = makeStyles((theme) => ({
  toolbar: {
    gap: '1rem',
  },
  toolbarRow: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3,1fr)',
    gap: '1rem',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
}))

const defaultVariables = {
  skip: 0,
  first: 20,
}

interface IProps extends RouteComponentProps {
  globalKey: string
}

type TUserLog = NexusGenFieldTypes['UserLog']

const LogsTable: React.FC<IProps> = ({
  globalKey: GLOBAL_UNIQUE_KEY,
  history,
}) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { list: actionList, translate: translateAction } = useActionEnum()
  const { translate: translateRole } = useRoleEnum()
  const classes = useStyles()

  const [selectedLog, setSelectedLog] = useState<TUserLog | null>(null)

  const [datatableVariables, setDatatableVariables] = useDatatableVariables(
    GLOBAL_UNIQUE_KEY,
    defaultVariables,
  )

  const { data: usersQuery } = useQuery<LogsUsers>(LOGS_USERS)

  const userList = useMemo(() => {
    if (!usersQuery) {
      return []
    }

    return usersQuery.users.map((user) => ({
      text: user.fullname,
      value: user.id,
    }))
  }, [usersQuery])

  function filterWith<T extends keyof NexusGenInputs['UserLogWhereInput']>(
    key: T,
    value: NexusGenInputs['UserLogWhereInput'][T],
  ) {
    setDatatableVariables((prev) => {
      const where: NexusGenInputs['UserLogWhereInput'] = {
        ...omit(prev.where, key),
      }

      if (value) {
        where[key] = value
      }

      return {
        ...prev,
        where,
      }
    })
  }

  return (
    <Box
      maxWidth="100%"
      width={1077}
      ml="auto"
      mr="auto"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <ScrollToTop />

      <LogDetailsDialog
        log={selectedLog}
        onClose={() => setSelectedLog(null)}
      />

      <Typography variant="h1" gutterBottom>
        {t('log.subTitle')} {!!user.data.customer && user.data.customer.name}
      </Typography>

      <Datatable<TUserLogData['logs']['items'], TUserLogVariables>
        globalKey={GLOBAL_UNIQUE_KEY}
        onRowClick={setSelectedLog}
        orderByList={[
          { value: 'createdAt_ASC', text: t('common.createdAt_ASC') },
          { value: 'createdAt_DESC', text: t('common.createdAt_DESC') },
        ]}
        renderToolbar={(elements) => {
          return (
            <Box
              display="flex"
              flexDirection="column"
              mb={2}
              className={classes.toolbar}
            >
              <div className={classes.toolbarRow}>
                <Select
                  label=""
                  placeholder={t('log.toolbar.filterByAction')}
                  list={[
                    { text: '-', value: null },
                    ...actionList.map((item) => ({
                      text: item.text,
                      value: item.id,
                    })),
                  ]}
                  value={
                    datatableVariables &&
                    datatableVariables.where &&
                    datatableVariables.where.action
                  }
                  onChange={(value) => filterWith('action', value as any)}
                  onRelationConnect={() => {}}
                  onBlur={() => {}}
                  hideLabel
                  hideEmptyValue
                  fullWidth
                />

                {elements.orderByElement}

                <DatePicker
                  label=""
                  placeholder={t('log.toolbar.filterByDay')}
                  value={
                    datatableVariables &&
                    datatableVariables.where &&
                    datatableVariables.where.AND &&
                    datatableVariables.where.AND[0].createdAt_gte
                  }
                  onChange={(date) => {
                    if (!date) {
                      filterWith('AND', null)
                    } else {
                      const start = moment(date).startOf('D')
                      const end = moment(date).endOf('D')

                      filterWith('AND', [
                        {
                          createdAt_gte: start,
                          createdAt_lte: end,
                        },
                      ])
                    }
                  }}
                  onBlur={() => {}}
                  minDate={user.data.customer.activationAt}
                  maxDate={moment().endOf('D')}
                />
              </div>

              <div className={classes.toolbarRow}>
                <Autocomplete
                  label=""
                  placeholder={t('log.toolbar.filterByAuthor')}
                  onChange={(id) => filterWith('createdBy', id ? { id } : null)}
                  value={
                    datatableVariables &&
                    datatableVariables.where &&
                    datatableVariables.where.createdBy &&
                    datatableVariables.where.createdBy.id
                  }
                  onBlur={() => {}}
                  onRelationConnect={() => {}}
                  list={userList}
                  hideLabel
                  hideEmptyValue
                  fullWidth
                />

                <Autocomplete
                  label=""
                  placeholder={t('log.toolbar.filterByAffectedUser')}
                  onChange={(id) =>
                    filterWith('affectedUser', id ? { id } : null)
                  }
                  value={
                    datatableVariables &&
                    datatableVariables.where &&
                    datatableVariables.where.affectedUser &&
                    datatableVariables.where.affectedUser.id
                  }
                  onBlur={() => {}}
                  onRelationConnect={() => {}}
                  list={userList}
                  hideLabel
                  hideEmptyValue
                  fullWidth
                />
              </div>
            </Box>
          )
        }}
        query={{
          query: LOGS,
          dataField: 'logs',
          options: {
            fetchPolicy: 'cache-and-network',
          },
        }}
        columns={[
          {
            key: 'sent',
            shrink: true,
            align: 'center',
            render(row) {
              return (
                <Tooltip
                  title={t('log.columns.actionTooltip', {
                    source:
                      row.source === 'APP'
                        ? t('log.columns.actionapp')
                        : t('log.columns.actionapi'),
                  })}
                >
                  <span>{row.source === 'APP' ? <Aplikace /> : <Api />}</span>
                </Tooltip>
              )
            },
          },
          {
            key: 'action',
            label: t('log.columns.action'),
            render(row) {
              return <Bold>{translateAction(row.action)}</Bold>
            },
          },
          {
            key: 'createdAt',
            label: t('log.columns.performedAt'),
            render(row) {
              return moment(row.createdAt).format('DD.MM.YYYY HH:mm')
            },
          },
          {
            key: 'createdBy',
            label: t('common.author'),
            render(row) {
              if (row.source === 'API' || !row.createdBy) {
                return t('log.columns.performedByAPI')
              }

              return (
                <Tooltip title={row.createdBy.email}>
                  <span>
                    <Bold>{translateRole(row.createdBy.role)}</Bold>{' '}
                    {/* @ts-ignore */}
                    {row.createdBy.fullname}
                  </span>
                </Tooltip>
              )
            },
          },
          {
            key: 'affectedUser',
            label: 'Upravený uživatel',
            render(row) {
              if (!row.affectedUser) {
                return '-'
              }

              return (
                <Tooltip title={row.affectedUser.email}>
                  <span>
                    <Bold>{translateRole(row.affectedUser.role)}</Bold>{' '}
                    {/* @ts-ignore */}
                    {row.affectedUser.fullname}
                  </span>
                </Tooltip>
              )
            },
          },
          // {
          //   key: 'actions',
          //   align: 'center',
          //   render(row) {
          //     return (
          //       <Center>
          //         <Button
          //           size="small"
          //           color="primary"
          //           onClick={() => setSelectedLog(row)}
          //         >
          //           {t('common.detail')}
          //         </Button>
          //       </Center>
          //     )
          //   },
          // },
        ]}
      />

      <Box padding="1rem" color="#898989">
        <BackButton
          color="inherit"
          onClick={() =>
            user.isSuperadmin()
              ? history.push(`/${user.data.id}/superadmin/settings`)
              : history.push(`/${user.data.id}/company-settings`)
          }
        />
      </Box>
    </Box>
  )
}

export default LogsTable
