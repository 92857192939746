import {IUpdateOwnLoginData, TUpdateOwnLoginVariables, UPDATE_OWN_LOGIN,} from './gql'
import {Green} from '../../../../components/CommonBox'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import {Observer} from 'mobx-react'
import {PrihlaseniIlustrace} from '../../../../components/icons/KubikIcons'
import {useMutation} from 'react-apollo'
import {Trans, useTranslation} from 'react-i18next'
import AppState from '../../../../components/AppState'
import ArrowIcon from '@material-ui/icons/PlayCircleFilled'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import classnames from 'classnames'
import Collapse from '@material-ui/core/Collapse'
import Divider from '../../../../components/Divider'
import FormControls from '../../../../components/form/FormControls'
import CheckIcon from '@material-ui/icons/CheckCircle'
import InsideLayout from '../../../../components/layouts/InsideLayout'
import React, {useRef, useState} from 'react'
import ScrollToTop from '../../../../components/ScrollToTop'
import Snackbars from '../../../../components/form/Snackbars'
import TextField from '../../../../components/form/TextField'
import Typography from '@material-ui/core/Typography'
import useForm from '../../../../hooks/useForm'
import useRouter from '../../../../hooks/useRouter'
import useSnackbar from '../../../../hooks/useSnackbar'
import useUser from '../../../../hooks/useUser'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles((theme) => ({
  passwordRule: {
    width: 300,
    display: 'flex',
    transition: theme.transitions.create('color'),
    paddingLeft: theme.spacing(1),
    color: theme.palette.text.hint,
    '& > svg': {
      marginRight: theme.spacing(1),
    },
  },
  green: { color: theme.palette.primary.main },
  red: { color: theme.palette.error.main },
}))

const EditLogin: React.FC = () => {
  const classes = useStyles()
  const { history, location } = useRouter()
  const { user } = useUser()
  const { t } = useTranslation()
  const { addMessage } = useSnackbar()

  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)

  const [editEmail, setEditEmail] = useState(false)
  const [editPassword, setEditPassword] = useState(false)

  const [updateOwnLogin] = useMutation<
    IUpdateOwnLoginData,
    TUpdateOwnLoginVariables
  >(UPDATE_OWN_LOGIN, {
    onCompleted() {
      addMessage(t('common.change', { context: 'SUCCESS' }))
    },
  })

  const { bind, form } = useForm<TUpdateOwnLoginVariables['data']>(
    useRef({ currentEmail: user.data.email }).current,
    {
      currentEmail: {
        label: t('common.currentEmail'),
        // rule: 'required',
      },
      email: {
        omitOnSubmit: !editEmail,
        label: t('common.newEmail'),
        placeholder: t('common.newEmailPlaceholder'),
        rule: () => (editEmail ? 'required|email|different:currentEmail' : ''),
        messages: {
          different: t('error.differentEmails'),
        },
      },
      oldPassword: {
        omitOnSubmit: !editPassword,
        label: t('common.currentPassword'),
        placeholder: () =>
          editPassword ? t('common.passwordPlaceholder') : '•••••',
        rule: () => (editPassword ? 'required' : ''),
      },
      password: {
        omitOnSubmit: !editPassword,
        label: t('common.newPassword'),
        placeholder: t('common.newPasswordPlaceholder'),
        rule: () =>
          editPassword
            ? [
                'required',
                'min:8',
                'regex:/[a-z]/',
                'regex:/[A-Z]/',
                'regex:/[0-9]/',
              ]
            : '',
      },
      passwordAgain: {
        omitOnSubmit: true,
        label: t('common.passwordAgain'),
        placeholder: t('common.passwordAgainPlaceholder'),
        help: t('profile.passwordConfirmHelp'),
        rule: () => (editPassword ? 'required|same:password' : ''),
      },
    },
    {
      async onSubmit(formdata, form) {
        try {
          const { data } = await updateOwnLogin({
            variables: { data: formdata },
          })
          if (data && data.updateOwnLogin) {
            form.onSuccess({ currentEmail: data.updateOwnLogin.email })
            history.push(`/${user.data.id}/profile`, location.state)
          }
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <Box
        maxWidth="100%"
        width={1077}
        ml="auto"
        mr="auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Observer>{() => <AppState loading={form.state.loading} />}</Observer>

        <Typography variant="h1" align="center">
          {t('profile.login')}
        </Typography>

        <Box display="flex" justifyContent="center" my={4}>
          <PrihlaseniIlustrace fontSize="large" />
        </Box>

        <Box maxWidth="100%" width={400}>
          <Box position="relative">
            <Observer>
              {() => (
                <TextField
                  fullWidth
                  gutterBottom={compactScreen ? false : true}
                  disabled
                  {...bind('currentEmail')}
                />
              )}
            </Observer>
            <Box
              position={compactScreen ? null : 'absolute'}
              left="100%"
              top="30px"
              ml={1}
            >
              {editEmail ? (
                <Button onClick={() => setEditEmail(false)}>
                  {t('common.cancel').toLowerCase()}
                </Button>
              ) : (
                <Button color="primary" onClick={() => setEditEmail(true)}>
                  {t('common.change').toLowerCase()}
                </Button>
              )}
            </Box>
          </Box>

          <Collapse in={editEmail}>
            <Observer>
              {() => (
                <TextField
                  fullWidth
                  gutterBottom
                  showValidationIcon
                  {...bind('email')}
                />
              )}
            </Observer>
          </Collapse>

          <Box position="relative">
            <Observer>
              {() => (
                <TextField
                  fullWidth
                  gutterBottom={compactScreen ? false : true}
                  type="password"
                  {...bind('oldPassword')}
                  disabled={!editPassword}
                />
              )}
            </Observer>

            <Box
              position={compactScreen ? null : 'absolute'}
              left="100%"
              top="30px"
              ml={1}
            >
              {editPassword ? (
                <Button onClick={() => setEditPassword(false)}>
                  {t('common.cancel').toLowerCase()}
                </Button>
              ) : (
                <Button color="primary" onClick={() => setEditPassword(true)}>
                  {t('common.change').toLowerCase()}
                </Button>
              )}
            </Box>
          </Box>

          <Collapse in={editPassword}>
            <>
              <Observer>
                {() => {
                  const password = form.getValue('password') || ''
                  const passwordLength = password && password.length > 7
                  const passwordHasDigit = (new RegExp('[0-9]', 'g')).test(password)
                  const passwordHasLowerUpper =
                    (new RegExp('[a-z]', 'g')).test(password) && (new RegExp('[A-Z]', 'g')).test(password) 

                  const passwordTouched = form.touched.get('password')
                  return (
                    <>
                      <TextField
                        fullWidth
                        gutterBottom
                        showValidationIcon
                        hideErrorText
                        type="password"
                        {...bind('password')}
                      />

                      <Typography
                        className={classnames(classes.passwordRule, {
                          [classes.green]: passwordLength,
                          [classes.red]: !passwordLength && passwordTouched,
                        })}
                      >
                        {passwordLength ? <CheckIcon /> : <ArrowIcon />}
                        {t('common.passwordRuleLength')}
                      </Typography>
                      <Typography
                        className={classnames(classes.passwordRule, {
                          [classes.green]: passwordHasLowerUpper,
                          [classes.red]:
                            !passwordHasLowerUpper && passwordTouched,
                        })}
                      >
                        {passwordHasLowerUpper ? <CheckIcon /> : <ArrowIcon />}
                        {t('common.passwordRuleUpperLower')}
                      </Typography>
                      <Typography
                        gutterBottom
                        className={classnames(classes.passwordRule, {
                          [classes.green]: passwordHasDigit,
                          [classes.red]: !passwordHasDigit && passwordTouched,
                        })}
                      >
                        {passwordHasDigit ? <CheckIcon /> : <ArrowIcon />}
                        {t('common.passwordRuleDigit')}
                      </Typography>
                    </>
                  )
                }}
              </Observer>

              <Observer>
                {() => (
                  <TextField
                    fullWidth
                    gutterBottom
                    showValidationIcon
                    type="password"
                    {...bind('passwordAgain')}
                  />
                )}
              </Observer>
            </>
          </Collapse>
        </Box>

        <Box textAlign="center" fontFamily="fontFamily" mt={3}>
          <Trans i18nKey="registration.securityNote">
            Své přihlašovací údaje si
            <Green>uschovejte</Green>a&nbsp;
            <Green>nesdílejte</Green>.
          </Trans>
        </Box>

        <Divider spacing={4} />

        <FormControls
          onSubmit={form.submit}
          onBack={() =>
            history.push(`/${user.data.id}/profile`, location.state)
          }
          submitContent={t('common.saveChanges')}
        />
        <Snackbars form={form} />
      </Box>
    </InsideLayout>
  )
}

export default EditLogin
