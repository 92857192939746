import {CUSTOMERS} from './gql'
import {customersPage_customers, customersPageVariables,} from '../../../../../../generated/customersPage'
import {CustomerOrderByInput, CustomerTypeEnum,} from '../../../../../../generated/globalTypes'
import {NastaveniGrey, PridatWhite, Smazat2Grey, Zablokovat,} from '../../../../../../components/icons/KubikIcons'
import {IDialog} from './index'
import {makeStyles} from '@material-ui/core/styles'
import {useTranslation} from 'react-i18next'
import {useUserStatusEnum} from '../../../../../../hooks/useEnumList'
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Datatable, {DatatableAction,} from '../../../../../../components/Datatable'
import get from 'lodash/get'
import moment from 'moment'
import React from 'react'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import Tabs, {Tab} from '../../../../../../components/Tabs'
import Typography from '@material-ui/core/Typography'
import useDatatableVariables from '../../../../../../hooks/useDatatableVariables'
import {useRouter, useUser} from '../../../../../../hooks'

type CustomerRows = NonNullable<customersPage_customers['items']>

const useStyles = makeStyles((theme) => ({
  actionIcon: {
    padding: theme.spacing(0.6),
    fontSize: '1.2rem',
    color: '#8a8a8a',
    fill: '#898989',
  },
  avatar: {
    backgroundColor: '#EEEEEE',
    color: theme.palette.common.black,
    fontWeight: 'bold',
    fontSize: '0.95rem',
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  invited: {
    color: '#F44336',
  },
}))

const defaultVariables: customersPageVariables = {
  skip: 0,
  first: 10,
  type: CustomerTypeEnum.ACTIVE,
}

interface IProps {
  setDialog: (args: IDialog) => void
  handleDialogClose: () => void
  globalKey: string
}

const Customers: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const { user } = useUser()
  const { setDialog, globalKey: GLOBAL_UNIQUE_KEY } = props
  const { history } = useRouter()

  const [datatableVariables, setDatatableVariables] = useDatatableVariables(
    GLOBAL_UNIQUE_KEY,
    defaultVariables,
  )

  const { t } = useTranslation()
  const handleTabChange = (type: CustomerTypeEnum) => {
    setDatatableVariables(
      () => ({
        ...defaultVariables,
        type,
      }),
      { resetSearch: true },
    )
  }
  const { translate } = useUserStatusEnum()

  const type: CustomerTypeEnum = get(datatableVariables, 'type')

  return (
    <Box
      maxWidth="100%"
      width={1077}
      ml="auto"
      mr="auto"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <ScrollToTop />
      <Typography variant="h1">{t('common.customers')}</Typography>
      <br />
      <Tabs
        onChange={handleTabChange}
        value={type}
        boxProps={{ mb: 2, width: '100%', justifyContent: 'center' }}
      >
        <Tab label={t('employees.active')} value="ACTIVE" />
        <Tab label={t('employees.blocked')} value="BLOCKED" color="#F44336" />
      </Tabs>
      <Datatable<CustomerRows, customersPageVariables>
        globalKey={GLOBAL_UNIQUE_KEY}
        query={{
          query: CUSTOMERS,
          dataField: 'customers',
          options: {
            fetchPolicy: 'network-only',
          },
        }}
        onRowClick={(row) => setDialog({ action: 'CHOICE', customer: row })}
        disableEven
        columns={[
          {
            key: 'inicials',
            shrink: true,
            align: 'center',
            render(row) {
              return (
                <Avatar className={classes.avatar}>
                  {row.name.substr(0, 1).toUpperCase()}
                </Avatar>
              )
            },
          },
          {
            key: 'name',
            label: t('common.customerName'),
            render(row) {
              return (
                <Box
                  component="span"
                  whiteSpace="pre-line"
                  minHeight={40}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  minWidth={200}
                >
                  <Box fontWeight="bold">{row.name}</Box>
                  {row.ico && (
                    <Box color="#8c8c8c">{`${row.ico} | ${row.address}`}</Box>
                  )}
                </Box>
              )
            },
          },
          {
            key: 'numberOfActiveUsers',
            label: t('reports.numberOfActiveUsers'),
            shrink: true,
            render(row) {
              return row.numberOfActiveUsers
            },
          },
          {
            key: 'activationFormDoneAt',
            label: t('superadmin.customersPage.activationFormDoneAt'),
            shrink: true,
            render(row) {
              return row.activationFormDoneAt
                ? moment(row.activationFormDoneAt).format('DD.MM.YYYY')
                : '--------'
            },
          },
          {
            key: 'dateOfActivation',
            label: t('superadmin.customersPage.activationDate'),
            shrink: true,
            render(row) {
              return row.activationAt
                ? moment(row.activationAt).format('DD.MM.YYYY')
                : 'DD.MM.YYYY'
            },
          },
          {
            key: 'createdAt',
            label: t('superadmin.customersPage.createdAt'),
            shrink: true,
            render(row) {
              return row.createdAt
                ? moment(row.createdAt).format('DD.MM.YYYY')
                : 'DD.MM.YYYY'
            },
          },
          {
            key: 'actions',
            align: 'right',
            shrink: true,
            render(row) {
              return (
                <Box
                  display="flex"
                  alignItems="normal"
                  justifyContent="flex-end"
                >
                  <DatatableAction
                    tooltip={'Nastavení nového roku'}
                    onClick={() =>
                      setDialog({ action: 'UPDATE', customer: row })
                    }
                    className={classes.actionIcon}
                  >
                    <NastaveniGrey fontSize="inherit" color="inherit" />
                  </DatatableAction>
                  <DatatableAction
                    tooltip={t(
                      row.blockedAt ? 'common.unblock' : 'common.block',
                    )}
                    onClick={() =>
                      setDialog({ action: 'BLOCK', customer: row })
                    }
                    className={classes.actionIcon}
                  >
                    <Zablokovat fontSize="inherit" color="inherit" />
                  </DatatableAction>
                  <DatatableAction
                    tooltip={t('common.remove')}
                    onClick={() =>
                      setDialog({ action: 'DELETE', customer: row })
                    }
                    className={classes.actionIcon}
                  >
                    <Smazat2Grey fontSize="inherit" color="inherit" />
                  </DatatableAction>
                </Box>
              )
            },
          },
        ]}
        renderToolbar={(elems) => (
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Box display="flex">
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  history.push(`/${user.data.id}/superadmin/customers/create`)
                }
              >
                <PridatWhite fontSize="inherit" />
                &nbsp;
                {t('superadmin.customersPage.addCustomer')}
              </Button>
            </Box>
            <Box
              display="flex"
              justifyContent="flex-start"
              ml={1}
              mr={1}
              minWidth={240} // TBD nějak ošetřit roztahování
            >
              {elems.orderByElement}
            </Box>
            <Box display="flex" flex="1" justifyContent="flex-end">
              {elems.searchElement}
            </Box>
          </Box>
        )}
        orderByList={[
          {
            value: CustomerOrderByInput.name_ASC,
            text: t('superadmin.customersPage.name_ASC'),
          },
          {
            value: CustomerOrderByInput.name_DESC,
            text: t('superadmin.customersPage.name_DESC'),
          },
          {
            value: CustomerOrderByInput.activationAt_ASC,
            text: t('superadmin.customersPage.dateOfActivation_ASC'),
          },
          {
            value: CustomerOrderByInput.activationAt_DESC,
            text: t('superadmin.customersPage.dateOfActivation_DESC'),
          },
        ]}
        searchFields={['search_contains']}
      />
    </Box>
  )
}

export default Customers
