import {gql} from 'apollo-boost'
import {NexusGenArgTypes, NexusGenFieldTypes} from 'kubik-server'
import {BASIC_REBATE_FULL_FRAGMENT} from '../../../../../../fragments/BasicRebate'
import {STUDENT_REBATE_FULL_FRAGMENT} from '../../../../../../fragments/StudentRebate'
import {CHILDREN_REBATE_FULL_FRAGMENT} from '../../../../../../fragments/ChildrenRebate'
import {DISABILITY_REBATE_FULL_FRAGMENT} from '../../../../../../fragments/DisabilityRebate'
import {ZTPP_REBATE_FULL_FRAGMENT} from '../../../../../../fragments/ZtppRebate'

export type TMonthlyRebate = NexusGenFieldTypes['MonthlyRebate']

export interface ISubmitMonthlyHistoryData {
  submitMonthlyHistory: Pick<
    NexusGenFieldTypes['MonthlyRebate'],
    'id' | 'submitedAt' | 'wizardStartedAt' | 'wizardFinishedAt'
  >
}
export type TSubmitMonthlyHistoryVariables = NexusGenArgTypes['Mutation']['submitMonthlyHistory']
export const SUBMIT_MONTHLY_HISTORY = gql`
  mutation submitMonthlyHistory($agreementAt: DateTime!) {
    submitMonthlyHistory(agreementAt: $agreementAt) {
      id
      submitedAt
      wizardStartedAt
      wizardFinishedAt
      # settlementRequest {
      #   id
      #   status
      # }
    }
  }
`

export interface IOwnMonthlyHistoryData {
  user: {
    monthlyHistory: NexusGenFieldTypes['MonthlyRebate']
  }
}
export const OWN_MONTHLY_HISTORY = gql`
  query userWithMonthlyHistoryRebate {
    user {
      id
      monthlyHistory {
        id
        year
        settlementRequest {
          id
          status
        }
        basicRebate {
          ...BasicRebateFull
        }
        basicRebates {
          ...BasicRebateFull
        }
        studentRebate {
          ...StudentRebateFull
        }
        studentRebates {
          ...StudentRebateFull
        }
        childrenRebate {
          ...ChildrenRebateFull
        }
        childrenRebates {
          ...ChildrenRebateFull
        }
        disabilityRebate {
          ...DisabilityRebateFull
        }
        disabilityRebates {
          ...DisabilityRebateFull
        }
        ztppRebate {
          ...ZtppRebateFull
        }
        ztppRebates {
          ...ZtppRebateFull
        }
      }
    }
  }

  ${BASIC_REBATE_FULL_FRAGMENT}
  ${STUDENT_REBATE_FULL_FRAGMENT}
  ${CHILDREN_REBATE_FULL_FRAGMENT}
  ${DISABILITY_REBATE_FULL_FRAGMENT}
  ${ZTPP_REBATE_FULL_FRAGMENT}
`
