import React from 'react'
import {useTranslation} from 'react-i18next'

import Button from '@material-ui/core/Button'

import Dialog from '../../../../../components/Dialog'
import DialogActions from '../../../../../components/DialogActions'
import DialogText from '../../../../../components/DialogText'
import DialogTitle from '../../../../../components/DialogTitle'
import {Center, IconCircle} from '../../../../../components/CommonBox'
import {OpravduSmazat} from '../../../../../components/icons/KubikIcons'
import { useUser } from '../../../../../hooks'

interface IProps {
  open: boolean
  onClose: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: () => Promise<any>
  type?: 'ANNUAL' | 'MONTHLY'
}
const CancelRequestDialog: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { refetch } = useUser()
  const { onClose, open, onSubmit, type } = props

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      scroll="body"
      transitionDuration={{
        exit: 80,
      }}
    >
      <DialogTitle onClose={onClose}>
        <Center mb={2}>
          <IconCircle fontSize="3rem">
            <OpravduSmazat fontSize="inherit" color="primary" />
          </IconCircle>
        </Center>
        {t('taxRebates.cancelRequestDialog', { context: type })}
      </DialogTitle>

      <DialogText align="center">
        {t('taxRebates.cancelRequestDialog_SUBTEXT', { context: type })}
      </DialogText>

      <DialogActions dividerProps={{ mt: 3 }} gutterBottom>
        <Button
          onClick={async () => {
            await onSubmit()
            await refetch()
          }}
          color="primary"
          size="large"
          variant="contained"
        >
          {t('common.enter')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CancelRequestDialog
