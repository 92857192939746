
import { Observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import React, {useState} from 'react'
import {useMutation, useQuery} from "react-apollo";
import {handleDownload} from "../../pages/Inside/pages/TaxRebates";
import {CHECK_MONTHLY_PDF_FORM, GENERATE_MONTHLY_PDF_FORM} from "./gql";
import {TMonthlyRebate, TFile} from "../../pages/Inside/pages/TaxRebates/gql";
import { FileButton } from '../../shared/AnnualPdfDownload/AnnualPdfFormDownload';

export type MonthlyRebateWithFile = Pick<TMonthlyRebate, 'id'> & {
  file: Pick<TFile, 'id'> | null
}

interface IProps {
  monthlyRebate: MonthlyRebateWithFile,
}

const hasSomeFile = (rebate: MonthlyRebateWithFile): boolean => {
  return !!rebate && !!rebate.file && !!rebate.file.id
}

const hasDifferentFiles = (oldRequest: MonthlyRebateWithFile, newRequest: MonthlyRebateWithFile): boolean => {
  return !!newRequest && !!oldRequest && (
      (oldRequest && newRequest && (oldRequest.file ? oldRequest.file.id : null) !== (newRequest.file ? newRequest.file.id : null))
  )
}

const download = (rebate: MonthlyRebateWithFile) => {
  rebate.file && rebate.file.id && handleDownload({id: rebate.file.id})
}

const MonthlyPdfFormDownload: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const [rebate, setRebate] = useState<MonthlyRebateWithFile & { shouldHaveMonthlyForm?: boolean }>(props.monthlyRebate)
  const [loadingBull, setLoadingBull] = useState(false)

  const { startPolling, stopPolling } = useQuery<{monthlyRebate: MonthlyRebateWithFile}>(CHECK_MONTHLY_PDF_FORM, {
    variables: { where: { id: rebate.id } },
    skip: !loadingBull,
    fetchPolicy: 'no-cache',
    pollInterval: 2500,
    notifyOnNetworkStatusChange: true,
    onError(e){
      console.error(e)
    },
    onCompleted(response) {
      const monthlyRebate = response && response.monthlyRebate
      if (monthlyRebate && hasSomeFile(monthlyRebate) && hasDifferentFiles(rebate, monthlyRebate)) {
        stopPolling()
        setLoadingBull(false)
        setRebate({
          ...monthlyRebate,
          shouldHaveMonthlyForm: true
        })
        download(monthlyRebate)
      }
    },
  })

  const [requestGenerateForms] = useMutation(GENERATE_MONTHLY_PDF_FORM, {
    variables: {where: { id: props.monthlyRebate.id }},
    onError: () => {
      setLoadingBull(false)
    },
    onCompleted: () => {
      startPolling(2500)
    }
  })

  const generateForms = async () => {
    setLoadingBull(true)
    await requestGenerateForms()
  }

  if (!rebate.shouldHaveMonthlyForm) {
    return null
  }

  return (
    <Observer>
      {() =>
        (
          <>
            {rebate && rebate.file && rebate.file.id && !loadingBull &&
              <FileButton
                onClick={() => rebate.file && handleDownload({id: rebate.file.id})}>
                {t('taxRebates.downloadButton')}
              </FileButton>
            }

            {rebate && !hasSomeFile(rebate) &&
              <FileButton
                loading={loadingBull}
                onClick={async () => await generateForms()}
              >
                {t('taxRebates.downloadButton')}
              </FileButton>
            }
          </>
        )
      }
    </Observer>
  )
}

export default MonthlyPdfFormDownload
