import InsideLayout from '../../../../components/layouts/InsideLayout'
import React from 'react'
import {Route, RouteComponentProps, Switch} from "react-router-dom";
import EmployeeProfile from "../Employees/EmployeeProfile";
import EmployeeForms from "../Employees/EmployeeForms";
import EmailsTable from "./Emails";

const GLOBAL_UNIQUE_KEY = 'EmailsDatatable'

interface IUnit {
  id: string
  name: string
  color: string
}


const Emails: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  return (
    <InsideLayout sidebar>
      <Switch>
        <Route
            path="/:userId/employees/:employeeId/profile"
            component={EmployeeProfile}
        />
        <Route
            path="/:userId/employees/:employeeId/forms"
            component={EmployeeForms}
        />
        <Route
            render={() => (
                <EmailsTable
                    globalKey={GLOBAL_UNIQUE_KEY}
                    {...props}
                />
            )}
        />
      </Switch>

    </InsideLayout>
  )
}

export default Emails
