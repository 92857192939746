import {
  DokonceniRegistrace,
  NastaveniSpolecnosti,
  UdajeSpolecnosti,
  ZalozeniZamestnancu,
} from '../../../../components/icons/KubikIcons'
import {CUSTOMER_UNITS_ROLES_EMPLOYEES, ICustomerData} from './gql'
import {createNewCustomer} from '../../../../generated/createNewCustomer'
import {makeStyles, styled, Theme} from '@material-ui/core/styles'
import {useQuery} from 'react-apollo'
import {useTranslation} from 'react-i18next'
import OutsideLayout from '../../../../components/layouts/OutsideLayout'
import ProgressContainer from '../../../../components/ProgressContainer'
import React, {useState} from 'react'
import Step from '@material-ui/core/Step'
import StepFour from './StepFour'
import StepLabel from '@material-ui/core/StepLabel'
import StepOne from './StepOne'
import Stepper from '@material-ui/core/Stepper'
import StepThree from './StepThree'
import StepTwo from './StepTwo'
import SetGlobalState from '../../../../components/SetGlobalState'
import useUser from '../../../../hooks/useUser'

const Container = styled('div')({
  maxWidth: 960,
  marginRight: 'auto',
  marginLeft: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const useStyles = makeStyles((theme: Theme) => ({
  stepper: {
    // width: '100%',
    marginTop: -theme.spacing(4),
  },
}))

interface IProps {
  newCustomer?: boolean
  children?: React.ReactNode
}

const Layout: React.FC<IProps> = (props: IProps) => {
  const { children, newCustomer } = props
  return (
    <>
      {newCustomer ? (
        <div>{children}</div>
      ) : (
        <OutsideLayout>{children}</OutsideLayout>
      )}
    </>
  )
}

const CustomerInactive: React.FC<IProps> = (props: IProps) => {
  const { newCustomer } = props || false
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useUser()
  const [activeStep, setActiveStep] = useState(0)
  const [initialized, setInitialized] = useState(false)
  const [newCustomerData, setCustomerData] = useState<
    createNewCustomer['createNewCustomer'] | null | undefined
  >()

  const { data, loading, error } = useQuery<ICustomerData>(
    CUSTOMER_UNITS_ROLES_EMPLOYEES,
    { skip: newCustomer },
  )
  /*React.useEffect(() => {
    console.log('effect',data)
  }, [data])*/

  if (!initialized && data && data.customer) {
    setActiveStep(data.customer.activationFormStep || 0)
    setInitialized(true)
  }

  function refreshComponentsTree(
    newData: createNewCustomer['createNewCustomer'],
  ) {
    setCustomerData(newData)
  }
  function handleNext(newData?: createNewCustomer['createNewCustomer']) {
    if (newData) {
      setCustomerData(newData)
    }
    setActiveStep((prevActiveStep) =>
      prevActiveStep === 3 ? 3 : prevActiveStep + 1,
    )
    window.scrollTo(0, 0)
  }
  function handlePrev() {
    setActiveStep((prevActiveStep) =>
      prevActiveStep === 0 ? 0 : prevActiveStep - 1,
    )
    window.scrollTo(0, 0)
  }

  return (
    <Layout newCustomer={newCustomer}>
      {/* zduplikovaný Container v Layout a zde -> nějaká logika {newCustomer}*/}
      <SetGlobalState property="lovers" value={user.data.customer.name} />
      <ProgressContainer loading={loading} error={!!error} fullWidth>
        <Container>
          <Stepper
            className={classes.stepper}
            activeStep={activeStep}
            nonLinear
          >
            <Step completed={activeStep === 0}>
              <StepLabel icon={<UdajeSpolecnosti fontSize="large" />}>
                {t('customerActivation.step1Heading')}
              </StepLabel>
            </Step>
            <Step completed={activeStep === 1}>
              <StepLabel icon={<NastaveniSpolecnosti fontSize="large" />}>
                {t('customerActivation.step2Heading')}
              </StepLabel>
            </Step>
            <Step completed={activeStep === 2}>
              <StepLabel icon={<ZalozeniZamestnancu fontSize="large" />}>
                {t('customerActivation.step3Heading')}
              </StepLabel>
            </Step>
            <Step completed={activeStep === 3}>
              <StepLabel icon={<DokonceniRegistrace fontSize="large" />}>
                {t('customerActivation.step4Label')}
              </StepLabel>
            </Step>
          </Stepper>
          {(data || newCustomer) && activeStep === 0 && (
            <StepOne
              nextStep={handleNext}
              data={data}
              newCustomer={newCustomer}
              customer={newCustomerData}
            />
          )}
          {(data || newCustomerData) && activeStep === 1 && (
            <StepTwo
              nextStep={handleNext}
              prevStep={handlePrev}
              data={data && data}
              customer={newCustomerData}
            />
          )}
          {(data || newCustomerData) && activeStep === 2 && (
            <StepThree
              nextStep={handleNext}
              prevStep={handlePrev}
              data={data && data}
              customer={newCustomerData}
            />
          )}
          {(data || newCustomerData) && activeStep === 3 && (
            <StepFour
              nextStep={handleNext}
              prevStep={handlePrev}
              data={data && data}
              customer={newCustomerData}
            />
          )}
        </Container>
      </ProgressContainer>
    </Layout>
  )
}

export default CustomerInactive
