import {apiUrl} from '../../../../../utils'
import {useUser} from '../../../../../hooks'
import React from 'react'
import Typography from '@material-ui/core/Typography'

export interface IReportLinkProps {
  report: string
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

const ReportLink: React.FC<IReportLinkProps> = props => {
  const { user } = useUser()

  const getReportUrl = (reportName: string) =>
    apiUrl(`api/${user.data.id}/reports/${reportName}`)

  return (
    <li>
      <Typography>
        <a
          href={getReportUrl(props.report)}
          target="_blank"
          rel="noopener noreferrer"
          onClick={props.onClick}
        >
          {props.children}
        </a>
      </Typography>
    </li>
  )
}

export default ReportLink
