import { gql } from 'apollo-boost'
import { NexusGenFieldTypes } from 'kubik-server'

export type TPensionInsuranceRebate = NexusGenFieldTypes['PensionInsuranceRebate']

export const PENSION_INSURANCE_REBATE_FULL_FRAGMENT = gql`
  fragment PensionInsuranceRebateFull on PensionInsuranceRebate {
    id
    createdAt
    status
    type
    commentOfDenied
    feeAmount
    feeAmountAdditionalPart
    feeAmountContributionPart
    feeAmountSpecializedPart
    feeConfirmationFiles {
      id
      filename
      size
      createdAt
    }
    contractConfirmationFiles {
      id
      filename
      size
      createdAt
    }
    settlementRequest {
      id
      requestedAt
      submittedAt
    }
  }
`
