import {NEW_CUSTOMER_ROLES} from '../../../CustomerActivation/gql'
import {
  DokonceniRegistrace,
  ManualnePridat,
  RegistraceZamestnance as ZamestnancuvProfil,
  SlevyGreen,
  Smazat2 as Zavrit,
  UdajeSpolecnosti as SeznamFormularu,
} from '../../../../../../components/icons/KubikIcons'
import {customersPage_customers_items,} from '../../../../../../generated/customersPage'
import {superadminUsers, superadminUsersVariables,} from '../../../../../../generated/superadminUsers'
import {Role} from '../../../../../../generated/globalTypes'
import {Center, IconCircle} from '../../../../../../components/CommonBox'
import {Link} from 'react-router-dom'
import {makeStyles} from '@material-ui/core/styles'
import {useTranslation} from 'react-i18next'
import CenteredTextFields from '../../../../../../components/CenteredTextFields'
import Box from '@material-ui/core/Box'
import classnames from 'classnames'
import Dialog from '../../../../../../components/Dialog'
import IconButton from '@material-ui/core/IconButton'
import ArrowForward from '@material-ui/icons/ArrowForward'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import useUser from '../../../../../../hooks/useUser'
import {useCountryEnum} from '../../../../../../hooks/useEnumList'
import Grid from '@material-ui/core/Grid'
import {useQuery} from 'react-apollo'

const useStyles = makeStyles((theme) => ({
  heading: {
    color: '#9E9E9E',
  },
  gutterBottom: {
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(4),
    overflowX: 'hidden'
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.15)',
    borderRadius: '5px',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '3rem',
  },
  label: {
    marginLeft: theme.spacing(3),
    display: 'flex',
    flex: 1,
    fontWeight: 700,
    fontSize: '1.1rem',
  },
  link: {
    fontSize: '1.2rem',
  },
  closeIconButton: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
    padding: theme.spacing(1),
    fontSize: '1rem',
  },
  infoContainer: {
    margin: theme.spacing(3, 0),
    paddingRight: theme.spacing(3),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  left: {
    paddingRight: 4,
  },
  right: {
    paddingLeft: 4,
  },
}))
interface IProps {
  open: boolean
  customer: customersPage_customers_items | null
  onClose: () => void
}

interface IOptionLink {
  label: string
  icon: any
  url: string
  close: () => void
  gutterBottom?: boolean
}
interface IOptionButton {
  label: string
  icon: any
  onClick: () => void
  gutterBottom?: boolean
}

const Option: React.FC<IOptionLink | IOptionButton> = (props) => {
  const classes = useStyles()
  const { label, icon, gutterBottom } = props

  return (
    <Box
      className={classnames(classes.option, {
        [classes.gutterBottom]: gutterBottom,
      })}
    >
      <div className={classes.icon}>{icon}</div>
      <Typography className={classes.label}>{label}</Typography>
      {'url' in props && (
        <IconButton
          className={classes.link}
          to={props.url}
          onClick={props.close}
          component={Link}
        >
          <ArrowForward color="primary" />
        </IconButton>
      )}
      {'onClick' in props && (
        <IconButton className={classes.link} onClick={props.onClick}>
          <ArrowForward color="primary" />
        </IconButton>
      )}
    </Box>
  )
}

const CustomerChoice: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { translate: translateCountry } = useCountryEnum()
  const { user } = useUser()
  const { open, customer, onClose } = props

  const { data: rolesData, loading } = useQuery<
    superadminUsers,
    superadminUsersVariables
  >(NEW_CUSTOMER_ROLES, {
    variables: {
      where: {
        customerId: customer && customer!.id!,
        role: Role.KEEPER,
      },
    },
    skip: !customer,
  })

  const finishRegistration =
    (customer && customer.activationFormStep < 3) ||
    (rolesData &&
      rolesData.superadminUsers &&
      rolesData.superadminUsers[0] &&
      !rolesData.superadminUsers[0].invitedAt)

  return (
    <Dialog
      open={Boolean(open && customer)}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      classes={{ paper: classes.paper }}
    >
      <IconButton onClick={onClose} className={classes.closeIconButton}>
        <Zavrit fontSize="inherit" />
      </IconButton>
      {customer && (
        <>
          <Typography
            variant="h3"
            align="center"
            gutterBottom
            className={classes.heading}
          >
            {t('companySettings.heading')}
          </Typography>

          <Center>
            <IconCircle fontSize="3.5rem" padding={2} my={2}>
              <ManualnePridat fontSize="inherit" />
            </IconCircle>
          </Center>

          <Typography variant="h2" align="center">
            {customer.name}
          </Typography>

          <Typography align="center" className={classes.marginTop}>
            {customer.address}
          </Typography>

          {/* <Grid container spacing={1} className={classes.infoContainer}>
            <CenteredTextFields
              label={t('common.address')}
              text={customer.address}
            />
          </Grid> */}

          <Grid container spacing={1} className={classes.infoContainer}>
            <CenteredTextFields label={t('common.ico')} text={customer.ico} />
          </Grid>

          <Option
            label={t('companySettings.subHeading1')}
            icon={<ZamestnancuvProfil fontSize="inherit" />}
            url={`/${user.data.id}/superadmin/customers/${customer.id}/edit-data`}
            close={onClose}
            gutterBottom
          />

          <Option
            label={t('companySettings.subHeading2')}
            icon={<SeznamFormularu fontSize="inherit" />}
            url={`/${user.data.id}/superadmin/customers/${customer.id}/edit-settings`}
            close={onClose}
            gutterBottom
          />

          <Option
            label={t('companySettings.subHeading3')}
            icon={<SlevyGreen fontSize="inherit" />}
            url={`/${user.data.id}/superadmin/customers/${customer.id}/import-employees`}
            close={onClose}
            gutterBottom={finishRegistration}
          />

          {finishRegistration && (
            <Option
              label={t('common.finishRegistration')}
              icon={<DokonceniRegistrace fontSize="inherit" />}
              url={`/${user.data.id}/superadmin/customers/${customer.id}/finish`}
              close={onClose}
            />
          )}
        </>
      )}
    </Dialog>
  )
}

export default CustomerChoice
