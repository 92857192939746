import {makeStyles} from '@material-ui/core/styles'
import {useTranslation} from 'react-i18next'
import Box from '@material-ui/core/Box'
import {Button, Grid} from '@material-ui/core'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import React from 'react'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import Typography from '@material-ui/core/Typography'
import {useQuery} from "@apollo/react-hooks";

import {Observer} from "mobx-react";
import Select from "../../../../../../components/form/Select";
import useForm from "../../../../../../hooks/useForm";
import {CUSTOMERS} from "../../../Statistics/gql";
import get from "lodash/get";
import Loader from "../../../../../../components/Loader";
import {useMutation} from "react-apollo";
import {GET_OR_CREATE_API_KEY} from "./gql";

const useStyles = makeStyles((theme) => ({}))

const SuperadminApiSettings: React.FC = () => {
    const {t} = useTranslation()


    const {data: customersData, loading: customersLoading, error} = useQuery(
        CUSTOMERS,
        {
            fetchPolicy: 'cache-and-network',
        },
    )
    const custData = get(customersData, 'customersList', [])
    const customers = custData.sort((a: any, b: any) =>
        a.name > b.name ? 1 : -1,
    )


    const [
        getApiKey,
        { loading, error: updateError, data },
    ] = useMutation(GET_OR_CREATE_API_KEY, { onError() {} })

    const {bind, form} = useForm<{
        customer: { id: string; text: string }[]
    }>(
        {
            customers: []
        },
        {
            customer: {
                placeholder: t('common.customer', { context: 'SELECT' }),
                list: customers,
                rule: 'required'
            }
        },
        {

            async onSubmit(formData, form) {
                getApiKey({
                    variables: {
                        where: {
                            customer: form.getData().customer
                        }
                    }
                })
            }
        },
    )
    const apiKeyData = data? data.getOrCreateApiKey : undefined
    

    return (
        <InsideLayout sidebar>
            <ScrollToTop/>
            <Box
                maxWidth="100%"
                width={1077}
                ml="auto"
                mr="auto"
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h1" align="center">
                    {t('common.api')}
                </Typography>

                <Observer>
                    {() => {
                        return (
                            <>
                                <h2>API klíče</h2>
                                <Grid style={{padding: '2rem 0'}} spacing={1} container>
                                    <Grid sm={4} item>
                                        <Select
                                            {...bind('customer')}
                                            textField="name"
                                            fullWidth

                                        />
                                    </Grid>
                                    <Grid sm={4} item>

                                    <Button
                                        onClick={async () => {
                                            form.submit()
                                        }}
                                        type="submit"
                                        color="primary"
                                        size="large"
                                        variant="contained">
                                        Zobrazit nebo vytvořit
                                    </Button>
                                    </Grid>
                                </Grid>

                                <Loader loading={loading || false}/>
                                {!!apiKeyData && <>
                                    <h2>{apiKeyData.customer.name}</h2>
                                    <h2>{apiKeyData.apiKey}</h2>

                                </>}

                            </>
                        )
                    }}
                </Observer>
            </Box>
        </InsideLayout>
    )
}
    export default SuperadminApiSettings