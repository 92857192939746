import {makeStyles, useTheme} from '@material-ui/core/styles'
import {ICustomerData, IUnitsData, NEW_CUSTOMER_ROLES, NEW_CUSTOMER_UNITS, UNITS,} from './gql'
import {superadminUnits, superadminUnitsVariables,} from '../../../../generated/superadminUnits'
import {
  superadminUsers,
  superadminUsers_superadminUsers,
  superadminUsersVariables,
} from '../../../../generated/superadminUsers'
import {Role} from '../../../../generated/globalTypes'
import {createNewCustomer} from '../../../../generated/createNewCustomer'
import {useQuery} from 'react-apollo-hooks'
import {useRoleEnum} from '../../../../hooks/useEnumList'
import {useTranslation} from 'react-i18next'
import AddIcon from '@material-ui/icons/Add'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import classnames from 'classnames'
import CloseIcon from '@material-ui/icons/Close'
import Divider from '../../../../components/Divider'
import EditIcon from '@material-ui/icons/Edit'
import Grid from '@material-ui/core/Grid'
import Help from '../../../../components/Help'
import moment from 'moment'
import React from 'react'
import RoleBlockDialog from './RoleBlock'
import RoleCreateDialog from './RoleCreate'
import RoleChangeKeeperDialog from './RoleChangeKeeper'
import RoleRemoveDialog from './RoleRemove'
import RoleUpdateDialog from './RoleUpdate'
import Typography from '@material-ui/core/Typography'
import useUser from '../../../../hooks/useUser'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles((theme) => ({
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  shadow: {
    boxShadow: theme.shadows[1],
  },
  notLastUnit: {
    maxWidth: theme.spacing(5),
    marginRight: -3,
    display: 'inline-block',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    /* overflow: hidden; */
  },
  marginBetween: {
    display: 'flex',
    flexWrap: 'nowrap',
    '& > *': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  label: {
    fontWeight: 'bold',
  },
  overflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

type TRole = Omit<superadminUsers_superadminUsers, '__typename'>

interface IProps {
  roles: TRole[]
  customer:
    | ICustomerData['customer']
    | createNewCustomer['createNewCustomer']
    | undefined
    | null
  blockRole?: boolean
}

const Roles: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)

  const { translate: translateRole } = useRoleEnum()
  const { user } = useUser()

  const isNewCustomer = user.isSuperadmin()

  const { data } = useQuery<IUnitsData>(UNITS, { skip: isNewCustomer })

  const { data: unitsData } = useQuery<
    superadminUnits,
    superadminUnitsVariables
  >(NEW_CUSTOMER_UNITS, {
    variables: {
      where: { customerId: (props.customer && props.customer.id) || '' },
    },
    skip: !isNewCustomer,
  })

  const { data: rolesData } = useQuery<
    superadminUsers,
    superadminUsersVariables
  >(NEW_CUSTOMER_ROLES, {
    variables: {
      where: {
        customerId: (props.customer && props.customer.id) || '',
        role: Role.KEEPER,
        blockedAt: null,
      },
    },
    skip: !isNewCustomer,
  })

  const units = isNewCustomer
    ? (unitsData && unitsData.superadminUnits) || []
    : (data && data.units) || []

  const [createDialogOpen, setCreateDialogOpen] = React.useState(false)
  const handleCreateDialogOpen = () => setCreateDialogOpen(true)
  const handleCreateDialogClose = () => setCreateDialogOpen(false)

  const [changeKeeperDialogOpen, setChangeKeeperDialogOpen] = React.useState(
    false,
  )
  const handleChangeKeeperDialogOpen = () => setChangeKeeperDialogOpen(true)
  const handleChangeKeeperDialogClose = () => setChangeKeeperDialogOpen(false)

  const [removeRole, setRemoveDialogOpen] = React.useState<TRole | null>(null)
  const factoryHandleRemoveDialogOpen = (role: TRole) => () =>
    setRemoveDialogOpen(role)
  const handleRemoveDialogClose = () => setRemoveDialogOpen(null)

  const [updateRole, setUpdateDialogOpen] = React.useState<TRole | null>(null)
  const factoryHandleUpdateDialogOpen = (role: TRole) => () =>
    setUpdateDialogOpen(role)
  const handleUpdateDialogClose = () => setUpdateDialogOpen(null)

  const getKeeperInfo = () => {
    let result
    if (isNewCustomer && rolesData && rolesData.superadminUsers && rolesData.superadminUsers.length) {
      const keeper = rolesData.superadminUsers[0]
      result = {
        id: keeper.id,
        firstname: keeper.firstname,
        lastname: keeper.lastname,
        email: keeper.email,
        role: keeper.role,
        language: keeper.language,
      }
    } else {
      result = {
        id: user.data.id,
        firstname: user.data.firstname,
        lastname: user.data.lastname,
        email: user.data.email,
        role: user.data.role,
      }
    }
    return result
  }

  return (
    <Grid direction="column" item container spacing={compactScreen ? 0 : 2}>
      <Box display="flex" justifyContent="space-between">
        <Typography className={classes.label}>
          {t('customerOptions.addingRoles')}
        </Typography>
        <Help text={t('customerOptions.addingRolesHelp')} />
      </Box>

      <Divider spacing={2} />

      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        direction="row"
        wrap="nowrap"
      >
        <Box display="flex" flexWrap="wrap" alignItems="center">
          <Box display="flex" alignItems="center" mr={2}>
            <Box width="145px" mr={2}>
              <Typography className={classes.overflow}>
                {`${getKeeperInfo().firstname} ${getKeeperInfo().lastname}`}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.overflow}
              >
                {getKeeperInfo().email}
              </Typography>
            </Box>
            <Typography>{t('role.keeper')}</Typography>
          </Box>
        </Box>

        {user.data.customer.activationFormDoneAt && !isNewCustomer && (
          <Grid item>
            <Button onClick={handleChangeKeeperDialogOpen}>
              {t('customerOptions.changeKeeper').toLowerCase()}
              <EditIcon className={classes.marginLeft} />
            </Button>
          </Grid>
        )}

        {user.data.customer.activationFormDoneAt && user.isSuperadmin() && (
          <Grid item>
            <Button
              onClick={factoryHandleUpdateDialogOpen(getKeeperInfo() as TRole)}
            >
              {t('common.edit').toLowerCase()}
              <EditIcon className={classes.marginLeft} />
            </Button>
          </Grid>
        )}
      </Grid>

      {props.roles
        .filter((role) => {
          let result = false
          if (role.role === 'KEEPER') {
            return result
          }
          if (role.blockedAt) {
            result = moment(role.blockedAt).isAfter()
          } else {
            result = true
          }
          return result
        })
        .map((role) => (
          <Grid
            key={role.id}
            container
            item
            justifyContent="space-between"
            alignItems="center"
            direction="row"
            wrap="nowrap"
          >
            <Box display="flex" flexWrap="wrap" alignItems="center">
              <Box display="flex" alignItems="center" mr={2}>
                <Box width="145px" mr={2}>
                  <Typography
                    className={classes.overflow}
                  >{`${role.firstname} ${role.lastname}`}</Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    className={classes.overflow}
                  >
                    {role.email}
                  </Typography>
                </Box>
                <Typography>{translateRole(role.role)}</Typography>
              </Box>

              {props.customer && props.customer.divideByUnits && (
                <Box overflow="hidden" display="flex">
                  {(role.managedUnits || []).map(
                    (unit: any, idx: any, arr: any) => (
                      <Box
                        display="inline"
                        whiteSpace="nowrap"
                        key={unit.id}
                        fontFamily="fontFamily"
                        // ml={idx > 0 ? -4 : 0}
                        className={classnames({
                          [classes.shadow]: idx > 0,
                          [classes.notLastUnit]: idx + 1 < arr.length,
                        })}
                        borderRadius={6}
                        px={2}
                        py={1}
                        bgcolor={unit.color}
                        color={theme.palette.getContrastText(unit.color)}
                      >
                        {unit.name}
                      </Box>
                    ),
                  )}
                </Box>
              )}
            </Box>

            <Grid item>
              <div className={classes.marginBetween}>
                <Button onClick={factoryHandleUpdateDialogOpen(role)}>
                  {t('common.edit').toLowerCase()}
                  <EditIcon className={classes.marginLeft} />
                </Button>
                <Button onClick={factoryHandleRemoveDialogOpen(role)}>
                  {props.blockRole
                    ? !!role.blockedAt
                      ? t('common.unblock').toLowerCase()
                      : t('common.block').toLowerCase()
                    : t('common.remove').toLowerCase()}
                  <CloseIcon className={classes.marginLeft} />
                </Button>
              </div>
            </Grid>
          </Grid>
        ))}
      <Grid item>
        <Button variant="outlined" onClick={handleCreateDialogOpen} fullWidth>
          <AddIcon />
          {t('customerOptions.addRole')}
        </Button>
      </Grid>

      <RoleCreateDialog
        customer={props.customer}
        units={units}
        open={createDialogOpen}
        onClose={handleCreateDialogClose}
      />

      <RoleChangeKeeperDialog
        open={changeKeeperDialogOpen}
        onClose={handleChangeKeeperDialogClose}
      />

      <RoleUpdateDialog
        customer={props.customer}
        open={Boolean(updateRole)}
        role={updateRole}
        units={units}
        onClose={handleUpdateDialogClose}
      />

      {props.blockRole ? (
        <RoleBlockDialog
          open={Boolean(removeRole)}
          role={removeRole}
          onClose={handleRemoveDialogClose}
        />
      ) : (
        <RoleRemoveDialog
          customer={props.customer}
          open={Boolean(removeRole)}
          role={removeRole}
          onClose={handleRemoveDialogClose}
        />
      )}
    </Grid>
  )
}

export default Roles
