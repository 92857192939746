import {MonthlyWizardStep} from '../../../../generated/globalTypes'
import {Redirect, Route, Switch} from 'react-router-dom'
import {useUser} from '../../../../hooks'
import BasicRebate from './pages/BasicRebate'
import DisabilityRebate from './pages/DisabilityRebate'
// import FinishWizard from './pages/FinishWizard'
import ChildrenRebate from './pages/ChildrenRebate'
import Intro from './pages/Intro'
import React from 'react'
import Root from './pages/Root'
import StudentRebate from './pages/StudentRebate'
import Summary from './pages/Summary'
import ZtppRebate from './pages/ZtppRebate'
import TaxResidence from './pages/TaxResidence'
import Questionnaire from './pages/Questionnaire'

const Rebates = () => {
  const { user } = useUser()
  const monthlyRebate = user.data.monthlyRebate

  if (
    monthlyRebate &&
    monthlyRebate.settlementRequest &&
    monthlyRebate.settlementRequest.status === 'NEW'
  ) {
    return <Redirect to={`/${user.data.id}/overview`} />
  }

  const redirectTo = () => {
    const {
      data: { monthlyRebate },
    } = user

    const redirectUrl = `/${user.data.id}/monthly-rebates`

    if (!monthlyRebate) {
      return redirectUrl + '/intro'
    } else {
      switch (monthlyRebate.wizardStep) {
        case MonthlyWizardStep.QUESTIONNAIRE:
          return redirectUrl + '/questionnaire'
        case MonthlyWizardStep.REBATE:
          return redirectUrl + '/root'
        case MonthlyWizardStep.SUMMARY:
          return redirectUrl + '/summary'
        case MonthlyWizardStep.TAX_RESIDENCE:
        default:
          return user.shouldHaveResidenceStep()
            ? redirectUrl + '/taxResidence'
            : redirectUrl + '/questionnaire'
      }
    }
  }

  return (
    <Switch>
      {user.shouldHaveResidenceStep() && (
        <Route
          path="/:userId/monthly-rebates/taxResidence"
          component={TaxResidence}
        />
      )}
      <Route
        path="/:userId/monthly-rebates/questionnaire"
        component={Questionnaire}
      />
      <Route
        path="/:userId/monthly-rebates/basic-rebate"
        component={BasicRebate}
      />
      <Route
        path="/:userId/monthly-rebates/student-rebate"
        component={StudentRebate}
      />
      <Route
        path="/:userId/monthly-rebates/disability-rebate"
        component={DisabilityRebate}
      />
      <Route
        path="/:userId/monthly-rebates/children-rebate"
        component={ChildrenRebate}
      />
      <Route
        path="/:userId/monthly-rebates/ztpp-rebate"
        component={ZtppRebate}
      />
      <Route path="/:userId/monthly-rebates/summary" component={Summary} />
      {/* <Route
        path="/:userId/monthly-rebates/finish-wizard"
        component={FinishWizard}
      /> */}
      <Route path="/:userId/monthly-rebates/root" component={Root} />
      <Route path="/:userId/monthly-rebates/intro" component={Intro} />

      <Redirect to={redirectTo()} />
    </Switch>
  )
}

export default Rebates
