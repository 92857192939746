import {
  IOwnAnnualInvestmentRebateData,
  IResetOwnAnnualInvestmentData,
  IUpdateOwnAnnualInvestmentRebateData,
  OWN_ANNUAL_INVESTMENT_REBATE,
  RESET_OWN_ANNUAL_INVESTMENT_REBATE,
  TInvestmentRebate,
  TUpdateOwnAnnualInvestmentRebateVariables,
  UPDATE_OWN_ANNUAL_INVESTMENT_REBATE,
} from './gql'
import {Bold, Center, Divider, Green,} from '../../../../../../components/CommonBox'
import ChangeRebateButton, {ChangeRebateChoice,} from '../../components/ChangeRebateButton'
import {getRebatesWithExtra} from '../../../../../../fragments/utils'
import {Observer} from 'mobx-react'
import {pickId} from '../../../../../../utils'
import {TMutationFunction} from '../../../../../../types'
import {useMutation, useQuery} from 'react-apollo'
import {Trans, useTranslation} from 'react-i18next'
import AppState from '../../../../../../components/AppState'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import Box from '@material-ui/core/Box'
import CancelRebateDialog from '../../../../../../components/CancelRebateDialog'
import CancelRebateSection from '../../components/CancelRebateSection'
import Fade from '../../../../../../components/Fade'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import NumberField from '../../../../../../components/form/NumberField'
import PrevRebate from '../../components/PrevRebate'
import React from 'react'
import RebateActions from '../../components/RebateActions'
import RebateLayout from '../../../../../../components/layouts/RebateLayout'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import InvestmentRebateOverview from '../../components/InvestmentRebateOverview'
import Uploader from '../../../../../../components/form/Uploader'
import useForm from '../../../../../../hooks/useForm'
import useRouter from '../../../../../../hooks/useRouter'
import useUser from '../../../../../../hooks/useUser'
import {InvestmentRebateConditions} from "./InvestmentRebateConditions";
import {calculateSharedMaximumFor, getSharedLimits, TSharedLimits} from "../Root";
import SharedRebateLimitInfo from "../../components/SharedRebateLimitInfo";
import Investice from "../../../../../../components/icons/Investice";
import { computed } from 'mobx'
import { CANCEL_OWN_LAST_ANNUAL_REBATE, TCancelOwnLastAnnualRebateVariables } from '../../gql/cancelOwnLastAnnualRebate'

// interface ISectionsProps {
//   formData: TInvestmentRebate | null
//   updateRebate: TMutationFunction<
//     IUpdateOwnAnnualInvestmentRebateData,
//     TUpdateOwnAnnualInvestmentRebateVariables
//   >
// }

const innerLayoutWidth = 680

const InvestmentRebate: React.FC = () => {
  const { history } = useRouter()
  const { t } = useTranslation()
  const { user, refetch } = useUser()

  const [
    updateRebate,
    { loading: updateLoading, error: updateError },
  ] = useMutation<
    IUpdateOwnAnnualInvestmentRebateData,
    TUpdateOwnAnnualInvestmentRebateVariables
  >(UPDATE_OWN_ANNUAL_INVESTMENT_REBATE, { onError() {} })

  const [
    resetRebate,
    { loading: resetLoading, error: resetError },
  ] = useMutation<IResetOwnAnnualInvestmentData>(RESET_OWN_ANNUAL_INVESTMENT_REBATE, {
    onError() {},
  })

  const { data, loading, error } = useQuery<IOwnAnnualInvestmentRebateData>(
    OWN_ANNUAL_INVESTMENT_REBATE,
    { fetchPolicy: 'cache-and-network', onError() {} },
  )

  const [
    cancelRebate,
    { loading: cancelLoading, error: cancelError },
  ] = useMutation<boolean, TCancelOwnLastAnnualRebateVariables>(CANCEL_OWN_LAST_ANNUAL_REBATE)

  const formData = get(
    data,
    'user.annualRebate.investmentRebate',
  ) as TInvestmentRebate | null
  const sharedLimits = getSharedLimits(get(data, 'user.annualRebate'), ['lifeInsuranceRebate', 'pensionInsuranceRebate', 'investmentRebate', 'longTermCareRebate'])
  const sharedMaximum = calculateSharedMaximumFor('investmentRebate', sharedLimits)

  const { bind, form } = useForm<TUpdateOwnAnnualInvestmentRebateVariables['data']>(
    formData,
    {
      amount: {
        label: t('common.feeAmount'),
        rule: (data) => (data.type !== 'REMOVE' ? `required|max:${sharedMaximum}` : ''),
        placeholder: sharedMaximum.toLocaleString('cs-CZ') + ' Kč',
      },
      confirmationAgreementFiles: {
        type: 'multi_relation',
        label: t('annualInvestmentRebate.uploadLabel'),
        help: t('common.uploadHelp'),
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
        isFileRelation: true
      },
      confirmationPaymentFiles: {
        type: 'multi_relation',
        label: t('annualInvestmentRebate.uploadLabel2'),
        help: t('common.uploadHelp'),
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
        isFileRelation: true
      },
    },
    {
      async onSubmit(data, form) {
        try {
          const { errors } = await updateRebate({
            variables: { data: { ...data, status: 'APPLY' } },
          })
          if (!errors || (errors && errors.length > 0))
            history.push(`/${user.data.id}/annual-rebates/root`)
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  const [
    prevRebates,
    {
      isFirstRequest,
      isPrevDenied,
      isPrevRemoved,
      showingCommentOfDenied,
      isCancelable,
      isPrevCancelled
    },
  ] = getRebatesWithExtra(data && data.user.annualRebate, 'investmentRebate')

  const cleanRebate = async () => {
    if (formData) {
      const agreementFiles = formData.confirmationAgreementFiles || []
      const paymentFiles = formData.confirmationPaymentFiles || []
      await updateRebate({
        variables: {
          data: {
            status: 'NEW',
            type: 'NEW',
            amount: null,
            confirmationAgreementFiles: {
              delete: agreementFiles.map(pickId),
            },
            confirmationPaymentFiles: {
              delete: paymentFiles.map(pickId),
            },
          },
        },
      })
    }
  }

  const onCancelRebate = async () => {
    if (get(data, 'user.annualRebate.investmentRebate.status') !== 'APPLY') {
      await Promise.all([
        cancelRebate({
          variables: { rebate: 'INVESTMENT' }
        }),
        refetch()
      ])
    }

    await cleanRebate()

    history.push(`/${user.data.id}/annual-rebates/root`)
  }
  // const handleApplyAgain = async () => {
  //   await cleanRebate()
  //   form.setField('type', 'NEW_PERIOD')
  // }

  const handleAlter = async () => {
    await resetRebate()
    form.setField('type', 'ALTER')
  }

  const handleRemove = async () => {
    await resetRebate()
    form.setField('type', 'REMOVE')
  }

  const showForm = computed<boolean>(() => {
    const type = form.getValue('type')
    const status = form.getValue('status')

    return (
      isFirstRequest ||
      isPrevRemoved ||
      isPrevDenied ||
      status === 'DENIED' ||
      type === 'ALTER' ||
      type === 'NEW_PERIOD' ||
      isPrevCancelled
    )
  })

  return (
    <InsideLayout sidebar moveLoversLeft={-190}>
      <ScrollToTop />
      <AppState loading={loading || updateLoading || resetLoading || cancelLoading} />
      <GraphQLErrorSnackbar error={error || updateError || resetError || cancelError} />

      {formData && (
        <Fade>
          <RebateLayout
            commentOfDenied={showingCommentOfDenied}
            sideHint={
              <Trans i18nKey={`annualInvestmentRebate.sideHint`}>
                <Bold>Slevu můžete</Bold> uplatňovat...
              </Trans>
          }
            upperHint={`${t('common.annualRebates')} ${
              user.data.customer.yearOfAnnualRebates
            }`}
            heading={
              <Trans i18nKey="annualInvestmentRebate.heading">
                Sleva na členské příspěvky <br /> odborovým organizacím
              </Trans>
            }
            subHeading={
              <Trans i18nKey="annualInvestmentRebate.subHeading">
                Chcete-li uplatňovat Slevu na odborové příspěvky, tak je nutné
                <Green>vyplnit výši zaplacených odborových příspěvků</Green> a
                <Green>nahrát potvrzení</Green>
              </Trans>
            }
            icon={<Investice fontSize="large" />}
          >
            {isCancelable && (
              <Box display="flex" justifyContent="center" mb={4}>
                <CancelRebateDialog onSubmit={onCancelRebate} />
              </Box>
            )}

            <Observer>
              {() => {
                const type = form.getValue('type')

                if (type === 'REMOVE') {
                  return (
                    <CancelRebateSection
                      onUndo={() => form.setField('type', 'NEW')}
                    >
                      {t('rebateChanges.cancelInvestment')}
                    </CancelRebateSection>
                  )
                }

                if (showForm.get()) {
                  return <FormSection bind={bind} sharedLimits={sharedLimits} />
                }

                return (
                  <Center maxWidth="100%" width={680}>
                    <ChangeRebateButton>
                      <ChangeRebateChoice
                        variant="REMOVE"
                        onClick={handleRemove}
                      />
                      <ChangeRebateChoice
                        label={t('rebateChanges.alterChange', { context: user.allowAlter() ? "2023" : "" })}
                        onClick={handleAlter}
                      />
                    </ChangeRebateButton>
                  </Center>
                )
              }}
            </Observer>

            <Observer>
              {() => {
                if (prevRebates.length < 1) return null

                const type = form.getValue('type')
                const status = form.getValue('status')
                const showFormSection =
                  isFirstRequest ||
                  isPrevRemoved ||
                  isPrevDenied ||
                  status === 'DENIED' ||
                  type === 'ALTER' ||
                  type === 'NEW_PERIOD' ||
                  type === 'REMOVE'
                return (
                  <Box maxWidth="100%" width={innerLayoutWidth} mt={4}>
                    <ArchiveChanges expand={!showFormSection}>
                      {prevRebates.map((rebate) => (
                        <PrevRebate
                          key={rebate.id}
                          settlementRequest={rebate.settlementRequest}
                          status={rebate.status}
                          commentOfDenied={rebate.commentOfDenied}
                        >
                          <InvestmentRebateOverview data={rebate} />
                        </PrevRebate>
                      ))}
                    </ArchiveChanges>
                  </Box>
                )
              }}
            </Observer>

            <Divider maxWidth={1080} my={4} />

            <Observer>
              {() => {
                const type = form.getValue('type')

                const submitToBack = (
                  !showForm.get() &&
                  type !== 'REMOVE'
                )

                return (
                  <RebateActions
                    submitToBack={submitToBack}
                    backTo={`/${user.data.id}/annual-rebates/root`}
                    onSubmit={form.submit}
                    isDirty={form.state.isDirty}
                    formLoading={form.state.loading}
                  />
                )
              }}
            </Observer>
          </RebateLayout>
        </Fade>
      )}
    </InsideLayout>
  )
}
export default InvestmentRebate

const FormSection: React.FC<{ bind: (fieldPath: string) => any, sharedLimits: TSharedLimits }> = (props) => {
  const { t } = useTranslation()
  const { bind } = props

  return (
    <>
      <Box maxWidth="100%" width={innerLayoutWidth}>
        <InvestmentRebateConditions />
      </Box>

      <Divider maxWidth="100%" width={innerLayoutWidth} my={4} />

      <Box maxWidth="100%" width={innerLayoutWidth}>
        <Observer>
          {() => (
            <NumberField
              inlineLabel={t('annualInvestmentRebate.feeAmountLabel')}
              {...bind('amount')}
              help={t('annualInvestmentRebate.inputHelp')}
            />
          )}
        </Observer>
      </Box>

      <Box maxWidth="100%" width={innerLayoutWidth}>
        <SharedRebateLimitInfo limits={props.sharedLimits} />
      </Box>

      <Divider maxWidth="100%" width={innerLayoutWidth} my={4} />

      <Box maxWidth="100%" width={innerLayoutWidth}>
        <Observer>
          {() => (
            <Uploader
              {...bind('confirmationAgreementFiles')}
              showLabel
              multiple
              itemGridProps={{ sm: 6 }}
            />
          )}
        </Observer>

        <Box mt={4}>
          <Observer>
            {() => (
              <Uploader
                {...bind('confirmationPaymentFiles')}
                showLabel
                multiple
                itemGridProps={{ sm: 6 }}
              />
            )}
          </Observer>
        </Box>
      </Box>
    </>
  )
}
