import {
  GridContainer,
  InlineLabel,
  DateWrapper,
  DatepickerWrapper,
  ActionWrapper,
} from '../PrevPeriods/PrevPeriods'
import { ILimiterWithMessage } from '../../components/form/DatePicker'
import { NexusGenEnums } from 'kubik-server'
import { Observer } from 'mobx-react'
import { useDisabilityLevelEnum } from '../../hooks/useEnumList'
import { useTranslation } from 'react-i18next'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import moment from 'moment'
import NextMonth from '../../shared/NextMonth/NextMonth'
import React, { useState, useEffect } from 'react'
import Select from '../../components/form/Select'
import { useForm, useUser } from '../../hooks'
import { Divider } from '../../components/CommonBox'
import YearMonthPicker from '../../components/form/YearMonthPicker'

interface IPrevYearPeriod {
  disabilityLevel?: NexusGenEnums['DisabilityLevel'] | null
  from?: any | null // DateTime
  id?: string // ID!
  to?: any | null // DateTime
}

export interface IDateLimiters {
  fromMinDates?: ILimiterWithMessage[]
  toMinDates?: ILimiterWithMessage[]
  fromMaxDates?: ILimiterWithMessage[]
  toMaxDates?: ILimiterWithMessage[]
}

interface IProps {
  value: IPrevYearPeriod[]
  onRelationUpdate: (id: string | number, data: any) => void
  onRelationDelete: (id: string | number) => void
  onRelationCreate: (data: any) => void
  dateLimiters: IDateLimiters
  label: React.ReactElement
  onBlur?: () => void
  excludeIntervals?: { from: any; to: any }[]
}

const PrevPeriods: React.FC<IProps> = (props) => {
  const {
    value,
    onRelationUpdate,
    onRelationCreate,
    onRelationDelete,
    label,
    excludeIntervals = [],
    onBlur = () => {},
  } = props
  const {
    fromMinDates = [],
    toMinDates = [],
    fromMaxDates = [],
    toMaxDates = [],
  } = props.dateLimiters

  const [initialized, setInitialized] = useState(false)
  const [newPeriod, setNewPeriod] = useState<IPrevYearPeriod | null>(
    value.length === 0 ? {} : null,
  )

  useEffect(() => {
    if (value.length > 0 && !initialized) {
      setNewPeriod(null)
      setInitialized(true)
    }
  }, [value, initialized])

  const handleChange =
    (id?: string) => (value: Pick<IPrevYearPeriod, 'from' | 'to'>) => {
      if (id) {
        onRelationUpdate(id, value)
      } else {
        onRelationCreate(value)
        setNewPeriod(null)
      }
    }

  const handleRemove = (id?: string) => () => {
    if (id) {
      if (value.length === 1 && newPeriod === null) {
        setNewPeriod({})
      }
      onRelationDelete(id)
    } else {
      setNewPeriod(value.length === 0 ? {} : null)
    }
  }

  const handleAdd = () => setNewPeriod({})

  /**
   * Calculate total maximal date
   */
  // const totalFromMaxDate = fromMaxDates.reduce<moment.Moment | null>(
  //   (prev, { date }) => {
  //     const nextDate = date && moment(date).isValid() ? moment(date) : null
  //     if (nextDate) {
  //       if (prev === null || nextDate.isSameOrBefore(prev)) {
  //         return nextDate
  //       }
  //     }
  //     return prev
  //   },
  //   null,
  // )

  let allowNew = true

  // let lastTo = value.length > 0 && value[value.length - 1].to
  // if (lastTo && moment(lastTo).isValid()) {
  //   if (totalFromMaxDate) {
  //     allowNew = moment(totalFromMaxDate).isAfter(lastTo, 'day')
  //   }

  //   lastTo = moment(lastTo).add(1, 'day')
  // }

  const usedIntervals = value
    .map(item => {
      return {
        from: moment(item.from),
        to: moment(item.to),
      }
    })

  return (
    <Observer>
      {() => (
        <>
          {value.map((item, idx, arr) => {
            const otherIntervals = value
            .filter((_, idy) => idx !== idy)
            .map(item => {
              return {
                from: moment(item.from),
                to: moment(item.to),
              }
            })

            return (
              <React.Fragment key={item.id}>
                <Divider maxWidth="100%" width={680} my={4} />
                <PrevPeriod
                  label={label}
                  data={item}
                  onBlur={onBlur}
                  onChange={handleChange(item.id)}
                  onRemove={handleRemove(item.id)}
                  excludeIntervals={[
                    ...excludeIntervals,
                    ...otherIntervals
                  ]}
                  dateLimiters={{
                    fromMinDates,
                    toMinDates,
                    fromMaxDates,
                    toMaxDates
                  }}
                  {...(idx === arr.length - 1 &&
                    !newPeriod &&
                    allowNew && {
                      onAdd: handleAdd,
                    })}
                />
              </React.Fragment>
            )
          })}

          {newPeriod && (
            <>
              <Divider maxWidth="100%" width={680} my={4} />
              <PrevPeriod
                label={label}
                data={newPeriod}
                onBlur={onBlur}
                excludeIntervals={[
                  ...excludeIntervals,
                  ...usedIntervals
                ]}
                dateLimiters={{
                  fromMinDates,
                  toMinDates,
                  fromMaxDates,
                  toMaxDates,
                }}
                onChange={handleChange()}
                {...(value.length > 0 && {
                  onRemove: handleRemove(),
                })}
              />
            </>
          )}
        </>
      )}
    </Observer>
  )
}

export default PrevPeriods

interface IPrevPeriodProps {
  data: IPrevYearPeriod
  label: React.ReactElement
  onChange: (value: IPrevYearPeriod) => void
  onRemove?: () => void
  onAdd?: () => void
  onBlur: () => void
  dateLimiters: IDateLimiters
  excludeIntervals: { from: moment.Moment; to: moment.Moment }[]
}

const PrevPeriod: React.FC<IPrevPeriodProps> = (props) => {
  const { t } = useTranslation()
  const { list: disabilityLevelList } = useDisabilityLevelEnum()
  const { user } = useUser()

  const year = user.data.annualRebate ? user.data.annualRebate.year : 2023

  const width = 680

  const { data, onChange, onRemove, onAdd, label, onBlur, excludeIntervals } = props
  const {
    fromMinDates = [],
    toMinDates = [],
    fromMaxDates = [],
    toMaxDates = [],
  } = props.dateLimiters

  const { bind, form } = useForm<any>(
    data,
    {
      disabilityLevel: {
        label: t('common.disabilityLevel'),
        help: t('common.disabilityLevel', { context: 'HELP' }),
        placeholder: t('common.disabilityLevel') + '...',
        list: disabilityLevelList,
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
      },
      from: {
        label: t('common.from'),
        rule: 'required|date',
        placeholder: moment(`${year}-01-01`).format('MMMM YYYY')
      },
      to: {
        label: t('common.to'),
        rule: 'required|date',
        placeholder: moment(`${year}-12-31`).format('MMMM YYYY')
      },
    },
    {
      onFieldChange(fieldPath, _, form) {
        if (fieldPath === 'disabilityLevel' && form.state.isValid) {
          onChange({
            disabilityLevel: form.getValue('disabilityLevel'),
            from: form.getValue('from'),
            to: form.getValue('to'),
          })
        }
      },
    },
  )

  const onValueUpdate = () => {
    if (form.state.isValid) {
      onChange({
        disabilityLevel: form.getValue('disabilityLevel'),
        from: form.getValue('from'),
        to: form.getValue('to'),
      })
    }
  }

  return (
    <>
      <Box maxWidth="100%" width={width}>
        <Observer>
          {() => <Select {...bind('disabilityLevel')} inline hideEmptyValue />}
        </Observer>
      </Box>

      <Divider maxWidth="100%" width={680} my={4} />

      <Box
        maxWidth="100%"
        width={width}
        display="flex"
        flexDirection="column"
      >
        <GridContainer
          container
          alignItems="flex-start"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item xs>
            <InlineLabel>{label}</InlineLabel>
          </Grid>

          <ActionWrapper>
            {onRemove && (
              <IconButton size="small" onClick={onRemove}>
                <CloseIcon />
              </IconButton>
            )}
            {onAdd && (
              <Button
                variant="text"
                color="primary"
                size="small"
                onClick={onAdd}
              >
                <AddCircleIcon />
                {t('common.add')}
              </Button>
            )}
          </ActionWrapper>

          <Observer>
            {() => {
              const from = form.getValue('from')

              const to = excludeIntervals
                .filter(interval => moment(from).isBefore(interval.from))
                .sort((a, b) => a.from.diff(b.from))

              const maxTo = to.length > 0 ? to[0].from.clone().subtract(1, 'month').endOf('month') : null

              return (
                <DateWrapper
                  item
                  sm={12}
                  md
                  container
                  alignItems="flex-start"
                  spacing={1}
                >
                  <Grid item sm={12} md={6} container wrap="nowrap">
                    <Box
                      mr={1}
                      component="span"
                      fontFamily="fontFamily"
                      color="text.secondary"
                      lineHeight="49px"
                    >
                      {t('common.from')}
                    </Box>

                    <DatepickerWrapper>
                      <YearMonthPicker
                        onValueUpdate={onValueUpdate}
                        excludeIntervals={excludeIntervals}
                        fullWidth
                        {...bind('from')}
                        onBlur={onBlur}
                        minDates={fromMinDates}
                        maxDates={fromMaxDates}
                      />
                    </DatepickerWrapper>
                  </Grid>

                  <Grid item sm={12} md={6} container wrap="nowrap">
                    <Box
                      mr={1}
                      component="span"
                      fontFamily="fontFamily"
                      color="text.secondary"
                      lineHeight="49px"
                    >
                      {t('common.to')}
                    </Box>

                    <DatepickerWrapper>
                      <YearMonthPicker
                        onValueUpdate={onValueUpdate}
                        excludeIntervals={excludeIntervals}
                        pickEndOfTheMonth
                        fullWidth
                        {...bind('to')}
                        onBlur={onBlur}
                        minDates={[...toMinDates, { date: from }]}
                        maxDates={[...toMaxDates, { date: maxTo }]}
                      />
                    </DatepickerWrapper>
                  </Grid>
                </DateWrapper>
              )
            }}
          </Observer>
        </GridContainer>
        <Observer>{() => <NextMonth date={form.getValue('from')} />}</Observer>
      </Box>
    </>
  )
}
