import {gql} from 'apollo-boost'
import {NexusGenFieldTypes} from 'kubik-server'

export interface ICustomersData {
  customers: NexusGenFieldTypes['CustomerList'][]
}
export const CUSTOMERS = gql`
  query customersForDialog {
    customersList {
      id
      name
    }
  }
`
export const UPDATE_CUSTOMER_SETTING = gql`
  mutation updateCustomerSetting(
    $all: Boolean!
    $where: CustomerWhereUniqueInput
    $data: CustomerSettingUpsertInput!
  ) {
    updateCustomerSetting(all: $all, where: $where, data: $data) {
      count
    }
  }
`
