import { Bold, Divider } from '../../../../../../components/CommonBox'
import { ClosePeriod, Normal, Remove } from './Type'
import { NexusGenFieldTypes } from 'kubik-server'
import { TRebateType } from '../gql'
import { useTranslation } from 'react-i18next'
import { ZivotniPojisteni } from '../../../../../../components/icons/KubikIcons'
import Box from '@material-ui/core/Box'
import CommentOfDenied from './CommentOfDenied'
import FileLink from '../../../../../../components/FileLink'
import Grid from '@material-ui/core/Grid'
import PrevRebate from '../../../AnnualRebates/components/PrevRebate'
import React from 'react'
import StatusSwitcher, { StatusView, StatusViewText } from './StatusSwitcher'
import Typography from '@material-ui/core/Typography'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { CancelNotification } from './CancelNotification'

const LifeInsuranceView: React.FC<{
  data: NexusGenFieldTypes['LifeInsuranceRebate']
  hideComment?: boolean
  rebateType: TRebateType
}> = (props) => {
  const { t } = useTranslation()
  const { data, hideComment, rebateType } = props
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)

  return (
    <Grid container spacing={1}>
      <ClosePeriod data={data}>
        {t(
          `${
            rebateType === 'MONTHLY' ? 'monthly' : 'annual'
          }LifeInsuranceRebate.closePeriodLabel_ACCOUNTANT`,
        )}
      </ClosePeriod>
      <Remove data={data} />
      <Normal data={data}>
        <Grid item xs={compactScreen ? 12 : 5}>
          <Bold>{t('annualLifeInsuranceRebate.feeAmountLabel')}</Bold>
        </Grid>
        <Grid item xs={compactScreen ? 12 : 7}>
          <Typography>{data.feeAmount} Kč</Typography>
        </Grid>
        <Grid item xs={12}>
          <Bold mb={1} display="block">
            {t('common.uploadedConfirmations')}
          </Bold>
          <Box pl={compactScreen ? 0 : 4}>
            {data.feeConfirmationFiles &&
              data.feeConfirmationFiles.map((file) => (
                <FileLink key={file.id} file={file} />
              ))}
          </Box>
          <Box pl={compactScreen ? 0 : 4}>
            {data.contractConfirmationFiles &&
              data.contractConfirmationFiles.map((file) => (
                <FileLink key={file.id} file={file} />
              ))}
          </Box>
        </Grid>
      </Normal>

      {!hideComment && (
        <Grid item xs={12}>
          <CommentOfDenied rebateName="lifeInsuranceRebate" />
        </Grid>
      )}
    </Grid>
  )
}

interface IProps {
  data: NexusGenFieldTypes['LifeInsuranceRebate']
  previous: NexusGenFieldTypes['LifeInsuranceRebate'][]
  rebateType: TRebateType
  bottomDivider?: boolean
}

export const LifeInsuranceRebatePrevious: React.FC<Omit<IProps, 'data'>> = (
  props,
) => {
  const { previous, rebateType, bottomDivider } = props
  const { t } = useTranslation()
  const [first] = previous
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)

  if (!first) return null

  return (
    <Box width={780} my={2} position="relative" maxWidth="100%">
      {!compactScreen ? (
        <>
          <Box position="absolute" left={-60} top={0} fontSize="2.5rem">
            <ZivotniPojisteni fontSize="inherit" />
          </Box>
          <StatusView status={first.status}>
            {t('rebate.lifeInsuranceRebate')}
          </StatusView>
        </>
      ) : (
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box fontSize="2.5rem">
              <ZivotniPojisteni fontSize="inherit" />
            </Box>
            <StatusView status={first.status} compactView />
          </Box>
          <Box>
            <StatusViewText status={first.status}>
              {t('rebate.lifeInsuranceRebate')}
            </StatusViewText>
          </Box>
        </Box>
      )}

      <ArchiveChanges>
        {previous.map((data) => (
          <PrevRebate
            key={data.id}
            status={data.status}
            settlementRequest={data.settlementRequest}
            commentOfDenied={data.commentOfDenied}
          >
            <LifeInsuranceView
              data={data}
              rebateType={rebateType}
              hideComment
            />
          </PrevRebate>
        ))}
      </ArchiveChanges>

      {bottomDivider && <Divider my={6} />}
    </Box>
  )
}

const LifeInsuranceRebate: React.FC<IProps> = (props) => {
  const { data, previous, rebateType, bottomDivider } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)

  return (
    <Box width={780} position="relative" maxWidth="100%">
      {!compactScreen ? (
        <>
          <Box position="absolute" left={-60} top={0} fontSize="2.5rem">
            <ZivotniPojisteni fontSize="inherit" />
          </Box>
          <StatusSwitcher rebateName="lifeInsuranceRebate">
            {t('rebate.lifeInsuranceRebate')}
          </StatusSwitcher>
        </>
      ) : (
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box fontSize="2.5rem">
              <ZivotniPojisteni fontSize="inherit" />
            </Box>
            <StatusSwitcher rebateName="lifeInsuranceRebate" compactView>
              {t('rebate.lifeInsuranceRebate')}
            </StatusSwitcher>
          </Box>
          <Box>
            <StatusViewText status={'APPLY'}>
              {t('rebate.lifeInsuranceRebate')}
            </StatusViewText>
          </Box>
        </Box>
      )}

      <LifeInsuranceView data={data} rebateType={rebateType} />
      <ArchiveChanges>
        {previous.map((data) => (
          <PrevRebate
            key={data.id}
            status={data.status}
            settlementRequest={data.settlementRequest}
            commentOfDenied={data.commentOfDenied}
          >
            <LifeInsuranceView
              data={data}
              rebateType={rebateType}
              hideComment
            />
          </PrevRebate>
        ))}
      </ArchiveChanges>

      <CancelNotification rebateName='lifeInsuranceRebate' />

      {bottomDivider && <Divider my={6} />}
    </Box>
  )
}

export default LifeInsuranceRebate
