import {Bold, Divider} from '../../../../../components/CommonBox'
import {makeStyles} from '@material-ui/core/styles'
import {NexusGenEnums, NexusGenRootTypes} from 'kubik-server'
import {Typography} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import Box from '@material-ui/core/Box'
import classnames from 'classnames'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    backgroundColor: '#F9F9F9',
    [theme.breakpoints.up('md')]: {
      width: 680 + theme.spacing(6 * 2),
    },
    padding: theme.spacing(2),
    border: '1px solid #CCC',
    borderRadius: 6,
    '&:not(:first-child)': {
      marginTop: theme.spacing(6),
    },
  },
  deniedWrapper: {
    display: 'flex',
    gap: `${theme.spacing(2)}px`,
    [theme.breakpoints.down('sm')]: {
      gap: `${theme.spacing(1)}px`,
      flexDirection: 'column-reverse',
    },
  },
  nestedRoot: {
    marginTop: theme.spacing(2),
    backgroundColor: '#EDEDED',
    padding: theme.spacing(2),
    border: '1px solid #CCC',
    borderRadius: 6,
    // width: 680 + theme.spacing(6 * 2),
  },
  bottom: {
    padding: theme.spacing(1, 6, 5, 6),
  },
  nestedBottom: {
    padding: theme.spacing(1, 3, 2, 3),
  },
  top: {
    textAlign: 'right',
    padding: theme.spacing(1, 2),
  },
  commentOfDenied: {
    color: 'red',
    textAlign: 'justify',
  },
  divider: {
    borderBottomColor: '#ccc',
  },
}))

interface IProps {
  status: NexusGenEnums['RebateStatus']
  settlementRequest?: Pick<
    NexusGenRootTypes['SettlementRequest'],
    'id' | 'requestedAt' | 'submittedAt'
  > | null
  commentOfDenied?: string | null
  nested?: boolean
}

const PrevRebate: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { settlementRequest, status, commentOfDenied, nested } = props

  if (!settlementRequest) {
    return null
  }

  return (
    <Grid
      spacing={2}
      container
      className={classnames({
        [classes.root]: !nested,
        [classes.nestedRoot]: nested,
      })}
    >
      <Grid item xs={12} sm={6}>
        <Typography variant="h2">{t('common.madeChange')}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body2" align="right">
          {t('common.requested')}
          {': '}
          {settlementRequest.requestedAt &&
            moment(settlementRequest.requestedAt).format(t('format.dateTime'))}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.divider} marginBottom={1} />
      </Grid>
      {settlementRequest.submittedAt &&
        (status === 'CONFIRMED' ||
          status === 'DENIED' ||
          status === 'CANCELED') && (
          <Grid item xs={12}>
            <Box
              component="span"
              className={classes.deniedWrapper}
              justifyContent={
                (status === 'DENIED' || status === 'CANCELED') &&
                !!commentOfDenied
                  ? 'space-between'
                  : 'flex-end'
              }
            >
              {(status === 'DENIED' || status === 'CANCELED') &&
                commentOfDenied && (
                  <Typography
                    variant="body2"
                    className={classes.commentOfDenied}
                  >
                    <span style={{ fontWeight: 900 }}>
                      {t('taxRebates.accountantsComment')}:{' '}
                    </span>
                    {commentOfDenied}
                  </Typography>
                )}
              <Typography
                variant="body2"
                align="right"
                {...((status === 'DENIED' || status === 'CANCELED') && {
                  style: { color: 'red', minWidth: 'fit-content' },
                })}
              >
                <Bold>
                  {status === 'CONFIRMED'
                    ? t('rebateStatus.confirmed')
                    : t('rebateStatus.denied')}
                  {': '}
                  {settlementRequest.submittedAt &&
                    moment(settlementRequest.submittedAt).format(
                      t('format.dateTime'),
                    )}
                </Bold>
              </Typography>
            </Box>
          </Grid>
        )}
      <Grid
        item
        xs={12}
        className={classnames({
          [classes.bottom]: !nested,
          [classes.nestedBottom]: nested,
        })}
      >
        {props.children}
      </Grid>
    </Grid>
  )
}

export default React.memo<IProps & { children: React.ReactNode }>(PrevRebate)
