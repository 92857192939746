import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {Divider, Green} from '../../../../../../components/CommonBox'
import {RebateConditions, RebateExpandable} from '../../../../../../shared/Rebates/Conditions'

export const SpouseRebateConditions: React.FC = () => {
    const { t } = useTranslation()
  
    return (
      <RebateExpandable>
        <Divider maxWidth="100%" width={680} my={4} />

        <Green>{t("common.mainInfo")}</Green>

        <RebateConditions items={[
            <Trans i18nKey={'annualSpouseRebate.conditionsDetail01'}>
              Slevu si může uplatnit <strong>každý zaměstnanec</strong>, jehož <strong>manželka</strong> nebo <strong>manžel neměli v minulém roce vlastní zdanitelné příjmy</strong>.
            </Trans>,
            <Trans i18nKey={'annualSpouseRebate.conditionsDetail02'}>
              Slevu si můžete uplatnit, pokud <strong>příjmy druhého z manželů nepřesáhly 68 000 Kč za rok</strong>.
            </Trans>,
            <Trans i18nKey={'annualSpouseRebate.conditionsDetail08'}>
              Sleva je ve výši <strong>24 840 Kč za rok</strong> a v případě, že je manžel/ka držitelem průkazu ZTP/P až 49 680 Kč.
            </Trans>,
            <Trans i18nKey={'annualSpouseRebate.conditionsDetail03'}>
              Slevu si můžete uplatnit i v případě <strong>registrovaného partnerství</strong>.
            </Trans>,
            <Trans i18nKey={'annualSpouseRebate.conditionsDetail04'}>
              Mezi <strong>příjmy započítávající se do limitu</strong> patří <strong>hrubá mzda, příjmy z podnikání, příjmy z pronájmu, nemocenské dávky, dávky v nezaměstnanosti, starobní</strong> a <strong>invalidní důchod</strong> a <strong>peněžitá pomoc v mateřství</strong>.
            </Trans>,
            <Trans i18nKey={'annualSpouseRebate.conditionsDetail05'}>
              Mezi <strong>příjmy nezapočítávajíce se do limitu</strong> patří <strong>rodičovský příspěvek, sociální dávky, státní příspěvek, stipendium</strong> a <strong>vrácení daně</strong>.
            </Trans>,
            <Trans i18nKey={'annualSpouseRebate.conditionsDetail06'}>
              Sleva je ve výši <strong>24 840 Kč za rok</strong> a v případě, že je manžel/ka držitelem průkazu ZTP/P až 49 680 Kč.
            </Trans>,
          <Trans i18nKey={'annualSpouseRebate.conditionsDetail07'}>
            Sleva je ve výši <strong>24 840 Kč za rok</strong> a v případě, že je manžel/ka držitelem průkazu ZTP/P až 49 680 Kč.
          </Trans>,
          {
            title: <Trans i18nKey={'annualSpouseRebate.conditionsDetail09'}>Podmínky pro určení měsíců ve kterých můžete čerpat slevu:</Trans>,
            subitems: [
              <Trans i18nKey={'annualSpouseRebate.conditionsDetail09a'}></Trans>,
              <Trans i18nKey={'annualSpouseRebate.conditionsDetail09b'}></Trans>,
              <Trans i18nKey={'annualSpouseRebate.conditionsDetail09c'}></Trans>
            ]
          }

        ]} />

        <Green>{t("common.docsInfo")}</Green>

        <RebateConditions items={[
              {
                primaryText: (
                  <Trans i18nKey="annualSpouseRebate.docsInfo01">
                    Oddací list
                  </Trans>
                ),
                supportText: <Trans i18nKey="annualSpouseRebate.docsInfo01b" />
              },
              {
                primaryText: (
                  <Trans i18nKey="annualSpouseRebate.docsInfo02">
                    Rodný list dítěte
                  </Trans>
                ),
                supportText: <Trans i18nKey="annualSpouseRebate.docsInfo02b" />
              }
        ]} />

        {/* TODO: (NEW-UI) - missing affidavit template */}
        {/* <Green>{t("common.documentsForDownload")}</Green>

        <RebateConditions items={[
          <a href={process.env.PUBLIC_URL + '/documents/vzor_cestne_prohlaseni_deti.pdf'} download>
            <Box display="flex" alignItems="center">
              {t("common.affidavit")}
              <CloudDownload style={{ marginLeft: "8px" }} />
            </Box>
          </a>
        ]} /> */}
      </RebateExpandable>
    )
}
