import {
  IOwnAnnualPensionInsuranceRebateData,
  IResetOwnAnnualPensionInsuranceData,
  IUpdateOwnAnnualPensionInsuranceRebateData,
  OWN_ANNUAL_PENSION_INSURANCE_REBATE,
  RESET_OWN_ANNUAL_PENSION_INSURANCE_REBATE,
  TPensionInsuranceRebate,
  TUpdateOwnAnnualPensionInsuranceRebateVariables,
  UPDATE_OWN_ANNUAL_PENSION_INSURANCE_REBATE,
} from './gql'
import {
  Green,
  Divider,
  Center, Error,
} from '../../../../../../components/CommonBox'
import ChangeRebateButton, {
  ChangeRebateChoice,
} from '../../components/ChangeRebateButton'
import { getRebatesWithExtra } from '../../../../../../fragments/utils'
import { Observer } from 'mobx-react'
import {PenzijniPojisteni} from '../../../../../../components/icons/KubikIcons'
import { pickId } from '../../../../../../utils'
import { Bold } from '../../../../../../components/CommonBox'
import { useQuery, useMutation } from 'react-apollo'
import { useTranslation, Trans } from 'react-i18next'
import AppState from '../../../../../../components/AppState'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import Box from '@material-ui/core/Box'
import CancelRebateDialog from '../../../../../../components/CancelRebateDialog'
import CancelRebateSection from '../../components/CancelRebateSection'
import Fade from '../../../../../../components/Fade'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import NumberField from '../../../../../../components/form/NumberField'
import PensionInsuranceRebateOverview from '../../components/PensionInsuranceRebateOverview'
import PrevRebate from '../../components/PrevRebate'
import React, {useState} from 'react'
import RebateActions from '../../components/RebateActions'
import RebateLayout from '../../../../../../components/layouts/RebateLayout'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import Uploader from '../../../../../../components/form/Uploader'
import useForm from '../../../../../../hooks/useForm'
import useRouter from '../../../../../../hooks/useRouter'
import useUser from '../../../../../../hooks/useUser'
import {PensionInsuranceRebateConditions} from "./PensionInsuranceRebateConditions"
import HelpAdornment from "../../../../../../components/form/HelpAdornment";
import {CANCEL_OWN_LAST_ANNUAL_REBATE, TCancelOwnLastAnnualRebateVariables} from '../../gql/cancelOwnLastAnnualRebate'
import {calculateSharedMaximumFor, getSharedLimits, TSharedLimits} from "../Root";
import SharedRebateLimitInfo from "../../components/SharedRebateLimitInfo";
import Button from "@material-ui/core/Button";
import InlineFormContainer from "../../../../../../components/form/InlineFormContainer";
import Select from "../../../../../../components/form/Select";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { computed } from 'mobx'

// interface ISectionsProps {
//   formData: TPensionInsuranceRebate | null
//   updateRebate: TMutationFunction<
//     IUpdateOwnAnnualPensionInsuranceRebateData,
//     TUpdateOwnAnnualPensionInsuranceRebateVariables
//   >
// }

const innerLayoutWidth = 680

const PART_NAMES = ['feeAmountAdditionalPart', 'feeAmountContributionPart', 'feeAmountSpecializedPart']

const PensionInsuranceRebate: React.FC = () => {
  const { history } = useRouter()
  const { t } = useTranslation()
  const { user, refetch } = useUser()

  const [
    updateRebate,
    { loading: updateLoading, error: updateError },
  ] = useMutation<
    IUpdateOwnAnnualPensionInsuranceRebateData,
    TUpdateOwnAnnualPensionInsuranceRebateVariables
  >(UPDATE_OWN_ANNUAL_PENSION_INSURANCE_REBATE, { onError() {} })

  const [
    resetRebate,
    { loading: resetLoading, error: resetError },
  ] = useMutation<IResetOwnAnnualPensionInsuranceData>(
    RESET_OWN_ANNUAL_PENSION_INSURANCE_REBATE,
    { onError() {} },
  )

  const [maximumExceeded, setMaximumExceeded] = useState<boolean>(false)

  const [
    cancelRebate,
    { loading: cancelLoading, error: cancelError },
  ] = useMutation<boolean, TCancelOwnLastAnnualRebateVariables>(CANCEL_OWN_LAST_ANNUAL_REBATE)

  const { data, loading, error } = useQuery<
    IOwnAnnualPensionInsuranceRebateData
  >(OWN_ANNUAL_PENSION_INSURANCE_REBATE, {
    fetchPolicy: 'cache-and-network',
    onError() {},
  })
  const formData = get(
    data,
    'user.annualRebate.pensionInsuranceRebate',
  ) as TPensionInsuranceRebate | null
  const sharedLimits = getSharedLimits(get(data, 'user.annualRebate'), ['lifeInsuranceRebate', 'pensionInsuranceRebate', 'investmentRebate', 'longTermCareRebate'])
  const sharedMaximum = calculateSharedMaximumFor('pensionInsuranceRebate', sharedLimits)

  const [valueFilled, setValueFilled] = useState<boolean>(!!formData && PART_NAMES.reduce((sum, partName) => sum + ((formData as any)[partName] || 0), 0) > 0)
  const [formSent, setFormSent] = useState<boolean>(false)

  let partForm: any = {}
  for (let partName of PART_NAMES) {
    partForm[partName] = {
      label: t('annualPensionInsuranceRebate.' + partName),
      rule: (data: any) => (data.type !== 'REMOVE' ? `max:${sharedMaximum}` : ''),
      placeholder: sharedMaximum.toLocaleString('cs-CZ') + ' Kč',
      help: t('annualPensionInsuranceRebate.help', { context: "2023" }),
    }
  }

  const { bind, form } = useForm<
    TUpdateOwnAnnualPensionInsuranceRebateVariables['data']
  >(
    formData,
    {
      ...partForm,
      feeConfirmationFiles: {
        type: 'multi_relation',
        label: t('annualPensionInsuranceRebate.uploadLabel'),
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
        isFileRelation: true
      },
      contractConfirmationFiles: {
        type: 'multi_relation',
        label: t('annualPensionInsuranceRebate.contractConfirmationFiles'),
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
        isFileRelation: true
      },
    },
    {
      onFieldChange(field, value, form) {
        if(PART_NAMES.includes(field)){
          const total = PART_NAMES.reduce((sum, partName) => sum + (form.getValue(partName) || 0), 0)
          form.setField('feeAmount', total)
          setMaximumExceeded(total > sharedMaximum)
          setValueFilled(total > 0)
        }
      },
      async onSubmit(data, form) {
        try {
          setFormSent(true)
          if (sharedMaximum < (get(data, 'feeAmount') || 0)) {
            setMaximumExceeded(true)
            return
          }

          if(!valueFilled){
            return
          }

          const { errors } = await updateRebate({
            variables: { data: { ...data, status: 'APPLY' } },
          })
          if (!errors || (errors && errors.length > 0))
            history.push(`/${user.data.id}/annual-rebates/root`)
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  const [
    prevRebates,
    {
      isFirstRequest,
      isPrevDenied,
      isPrevRemoved,
      showingCommentOfDenied,
      isCancelable,
      isPrevCancelled
    },
  ] = getRebatesWithExtra(
    data && data.user.annualRebate,
    'pensionInsuranceRebate',
  )

  const cleanRebate = async () => {
    if (formData) {
      const feeConfirmationFiles = formData.feeConfirmationFiles || []
      const contractConfirmationFiles = formData.contractConfirmationFiles || []

      await updateRebate({
        variables: {
          data: {
            status: 'NEW',
            type: 'NEW',
            feeAmount: null,
            feeAmountAdditionalPart: null,
            feeAmountContributionPart: null,
            feeAmountSpecializedPart: null,
            feeConfirmationFiles: {
              delete: feeConfirmationFiles.map(pickId),
            },
            contractConfirmationFiles: {
              delete: contractConfirmationFiles.map(pickId),
            }
          },
        },
      })
    }
  }

  const onCancelRebate = async () => {
    if (get(data, 'user.annualRebate.pensionInsuranceRebate.status') !== 'APPLY') {
      await Promise.all([
        cancelRebate({
          variables: { rebate: 'PENSION_INSURANCE' }
        }),
        refetch()
      ])
    }

    await cleanRebate()

    history.push(`/${user.data.id}/annual-rebates/root`)
  }

  // const handleApplyAgain = async () => {
  //   await cleanRebate()
  //   form.setField('type', 'NEW_PERIOD')
  // }

  const handleAlter = async () => {
    await resetRebate()
    form.setField('type', 'ALTER')
  }

  const handleRemove = async () => {
    await resetRebate()
    form.setField('type', 'REMOVE')
    setValueFilled(true)
  }

  const showForm = computed<boolean>(() => {
    const type = form.getValue('type')
    const status = form.getValue('status')

    return (
      isFirstRequest ||
      isPrevRemoved ||
      isPrevDenied ||
      status === 'DENIED' ||
      type === 'ALTER' ||
      type === 'NEW_PERIOD' ||
      isPrevCancelled
    )
  })

  return (
    <InsideLayout sidebar moveLoversLeft={-190}>
      <ScrollToTop />
      <AppState loading={loading || updateLoading || resetLoading || cancelLoading} />
      <GraphQLErrorSnackbar error={error || updateError || resetError || cancelError} />

      {formData && (
        <Fade>
          <RebateLayout
            commentOfDenied={showingCommentOfDenied}
            sideHint={
              <Trans i18nKey={'annualPensionInsuranceRebate.sideHint'}>
                <strong>Slevu na penzijní připojištění</strong> můžete uplatňovat pouze <strong>jednou ročně v rámci ročního zúčtování</strong> (formulář Roční zúčtování). Při vlastním spoření máte možnost získat nejen <strong>nárok na uplatnění daňové slevy</strong>, ale <strong>také příspěvek od státu</strong>.
              </Trans>
            }
            upperHint={`${t('common.annualRebates')} ${
              user.data.customer.yearOfAnnualRebates
            }`}
            heading={t('annualPensionInsuranceRebate.heading')}
            subHeading={
              <Trans i18nKey="annualPensionInsuranceRebate.subHeading">
                Chcete-li uplatňovat Slevu na penzijní připojištění, <Green>vyplňte výši zaplaceného pojistného</Green> a <Green>nahrajte potvrzení</Green>
              </Trans>
            }
            icon={<PenzijniPojisteni fontSize="large" />}
          >
            {isCancelable && (
              <Box display="flex" justifyContent="center" mb={4}>
                <CancelRebateDialog onSubmit={onCancelRebate} />
              </Box>
            )}

            <Observer>
              {() => {
                const type = form.getValue('type')

                if (type === 'REMOVE') {
                  return (
                    <CancelRebateSection
                      onUndo={() => form.setField('type', 'NEW')}
                    >
                      {t('rebateChanges.cancelPensionInsurance')}
                    </CancelRebateSection>
                  )
                }

                if (showForm.get()) {
                  return <FormSection bind={bind} initialData={formData} sharedLimits={sharedLimits} maximumExceededAmount={maximumExceeded ? sharedMaximum : 0} valueRequired={formSent && !valueFilled} />
                }

                return (
                  <Center maxWidth="100%" width={680}>
                    <ChangeRebateButton>
                      <ChangeRebateChoice
                        variant="REMOVE"
                        onClick={handleRemove}
                      />
                      <ChangeRebateChoice
                        label={t('rebateChanges.alterChange', { context: user.allowAlter() ? "2023" : "" })}
                        onClick={handleAlter}
                      />
                    </ChangeRebateButton>
                  </Center>
                )
              }}
            </Observer>

            <Observer>
              {() => {
                if (prevRebates.length < 1) return null

                const type = form.getValue('type')
                const status = form.getValue('status')
                const showFormSection =
                  isFirstRequest ||
                  isPrevRemoved ||
                  isPrevDenied ||
                  status === 'DENIED' ||
                  type === 'ALTER' ||
                  type === 'NEW_PERIOD' ||
                  type === 'REMOVE'
                return (
                  <Box maxWidth="100%" width={innerLayoutWidth} mt={4}>
                    <ArchiveChanges expand={!showFormSection}>
                      {prevRebates.map((rebate) => (
                        <PrevRebate
                          key={rebate.id}
                          settlementRequest={rebate.settlementRequest}
                          status={rebate.status}
                          commentOfDenied={rebate.commentOfDenied}
                        >
                          <PensionInsuranceRebateOverview data={rebate} />
                        </PrevRebate>
                      ))}
                    </ArchiveChanges>
                  </Box>
                )
              }}
            </Observer>

            <Divider maxWidth={1080} my={4} />

            <Observer>
              {() => {
                const type = form.getValue('type')

                const submitToBack = (
                  !showForm.get() &&
                  type !== 'REMOVE'
                )

                return (
                  <RebateActions
                    submitToBack={submitToBack}
                    backTo={`/${user.data.id}/annual-rebates/root`}
                    onSubmit={form.submit}
                    isDirty={form.state.isDirty}
                    formLoading={form.state.loading}
                  />
                )
              }}
            </Observer>
          </RebateLayout>
        </Fade>
      )}
    </InsideLayout>
  )
}
export default PensionInsuranceRebate

const FormSection: React.FC<{ bind: (fieldPath: string) => any, initialData: TPensionInsuranceRebate | null, sharedLimits: TSharedLimits, maximumExceededAmount?: number, valueRequired?: boolean }> = (props) => {
  const { t } = useTranslation()
  const { bind, maximumExceededAmount, valueRequired, initialData } = props
  const [visibleParts, setVisibleParts] = useState<string[]>(() => {
    if(!initialData) {
      return []
    }
    let usedParts = []
    for (let partName of PART_NAMES) {
      if((initialData as any)[partName]) {
        usedParts.push(partName)
      }
    }
    return usedParts
  })
  const [addAnother, setAddAnother] = useState<boolean>(visibleParts.length === 0)

  return (
    <>
      <Box maxWidth="100%" width={innerLayoutWidth}>
        <PensionInsuranceRebateConditions />
      </Box>

      <Divider maxWidth="100%" width={innerLayoutWidth} my={4} />

      <Bold textAlign="center" maxWidth={480}>{t("annualPensionInsuranceRebate.text2")}</Bold>

      <Box maxWidth="100%" width={innerLayoutWidth} mt={4}>
        <Observer>
          {() => {
            const anotherAvailableNames: string[] = PART_NAMES.filter(name => !visibleParts.includes(name))
            return (
              <>
                {visibleParts.map((name) => (
                  <Box mb={1} key={name} display={'flex'}>
                    <NumberField
                      inlineLabel={t('annualPensionInsuranceRebate.' + name)}
                      {...bind(name)}
                      helpInside
                      inputWidth={'220px'}
                    />
                    <IconButton key="close" color="inherit" style={{zIndex: 200}} onClick={() => {
                      const binder = bind(name)
                      binder.onChange(0)
                      setVisibleParts(visibleParts.filter(p => p !== name))
                    }}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                ))}

                {!addAnother && !!anotherAvailableNames.length && <Box mb={1} display={'flex'} justifyContent={'right'}>
                  <Button
                    variant="text"
                    color="primary"
                    size="small"
                    onClick={() => setAddAnother(true)}
                  >
                    <AddCircleIcon />
                    {t('annualPensionInsuranceRebate.addItem')}
                  </Button>
                </Box>}
                {addAnother && !!anotherAvailableNames.length && <Box mb={1} pr={visibleParts.length > 0 ? '3rem' : 0}>
                  <InlineFormContainer inline label={t('annualPensionInsuranceRebate.selectType')} inputWidth={'220px'}>
                      <Select
                        label={''}
                        list={anotherAvailableNames.map(name => ({value: name, text: t('annualPensionInsuranceRebate.' + name)}))}

                        value={''}
                        onChange={(value) => {
                          setVisibleParts([...visibleParts, value])
                          setAddAnother(false)
                        }}
                        onRelationConnect={() => {}}
                        onBlur={() => {}}
                        fullWidth
                        hideEmptyValue
                        emptyValueLabel={''}
                      />
                  </InlineFormContainer>
                </Box>}

                {!!maximumExceededAmount &&
                  <Error>{t('annualPensionInsuranceRebate.maximumExceededError')} {maximumExceededAmount.toLocaleString('cs-CZ') + ' Kč'}.</Error>}
                {!!valueRequired && <Error>{t('annualPensionInsuranceRebate.valuedRequiredError')}</Error>}
              </>
            )
          }}
        </Observer>
      </Box>

      <Box maxWidth="100%" width={innerLayoutWidth}>
        <SharedRebateLimitInfo limits={props.sharedLimits} />
      </Box>

      <Divider maxWidth="100%" width={innerLayoutWidth} my={4} />

      <Bold maxWidth="100%" width={innerLayoutWidth} display="flex" alignItems="center">
        {t('annualPensionInsuranceRebate.text1')}
        <HelpAdornment inline text={<Trans i18nKey="common.uploadHelp" />} />
      </Bold>

      <Divider maxWidth="100%" width={100} my={4} />

      <Box maxWidth="100%" width={innerLayoutWidth}>
        <Box mb={4}>
          <Observer>
            {() => (
              <Uploader
              {...bind('feeConfirmationFiles')}
              showLabel
              multiple
              itemGridProps={{ sm: 6 }}
              />
            )}
          </Observer>
        </Box>

        <Observer>
          {() => (
            <Uploader
              showLabel
              {...bind('contractConfirmationFiles')}
              multiple
              itemGridProps={{ sm: 6 }}
            />
          )}
        </Observer>
      </Box>
    </>
  )
}
