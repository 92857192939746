import { Bold, Divider } from '../../../../../../components/CommonBox'
import { ClosePeriod, Normal, Remove } from './Type'
import { Manzel } from '../../../../../../components/icons/KubikIcons'
import { NexusGenFieldTypes } from 'kubik-server'
import { TRebateType } from '../gql'
import { useMonthShortcutEnum } from '../../../../../../hooks/useEnumList'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import CommentOfDenied from './CommentOfDenied'
import FileLink from '../../../../../../components/FileLink'
import Grid from '@material-ui/core/Grid'
import PrevRebate from '../../../AnnualRebates/components/PrevRebate'
import React from 'react'
import StatusSwitcher, { StatusView, StatusViewText } from './StatusSwitcher'
import Typography from '@material-ui/core/Typography'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { CancelNotification } from './CancelNotification'

const SpouseView: React.FC<{
  data: NexusGenFieldTypes['SpouseRebate']
  hideComment?: boolean
  rebateType: TRebateType
}> = (props) => {
  const { t } = useTranslation()
  const { translate } = useMonthShortcutEnum()
  const { data, hideComment, rebateType } = props
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)

  return (
    <Grid container spacing={1}>
      <ClosePeriod data={data}>
        {t(
          `${
            rebateType === 'MONTHLY' ? 'monthly' : 'annual'
          }SpouseRebate.closePeriodLabel_ACCOUNTANT`,
        )}
      </ClosePeriod>

      <Remove data={data} />

      <Normal data={data}>
        <Grid item xs={compactScreen ? 12 : 5}>
          <Bold>{t('common.firstname')}</Bold>
        </Grid>
        <Grid item xs={compactScreen ? 12 : 7}>
          <Typography>{data.firstname}</Typography>
        </Grid>

        <Grid item xs={compactScreen ? 12 : 5}>
          <Bold>{t('common.lastname')}</Bold>
        </Grid>
        <Grid item xs={compactScreen ? 12 : 7}>
          <Typography>{data.lastname}</Typography>
        </Grid>

        <Grid item xs={compactScreen ? 12 : 5}>
          <Bold>{t('common.nationalIDNumber')}</Bold>
        </Grid>
        <Grid item xs={compactScreen ? 12 : 7}>
          <Typography>{data.nationalIDNumber}</Typography>
        </Grid>

        <Grid item xs={compactScreen ? 12 : 5}>
          <Bold>{t('common.monthOfApply2')}</Bold>
        </Grid>
        <Grid item xs={compactScreen ? 12 : 7}>
          <Typography>
            {data.applyInMonths &&
              data.applyInMonths.map((month) => translate(month)).join(', ')}
          </Typography>
        </Grid>

        <Grid item xs={compactScreen ? 12 : 5}>
          <Bold>{t('common.ztpp')}</Bold>
        </Grid>
        <Grid item xs={compactScreen ? 12 : 7}>
          <Typography>
            {data.ztpp ? t('common.yes') : t('common.no')}
          </Typography>
        </Grid>

        <Grid item xs={compactScreen ? 12 : 5}>
          <Bold>{t('common.child')}</Bold>
        </Grid>
        <Grid item xs={compactScreen ? 12 : 7}>
          {data.childFirstname} {data.childLastname}<br /> {data.childBirthNumberOrDate}
        </Grid>

        {data.ztpp && (
          <>
            <Grid item xs={compactScreen ? 12 : 5}>
              <Bold>{t('common.monthOfApply2', { context: 'ZTPP' })}</Bold>
            </Grid>
            <Grid item xs={compactScreen ? 12 : 7}>
              <Typography>
                {data.ztppMonths &&
                  data.ztppMonths.map((month) => translate(month)).join(', ')}
              </Typography>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Bold mb={1} display="block">
            {t('common.uploadedConfirmations')}
          </Bold>
          <Box pl={compactScreen ? 0 : 4}>
            {data.marriageCertificateFiles &&
              data.marriageCertificateFiles.map((file) => (
                <FileLink key={file.id} file={file} />
              ))}
            {/* {data.spousesIncomeConfirmationFiles &&
              data.spousesIncomeConfirmationFiles.map(file => (
                <FileLink key={file.id} file={file} />
              ))} */}
            {data.ztppConfirmationFiles &&
              data.ztppConfirmationFiles.map((file) => (
                <FileLink key={file.id} file={file} />
              ))}
            {data.childBirthCertificateFiles &&
              data.childBirthCertificateFiles.map((file) => (
                <FileLink key={file.id} file={file} />
              ))}
          </Box>
        </Grid>
      </Normal>
      {!hideComment && (
        <Grid item xs={12}>
          <CommentOfDenied rebateName="spouseRebate" />
        </Grid>
      )}
    </Grid>
  )
}

interface IProps {
  data: NexusGenFieldTypes['SpouseRebate']
  previous: NexusGenFieldTypes['SpouseRebate'][]
  rebateType: TRebateType
  bottomDivider?: boolean
}

export const SpouseRebatePrevious: React.FC<Omit<IProps, 'data'>> = (props) => {
  const { previous, rebateType, bottomDivider } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)

  const [first] = previous

  if (!first) return null

  return (
    <Box width={780} my={2} position="relative" maxWidth="100%">
      {!compactScreen ? (
        <>
          <Box position="absolute" left={-60} top={0} fontSize="2.5rem">
            <Manzel fontSize="inherit" />
          </Box>
          <StatusView status={first.status}>
            {t('rebate.spouseRebate')}
          </StatusView>
        </>
      ) : (
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box fontSize="2.5rem">
              <Manzel fontSize="inherit" />
            </Box>
            <StatusView status={first.status} compactView />
          </Box>
          <Box>
            <StatusViewText status={first.status}>
              {t('rebate.spouseRebate')}
            </StatusViewText>
          </Box>
        </Box>
      )}
      <ArchiveChanges>
        {previous.map((data) => (
          <PrevRebate
            key={data.id}
            status={data.status}
            settlementRequest={data.settlementRequest}
            commentOfDenied={data.commentOfDenied}
          >
            <SpouseView data={data} rebateType={rebateType} hideComment />
          </PrevRebate>
        ))}
      </ArchiveChanges>

      {bottomDivider && <Divider my={6} />}
    </Box>
  )
}

const SpouseRebate: React.FC<IProps> = (props) => {
  const { data, previous, rebateType, bottomDivider } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)

  return (
    <Box width={780} position="relative" maxWidth="100%">
      {!compactScreen ? (
        <>
          <Box position="absolute" left={-60} top={0} fontSize="2.5rem">
            <Manzel fontSize="inherit" />
          </Box>

          <StatusSwitcher rebateName="spouseRebate">
            {t('rebate.spouseRebate')}
          </StatusSwitcher>
        </>
      ) : (
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box fontSize="2.5rem">
              <Manzel fontSize="inherit" />
            </Box>
            <StatusSwitcher rebateName="spouseRebate" compactView>
              {t('rebate.spouseRebate')}
            </StatusSwitcher>
          </Box>
          <Box>
            <StatusViewText status={'APPLY'}>
              {t('rebate.spouseRebate')}
            </StatusViewText>
          </Box>
        </Box>
      )}

      <SpouseView data={data} rebateType={rebateType} />

      <ArchiveChanges>
        {previous.map((data) => (
          <PrevRebate
            key={data.id}
            status={data.status}
            settlementRequest={data.settlementRequest}
            commentOfDenied={data.commentOfDenied}
          >
            <SpouseView data={data} rebateType={rebateType} hideComment />
          </PrevRebate>
        ))}
      </ArchiveChanges>

      <CancelNotification rebateName='spouseRebate' />

      {bottomDivider && <Divider my={6} />}
    </Box>
  )
}

export default SpouseRebate
