import {
  AnnualWizardStep,
  MonthlyWizardStep,
} from '../../generated/globalTypes'
import { useGlobalState, useRouter, useUser } from '../../hooks'
import { Redirect, Switch, Route } from 'react-router-dom'
import { UserConsumer } from '../../containers/User'
import AnnualRebates from './pages/AnnualRebates'
import CustomerActivation from './pages/CustomerActivation/index'
import CustomerEditData from './pages/CustomerSettings/EditData'
import CustomerEditSettings from './pages/CustomerSettings/EditSettings'
import CustomerInactive from './pages/CustomerInactive'
import CustomerSettings from './pages/CustomerSettings'
import Documents from './pages/Documents'
import EditLogin from './pages/Profile/EditLogin'
import EditPersonal from './pages/Profile/EditPersonal'
import Employees from './pages/Employees'
import EntryForm from './pages/EntryForm'
import Help from './pages/Help'
import Logout from './pages/Logout'
import Messages from './pages/Messages'
import MonthlyRebates from './pages/MonthlyRebates'
import MonthlyHistory from './pages/MonthlyHistory'
import Overview from './pages/Overview'
import PreviousTaxRebates from './pages/PreviousTaxRebates'
import Profile from './pages/Profile'
import React from 'react'
import SetGlobalState from '../../components/SetGlobalState'
import SettlementRequests from './pages/SettlementRequests'
import SettlementRequestsDetail from './pages/SettlementRequests/Detail'
import Start from './pages/Start'
import Statistics from './pages/Statistics'
import Archive from './pages/Archive'
import TaxRebates from './pages/TaxRebates'
import UserBlocked from './pages/UserBlocked'
import Welcome from './pages/Welcome'
import AnnualIntro from './pages/AnnualRebates/pages/Intro'
import HistoryIntro from './pages/MonthlyHistory/pages/Intro'
import MonthlyIntro from './pages/MonthlyRebates/pages/Intro'
import Superadmin from './pages/Superadmin'
import SuperadminCustomers from './pages/Superadmin/pages/Customers'
import SuperadminInvoicing from './pages/Superadmin/pages/Invoicing'
import SuperadminSettings from './pages/Superadmin/pages/Settings'
import SuperadminStatistics from './pages/Superadmin/pages/Statistics'
import SuperadminApiSettings from './pages/Superadmin/pages/ApiSettings'
import Emails from './pages/Emails'
import Log from './pages/Log'

import { FinishWizardDialog as AnnualFinishWizardDialog } from './pages/AnnualRebates/components/FinishWizardDialog'
import { FinishWizardDialog as MonthlyFinishWizardDialog } from './pages/MonthlyRebates/components/FinishWizardDialog'

import GuidePage from './pages/GuidePage'
import Support from './pages/Support'
import SupportFormDetail from './pages/Support/SupportDetail'
import SupportWizard from './pages/Support/SupportWizard'
import SupportEndingRebates from "./pages/Support/SupportEndingRebates";
/**
 * @param redirects vrátí první path, kde je splněna podmínka
 * @param defaultPath vrátí, pokud není splněna žádná podmínka
 */
const redirects = (
  redirects: {
    path: string
    condition: (() => boolean) | boolean | undefined | null
  }[],
  defaultPath: string,
) => {
  for (const redirect of redirects) {
    if (
      typeof redirect.condition === 'function'
        ? redirect.condition()
        : redirect.condition
    )
      return redirect.path
  }
  return defaultPath
}

const App: React.FC = () => {
  const { state: globalState } = useGlobalState()

  return (
    <>
      <UserConsumer>
        {({ user }) => {
          if (!user) return <Redirect to="/" />

          const customerBlocked =
            (user.data.customer.blocked && !user.isKeeper()) || false

          if (
            (user.data.blocked && !user.blockedAnnualOverride()) ||
            customerBlocked
          )
            return <UserBlocked customerBlocked={customerBlocked} />

          if (!user.data.customer.activationFormDoneAt) {
            if (user.isKeeper()) {
              return <CustomerActivation />
            } else {
              return <CustomerInactive />
            }
          } else {
            if (user.isAccountant()) {
              return <AccountantRole />
            }
            if (user.isEmployee()) {
              if (user.data.customer.activated) {
                return <EmployeeRole />
              } else {
                return <CustomerInactive />
              }
            }
            if (user.isImplementator()) {
              return <ImplementatorRole />
            }
            if (user.isKeeper()) {
              return <KeeperRole />
            }
            if (user.isSupport()) {
              return <SupportRole />
            }
            if (user.isSuperadmin()) {
              return <SuperadminRole />
            }
            if (user.isViewer()) {
              return <ViewerRole />
            }
          }

          return <Logout />
        }}
      </UserConsumer>

      {globalState.atoms.annualFinishWizardDialog ? (
        <AnnualFinishWizardDialog
          isPozp={globalState.atoms.annualFinishWizardDialog.isPozp}
        />
      ) : null}
      {globalState.atoms.monthlyFinishWizardDialog ? (
        <MonthlyFinishWizardDialog
          dontApply={globalState.atoms.monthlyFinishWizardDialog.dontApply}
        />
      ) : null}
    </>
  )
}
export default App

const getRoleRedirectTo = (user: ReturnType<typeof useUser>['user']) =>
  redirects(
    [
      { path: '/:userId/start', condition: !user.data.entryFormDoneAt },
      {
        path: '/:userId/profile',
        condition: user.data.role === 'IMPLEMENTATOR', //Nemá overview
      },
    ],
    '/:userId/overview',
  )

const KeeperRole = () => {
  const { user } = useUser()

  return (
    <>
      <SetGlobalState property="lovers" value={user.data.customer.name} />
      <Switch>
        <Route path="/:userId/profile/login" component={EditLogin} />
        <Route path="/:userId/profile/personal" component={EditPersonal} />
        <Route path="/:userId/profile" component={Profile} />
        <Route
          path="/:userId/company-settings/edit-data"
          component={CustomerEditData}
        />
        <Route
          path="/:userId/company-settings/edit-settings"
          component={CustomerEditSettings}
        />
        <Route path="/:userId/company-settings" component={CustomerSettings} />
        <Route path="/:userId/company-emails" component={Emails} />
        <Route path="/:userId/company-log" component={Log} />
        <Route path="/:userId/overview" component={Overview} />
        <Route path="/:userId/help" component={Help} />
        <Route path="/:userId/start" component={Start} />
        <Route
          path="/:userId/settlement-requests/:requestId"
          component={SettlementRequestsDetail}
        />
        <Route
          path="/:userId/settlement-requests"
          component={SettlementRequests}
        />
        <Route path="/:userId/documents/:employeeId" component={Documents} />
        <Route path="/:userId/documents" component={Documents} />
        {/*<Route path="/:userId/applications" component={SettlementRequests} />*/}
        <Route path="/:userId/employees" component={Employees} />
        <Route path="/:userId/statistics" component={Statistics} />
        <Route path="/:userId/archive" component={Archive} />
        <Route path="/:userId/messages" component={Messages} />

        <Route path="/:userId/guide" component={GuidePage} />

        <Redirect to={getRoleRedirectTo(user)} />
      </Switch>
    </>
  )
}

const SupportRole = () => {
  const { user } = useUser()

  return (
    <>
      <SetGlobalState property="lovers" value={user.data.customer.name} />
      <Switch>
        <Route path="/:userId/profile/login" component={EditLogin} />
        <Route path="/:userId/profile/personal" component={EditPersonal} />
        <Route path="/:userId/profile" component={Profile} />
        <Route
          path="/:userId/company-settings/edit-data"
          component={CustomerEditData}
        />
        <Route
          path="/:userId/company-settings/edit-settings"
          component={CustomerEditSettings}
        />
        <Route path="/:userId/company-settings" component={CustomerSettings} />
        <Route path="/:userId/company-emails" component={Emails} />
        <Route path="/:userId/overview" component={Overview} />

        <Route
          path="/:userId/support/formdetail"
          component={SupportFormDetail}
        />
        <Route path="/:userId/support/wizard" component={SupportWizard} />
        <Route path="/:userId/support/ending-rebates" component={SupportEndingRebates} />
        <Route path="/:userId/support" component={Support} />

        <Route path="/:userId/help" component={Help} />
        <Route path="/:userId/start" component={Support} />
        <Route
          path="/:userId/settlement-requests/:requestId"
          component={SettlementRequestsDetail}
        />
        <Route
          path="/:userId/settlement-requests"
          component={SettlementRequests}
        />
        <Route path="/:userId/documents/:employeeId" component={Documents} />
        <Route path="/:userId/documents" component={Documents} />
        {/*<Route path="/:userId/applications" component={SettlementRequests} />*/}
        <Route path="/:userId/employees" component={Employees} />
        <Route path="/:userId/archive" component={Archive} />
        <Route path="/:userId/messages" component={Messages} />
        <Redirect to={getRoleRedirectTo(user)} />
      </Switch>
    </>
  )
}

const SuperadminRole = () => {
  const { user } = useUser()

  return (
    <>
      <Switch>
        <Route
          path="/:userId/superadmin/customers"
          component={SuperadminCustomers}
        />
        <Route
          path="/:userId/superadmin/invoicing"
          component={SuperadminInvoicing}
        />
        <Route
          path="/:userId/superadmin/settings"
          component={SuperadminSettings}
        />
        <Route
          path="/:userId/superadmin/statistics"
          component={SuperadminStatistics}
        />
        <Route
          path="/:userId/superadmin/api-settings"
          component={SuperadminApiSettings}
        />
        <Route path="/:userId/superadmin" component={Superadmin} />
        <Route path="/:userId/profile/login" component={EditLogin} />
        <Route path="/:userId/profile/personal" component={EditPersonal} />
        <Route path="/:userId/profile" component={Profile} />
        <Route
          path="/:userId/company-settings/edit-data"
          component={CustomerEditData}
        />
        <Route
          path="/:userId/company-settings/edit-settings"
          component={CustomerEditSettings}
        />
        <Route path="/:userId/company-settings" component={CustomerSettings} />
        <Route path="/:userId/company-emails" component={Emails} />

        <Redirect to="/:userId/superadmin" />
      </Switch>
    </>
  )
}

const EmployeeRole = () => {
  const { user } = useUser()
  const { history } = useRouter()

  const { annualRebate, monthlyRebate, monthlyHistory } = user.data
  const isReincarnation = user.isReincarnation()
  const firstRound = user.firstRound()

  const state = history.location.state as
    | {
        from?: 'annualRebatesFinish' | 'monthlyRebatesFinish'
        globalRedirect?: boolean
      }
    | undefined

  const from = state && state.from

  /**
   * Zobrazuje pouze stránky historie
   * - v období RZD dokud nebude historie schválena
   * - mimo RZD dokud neodeslal první žádost, pak se dostane na přehled
   */
  const forceHistory = user.data.allowAnnual
    ? user.data.monthlyHistoryRequired
    : user.data.monthlyHistoryRequired &&
      (!monthlyHistory || !monthlyHistory.settlementRequest)

  const allowHistoryWizard = Boolean(monthlyHistory) || forceHistory // user.data.monthlyHistoryRequired mělo by stačit a po schválení historie by ho to redirectlo
  const allowMonthlyWizard =
    !user.blockedAnnualOverride() && !allowHistoryWizard
  const allowAnnualWizard = Boolean(user.data.allowAnnual && !forceHistory)

  const allowWelcome =
    (forceHistory && !monthlyHistory) ||
    (allowAnnualWizard || allowMonthlyWizard
      ? (allowAnnualWizard ? !annualRebate : true) &&
        (allowMonthlyWizard ? !monthlyRebate : true)
      : false)

  const annualWizardStepIsActive = Boolean(
    annualRebate &&
      (annualRebate.wizardStep === AnnualWizardStep.PREV_EMPLOYER ||
        annualRebate.wizardStep === AnnualWizardStep.QUESTIONNAIRE ||
        annualRebate.wizardStep === AnnualWizardStep.REBATE ||
        annualRebate.wizardStep === AnnualWizardStep.REBATE_MONTHLY ||
        annualRebate.wizardStep === AnnualWizardStep.SUMMARY ||
        annualRebate.wizardStep === AnnualWizardStep.TAX_RESIDENCE),
  )
  const monthlyWizardStepIsActive = Boolean(
    monthlyRebate &&
      (monthlyRebate.wizardStep === MonthlyWizardStep.QUESTIONNAIRE ||
        monthlyRebate.wizardStep === MonthlyWizardStep.REBATE ||
        monthlyRebate.wizardStep === MonthlyWizardStep.SUMMARY ||
        monthlyRebate.wizardStep === MonthlyWizardStep.TAX_RESIDENCE),
  )

  const annualWizardNotFinished =
    annualRebate &&
    (!annualRebate.wizardFinishedAt || !annualRebate.settlementRequest)

  const monthlyWizardNotFinished =
    monthlyRebate &&
    (!monthlyRebate.wizardFinishedAt || !monthlyRebate.settlementRequest)

  const redirectTo = redirects(
    [
      { path: '/:userId/welcome', condition: allowWelcome },
      {
        path: '/:userId/monthly-history',
        condition: Boolean(forceHistory && monthlyHistory),
      },
      {
        path: '/:userId/annual-rebates',
        condition:
          allowAnnualWizard &&
          ((!annualRebate && !monthlyWizardStepIsActive) || // Pokud nemám RZD a zároveň NENí MS wizard aktivní
            annualWizardStepIsActive ||
            annualWizardNotFinished),

        // || (annualRebate.settlementRequest &&
        //   annualRebate.settlementRequest.status !== 'APPLY')
      },
      {
        path: '/:userId/monthly-rebates',
        condition:
          allowMonthlyWizard &&
          (!monthlyRebate ||
            monthlyWizardStepIsActive ||
            monthlyWizardNotFinished),
        //   || (
        // monthlyRebate.settlementRequest &&
        // monthlyRebate.settlementRequest.status !== 'APPLY')),
      },
      {
        path: '/:userId/start',
        condition: () => {
          if (
            !(from === 'annualRebatesFinish' || from === 'monthlyRebatesFinish')
          ) {
            return false
          }
          if (
            allowAnnualWizard &&
            annualRebate &&
            annualRebate.settlementRequests.filter(
              (sr) => sr.type.indexOf('ANNUAL') === 0,
            ).length !== 1
          ) {
            return false
          }
          if (
            allowMonthlyWizard &&
            monthlyRebate &&
            monthlyRebate.settlementRequests.length !== 1
          ) {
            return false
          }
          return true
        },
      },
    ],
    isReincarnation ? `/:userId/rebates` : `/:userId/overview`,
  )

  if (user.data.entryFormDoneAt) {
    return (
      <>
        <SetGlobalState property="lovers" value={user.data.customer.name} />

        <Switch>
          {!isReincarnation && (
            <Route path="/:userId/profile/personal" component={EditPersonal} />
          )}
          {!isReincarnation && (
            <Route path="/:userId/profile/login" component={EditLogin} />
          )}
          {!isReincarnation && (
            <Route path="/:userId/profile" component={Profile} />
          )}
          {!isReincarnation && (
            <Route path="/:userId/overview" component={Overview} />
          )}
          {!isReincarnation && (
            <Route path="/:userId/documents" component={Documents} />
          )}
          {!isReincarnation && user.allowMessages() && (
            <Route path="/:userId/messages" component={Messages} />
          )}

          <Route path="/:userId/start" component={Start} />
          <Route path="/:userId/help" component={Help} />

          <Route
            path="/:userId/rebates/previous"
            component={PreviousTaxRebates}
          />
          {!firstRound && (
            <Route
              path="/:userId/rebates/annual-rebates/help"
              component={AnnualIntro}
            />
          )}
          {!firstRound && (
            <Route
              path="/:userId/rebates/monthly-history/help"
              component={HistoryIntro}
            />
          )}
          {!firstRound && (
            <Route
              path="/:userId/rebates/monthly-rebates/help"
              component={MonthlyIntro}
            />
          )}
          {!firstRound && (
            <Route path="/:userId/rebates" component={TaxRebates} />
          )}

          {allowHistoryWizard && (
            <Route path="/:userId/monthly-history" component={MonthlyHistory} />
          )}

          {allowMonthlyWizard && (
            <Route path="/:userId/monthly-rebates" component={MonthlyRebates} />
          )}

          {allowAnnualWizard && (
            <Route path="/:userId/annual-rebates" component={AnnualRebates} />
          )}

          {allowWelcome && (
            <Route path="/:userId/welcome" component={Welcome} />
          )}

          <Route path="/:userId/guide" component={GuidePage} />

          <Redirect
            to={{ pathname: redirectTo, state: { globalRedirect: true } }}
          />
        </Switch>
      </>
    )
  } else {
    return <EntryForm />
  }
}

const ImplementatorRole = () => {
  const { user } = useUser()
  return (
    <>
      <SetGlobalState property="lovers" value={user.data.customer.name} />
      <Switch>
        <Route
          path="/:userId/company-settings/edit-data"
          component={CustomerEditData}
        />
        <Route
          path="/:userId/company-settings/edit-settings"
          component={CustomerEditSettings}
        />
        <Route path="/:userId/company-settings" component={CustomerSettings} />
        <Route path="/:userId/help" component={Help} /> {/* TBD? */}
        <Route path="/:userId/start" component={Start} />
        <Route path="/:userId/profile/login" component={EditLogin} />
        <Route path="/:userId/profile/personal" component={EditPersonal} />
        <Route path="/:userId/profile" component={Profile} />
        <Route path="/:userId/messages" component={Messages} />
        <Route path="/:userId/employees" component={Employees} />
        <Redirect to={getRoleRedirectTo(user)} />
      </Switch>
    </>
  )
}

const AccountantRole = () => {
  const { user } = useUser()
  return (
    <>
      <SetGlobalState property="lovers" value={user.data.customer.name} />
      <Switch>
        <Route path="/:userId/company-settings" component={CustomerSettings} />
        <Route path="/:userId/company-emails" component={Emails} />
        <Route path="/:userId/company-log" component={Log} />
        <Route path="/:userId/employees" component={Employees} />
        <Route path="/:userId/messages" component={Messages} />
        <Route path="/:userId/help" component={Help} />
        <Route path="/:userId/start" component={Start} />
        <Route
          path="/:userId/settlement-requests/:requestId"
          component={SettlementRequestsDetail}
        />
        <Route
          path="/:userId/settlement-requests"
          component={SettlementRequests}
        />
        <Route path="/:userId/documents/:employeeId" component={Documents} />
        <Route path="/:userId/documents" component={Documents} />
        <Route path="/:userId/profile/login" component={EditLogin} />
        <Route path="/:userId/profile/personal" component={EditPersonal} />
        <Route path="/:userId/profile" component={Profile} />
        <Route path="/:userId/overview" component={Overview} />
        <Route path="/:userId/statistics" component={Statistics} />
        <Route path="/:userId/archive" component={Archive} />
        <Route path="/:userId/guide" component={GuidePage} />
        <Redirect to={getRoleRedirectTo(user)} />
        <Redirect to="/:userId/overview" />
      </Switch>
    </>
  )
}

const ViewerRole = () => {
  const { user } = useUser()
  return (
    <>
      <SetGlobalState property="lovers" value={user.data.customer.name} />
      <Switch>
        <Route
          path="/:userId/settlement-requests/:requestId"
          component={SettlementRequestsDetail}
        />
        <Route
          path="/:userId/settlement-requests"
          component={SettlementRequests}
        />
        <Route
          path="/:userId/company-settings/edit-data"
          component={CustomerEditData}
        />
        <Route
          path="/:userId/company-settings/edit-settings"
          component={CustomerEditSettings}
        />
        <Route path="/:userId/company-settings" component={CustomerSettings} />
        <Route path="/:userId/overview" component={Overview} />
        <Route path="/:userId/documents" component={Documents} />
        {user.allowMessages() ? (
          <Route path="/:userId/messages" component={Messages} />
        ) : null}
        <Route path="/:userId/employees" component={Employees} />
        <Route path="/:userId/help" component={Help} />
        <Route path="/:userId/start" component={Start} />
        <Route path="/:userId/profile/login" component={EditLogin} />
        <Route path="/:userId/profile/personal" component={EditPersonal} />
        <Route path="/:userId/profile" component={Profile} />
        <Route path="/:userId/statistics" component={Statistics} />
        <Route path="/:userId/archive" component={Archive} />
        <Route path="/:userId/guide" component={GuidePage} />
        <Redirect to={getRoleRedirectTo(user)} />
      </Switch>
    </>
  )
}
