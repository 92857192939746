import {Bold, Center, Divider, Green, SpaceBetween, UnitChimp,} from '../../../../components/CommonBox'
import {HromadnePridat, ManualnePridat, PridatWhite, StahnoutSoubor,} from '../../../../components/icons/KubikIcons'
import {
  CANCEL_IMPORT,
  CUSTOMER_BLOCK,
  CUSTOMER_UNITS_ROLES,
  ICustomerBlockData,
  ICustomerBlockVariables,
  ICustomerData,
  ICustomerVariables,
  IMPORT_EMPLOYEES,
  IMPORTLOGS,
  REMOVE_IMPORT,
} from './gql'
import {Link} from 'react-router-dom'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {makeStyles, styled, useTheme} from '@material-ui/core/styles'
import {Observer} from 'mobx-react'
import {useMutation, useQuery} from 'react-apollo'
import {useRoleEnum} from '../../../../hooks/useEnumList'
import {Trans, useTranslation} from 'react-i18next'
import AppState from '../../../../components/AppState'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ButtonDialog from '../../../../components/ButtonDialog'
import CenteredTextFields from '../../../../components/CenteredTextFields'
import classnames from 'classnames'
import EmployeeCreateDialog from '../CustomerActivation/EmployeeCreate'
import Fade from '../../../../components/Fade'
import FileImport from '../../../../components/FileImport'
import GraphQLErrorSnackbar from '../../../../components/GraphQLErrorSnackbar'
import Grid from '@material-ui/core/Grid'
import IconPaper from '../../../../components/IconPaper'
import InsideLayout from '../../../../components/layouts/InsideLayout'
import moment from 'moment'
import MuiEditIcon from '@material-ui/icons/Edit'
import React from 'react'
import ScrollToTop from '../../../../components/ScrollToTop'
import SuccesSnackbar from '../../../../components/SuccessSnackbar'
import Typography from '@material-ui/core/Typography'
import useRouter from '../../../../hooks/useRouter'
import useUser from '../../../../hooks/useUser'
import get from 'lodash/get'
import ListIcon from '@material-ui/icons/ListAlt'
import RebatesExport from './components/RebatesExport'

const EditIcon = styled(MuiEditIcon)({
  marginLeft: 8,
})

const useStyles = makeStyles((theme) => ({
  errorButton: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
  shadow: {
    boxShadow: theme.shadows[1],
  },
  notLastUnit: {
    maxWidth: theme.spacing(5),
    marginRight: -4,
    display: 'inline-block',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    /* overflow: hidden; */
  },
  pridatIconContainer: {
    fontSize: '0.9rem',
    marginRight: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(2),
    fontSize: theme.typography.pxToRem(26),
  },
  iconContainer1: {
    fontSize: '2.5rem',
  },
  iconContainer2: {
    fontSize: '3.3rem',
  },
}))

const CustomerSettings: React.FC = () => {
  const classes = useStyles()
  const { user } = useUser()
  const { t } = useTranslation()
  const { translate: translateRole } = useRoleEnum()
  const { location, history } = useRouter()

  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)

  const [updatedActivationAt, setUpdatedActivationAt] = React.useState<{
    open: boolean
    value: string | undefined
  }>({ open: false, value: undefined })

  React.useEffect(() => {
    setUpdatedActivationAt((prev) => ({
      ...(location.state && location.state.activationAt
        ? {
            open: true,
            value: location.state.activationAt,
          }
        : { ...prev, open: false }),
    }))
  }, [location.state])

  const [createDialogOpen, setCreateDialogOpen] = React.useState(false)
  const handleCreateDialogClose = () => setCreateDialogOpen(false)
  const handleCreateDialogOpen = () => setCreateDialogOpen(true)

  const { data, loading, error } = useQuery<ICustomerData, ICustomerVariables>(
    CUSTOMER_UNITS_ROLES,
    {
      variables: {
        roles: ['ACCOUNTANT', 'IMPLEMENTATOR', 'VIEWER', 'KEEPER', 'SUPPORT'],
      },
      fetchPolicy: 'cache-and-network',
    },
  )
  const [customerBlock, { error: errorCustomerBlock }] = useMutation<
    ICustomerBlockData,
    ICustomerBlockVariables
  >(CUSTOMER_BLOCK, { onError() {} })

  let roles = get(data, 'roles')
  roles = (roles || []).filter((role) => {
    let result = false
    if (role.blockedAt) {
      result = moment(role.blockedAt).isAfter()
    } else {
      result = true
    }
    return result
  })

  let units = data ? data.units : []
  if (data && data.units && data.customer) {
    if (data.customer.divideByUnits && (user.isAccountant() || user.isViewer)) {
      units = user.managedUnits()
    }
  }

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <AppState loading={loading} />
      <GraphQLErrorSnackbar error={error || errorCustomerBlock} />
      <SuccesSnackbar
        open={updatedActivationAt.open}
        close={() => history.replace(location.pathname, undefined)}
        message={t('customerActivation.activationAtUpdated', {
          activationAt: moment(updatedActivationAt.value).format(
            'DD.MM.YYYY HH:mm',
          ),
        })}
      />

      {data && data.customer && data.roles && units && (
        <Fade>
          <Box
            maxWidth="100%"
            width={1077}
            ml="auto"
            mr="auto"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography variant="h1" align="center">
              {t('companySettings.heading')}
            </Typography>

            <Box maxWidth="100%" width={800} mt={6} mb={6}>
              <SpaceBetween mb={4}>
                <Typography variant="h2">
                  {t('companySettings.subHeading1')}
                </Typography>
                {!user.isAccountant() && (
                  <Button
                    color="primary"
                    component={Link}
                    to={`/${user.data.id}/company-settings/edit-data`}
                  >
                    {t('common.edit')}
                    <EditIcon fontSize="small" />
                  </Button>
                )}
              </SpaceBetween>

              <Grid container spacing={2}>
                <CenteredTextFields
                  label={t('common.customerName')}
                  text={data.customer.name}
                />
                <CenteredTextFields
                  label={t('common.ico')}
                  text={data.customer.ico}
                />
                <CenteredTextFields
                  label={t('common.dic')}
                  text={data.customer.dic}
                />
                <CenteredTextFields
                  label={t('common.customerAddress')}
                  text={data.customer.address}
                />
              </Grid>
            </Box>

            <Divider />

            <Box maxWidth="100%" width={800} mt={6} mb={6}>
              <SpaceBetween mb={4}>
                <Typography variant="h2">
                  {t('companySettings.subHeading2')}
                </Typography>
                {!user.isAccountant() && (
                  <Button
                    color="primary"
                    component={Link}
                    to={`/${user.data.id}/company-settings/edit-settings`}
                  >
                    {t('common.edit')}
                    <EditIcon fontSize="small" />
                  </Button>
                )}
              </SpaceBetween>

              <Grid container spacing={2}>
                <CenteredTextFields
                  label={t('customerActivation.activationAt')}
                  text={new Date(
                    data.customer.activationAt,
                  ).toLocaleDateString()}
                />

                <CenteredTextFields
                  label={
                    t('common.annualRebates') +
                    ` ${data.customer.yearOfAnnualRebates}`
                  }
                >
                  <Green>
                    {(data.customer.activationAnnualtax
                      ? t('common.yes')
                      : t('common.no')
                    ).toUpperCase()}
                  </Green>
                </CenteredTextFields>

                <CenteredTextFields
                  label={t('customerActivation.allowMessages')}
                >
                  <Green>
                    {(data.customer.allowMessages
                      ? t('common.yes')
                      : t('common.no')
                    ).toUpperCase()}
                  </Green>
                </CenteredTextFields>

                <CenteredTextFields
                  label={t('customerActivation.allowMessagesForViewer')}
                >
                  <Green>
                    {(data.customer.allowMessagesForViewer
                      ? t('common.yes')
                      : t('common.no')
                    ).toUpperCase()}
                  </Green>
                </CenteredTextFields>

                <CenteredTextFields label={t('common.sharedMessages')}>
                  <Green>
                    {(data.customer.sharedMessages
                      ? t('common.yes')
                      : t('common.no')
                    ).toUpperCase()}
                  </Green>
                </CenteredTextFields>

                <CenteredTextFields
                  label={t('customerActivation.allowFilesUpload')}
                >
                  <Green>
                    {(data.customer.allowFilesUpload
                      ? t('common.yes')
                      : t('common.no')
                    ).toUpperCase()}
                  </Green>
                </CenteredTextFields>

                <CenteredTextFields
                  label={t('customerActivation.allowDocumentsForViewer')}
                >
                  <Green>
                    {(data.customer.allowDocumentsForViewer
                      ? t('common.yes')
                      : t('common.no')
                    ).toUpperCase()}
                  </Green>
                </CenteredTextFields>

                <CenteredTextFields
                  label={t(
                    'customerActivation.dontRequireBasicRebateAffidavitFiles',
                  )}
                >
                  <Green>
                    {(data.customer.dontRequireBasicRebateAffidavitFiles
                      ? t('common.yes')
                      : t('common.no')
                    ).toUpperCase()}
                  </Green>
                </CenteredTextFields>

                <CenteredTextFields
                  label={t('customerActivation.allowApplyCurrentMonth')}
                >
                  <Green>
                    {(data.customer.allowApplyCurrentMonth
                      ? t('common.yes')
                      : t('common.no')
                    ).toUpperCase()}
                  </Green>
                </CenteredTextFields>

                <CenteredTextFields label={t('common.units')}>
                  {data.units.length > 0 ? (
                    <Box mt={-1.5} mb={2} mx={-0.5}>
                      {data.units.map((unit) => (
                        <UnitChimp
                          key={unit.id}
                          unitColor={unit.color}
                          unitName={unit.name}
                        />
                      ))}
                    </Box>
                  ) : (
                    <Box fontFamily="fontFamily">
                      {t('common.no').toUpperCase()}
                    </Box>
                  )}
                </CenteredTextFields>

                <Grid item xs={12}>
                  <Typography variant="h3">{t('common.role2')}</Typography>
                </Grid>

                {roles.map((role) => (
                  <React.Fragment key={role.id}>
                    <Divider />
                    <Grid
                      item
                      container
                      justifyContent="space-between"
                      alignItems={compactScreen ? 'flex-start' : 'center'}
                      wrap={compactScreen ? 'nowrap' : 'wrap'}
                      direction={compactScreen ? 'column' : 'row'}
                    >
                      <Grid item xs={compactScreen ? 6 : 4}>
                        <Typography>{`${role.firstname} ${role.lastname}`}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {role.email}
                        </Typography>
                      </Grid>
                      <Grid item xs={compactScreen ? 6 : 3}>
                        <Typography>{translateRole(role.role)}</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={compactScreen ? 12 : 5}
                        container
                        justifyContent="flex-end"
                      >
                        {data.customer.divideByUnits && (
                          <Box overflow="hidden" display="flex">
                            {(role.managedUnits || []).map((unit, idx, arr) => (
                              <UnitChimp
                                key={unit.id}
                                unitColor={unit.color}
                                unitName={unit.name}
                                display="inline"
                                whiteSpace="nowrap"
                                m={0}
                                className={classnames({
                                  [classes.shadow]: idx > 0,
                                  [classes.notLastUnit]: idx + 1 < arr.length,
                                })}
                              />
                            ))}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </Box>

            <Divider />

            <Box maxWidth="100%" width={800} mt={6} mb={6}>
              <Box mb={4}>
                <Typography variant="h2">
                  {t('companySettings.subHeading3')}
                </Typography>
              </Box>

              <Box mb={4} px={compactScreen ? 1 : 10}>
                <Typography align="center" gutterBottom>
                  <Bold>{t('companySettings.createEmployeesHeader')}</Bold>
                </Typography>

                <Typography align="center">
                  {t('companySettings.createEmployeesText')}
                </Typography>
              </Box>

              <Box px={4}>
                <Grid container spacing={compactScreen ? 1 : 3}>
                  <Grid item xs={compactScreen ? 12 : 6} container>
                    <IconPaper
                      icon={
                        <span className={classes.iconContainer1}>
                          <ManualnePridat fontSize="inherit" />
                        </span>
                      }
                    >
                      <Typography variant="h2" align="center" gutterBottom>
                        {t('customerActivation.createUserHeading')}
                      </Typography>
                      <Typography align="center">
                        <Trans i18nKey="customerActivation.createUserDescription">
                          Chcete-li založit zaměstnance manuálně, tak klikněte
                          na “<Green>Přidat zaměstnance</Green>” . Vyplníte
                          několik nutných údajů k zaměstnanci (email, datum
                          nástupu...) a rozešlou se pozvánky k registraci. Tento
                          způsob založení zaměstnanců doporučujeme, pokud máte
                          menší počet zaměstnanců.
                        </Trans>
                      </Typography>
                    </IconPaper>
                  </Grid>
                  {compactScreen && (
                    <Grid item xs={12}>
                      <Button
                        onClick={handleCreateDialogOpen}
                        variant="contained"
                        color="primary"
                        fullWidth
                      >
                        <span className={classes.pridatIconContainer}>
                          <PridatWhite fontSize="inherit" />
                        </span>{' '}
                        {t('employee.addEmployee')}
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={compactScreen ? 12 : 6} container>
                    <IconPaper
                      icon={
                        <span className={classes.iconContainer2}>
                          <HromadnePridat fontSize="inherit" />
                        </span>
                      }
                    >
                      <Typography variant="h2" align="center" gutterBottom>
                        {t('customerActivation.bulkCreateUserHeading')}
                      </Typography>
                      <Typography align="center">
                        <Trans i18nKey="customerActivation.bulkCreateUserDescription">
                          Chcete-li založit zaměstnance hromadně, tak si
                          stáhněte dokument “<Green>PAYMINATOR import</Green>”
                          ke stažení níže, vyplňte údaje o zaměstnancích a
                          nahrajte vyplněný dokument zpět do aplikace (“Nahrát
                          zaměstnance”). Zaměstnancům se rozešlou pozvánky a v
                          registraci pouze zkontrolují své nahrané osobní údaje.
                          Tento způsob založení zaměstnanců doporučujeme, pokud
                          máte větší počet zaměstnanců.
                        </Trans>
                      </Typography>
                    </IconPaper>
                  </Grid>

                  <Grid item xs={compactScreen ? 12 : 6}>
                    {!compactScreen && (
                      <Button
                        onClick={handleCreateDialogOpen}
                        variant="contained"
                        color="primary"
                        fullWidth
                      >
                        <span className={classes.pridatIconContainer}>
                          <PridatWhite fontSize="inherit" />
                        </span>{' '}
                        {t('employee.addEmployee')}
                      </Button>
                    )}

                    <EmployeeCreateDialog
                      units={units}
                      customer={data.customer}
                      employees={[]}
                      open={createDialogOpen}
                      onClose={handleCreateDialogClose}
                      skipCacheUpdate
                      skipKeeperCreation
                    />
                  </Grid>

                  <Grid
                    item
                    xs={compactScreen ? 12 : 6}
                    container
                    justifyContent="center"
                  >
                    <a
                      href={
                        process.env.PUBLIC_URL +
                        '/documents/Payminator_employees_import_template.csv'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Box
                        mb={3}
                        display="flex"
                        alignItems="center"
                        fontFamily="fontFamily"
                      >
                        <StahnoutSoubor
                          color="primary"
                          className={classes.icon}
                        />
                        {t('common.importTemplate')}
                      </Box>
                    </a>
                    <Observer>
                      {() => (
                        <FileImport
                          //heading="Import zaměstnanců neproběhl dobře"
                          timeLimit={1}
                          type="EMPLOYEES"
                          gql={{
                            importLogs: IMPORTLOGS,
                            mutation: IMPORT_EMPLOYEES,
                            removeImport: REMOVE_IMPORT, // nemělo by být potřeba, ale stěžuje si to pak uvnitř v useMutation(undefined)...
                            cancelImport: CANCEL_IMPORT,
                          }}
                          snackbar={{
                            successMessage:
                              'companySettings.employeesImportSuccess',
                            failedMessage: 'TBD failed', // TBD
                          }}
                          /* TBD předělat snackbar messages:
                          (count) => {
                            t('companySettings.employeesImportSuccess', {imported:count})
                          }
                          */
                          row={t('common.row')}
                          columns={[
                            {
                              field: 'personalNumber',
                              label: t('employee.personalNumber'),
                              align: 'right',
                            },
                            {
                              field: 'employmentType',
                              label: t('employee.employmentType'),
                              align: 'center',
                            },
                            { field: 'email', label: t('common.email') },
                            {
                              field: 'firstname',
                              label: t('common.firstname'),
                            },
                            { field: 'lastname', label: t('common.lastname') },
                            { field: 'unit', label: t('common.unit') },
                            {
                              field: 'signedConfirmation',
                              label: t('employee.signedConfirmation'),
                              align: 'center',
                            },
                            {
                              field: 'dateOfEmployment',
                              label: t('employee.dateOfEmployment'),
                              align: 'right',
                            },
                            {
                              field: 'dateOfQuit',
                              label: t('employee.dateOfQuit'),
                              align: 'right',
                            },
                            {
                              field: 'permanentAddress',
                              label: t('common.permanentAddress'),
                            },
                            {
                              field: 'language',
                              label: t('common.language'),
                            },
                            {
                              field: 'gender',
                              label: t('common.gender'),
                            },
                            {
                              field: 'birthdate',
                              label: t('common.birthdate'),
                            },
                            {
                              field: 'nationality',
                              label: t('common.nationality'),
                            },
                            {
                              field: 'czechTaxResidence',
                              label: t('common.czechTaxResidence'),
                            },
                            {
                              field: 'nationalIDNumber',
                              label: t('common.nationalIDNumber'),
                            },
                            {
                              field: 'foreignIDType',
                              label: t('common.foreignIDType'),
                            },
                            {
                              field: 'foreignIDNumber',
                              label: t('common.foreignIDNumber'),
                            },
                            {
                              field: 'foreignIDCountry',
                              label: t('common.foreignIDCountry'),
                            },
                            {
                              field: 'foreignTaxIdentification',
                              label: t('common.foreignTaxIdentification'),
                            },
                            {
                              field: 'foreignTaxResidentOfState',
                              label: t('common.foreignTaxResidentOfState'),
                            },
                          ]}
                        />
                      )}
                    </Observer>
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <Divider />

            <Box maxWidth="100%" width={800} mt={6} mb={6}>
              <Box mb={4}>
                <Typography variant="h2">
                  {t('companySettings.subHeadingExport')}
                </Typography>
                {data &&
                  data.customer &&
                  data.customer.annualRebatesExportType !== 'BYZNYS' && (
                    <small>(typ {data.customer.annualRebatesExportType})</small>
                  )}
              </Box>

              <Box mb={4} px={compactScreen ? 1 : 10}>
                <Typography align="center">
                  {t('companySettings.exportText')}
                </Typography>
              </Box>

              <RebatesExport />
            </Box>

            {/* {!user.isAccountant() && (
              <>
                <Divider />
                <Box width={800} mt={6} mb={6}>
                  <Box mb={4}>
                    <Typography variant="h2">
                      {t('companySettings.subHeading4')}
                    </Typography>
                  </Box>

                  <Typography align="center">
                    <Trans i18nKey="companySettings.integrationText1">
                      Propojte <Green>Payminatora</Green> s aplikacemi třetích
                      stran a usnadněte si tak svojí práci.
                    </Trans>
                  </Typography>

                  <Center mt={2}>
                    <Button
                      // onClick={() => setCreateDialogOpen(true)}
                      variant="contained"
                      color="primary"
                    >
                      <span className={classes.pridatIconContainer}>
                        <PridatWhite fontSize="inherit" />
                      </span>
                      {t('companySettings.addIntegration')}
                    </Button>
                  </Center>
                </Box>
              </>
            )} */}

           {!user.isViewer() ? (
            <>
              <Divider />

              <Box maxWidth="100%" width={800} mt={6} mb={6}>
                <Box mb={4}>
                  <Typography variant="h2">
                    {t('companySettings.emailsHeading')}
                  </Typography>
                </Box>

                <Box mb={4} px={compactScreen ? 1 : 10}>
                  <Typography align="center">
                    {t('companySettings.emailsText')}
                  </Typography>
                </Box>

                <Center mt={2}>
                  <Button
                    color="primary"
                    variant={'contained'}
                    component={Link}
                    to={`/${user.data.id}/company-emails`}
                  >
                    <div
                      style={{
                        marginRight: '8px',
                        display: 'flex',
                        padding: '4px 0',
                      }}
                    >
                      <ListIcon style={{ marginRight: '0.5rem 1rem' }} />
                    </div>
                    {t('companySettings.emailsShowBtn')}
                  </Button>
                </Center>
              </Box>
            </>
           ) : null}

            {user.isKeeper() || user.isAccountant() ? (
              <>
                <Divider />

                <Box maxWidth="100%" width={800} mt={6} mb={6}>
                  <Box mb={4}>
                    <Typography variant="h2">{t('log.title')}</Typography>
                  </Box>

                  <Box mb={4} px={compactScreen ? 1 : 10}>
                    <Typography align="center">
                      {t('log.description')}
                    </Typography>
                  </Box>

                  <Center mt={2}>
                    <Button
                      color="primary"
                      variant={'contained'}
                      component={Link}
                      to={`/${user.data.id}/company-log`}
                    >
                      <div
                        style={{
                          marginRight: '8px',
                          display: 'flex',
                          padding: '4px 0',
                        }}
                      >
                        <ListIcon style={{ marginRight: '0.5rem 1rem' }} />
                      </div>
                      {t('companySettings.emailsShowBtn')}
                    </Button>
                  </Center>
                </Box>
              </>
            ) : null}

            {user.isKeeper() && (
              <>
                <Divider />

                <Box maxWidth="100%" width={800} mt={6} mb={6}>
                  {data && data.customer && data.customer.blocked ? (
                    <>
                      <Box mb={4}>
                        <Typography variant="h2">
                          {t('companySettings.subHeading7')}
                        </Typography>
                      </Box>

                      <Typography align="center">
                        {t('companySettings.deleteAccountDescriptionUndo')}
                      </Typography>

                      <Center mt={2}>
                        <ButtonDialog
                          variant="contained"
                          color="primary"
                          //className={classes.errorButton}
                          onClick={() => {
                            customerBlock({
                              variables: {
                                value: false,
                              },
                            })
                          }}
                          showDialog={true}
                          dialogTitle={t('companySettings.deleteAccountUndo')}
                          dialogContent={t(
                            'companySettings.deleteAccountQuestionUndo',
                          )}
                        >
                          {t('companySettings.deleteAccountUndo')}
                        </ButtonDialog>
                      </Center>
                    </>
                  ) : (
                    <>
                      <Box mb={4}>
                        <Typography variant="h2">
                          {t('companySettings.subHeading6')}
                        </Typography>
                      </Box>

                      <Typography align="center">
                        {t('companySettings.deleteAccountDescription')}
                      </Typography>

                      <Center mt={2}>
                        <ButtonDialog
                          variant="contained"
                          color="primary"
                          className={classes.errorButton}
                          onClick={() => {
                            customerBlock({
                              variables: {
                                value: true,
                              },
                            })
                          }}
                          showDialog={true}
                          dialogTitle={t('companySettings.deleteAccount')}
                          dialogContent={t(
                            'companySettings.deleteAccountQuestion',
                          )}
                        >
                          {t('companySettings.deleteAccount')}
                        </ButtonDialog>
                      </Center>
                    </>
                  )}
                </Box>
              </>
            )}
          </Box>
        </Fade>
      )}
    </InsideLayout>
  )
}

export default CustomerSettings
