import {useCountryEnum, useGenderEnum, useRoleEnum,} from '../../../../hooks/useEnumList'
import {Divider} from '../../../../components/CommonBox'
import {Link, RouteComponentProps, withRouter} from 'react-router-dom'
import {makeStyles, styled} from '@material-ui/core/styles'
import {useTranslation} from 'react-i18next'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CenteredTextFields from '../../../../components/CenteredTextFields'
import Grid from '@material-ui/core/Grid'
import InsideLayout from '../../../../components/layouts/InsideLayout'
import MuiEditIcon from '@material-ui/icons/Edit'
import React from 'react'
import ScrollToTop from '../../../../components/ScrollToTop'
import Typography from '@material-ui/core/Typography'
import useUser from '../../../../hooks/useUser'

const EditIcon = styled(MuiEditIcon)({
  marginLeft: 8,
})

const useStyles = makeStyles((theme) => ({
  subheadingGutter: {
    marginBottom: theme.spacing(4),
  },
}))

const Profile: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles()
  const { user } = useUser()
  const { t } = useTranslation()
  const { translate: translateGender } = useGenderEnum()
  const { translate: translateRole } = useRoleEnum()
  const { translate: translateCountry } = useCountryEnum()

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <Box
        maxWidth="100%"
        width={1077}
        ml="auto"
        mr="auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography variant="h1" align="center">
          {t('profile.heading')}
        </Typography>

        <Box maxWidth="100%" width={800} mt={6} mb={6}>
          <Typography variant="h2" className={classes.subheadingGutter}>
            {`${user.data.firstname} ${user.data.lastname}`}
          </Typography>

          <Grid container spacing={2}>
            <CenteredTextFields
              label={t('common.company')}
              text={user.data.customer.name}
            />
            <CenteredTextFields
              label={t('common.roleType')}
              text={translateRole(user.data.role)}
            />
            <CenteredTextFields
              label={t('common.lastActivity')}
              text={
                user.data.lastActivityAt &&
                new Date(user.data.lastActivityAt).toLocaleDateString()
              }
            />
            {user.isEmployee() && (
              <>
                <CenteredTextFields
                  label={t('employee.employmentType')}
                  text={user.data.employmentType}
                />
                <CenteredTextFields
                  label={t('employee.dateOfEmployment')}
                  text={new Date(
                    user.data.dateOfEmployment,
                  ).toLocaleDateString()}
                />
                <CenteredTextFields
                  label={t('employee.personalNumber')}
                  text={user.data.personalNumber}
                />
              </>
            )}
          </Grid>
        </Box>

        <Divider />

        <Box maxWidth="100%" width={800} mt={6} mb={6}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={4}
          >
            <Typography variant="h2">{t('profile.login')}</Typography>
            <Button
              color="primary"
              component={Link}
              to={{
                pathname: `${props.match.url}/login`,
                state: props.location.state,
              }}
            >
              {t('common.edit')}
              <EditIcon fontSize="small" />
            </Button>
          </Box>

          <Grid container spacing={2}>
            <CenteredTextFields
              label={t('common.email')}
              text={user.data.email}
            />
            <CenteredTextFields
              label={t('common.password')}
              text="&#8226;&#8226;&#8226;&#8226;&#8226;"
            />
          </Grid>
        </Box>

        <Divider />

        <Box maxWidth="100%" width={800} mt={6} mb={6}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={4}
          >
            <Typography variant="h2">{t('profile.personalData')}</Typography>
            <Button
              color="primary"
              component={Link}
              to={{
                pathname: `${props.match.url}/personal`,
                state: props.location.state,
              }}
            >
              {t('common.edit')}
              <EditIcon fontSize="small" />
            </Button>
          </Box>

          <Grid container spacing={2}>
            <CenteredTextFields
              label={t('common.firstname')}
              text={user.data.firstname}
            />
            <CenteredTextFields
              label={t('common.lastname')}
              text={user.data.lastname}
            />
            <CenteredTextFields
              label={t('common.gender')}
              text={translateGender(user.data.gender)}
            />

            {user.isAccountant() && user.data.nickname && (
              <CenteredTextFields
                label={t('common.nickname')}
                text={user.data.nickname}
              />
            )}

            {user.isEmployee() && (
              <>
                <CenteredTextFields
                  label={t('common.birthdate')}
                  text={
                    user.data.birthdate &&
                    new Date(user.data.birthdate).toLocaleDateString()
                  }
                />
                <CenteredTextFields
                  label={t('common.nationalIDNumber')}
                  text={user.data.nationalIDNumber}
                />
                <CenteredTextFields
                  label={t('common.permanentAddress2')}
                  text={user.data.permanentAddress}
                />
                <CenteredTextFields
                  label={t('common.czechTaxResidence')}
                  text={
                    user.data.czechTaxResidence
                      ? t('common.yes').toUpperCase()
                      : t('common.no').toUpperCase()
                  }
                />
                <CenteredTextFields
                  label={t('common.nationality')}
                  text={translateCountry(user.data.nationality)}
                />
              </>
            )}
          </Grid>
        </Box>
      </Box>
    </InsideLayout>
  )
}

export default withRouter(Profile)
