import {
  MESSAGE_EMPTYBIN,
  MESSAGE_RELOCATE,
  TMessage,
  TMessageEmptyBinData,
  TMessageRelocateData,
  TMessageRelocateVariables,
  TMessagesVariables,
} from './gql'
import MessageActionDialog, {IProps as IMessageActionDialog,} from './components/MessageActionDialog'
import {IActions} from './components/Message'
import {useMutation} from 'react-apollo'
import {useTranslation} from 'react-i18next'
import Box from '@material-ui/core/Box'
import get from 'lodash/get'
import InsideLayout from '../../../../components/layouts/InsideLayout'
import MessageTable from './Table'
import NewMessage, {IProps as INewMessageProps} from './NewMessage'
import Purple from '@material-ui/core/colors/purple'
import React from 'react'
import ScrollToTop from '../../../../components/ScrollToTop'
import Tabs, {Tab} from '../../../../components/Tabs'
import Typography from '@material-ui/core/Typography'
import useDatatableVariables from '../../../../hooks/useDatatableVariables'
import useRouter from '../../../../hooks/useRouter'
import useSnackbar from '../../../../hooks/useSnackbar'
import ViewMessage from './ViewMessage'
import {PAGE_SIZE} from '../../../../components/Datatable/Pagination'

const GLOBAL_UNIQUE_KEY = 'MessagesDatatable'
const defaultVariables = {
  skip: 0,
  first: PAGE_SIZE,
  type: 'RECEIVED',
}

const Messages: React.FC = () => {
  const { history } = useRouter()
  const { t } = useTranslation()
  const [datatableVariables, setDatatableVariables] = useDatatableVariables(
    GLOBAL_UNIQUE_KEY,
    defaultVariables,
  )
  const { addMessage } = useSnackbar()

  const [message, setMessage] = React.useState<
    INewMessageProps['message'] | null
  >(null)

  const [messageAction, setMessageAction] = React.useState<
    IMessageActionDialog['messageAction']
  >({ message: null, action: 'ARCHIVE', onAccept: () => {} })

  const handleCloseMessage = () => {
    if (message !== null) setMessage(null)
  }

  const handleTabChange = (type: TMessagesVariables['type'] | 'NEWMESSAGE') => {
    setDatatableVariables(
      () => ({
        ...defaultVariables,
        type,
      }),
      { resetSearch: true },
    )
    handleCloseMessage()
  }

  const handleCloseMessageAction = () => {
    setMessageAction((prevAction: IMessageActionDialog['messageAction']) => {
      return {
        ...prevAction,
        action: null,
        message: null,
        onAccept: () => {},
      }
    })
  }

  const type: TMessagesVariables['type'] | 'NEWMESSAGE' = get(
    datatableVariables,
    'type',
  )

  const [relocateMessage] = useMutation<
    TMessageRelocateData,
    TMessageRelocateVariables
  >(MESSAGE_RELOCATE, {
    refetchQueries: ['messages'],
    onCompleted({ relocateMessage: { location } }) {
      //eslint-disable-next-line
      actions.close()
      addMessage(t('message.moved', { context: location }))
    },
  })

  const [emptyBin] = useMutation<TMessageEmptyBinData>(MESSAGE_EMPTYBIN, {
    refetchQueries: ['messages'],
  })

  const actions: IActions = {
    new: (selectedMessage?: INewMessageProps['message']) => {
      handleTabChange('NEWMESSAGE')
      if (selectedMessage) setMessage(selectedMessage)
    },
    archive: (selectedMessage: TMessage) => {
      setMessageAction({
        message: selectedMessage,
        action: 'ARCHIVE',
        onAccept: () => {
          relocateMessage({
            variables: {
              where: { id: selectedMessage.id },
              to: 'ARCHIVE',
            },
          })
        },
      })
    },
    trash: (selectedMessage: TMessage) => {
      setMessageAction({
        message: selectedMessage,
        action: 'TRASH',
        onAccept: () => {
          relocateMessage({
            variables: {
              where: { id: selectedMessage.id },
              to: type === 'TRASH' ? 'DELETED' : 'TRASH',
            },
          })
        },
      })
    },
    close: () => {
      handleCloseMessage()
      if (type === 'NEWMESSAGE') {
        handleTabChange('RECEIVED')
      }
      handleCloseMessageAction()
    },
    emptyBin: () => {
      setMessageAction({
        message: null,
        action: 'EMPTYBIN',
        onAccept: () => {
          emptyBin()
        },
      })
    },
  }

  React.useEffect(() => {
    if (history.location.state && history.location.state.to) {
      if (!message || message.data.to !== history.location.state.to) {
        actions.new({ action: 'TO', data: history.location.state.to })
      }
    }
  }, [history.location.state])

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <Box
        maxWidth="100%" width={1077}
        ml="auto"
        mr="auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography variant="h1">{t('common.messages')}</Typography>
        <br />
        <Tabs
          onChange={handleTabChange}
          value={type}
          boxProps={{ mb: 2, width: '100%', justifyContent: 'start' }}
        >
          <Tab label={t('message.received')} value="RECEIVED" />
          <Tab label={t('message.sent')} value="SENT" color="#4EBE4E" />
          <Tab
            label={t('message.archived')}
            value="ARCHIVED"
            color={Purple['400']}
          />
          <Tab label={t('message.trash')} value="TRASH" color="#f44336" />
        </Tabs>
        {!message && type !== 'NEWMESSAGE' && (
          <MessageTable
            globalKey={GLOBAL_UNIQUE_KEY}
            type={type}
            onRowClick={data => {
              setMessage({ data: data })
            }}
            actions={actions}
          />
        )}
        {type === 'NEWMESSAGE' && (
          <NewMessage message={message} actions={actions} />
        )}
        {message && type !== 'NEWMESSAGE' && (
          <ViewMessage message={message} actions={actions} />
        )}
        <MessageActionDialog
          messageAction={messageAction}
          onClose={() => handleCloseMessageAction()}
        />
      </Box>
    </InsideLayout>
  )
}

export default Messages
