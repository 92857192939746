import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {Divider, Green} from '../../../../../../components/CommonBox'
import {RebateConditions, RebateExpandable} from '../../../../../../shared/Rebates/Conditions'

export const LongTermCareRebateConditions: React.FC = () => {
    const { t } = useTranslation()
  
    return (
      <RebateExpandable>
        <Divider maxWidth="100%" width={680} my={4} />

        <Green>{t("common.mainInfo")}</Green>

        <RebateConditions items={[
            <Trans i18nKey={'annualLongTermCareRebate.conditionsDetail01'}>
                Slevu si může uplatnit <strong>každý zaměstnanec</strong>
            </Trans>,
            <Trans i18nKey={'annualLongTermCareRebate.conditionsDetail02'}>
                Slevu si může uplatnit <strong>každý zaměstnanec</strong>
            </Trans>,
            <Trans i18nKey={'annualLongTermCareRebate.conditionsDetail03'}>
                Slevu si může uplatnit <strong>každý zaměstnanec</strong>
            </Trans>,
            <Trans i18nKey={'annualLongTermCareRebate.conditionsDetail04'}>
                Slevu si může uplatnit <strong>každý zaměstnanec</strong>
            </Trans>,
            <Trans i18nKey={'annualLongTermCareRebate.conditionsDetail05'}>
                Slevu si může uplatnit <strong>každý zaměstnanec</strong>
            </Trans>,
            <Trans i18nKey={'annualLongTermCareRebate.conditionsDetail06'}>
                Slevu si může uplatnit <strong>každý zaměstnanec</strong>
            </Trans>,
            <Trans i18nKey={'annualLongTermCareRebate.conditionsDetail07'}>
                Slevu si může uplatnit <strong>každý zaměstnanec</strong>
            </Trans>
        ]} />
      </RebateExpandable>
    )
}
