import {
  IOwnAnnualDisabilityRebateData,
  IResetOwnAnnualDisabilityData,
  IUpdateOwnAnnualDisabilityRebateData,
  OWN_ANNUAL_DISABILITY_REBATE,
  RESET_OWN_ANNUAL_DISABILITY_REBATE,
  TUpdateOwnAnnualDisabilityRebateVariables,
  UPDATE_OWN_ANNUAL_DISABILITY_REBATE,
} from './gql'
import {Center, Divider, Green} from '../../../../../../components/CommonBox'
import ChangeRebateButton, {ChangeRebateChoice,} from '../../components/ChangeRebateButton'
import {FormProvider} from '../../../../../../hooks/useForm'
import {getRebatesWithExtra} from '../../../../../../fragments/utils'
import {Observer} from 'mobx-react'
import {pickId, yearRange} from '../../../../../../utils'
import {useForm, useRouter, useUser} from '../../../../../../hooks'
import {useMutation, useQuery} from 'react-apollo'
import {Trans, useTranslation} from 'react-i18next'
import AppState from '../../../../../../components/AppState'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import Box from '@material-ui/core/Box'
import CancelRebateDialog from '../../../../../../components/CancelRebateDialog'
import CancelRebateSection from '../../components/CancelRebateSection'
import DisabilityRebateOverview from '../../components/DisabilityRebateOverview'
import DisabilityWithPeriodsFormSection from '../../../../../../shared/DisabilityWithPeriodsFormSection'
import Fade from '../../../../../../components/Fade'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import Invalida from '../../../../../../components/icons/Invalida'
import PrevRebate from '../../components/PrevRebate'
import React from 'react'
import RebateActions from '../../components/RebateActions'
import RebateLayout from '../../../../../../components/layouts/RebateLayout'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import {CANCEL_OWN_LAST_ANNUAL_REBATE, TCancelOwnLastAnnualRebateVariables} from '../../gql/cancelOwnLastAnnualRebate'
import {calculatePrevYearPeriods} from '../../utils'
import { AppliedRebates } from '../../../../../../pages/Inside/pages/MonthlyRebates/components/AppliedRebates'
import { computed } from 'mobx'

const DisabilityRebate: React.FC = () => {
  const { t } = useTranslation()
  const { user, refetch } = useUser()
  const { history } = useRouter()

  const [
    updateRebate,
    { loading: updateLoading, error: updateError },
  ] = useMutation<
    IUpdateOwnAnnualDisabilityRebateData,
    TUpdateOwnAnnualDisabilityRebateVariables
  >(UPDATE_OWN_ANNUAL_DISABILITY_REBATE, { onError() {} })

  const [
    cancelRebate,
    { loading: cancelLoading, error: cancelError },
  ] = useMutation<boolean, TCancelOwnLastAnnualRebateVariables>(CANCEL_OWN_LAST_ANNUAL_REBATE)

  const [
    resetRebate,
    { loading: resetLoading, error: resetError },
  ] = useMutation<IResetOwnAnnualDisabilityData>(
    RESET_OWN_ANNUAL_DISABILITY_REBATE,
    { onError() {} },
  )

  const { data, loading, error } = useQuery<IOwnAnnualDisabilityRebateData>(
    OWN_ANNUAL_DISABILITY_REBATE,
    { fetchPolicy: 'cache-and-network', onError() {} },
  )
  const year = get(data, 'user.annualRebate.year')
  const [yearStart, yearEnd, prevYearStart, nextYearEnd] = yearRange(year)
  const formData = get(data, 'user.annualRebate.disabilityRebate') || {}

  if (formData.disabilityOtherFiles) {
    formData.disabilityOtherFiles = formData.disabilityOtherFiles.filter((rel: any) => rel.type === 'PAYMENT_CONFIRMATION' || !rel.file).map(rel => rel.file ? rel.file : rel)
  }

  const { bind, form } = useForm<
    TUpdateOwnAnnualDisabilityRebateVariables['data']
  >(
    formData,
    {
      prevYearPeriods: {
        type: 'multi_relation',
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
      },
      disabilityConfirmationFiles: {
        type: 'multi_relation',
        label: t('monthlyDisabilityRebate.uploadLabel'),
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
        isFileRelation: true,
        help: t('common.uploadHelp')
      },
      disabilityOtherFiles: {
        type: 'multi_relation',
        label: t('monthlyDisabilityRebate.uploadLabelOther'),
        rule: (data) => (data.type !== 'CLOSE_PERIOD' ? 'required' : ''),
        isFileRelation: true,
        help: t('common.uploadHelp')
      },
    },
    {
      async onSubmit(data, form) {
        try {
          const { errors } = await updateRebate({
            variables: { data: { ...data, status: 'APPLY' } },
          })
          if (!errors || (errors && errors.length > 0))
            history.push(`/${user.data.id}/annual-rebates/rootMonthly`)
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  const [
    prevRebates,
    {
      isFirstRequest,
      isPrevDenied,
      isPrevRemoved,
      showingCommentOfDenied,
      isCancelable,
      isPrevYear,
      isPrevCancelled,
      prevPositiveRebateWithoutPrevYear,
      overallConfirmed
    },
  ] = getRebatesWithExtra(data && data.user.annualRebate, 'disabilityRebate')

  const cleanRebate = async () => {
    if (formData) {
      const files = formData.disabilityConfirmationFiles || []
      const otherFiles = formData.disabilityOtherFiles || []
      const prevYearPeriods = formData.prevYearPeriods || []

      await updateRebate({
        variables: {
          data: {
            status: 'NEW',
            type: 'NEW',
            prevYearPeriods: {
              delete: prevYearPeriods.map(pickId),
            },
            disabilityConfirmationFiles: {
              delete: files.map(pickId),
            },
            disabilityOtherFiles: {
              delete: otherFiles.map(pickId),
            },
          },
        },
      })
    }
  }

  const onCancelRebate = async () => {
    if (get(data, 'user.annualRebate.disabilityRebate.status') !== 'APPLY') {
      await Promise.all([
        cancelRebate({
          variables: { rebate: 'DISABILITY' }
        }),
        refetch()
      ])
    }

    await cleanRebate()

    history.push(`/${user.data.id}/annual-rebates/rootMonthly`)
  }

  const handleApplyAgain = async () => {
    await cleanRebate()
    form.setField('type', 'NEW_PERIOD')
  }

  const handleAlter = async () => {
    await resetRebate()
    form.setField('type', 'ALTER')
  }

  const handleRemove = async () => {
    await resetRebate()
    form.setField('type', 'REMOVE')
  }

  const prevYearIntervals = calculatePrevYearPeriods(prevRebates)

  // const prevYearRebate = prevRebates.find((r) =>
  //   Boolean(
  //     r.settlementRequest && r.settlementRequest.type === 'MONTHLY_PREV_YEAR',
  //   ),
  // )

  // let prevYearLastTo =
  //   prevYearRebate &&
  //   prevYearRebate.prevYearPeriods &&
  //   prevYearRebate.prevYearPeriods.length > 0 &&
  //   prevYearRebate.prevYearPeriods[prevYearRebate.prevYearPeriods.length - 1].to

  // if (prevYearLastTo && moment(prevYearLastTo).isValid()) {
  //   prevYearLastTo = moment(prevYearLastTo).add(1, 'day')
  // }

  // const prevYearIntervals = prevRebates
  // .filter(rebate => rebate.status === "CONFIRMED")
  // .map(rebate => {
  //   if (!rebate.prevYearPeriods) {
  //     return []
  //   }

  //   return rebate.prevYearPeriods.map(period => {
  //     return {
  //       from: moment(period.from),
  //       to: moment(period.to)
  //     }
  //   })
  // })
  // .flat()

  const showForm = computed<boolean>(() => {
    const type = form.getValue('type')
    const status = form.getValue('status')

    return (
      !overallConfirmed ||
      status === 'DENIED' ||
      type === 'ALTER' ||
      type === 'NEW_PERIOD'
    )
  })

  return (
    <InsideLayout sidebar moveLoversLeft={-190}>
      <ScrollToTop /> 
      <AppState loading={loading || updateLoading || resetLoading || cancelLoading} />
      <GraphQLErrorSnackbar error={error || updateError || resetError || cancelError} />

      {data && data.user && (
        <Fade>
          <RebateLayout
            commentOfDenied={showingCommentOfDenied}
            sideHint={
              <Trans i18nKey={'monthlyDisabilityRebate.sideHint'}>
                <strong>Slevu na invaliditu</strong> můžete uplatňovat <strong>měsíčně</strong> nebo <strong>jednou ročně v rámci ročního zúčtování</strong> (formulář Roční zúčtování &gt; krok Měsíční slevy dodatečné uplatnění). Sleva je ve výši <strong>210 Kč měsíčně</strong> neboli <strong>2 520 Kč ročně u I. a II. stupně</strong>, a <strong>420 Kč měsíčně</strong> neboli <strong>5 040 Kč ročně u III. stupně</strong>.
              </Trans>
            }
            upperHint={`${t('common.annualRebates')} ${year}`}
            heading={t('monthlyDisabilityRebate.heading')}
            icon={<Invalida fontSize="large" />}
            subHeading={
              <Trans i18nKey="monthlyDisabilityRebate.subHeading">
                Chcete-li uplatňovat Slevu na invaliditu, <Green>vyplňte údaje</Green> a <Green>nahrajte potvrzení o invaliditě</Green>
              </Trans>
            }
          >
            {isCancelable && (
              <Box display="flex" justifyContent="center" mb={4}>
                <CancelRebateDialog onSubmit={onCancelRebate} />
              </Box>
            )}

            <Observer>
              {() => {
                const type = form.getValue('type')

                if (type === 'REMOVE') {
                  return (
                    <CancelRebateSection
                      onUndo={() => form.setField('type', 'NEW')}
                    >
                      {t('rebateChanges.cancelDisability')}
                    </CancelRebateSection>
                  )
                }

                if (showForm.get()) {
                  return (
                    <FormProvider form={form}>
                      <DisabilityWithPeriodsFormSection
                        bind={bind}
                        excludeIntervals={prevYearIntervals}
                        dateLimiters={{
                          fromMinDates: [
                            {
                              date: prevYearStart
                            },
                            // {
                            //   date: type === "ALTER" ? null : prevYearLastTo,
                            // }
                          ],
                          toMinDates: [
                            {
                              date: yearStart,
                              message: t('error.minDateAnnualYearStart'),
                            },
                            // {
                            //   date: type === "ALTER" ? null : prevYearLastTo,
                            // }
                          ],
                          fromMaxDates: [
                            {
                              date: yearEnd,
                              message: t('error.maxDateAnnualYearEnd'),
                            },
                          ],
                          toMaxDates: [
                            {
                              date: nextYearEnd,
                            },
                          ],
                        }}
                      />
                    </FormProvider>
                  )
                }

                return (
                  <Center maxWidth="100%" width={680} flexDirection="column">
                    <ChangeRebateButton>
                      {isPrevYear ? (
                        <ChangeRebateChoice
                        variant="APPLY_AGAIN"
                        onClick={handleApplyAgain}
                      />
                      ) : (
                        <ChangeRebateChoice
                          variant="REMOVE"
                          onClick={handleRemove}
                        />
                      )}

                      {!isPrevYear ? (
                        <ChangeRebateChoice
                          label={t('rebateChanges.alterChange', { context: user.allowAlter() ? "2023" : "" })}
                          onClick={handleAlter}
                        />
                      ) : null}
                    </ChangeRebateButton>

                    {!isPrevRemoved && prevPositiveRebateWithoutPrevYear && prevPositiveRebateWithoutPrevYear.prevYearPeriods ? (
                      <AppliedRebates
                        type="ANNUAL"
                        rebates={
                          prevPositiveRebateWithoutPrevYear.prevYearPeriods.map(period => ({
                            from: period.from,
                            to: period.to
                          }))
                        }
                      />
                    ) : null}

                    {prevYearIntervals.length > 0 ? (
                      <AppliedRebates
                        type="PREV_YEAR"
                        rebates={prevYearIntervals.map(period => ({
                          from: period.from,
                          to: period.to
                        }))}
                      />
                    ) : null}
                  </Center>
                )
              }}
            </Observer>

            <Observer>
              {() => {
                if (prevRebates.length < 1) return null

                const type = form.getValue('type')
                const status = form.getValue('status')
                const showFormSection =
                  isFirstRequest ||
                  isPrevRemoved ||
                  isPrevDenied ||
                  status === 'DENIED' ||
                  type === 'ALTER' ||
                  type === 'NEW_PERIOD' ||
                  type === 'REMOVE'

                return (
                  <Box maxWidth="100%" width={680} mt={4}>
                    <ArchiveChanges expand={!showFormSection}>
                      {prevRebates.map((rebate) => (
                        <PrevRebate
                          key={rebate.id}
                          settlementRequest={rebate.settlementRequest}
                          status={rebate.status}
                          commentOfDenied={rebate.commentOfDenied}
                        >
                          <DisabilityRebateOverview data={rebate} />
                        </PrevRebate>
                      ))}
                    </ArchiveChanges>
                  </Box>
                )
              }}
            </Observer>

            <Divider maxWidth={1080} my={4} />

            <Observer>
              {() => {
                const type = form.getValue('type')

                const submitToBack = (
                  !showForm.get() &&
                  type !== 'REMOVE'
                )

                return (
                  <RebateActions
                    backTo={`/${user.data.id}/annual-rebates/rootMonthly`}
                    onSubmit={form.submit}
                    isDirty={form.state.isDirty}
                    submitToBack={submitToBack}
                    formLoading={form.state.loading}
                  />
                )
              }}
            </Observer>
          </RebateLayout>
        </Fade>
      )}
    </InsideLayout>
  )
}

export default DisabilityRebate
