import {gql} from 'apollo-boost'
import {NexusGenArgTypes, NexusGenFieldTypes} from 'kubik-server'
import {DISABILITY_REBATE_FULL_FRAGMENT, TDisabilityRebate,} from '../../../../../../fragments/DisabilityRebate'

export interface IOwnMonthlyDisabilityRebateData {
  user: {
    monthlyRebate: NexusGenFieldTypes['MonthlyRebate'] & {
      disabilityRebate: TDisabilityRebate
      disabilityRebates: TDisabilityRebate[]
    }
  }
}

export const OWN_MONTHLY_DISABILITY_REBATE = gql`
  query ownMonthlyDisabilityRebate {
    user {
      id
      monthlyRebate {
        id
        year
        disabilityRebate {
          ...DisabilityRebateFull
        }
        disabilityRebates {
          ...DisabilityRebateFull
        }
      }
    }
  }
  ${DISABILITY_REBATE_FULL_FRAGMENT}
`

export interface IUpdateOwnMonthlyDisabilityRebateData {
  updateOwnMonthlyDisabilityRebate: NexusGenFieldTypes['DisabilityRebate']
}
export type TUpdateOwnMonthlyDisabilityRebateVariables = NexusGenArgTypes['Mutation']['updateOwnMonthlyDisabilityRebate']
export const UPDATE_OWN_MONTHLY_DISABILITY_REBATE = gql`
  mutation updateOwnMonthlyDisabilityRebate(
    $data: OwnDisabilityMonthlyRebateUpdateInput!
  ) {
    updateOwnMonthlyDisabilityRebate(data: $data) {
      ...DisabilityRebateFull
    }
  }
  ${DISABILITY_REBATE_FULL_FRAGMENT}
`

export interface IResetOwnMonthlyDisabilityRebateData {
  resetOwnMonthlyDisabilityRebate: TDisabilityRebate
}
export const RESET_OWN_MONTHLY_DISABILITY_REBATE = gql`
  mutation resetOwnMonthlyDisabilityRebate {
    resetOwnMonthlyDisabilityRebate {
      ...DisabilityRebateFull
    }
  }
  ${DISABILITY_REBATE_FULL_FRAGMENT}
`
