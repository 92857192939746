import {gql} from 'apollo-boost'
import {NexusGenFieldTypes} from 'kubik-server'

export interface IStartMonthlyHistoryWizardData {
  startMonthlyHistoryWizard: Pick<
    NexusGenFieldTypes['MonthlyRebate'],
    | 'id'
    | 'year'
    | 'submitedAt'
    | 'wizardStartedAt'
    | 'wizardFinishedAt'
    | 'createdAt'
    | 'variant'
  >
}
export const START_MONTHLY_HISTORY_WIZARD = gql`
  mutation startMonthlyHistoryWizard {
    startMonthlyHistoryWizard {
      id
      year
      variant
      submitedAt
      createdAt
      wizardStartedAt
      wizardFinishedAt
      settlementRequest {
        id
        status
      }
      settlementRequests {
        id
        status
      }
      basicRebate {
        id
        status
      }
      studentRebate {
        id
        status
      }
      disabilityRebate {
        id
        status
      }
      ztppRebate {
        id
        status
      }
      childrenRebate {
        id
        status
        children {
          id
          status
        }
        otherParentStatus
      }
    }
  }
`
