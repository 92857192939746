import {Green} from '../../../../../components/CommonBox'
// import { Link } from 'react-router-dom'
import {makeStyles} from '@material-ui/core/styles'
import {useTranslation} from 'react-i18next'
import React from 'react'
import useUser from '../../../../../hooks/useUser'
import {Link} from "react-router-dom";
import {SupportLayout} from "../index";
import Podpora from "../../../../../components/icons/Podpora";

const useStyles = makeStyles((theme) => ({
}))



const SupportFormDetail: React.FC = (props) => {
  const classes = useStyles()
  const { user } = useUser()
  const { t } = useTranslation()


  return (

    <SupportLayout title={"Detail archivu"}>
      <ul>
        <li>přejdi do <Link to={`/${user.data.id}/archive`}><Green>Archivu</Green></Link></li>
        <li>u zaměstnance klikni na <Podpora color={"primary"} /></li>
      </ul>

    </SupportLayout>
  )
}

export default SupportFormDetail