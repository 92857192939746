import {gql} from 'apollo-boost'
import {NexusGenFieldTypes} from 'kubik-server'


export interface IAdminStatisticsData {
    adminStatistics: NexusGenFieldTypes['AdminStatistics']
}
export const ADMIN_STATISTICS = gql`
    query adminStatistics ($where: AdminStatisticsWhereInput) {
        adminStatistics(where: $where) {
            companiesTitle
            yearAnnual
            yearMonthly
            
            annualUsersCount
            annualChangeUsersCount
            annualPOZPUsersCount
            otherCitizenshipUsersCount
            
            annualConfirmedUsersCount
            annualConfirmedChangeUsersCount
            annualConfirmedPOZPUsersCount
            otherCitizenshipConfirmedUsersCount

            annualOnlyBasicUsersCount
            annualNothingUsersCount

            annualBasicUsersCount
            annualSpouseUsersCount
            annualLoanUsersCount
            annualGiftUsersCount
            annualPreschoolUsersCount
            annualLifeInsuranceUsersCount
            annualPensionInsuranceUsersCount
            annualUnionUsersCount
            annualExamUsersCount
            annualForeclosureUsersCount

            monthlyUsersCount
            monthlyOnlyBasicUsersCount
            monthlyBasicUsersCount
            monthlyChildrenUsersCount
            monthlyStudentUsersCount
            monthlyDisabilityUsersCount
            monthlyZTPPUsersCount

            rebatesCountAverage
            
            genderMaleWithSettlementCount
            genderFemaleWithSettlementCount
            
        }
    }
`