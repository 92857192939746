import {gql} from 'apollo-boost'

import {NexusGenArgTypes, NexusGenFieldTypes} from 'kubik-server'

export type TUserLog = NexusGenFieldTypes['UserLog']
export interface TUserLogData {
  logs: {
    items: TUserLog[]
    total: number
  }
}
export type TUserLogVariables = NexusGenArgTypes['Query']['logs']

export const LOGS = gql`
  query Logs(
    $where: UserLogWhereInput
    $orderBy: LogsTableOrderByInput
    $skip: Int
    $first: Int
  ) {
    logs(where: $where, orderBy: $orderBy, skip: $skip, first: $first) {
      items {
        id
        createdAt
        action
        createdBy {
          email
          fullname
          role
        }
        affectedUser {
          email
          fullname
          role
        }
        data
        source
      }
      total
    }
  }
`

export const LOGS_USERS = gql`
  query LogsUsers {
    users {
      id
      fullname
    }
  }
`
