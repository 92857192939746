import {gql} from 'apollo-boost'
import {TAX_QUESTIONNAIRE_WITH_HISTORY} from '../../../../../../fragments/TaxQuestionnaire'

export const OWN_ANNUAL_TAX_QUESTIONNAIRE = gql`
  query userWithAnnualTaxQuestionnaire {
    user {
      id
      annualRebate {
        id
        year
        wizardStep
        taxQuestionnaire {
          ...TaxQuestionnaireWithHistory
        }
      }
    }
  }

  ${TAX_QUESTIONNAIRE_WITH_HISTORY}
`

export const UPDATE_TAX_QUESTIONNAIRE = gql`
  mutation updateTaxQuestionnaire($data: TaxQuestionnaireUpdateInput!) {
    updateTaxQuestionnaire(data: $data) {
      id
      createByActualEmployer
      samePreviousEmployer
      otherIncomes
      selfEmployed
      concurrentEmployers
      solidarityTax
      foreignIncome
      laborOffice
      laborOfficeMonths
      laborOfficeFiles {
        id
        filename
        size
        createdAt
      }
    }
  }
`
