import {useCountryEnum, useEmploymentTypeEnum, useGenderEnum, useRoleEnum,} from '../../../../hooks/useEnumList'
import {EMPLOYEE, TEmployeeData, TEmployeeVariables} from './gql'
import {Link, RouteComponentProps} from 'react-router-dom'
import {makeStyles, styled} from '@material-ui/core/styles'
import {useQuery} from 'react-apollo'
import {useTranslation} from 'react-i18next'
import AppState from '../../../../components/AppState'
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CenteredTextFields from '../../../../components/CenteredTextFields'
import Divider from '../../../../components/Divider'
import GraphQLErrorSnackbar from '../../../../components/GraphQLErrorSnackbar'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import React from 'react'
import ScrollToTop from '../../../../components/ScrollToTop'
import Typography from '@material-ui/core/Typography'
import useRouter from '../../../../hooks/useRouter'
import useUser from '../../../../hooks/useUser'
import MuiEditIcon from '@material-ui/icons/Edit'

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: theme.typography.fontWeightMedium,
    color: '#898989',
  },
  subheadingGutter: {
    marginBottom: theme.spacing(4),
  },
}))

const EditIcon = styled(MuiEditIcon)({
  marginLeft: 8,
})

const EmployeeProfile: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles()
  const { match, history } = useRouter<{ employeeId: string }>()
  const { t } = useTranslation()
  const { user } = useUser()
  const { translate: translateGender } = useGenderEnum()
  const { translate: translateRole } = useRoleEnum()
  const { translate: translateCountry } = useCountryEnum()
  const { translate: translateEmploymentType } = useEmploymentTypeEnum()

  const employeeId = match.params.employeeId

  const { data, loading, error } = useQuery<TEmployeeData, TEmployeeVariables>(
    EMPLOYEE,
    {
      variables: { where: { id: employeeId } },
      fetchPolicy: 'cache-and-network',
    },
  )
  const employee = data && data.user
  const backTo =
    (history.location.state && history.location.state.from) ||
    `/${user.data.id}/employees`

  return (
    <>
      <ScrollToTop />
      <AppState loading={loading} />
      <GraphQLErrorSnackbar error={error} />
      <Box
        maxWidth="100%"
        width={1077}
        ml="auto"
        mr="auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {employee && (
          <>
            <Typography variant="h1" align="center">
              {`${employee.firstname} ${employee.lastname}`}
            </Typography>
            <Box maxWidth="100%" width={800} mt={6} mb={6}>
              <Typography variant="h2" className={classes.subheadingGutter}>
                {t('employees.baseData')}
              </Typography>

              <Grid container spacing={2}>
                <CenteredTextFields
                  label={t('common.roleType')}
                  text={translateRole(employee.role)}
                />
                <CenteredTextFields
                  label={t('employee.personalNumber')}
                  text={employee.personalNumber}
                />
                <CenteredTextFields
                  label={t('employee.dateOfEmployment')}
                  text={moment(employee.dateOfEmployment).format(
                    'DD. MM. YYYY',
                  )}
                />
                {employee.unit && (
                  <CenteredTextFields
                    label={t('common.unit')}
                    text={employee.unit.name}
                  />
                )}
                <CenteredTextFields
                  label={t('employee.employmentType')}
                  text={translateEmploymentType(employee.employmentType)}
                />
                <CenteredTextFields
                  label={t('common.email')}
                  text={employee.email}
                />
              </Grid>
            </Box>

            <Divider />

            <Box maxWidth="100%" width={800} mt={6} mb={6}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={4}
              >
                <Typography variant="h2">
                  {t('profile.personalData')}
                </Typography>

                <Button
                  color="primary"
                  component={Link}
                  to={{
                    pathname: `${props.match.url}/personal`,
                    state: {
                      employee,
                    },
                  }}
                >
                  {t('common.edit')}
                  <EditIcon fontSize="small" />
                </Button>
              </Box>

              <Grid container spacing={2}>
                <CenteredTextFields
                  label={t('common.firstname')}
                  text={employee.firstname}
                />
                <CenteredTextFields
                  label={t('common.lastname')}
                  text={employee.lastname}
                />
                <CenteredTextFields
                  label={t('common.gender')}
                  text={translateGender(employee.gender)}
                />

                {employee.role === 'EMPLOYEE' && (
                  <>
                    <CenteredTextFields
                      label={t('common.birthdate')}
                      text={
                        employee.birthdate &&
                        new Date(employee.birthdate).toLocaleDateString()
                      }
                    />
                    <CenteredTextFields
                      label={t('common.nationalIDNumber')}
                      text={employee.nationalIDNumber}
                    />
                    <CenteredTextFields
                      label={t('common.permanentAddress2')}
                      text={employee.permanentAddress}
                    />
                    <CenteredTextFields
                      label={t('common.czechTaxResidence')}
                      text={
                        employee.czechTaxResidence
                          ? t('common.yes').toUpperCase()
                          : t('common.no').toUpperCase()
                      }
                    />
                    <CenteredTextFields
                      label={t('common.nationality')}
                      text={translateCountry(employee.nationality)}
                    />
                  </>
                )}
              </Grid>
            </Box>

            <Button style={{ color: '#898989' }} component={Link} to={backTo}>
              <ArrowLeftIcon />
              {t('common.back')}
            </Button>
          </>
        )}
      </Box>
    </>
  )
}

export default EmployeeProfile
