/* eslint-disable @typescript-eslint/no-explicit-any */
import {action, decorate, observable, toJS} from 'mobx'
import React, {useContext, useEffect, useMemo, useRef} from 'react'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import isFunction from 'lodash/isFunction'
import isString from 'lodash/isString'
import isUndefined from 'lodash/isUndefined'
import mapKeys from 'lodash/mapKeys'
import mapValues from 'lodash/mapValues'
import reduce from 'lodash/reduce'
import omit from 'lodash/omit'
import pickBy from 'lodash/pickBy'
import isPlainObject from 'lodash/isPlainObject'
import set from 'lodash/set'
import Validator from 'validatorjs'
import {mapToObject, objectToMap} from '../utils'

let _id = 0
const id = () => {
  _id++
  return _id
}

Validator.useLang('cs') // TBD předělat po vyřešení nastavení jazyka

interface IIdentifier {
  [identifier: string]: string | number
}
interface IMultiRelationValue {
  connect?: IIdentifier[]
  disconnect?: IIdentifier[]
  delete?: IIdentifier[]
  create?: (any & IIdentifier)[]
  update?: {
    where: IIdentifier
    data: any
  }[]
}
interface ISingleRelationValue {
  connect?: IIdentifier
  disconnect?: IIdentifier
  delete?: IIdentifier
  create?: any & IIdentifier
  update?: {
    where: IIdentifier
    data: any
  }
}
interface IListValue {
  prisma_set?: any[]
}
type TInitialMultiRelationValue =
  | undefined
  | null
  | any[]
  | (IMultiRelationValue & { value: any[] })

const hasMultirelationKey = (input: object | null | undefined) => {
  return (
    input &&
    (input.hasOwnProperty('connect') ||
      input.hasOwnProperty('disconnect') ||
      input.hasOwnProperty('delete') ||
      input.hasOwnProperty('create') ||
      input.hasOwnProperty('update'))
  )
}
export const mergeMultirelationValueWithInitial = <K extends string>(
  key: K,
  value: Record<K, any>,
  initial: Record<K, any> | null | undefined,
) => {
  if (hasMultirelationKey(value[key])) {
    return {
      [key]: {
        ...value[key],
        value: (initial && initial[key]) || [],
      },
    }
  }
  return {}
}

type TRule = string | string[] | undefined
type TList = object[] | undefined

interface IProps<TData> {
  [path: string]: {
    omitOnSubmit?: boolean | ((data: TData, form: Form<TData>) => boolean)
    label?: string
    help?: string
    placeholder?:
      | string
      | undefined
      | ((data: TData, form: Form<TData>) => string | undefined)
    rule?: TRule | ((data: TData, form: Form<TData>) => TRule)
    list?: TList | ((data: TData, form: Form<TData>) => TList)
    type?: 'multi_relation' | 'single_relation' | 'list' | undefined
    isFileRelation?: boolean
    onEnter?: 'submit' // | 'focusNext' // TBD focus na další input
    // relationType?: 'connect' | 'crud' | undefined
    relationIdentifier?: string
    messages?: {
      // [rule: string]: string
      // Feel free to add new rule ;]
      required?: string
      required_if?: string
      email?: string
      date?: string
      numeric?: string
      digits?: string
      // Validatorjs bug: "different" template doesn't work
      different?: string
      same?: string
      accepted?: string
      denied?: string
    }
  }
}
interface IData {
  [key: string]: any
}
interface ITouched {
  [key: string]: boolean
}
interface IRule {
  [key: string]: string | string[]
}
interface ILabel {
  [key: string]: string
}
interface IOptions<TData> {
  variables?: any
  onSubmit?: (data: TData, form: Form<TData>) => Promise<any> | any
  onFieldChange?: (
    fieldPath: string,
    value: any,
    form: Form<TData>,
    error?: string,
  ) => Promise<any> | void
  onFieldBlur?: (
    fieldPath: string,
    value: any,
    form: Form<TData>,
    error?: string,
  ) => void
  submitWholeModel?: boolean
  submitNested?: boolean
  skipPrismasetReplace?: boolean
}

interface IState {
  isValid: boolean
  isDirty: boolean
  loading: boolean
  errors: string[]
  success: boolean
  // validatorErrors: ValidationErrors
}

interface ISharedState {
  isValid: boolean
}

interface IExtraOptions {
  onStateChange: (formId: string, state: ISharedState) => void
}

const substractArrayByField = (arr1: any[], arr2: any[], field: string) =>
  arr1.filter((v1) => !arr2.some((v2) => v1[field] === v2[field]))

// const concatUniqueByField = (
//   arr1: Array<any>,
//   arr2: Array<any> | undefined,
//   field: string,
// ) => {
//   const tmp = arr1.concat(arr2 || [])
//   const values = tmp.map(el => el[field])
//   return tmp.filter((item, idx) => values.indexOf(item[field]) === idx)
// }

interface IFormContext {
  registerForm: (form: Form<any>) => void
  unregisterForm: (form: Form<any>) => void
}

export const FormContext = React.createContext<IFormContext>({
  registerForm: () => {},
  unregisterForm: () => {},
})

class Form<TData = any> {
  // public readonly id: string
  public data: Map<string, any> = new Map()
  public changedData: any = {}
  public initials: IData = {}
  public touched: Map<string, boolean> = new Map()
  public focused: Map<string, boolean> = new Map()
  public validator: Validator.Validator<IData> = new Validator({}, {})
  public rules: IRule = {}
  public labels: ILabel = {}
  public props: IProps<TData> = {}
  public boundFields: Set<string> = new Set()
  public options: IOptions<TData>

  public state: IState = {
    isValid: true,
    isDirty: false,
    loading: false,
    errors: [],
    success: false,
    // validatorErrors: {},
  }

  public nestedForms: Set<Form> = new Set()
  public rootForm: Form | null = null
  public addNestedForm(form: Form) {
    this.nestedForms.add(form)
    form.rootForm = this
  }
  public removeNestedForm(form: Form) {
    this.nestedForms.delete(form)
    form.rootForm = null
  }
  public getNeighbourForms() {
    const neighbourForms: Form[] = []
    if (this.rootForm) {
      this.rootForm.nestedForms.forEach((f) => {
        if (f !== this) neighbourForms.push(f)
      })
    }
    return neighbourForms
  }

  // private stateOfNestedForms: Map<string, ISharedState> = new Map()
  // private onStateChangeHandler: IReactionDisposer

  public constructor(
    data: object,
    props: IProps<TData>,
    options: IOptions<TData>,
  ) {
    this.props = props
    this.options = options

    this.parseRules(props)
    this.parseLabels(props)

    this.initData(data)
  }

  public isValid() {
    let valid = this.state.isValid
    this.nestedForms.forEach((f) => {
      f.validate()
      if (!f.isValid()) {
        valid = false
      }
    })
    return valid
  }

  public initData(data: IData) {
    this.state.loading = false
    this.state.errors = []
    this.touched = new Map()
    this.changedData = {}
    this.state.isDirty = false

    this.data = objectToMap(toJS(data)) // objectToMap(cloneDeep(data)) // Vznikal mobx-error při opětovné editaci dítěte; toto to zdá se řeší - a nic to (očividně) nerozdrbalo.
    this.initials = cloneDeep(data)

    this.formatRelationData(data)
    this.validate()
  }

  public parseRules(props: IProps<TData>) {
    this.rules = pickBy(mapValues(props, (o) => o.rule)) as IRule
  }
  public parseLabels(props: IProps<TData>) {
    this.labels = pickBy(mapValues(props, (o) => o.label)) as ILabel
  }

  public takeOrGet<T>(value: T | ((data: TData, form: Form<TData>) => T)): T {
    return isFunction(value)
      ? value(mapToObject(this.data) as TData, this)
      : value
  }

  private formatRelationData(data: IData) {
    const multiRelations = Object.keys(this.props).filter(
      (key) => this.props[key].type === 'multi_relation',
      // || this.props[key].type === 'single_relation',
    )

    multiRelations.forEach((field) => {
      const fieldProps = this.props[field] || {}
      const ident = fieldProps.relationIdentifier || 'id'

      const init: TInitialMultiRelationValue = data[field]
      if (init) {
        if (!Array.isArray(init)) {
          const { value, ...rest } = init
          if (rest && rest.create && rest.create.slice) {
            rest.create = rest.create.map((it) => {
              const newId = id()
              if (it instanceof File) {
                // @ts-ignore
                it[ident] = newId
                return it
              } else {
                return { [ident]: newId, ...it }
              }
            })
          }
          this.data.set(field, rest)
          if (value && value.slice) {
            this.initials[field] = value
          } else {
            this.initials[field] = []
          }
          return
        }
      }
      this.data.set(field, {})
    })
  }

  private setData(fieldPath: string, data: any) {
    this.data.set(fieldPath, data)
    set(this.changedData, fieldPath, data)
    this.state.isDirty = true
    this.validate()
    this.onFieldChange(fieldPath)
  }

  public getValue(fieldPath: string) {
    const fieldProps = this.props[fieldPath] || {}
    const ident = fieldProps.relationIdentifier || 'id'
    const list = this.takeOrGet(fieldProps.list) || []
    const isFileRelation = fieldProps.isFileRelation || false

    if (fieldProps.type === 'multi_relation') {
      const value: IMultiRelationValue = this.data.get(fieldPath) || {}

      // const initialsOrigin = toJS(this.initials[fieldPath])
      // const initialsClone: any[] = cloneDeep(initialsOrigin)
      // let initials: any[] = initialsClone || []
      let initials: any[] = toJS(this.initials[fieldPath]) || []

      if (value.disconnect && value.disconnect.slice) {
        initials = substractArrayByField(initials, value.disconnect, ident)
      }

      if (value.delete && value.delete.slice) {
        initials = substractArrayByField(initials, value.delete, ident)
      }

      if (value.connect && value.connect.slice) {
        const connectIdents = value.connect.map((item) => item[ident])

        if (isFileRelation) {
          initials = [...initials, ...value.connect]
        } else {
          const connectItems = list.filter(
            (item: any) => connectIdents.indexOf(item[ident]) > -1,
          )
          initials = [...initials, ...connectItems]
        }
      }

      if (value.create && value.create.slice) {
        initials = [...initials, ...value.create]
      }

      if (value.update && value.update.slice) {
        initials = initials.map((item) => {
          const update =
            value &&
            value.update &&
            value.update.find(({ where }) => where[ident] === item[ident])
          if (update) {
            return Object.assign({}, item, update.data)
          }
          return item
        })

        // value.update.forEach((updateItem) => {
        //   const itemToUpdate = initials.find(
        //     (item) => item[ident] === updateItem.where[ident],
        //   )
        //   itemToUpdate && Object.assign(itemToUpdate, updateItem.data)
        // })
      }

      return toJS(initials)
    }

    if (fieldProps.type === 'single_relation') {
      const value: ISingleRelationValue = this.data.get(fieldPath) || {}
      const initials: any = toJS(this.initials[fieldPath])

      if (value.create) {
        return toJS(value.create)
      }
      if (value.connect) {
        return toJS(value.connect[ident])
      }

      if (initials && initials[ident]) {
        return toJS(initials[ident])
      }
    }

    if (fieldProps.type === 'list') {
      const value: IListValue = this.data.get(fieldPath) || {}
      const initials: any = toJS(this.initials[fieldPath])

      if (value.prisma_set) {
        return toJS(value.prisma_set) || []
      }
      // if (initials && initials.prisma_set) {
      //   return toJS(initials.prisma_set) || []
      // }
      return toJS(initials) || []
    }

    const result = toJS(this.data.get(fieldPath))
    if (['boolean', 'number'].includes(typeof result)) return result
    return result || ''
  }

  public getData(): TData {
    return mapToObject(this.data) as TData
  }

  public getDeletedValue(fieldPath: string) {
    const fieldProps = this.props[fieldPath] || {}
    const ident = fieldProps.relationIdentifier || 'id'

    if (fieldProps.type === 'multi_relation') {
      const value: IMultiRelationValue = this.data.get(fieldPath) || {}
      const initials: any[] = toJS(this.initials[fieldPath]) || []

      if (value.delete && value.delete.map) {
        const deletedIds = value.delete.map((item) => item[ident])

        return initials.filter((item) => deletedIds.includes(item[ident]))
      }

      return []
    }
  }

  public onSuccess(data: IData) {
    this.initData(data)
    this.state.success = true
  }

  public onFail(err: any) {
    if (err && Array.isArray(err.graphQLErrors)) {
      const errors = err.graphQLErrors.map(
        (gqlErrs: any) => gqlErrs.message,
      ) as string[]
      this.state.errors = errors
    }
    this.state.success = false
    this.state.loading = false
  }

  public async submit() {
    if (this.state.loading) {
      return true
    }

    if (this.isValid()) {
      this.state.loading = true
      if (this.options.submitNested) {
        this.nestedForms.forEach((f) => {
          f.submit()
        })
      }

      let data = toJS(
        this.options.submitWholeModel ? this.data : this.changedData,
      )

      // Remove client-temp ids
      Object.keys(this.props).forEach((field) => {
        const ident = this.props[field].relationIdentifier || 'id'

        if (this.props[field].type === 'multi_relation') {
          if (data[field] && data[field].create && data[field].create.forEach) {
            data[field].create.forEach((item: any) => delete item[ident])
          }
        }

        if (this.props[field].type === 'single_relation') {
          if (data[field] && data[field].create) {
            delete data[field].create[ident]
          }
        }
      })

      if (!this.options.skipPrismasetReplace) {
        data = this.replacePrismaset(data)
      }

      const omitOnSubmit = reduce(
        this.props,
        (result, value, key) => {
          if (value.omitOnSubmit) {
            if (
              (typeof value.omitOnSubmit === 'function' &&
                value.omitOnSubmit(mapToObject(this.data) as TData, this)) ||
              typeof value.omitOnSubmit === 'boolean'
            )
              return [...result, key]
          }
          return result
        },
        [] as string[],
      )

      if (this.options.onSubmit) {
        const result = await this.options.onSubmit(
          omit(data, omitOnSubmit) as any,
          this,
        )
        if (typeof result === 'string' || typeof result === 'boolean') {
          this.state.loading = false
          return result
        }
      }
      this.state.loading = false
      return true
    } else {
      this.setTouchedAll()
      return false
    }
  }

  public setField(fieldPath: string, value: any) {
    this.state.errors = []
    if (isString(fieldPath) && !isUndefined(value)) {
      this.state.isDirty = true
      this.data.set(fieldPath, value)
      set(this.changedData, fieldPath, value)
      this.validate()

      this.onFieldChange(fieldPath)

      return
    }

    // TODO set complex object: { fieldA: 'valueA', fieldB: { fieldC: 'valueBC'}}
  }

  public blurField(fieldPath: string) {
    this.touched.set(fieldPath, true)
    this.onFieldBlur(fieldPath)
  }

  public focusField(fieldPath: string) {
    this.focused.set(fieldPath, true)
    // const element = document.getElementById(fieldPath)
    // if (element) {
    //   element.focus()
    // }
  }

  public relationConnect(fieldPath: string, value: string | number) {
    const fieldProps = this.props[fieldPath] || {}
    const ident = fieldProps.relationIdentifier || 'id'

    const initData: any = get(this.initials, fieldPath) || []

    if (fieldProps.type === 'multi_relation') {
      const data: IMultiRelationValue = {
        ...(this.data.get(fieldPath) || {}),
      }

      if (data.disconnect) {
        data.disconnect = data.disconnect.filter(
          (item) => item[ident] !== value,
        )
        if (data.disconnect.length === 0) {
          delete data.disconnect
        }
      }

      if (initData.every((item: any) => item[ident] !== value)) {
        data.connect = [...(data.connect || []), { [ident]: value }]
      }

      this.setData(fieldPath, data)
    }

    if (fieldProps.type === 'single_relation') {
      // const data: ISingleRelationValue = {
      //   ...(get(this.data, fieldPath) || {}),
      // } as ISingleRelationValue
      const data = {
        connect: { [ident]: value },
      }

      this.setData(fieldPath, data)
    }
  }

  public relationDisconnect(fieldPath: string, value: string | number) {
    const fieldProps = this.props[fieldPath] || {}
    const ident = fieldProps.relationIdentifier || 'id'

    const initData: any = get(this.initials, fieldPath) || []

    // if(fieldProps.type === 'multi_relation')
    const data: IMultiRelationValue = {
      ...(this.data.get(fieldPath) || {}),
    }

    if (data.connect) {
      data.connect = data.connect.filter((item) => item[ident] !== value)

      if (data.connect.length === 0) {
        delete data.connect
      }
    }

    if (initData.some((item: any) => item[ident] === value)) {
      data.disconnect = [...(data.disconnect || []), { [ident]: value }]
    }

    this.setData(fieldPath, data)
  }

  public relationCreate(fieldPath: string, value: any) {
    const fieldProps = this.props[fieldPath] || {}
    const ident = fieldProps.relationIdentifier || 'id'

    if (fieldProps.type === 'multi_relation') {
      const data: IMultiRelationValue = {
        ...(this.data.get(fieldPath) || {}),
      }
      const newId = id()
      if (value instanceof File) {
        // @ts-ignore
        value[ident] = newId
        data.create = [...(data.create || []), value]
      } else {
        data.create = [...(data.create || []), { [ident]: newId, ...value }]
      }

      this.setData(fieldPath, data)
      return data.create[data.create.length - 1]
    }

    if (fieldProps.type === 'single_relation') {
      // const data: ISingleRelationValue = {
      //   ...(get(this.data, fieldPath) || {}),
      // } as ISingleRelationValue
      const data = {
        create: value,
      }

      this.setData(fieldPath, data)
      return this.getValue(fieldPath)
    }
  }

  public listSet(fieldPath: string, value: any[]) {
    this.setData(fieldPath, { prisma_set: value })
  }

  public relationUpdate(fieldPath: string, id: string | number, value: any) {
    const fieldProps = this.props[fieldPath] || {}
    const ident = fieldProps.relationIdentifier || 'id'

    // if (fieldProps.type === 'multi_relation') {
    const data: IMultiRelationValue = {
      ...(this.data.get(fieldPath) || {}),
    }
    let updated = false

    if (data.create) {
      data.create = data.create.map((item) => {
        if (item[ident] === id) {
          updated = true
          return { ...item, ...value }
        }
        return item
      })
    }

    if (!updated && data.update) {
      data.update = data.update.map((item) => {
        if (item.where[ident] === id) {
          updated = true
          return { ...item, data: { ...item.data, ...value } }
        }
        return item
      })
    }

    if (!updated) {
      data.update = [
        ...(data.update || []),
        {
          where: { [ident]: id },
          data: value,
        },
      ]
    }

    this.setData(fieldPath, data)
  }

  public relationReset(fieldPath: string, id: string | number) {
    const fieldProps = this.props[fieldPath] || {}
    const ident = fieldProps.relationIdentifier || 'id'

    const data: IMultiRelationValue = {
      ...(this.data.get(fieldPath) || {}),
    }

    if (data.create) {
      data.create = data.create.filter((item) => item[ident] !== id)
    }

    if (data.update) {
      data.update = data.update.filter((item) => item.where[ident] !== id)
    }

    this.setData(fieldPath, data)
  }

  public relationDelete(fieldPath: string, value: string | number) {
    const fieldProps = this.props[fieldPath] || {}
    const ident = fieldProps.relationIdentifier || 'id'
    const initData: any = get(this.initials, fieldPath) || []

    // if (fieldProps.type === 'multi_relation') {
    const data: IMultiRelationValue = {
      ...(this.data.get(fieldPath) || {}),
    }

    if (data.create) {
      data.create = data.create.filter((item) => item[ident] !== value)

      if (data.create.length === 0) {
        delete data.create
      }
    }

    if (data.update) {
      data.update = data.update.filter((item) => item.data[ident] !== value)

      if (data.update.length === 0) {
        delete data.update
      }
    }

    if (data.connect) {
      data.connect = data.connect.filter((item) => item[ident] !== value)

      if (data.connect.length === 0) {
        delete data.connect
      }
    }

    if (initData.some((item: any) => item[ident] === value)) {
      data.delete = [...(data.delete || []), { [ident]: value }]
    }

    this.data.set(fieldPath, data)
    set(this.changedData, fieldPath, data)
    this.state.isDirty = true
    this.validate()
    this.onFieldChange(fieldPath)
  }

  public undoRelationDelete(fieldPath: string, value: string | number) {
    const fieldProps = this.props[fieldPath] || {}
    const ident = fieldProps.relationIdentifier || 'id'

    if (fieldProps.type === 'multi_relation') {
      const data: IMultiRelationValue = {
        ...(this.data.get(fieldPath) || {}),
      }

      if (data.delete) {
        data.delete = data.delete.filter((item) => item[ident] !== value)

        if (data.delete.length === 0) {
          delete data.delete
        }
      }

      this.setData(fieldPath, data)
    }

    if (fieldProps.type === 'single_relation') {
      const data: ISingleRelationValue = {
        ...(this.data.get(fieldPath) || {}),
      }

      delete data.delete

      this.setData(fieldPath, data)
    }
  }

  private onFieldChange(fieldPath: string) {
    if (this.options.onFieldChange) {
      this.options.onFieldChange(
        fieldPath,
        this.data.get(fieldPath),
        this,
        this.validator.errors.first(fieldPath) || undefined,
      )
    }
  }

  private onFieldBlur(fieldPath: string) {
    if (this.options.onFieldBlur) {
      this.options.onFieldBlur(
        fieldPath,
        this.data.get(fieldPath),
        this,
        this.validator.errors.first(fieldPath) || undefined,
      )
    }
  }

  public setTouchedAll() {
    this.nestedForms.forEach((f) => f.setTouchedAll())
    this.boundFields.forEach((fieldPath) => this.touched.set(fieldPath, true))
  }

  public setUntouchedAll() {
    this.nestedForms.forEach((f) => f.setUntouchedAll())
    this.boundFields.forEach((fieldPath) => this.touched.set(fieldPath, false))
  }

  public reset() {
    this.data = objectToMap(toJS(this.initials))
    this.changedData = {}
    this.state.isDirty = false
    this.state.errors = []
    this.validate()
  }

  public validate() {
    const rules = pickBy(
      mapValues(toJS(this.rules), (o) => this.takeOrGet<TRule>(o)),
      (v) => !!v,
    ) as Validator.Rules

    const form = this
    // Validate form data instead "Nexus" data
    const values = Object.keys(this.props).reduce(
      (prev, curr) => ({
        ...prev,
        [curr]: form.getValue(curr),
      }),
      {},
    )

    // Custom error messages
    const messages = reduce(
      this.props,
      (result, value, key) => {
        if (value.messages) {
          return {
            ...result,
            ...mapKeys(value.messages, (value, key2) => `${key2}.${key}`),
          }
        }
        return result
      },
      {},
    )

    const validator = new Validator(toJS(values), rules, messages)
    validator.setAttributeNames(this.labels)
    this.state.isValid = validator.passes() || false
    // this.state.validatorErrors = validator.errors.all()
    this.validator = validator
    // this.errors = validator.errors;
  }

  public replacePrismaset<T>(o: T): T {
    const eachProperty = (o: any): any => {
      if (isPlainObject(o)) {
        return Object.keys(o).reduce<{ [key: string]: any }>(
          (accum, key) => ({
            ...accum,
            [key === 'prisma_set' ? 'set' : key]: eachProperty(o[key]),
          }),
          {},
        )
      }

      if (o && o.map) {
        return o.map(eachProperty)
      }

      return o
    }

    return eachProperty(o)

    // debugger
    // // TODO nelze stringify kvuli Streamům přu ukládání files, musí se asi rekurzivně projít komplet data
    // let str = JSON.stringify(toJS(o))
    // str = str.replace(/\"prisma_set\":/g, '"set":')
    // return JSON.parse(str)
  }

  // public setStateOfNestedForm(formId: string, state: ISharedState) {
  //   this.stateOfNestedForms.set(formId, state)
  // }
  // public clearStateOfNestedForm(formId: string) {
  //   this.stateOfNestedForms.delete(formId)
  // }

  // public dispose() {
  //   console.log('__dispose')
  //   this.onStateChangeHandler && this.onStateChangeHandler()
  // }
}

decorate(Form, {
  data: observable,
  state: observable,
  touched: observable,
  focused: observable,

  validate: action,
  initData: action,
  setField: action.bound,
  setTouchedAll: action.bound,
  submit: action.bound,
  onSuccess: action.bound,
  onFail: action.bound,
  reset: action.bound,
})

function usePrevious(value: any) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

function useForm<TData>(
  data: any,
  props: IProps<TData>,
  options: IOptions<TData>,
) {
  const data_ = data || {}
  // Object.keys(props)
  //   .filter(k => props[k] && props[k].type === 'list')
  //   .forEach(k => {
  //     if (
  //       data_[k] &&
  //       Boolean(data_[k].prisma_set) &&
  //       data_[k].prisma_set.slice
  //     ) {
  //       data_[k] = data_[k].prisma_set.slice()
  //     }
  //   })

  // console.log('__useForm', data, data_)

  const form = useRef(new Form<TData>(data_, props, options)).current

  const { registerForm, unregisterForm } = useContext(FormContext)
  useEffect(() => {
    registerForm && registerForm(form)
    return () => unregisterForm && unregisterForm(form)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // @ts-ignore
  window.xform = form

  // Pokud se pocatecni data zmenila, tak nastavi do formu - napr. po nacteni dat z BE
  const previousData = usePrevious(data)
  useEffect(() => {
    if (!isEqual(previousData, data)) {
      form.initData(data || {})
    }
  }, [data, form, previousData])

  useMemo(() => {
    form.props = props
    form.parseLabels(props)
    form.parseRules(props)
    // Po nastaveni labels a rules musi probehnout validate()
    form.validate()
  }, [form, props])

  useMemo(() => {
    form.options = options
  }, [form.options, options])

  const handleChange = (fieldPath: string) => (value: any) => {
    form.setField(fieldPath, value)
    form.validate()
  }

  const handleRelationConnect = (fieldPath: string) => (
    value: string | number,
  ) => form.relationConnect(fieldPath, value)

  const handleRelationDisconnect = (fieldPath: string) => (
    value: string | number,
  ) => form.relationDisconnect(fieldPath, value)

  const handleRelationCreate = (fieldPath: string) => (value: any) =>
    form.relationCreate(fieldPath, value)

  const handleRelationDelete = (fieldPath: string) => (value: any) =>
    form.relationDelete(fieldPath, value)

  const handleUndoRelationDelete = (fieldPath: string) => (value: any) =>
    form.undoRelationDelete(fieldPath, value)

  const handleRelationUpdate = (fieldPath: string) => (
    id: string | number,
    data: any,
  ) => form.relationUpdate(fieldPath, id, data)

  const handleRelationReset = (fieldPath: string) => (id: string | number) =>
    form.relationReset(fieldPath, id)

  const handleListSet = (fieldPath: string) => (value: any[]) =>
    form.listSet(fieldPath, value)

  const handleBlur = (fieldPath: string) => () => {
    form.blurField(fieldPath)
  }

  const handleFocus = (fieldPath: string) => () => {
    form.focusField(fieldPath)
  }

  const handleOnEnter = (fieldPath: string) => (e: any) => {
    if (form.props[fieldPath].onEnter && e.key === 'Enter') {
      switch (form.props[fieldPath].onEnter) {
        case 'submit':
          form.submit()
          break
        // case 'focusNext':
        //   if (!form.validator.errors.first(fieldPath)) {
        //     const boundFields: string[] = []
        //     form.boundFields.forEach(field => boundFields.push(field))
        //     const index = boundFields.indexOf(fieldPath)
        //     if (boundFields[index + 1]) {
        //       form.focusField(boundFields[index + 1])
        //     }
        //   }
        default:
          // console.log(`${fieldPath}.onEnter: ---`)
          break
      }
    }
  }

  /**
   *  TODO bindovani na pole
   *  bind(<path s wildcards>, Array<identifikacni funkce>)
   *  bind("child.*.child.*.firstname", [child => child.id === "123", child => child.id === "1234"])
   */
  const bind = (fieldPath: string) => {
    form.boundFields.add(fieldPath)

    const props = form.props[fieldPath] || {}

    const label = props.label || ''
    const help = props.help || ''
    const placeholder =
      form.takeOrGet<string | undefined>(props.placeholder) || ''
    const list = form.takeOrGet<TList>(props.list) || []
    const formType = props.type
    const isFileRelation = props.isFileRelation || false

    return {
      // nepoužívat id - riziko duplicity, např. na zaměstnavatelích
      id: fieldPath,
      value: form.getValue(fieldPath),
      deletedValue: form.getDeletedValue(fieldPath),
      identifier: props.relationIdentifier || 'id',
      valueField: props.relationIdentifier || 'id',
      onChange: handleChange(fieldPath),
      onBlur: handleBlur(fieldPath),
      onFocus: handleFocus(fieldPath),
      touched: form.touched.get(fieldPath) || false,
      error: form.validator.errors.first(fieldPath) || undefined,
      label,
      formType,
      isFileRelation,
      list,
      placeholder,
      help,
      onRelationConnect: handleRelationConnect(fieldPath),
      onRelationDisconnect: handleRelationDisconnect(fieldPath),
      onRelationCreate: handleRelationCreate(fieldPath),
      onRelationDelete: handleRelationDelete(fieldPath),
      onRelationUpdate: handleRelationUpdate(fieldPath),
      onRelationReset: handleRelationReset(fieldPath),
      onUndoRelationDelete: handleUndoRelationDelete(fieldPath),
      onListSet: handleListSet(fieldPath),
      onKeyUp: handleOnEnter(fieldPath),
    }
  }

  return { bind, form }
}

export default useForm

const FormProvider: React.FC<{ form: Form }> = ({ form, children }) => {
  return (
    <FormContext.Provider
      value={{
        registerForm: form.addNestedForm.bind(form),
        unregisterForm: form.removeNestedForm.bind(form),
        // setFormState: form.setStateOfNestedForm,
        // clearFormState: form.clearStateOfNestedForm,
      }}
    >
      {children}
    </FormContext.Provider>
  )
}

export { Form, FormProvider }
