
import { Observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import React, {useState} from 'react'
import {useMutation, useQuery} from "react-apollo";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {BlueButton, handleDownload} from "../../pages/Inside/pages/TaxRebates";
import CircularProgress from "@material-ui/core/CircularProgress";
import {CHECK_ANNUAL_PDF_FORM, GENERATE_ANNUAL_PDF_FORM} from "./gql";
import {TAnnualRebate, TFile} from "../../pages/Inside/pages/TaxRebates/gql";

export type AnnualRebateWithFiles = Pick<TAnnualRebate, 'id'> & {
  file: Pick<TFile, 'id'> | null
  fileMonthly: Pick<TFile, 'id'> | null
}

const useStyles = makeStyles<Theme, { loading?: boolean }>(() => ({
  buttonText: {
    visibility: (props) => props.loading ? "hidden" : "visible"
  },
  button: {
    position: "relative"
  },
  icon: {
    display: "flex",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  }
}))

interface IProps {
  annualRebate: AnnualRebateWithFiles,
}

const hasSomeFile = (rebate: AnnualRebateWithFiles): boolean => {
  return !!rebate && !!(
      (rebate && rebate.file && rebate.file.id)
      || (rebate && rebate.fileMonthly && rebate.fileMonthly.id))
}
const hasDifferentFiles = (oldRequest: AnnualRebateWithFiles, newRequest: AnnualRebateWithFiles): boolean => {
  return !!newRequest && !!oldRequest && (
      (oldRequest && newRequest && (oldRequest.file ? oldRequest.file.id : null) !== (newRequest.file ? newRequest.file.id : null))
      || (oldRequest && newRequest && (oldRequest.fileMonthly ? oldRequest.fileMonthly.id : null) !== (newRequest.fileMonthly ? newRequest.fileMonthly.id : null))
  )
}
const download = (rebate: AnnualRebateWithFiles) => {
  handleDownload(...[
    ...(rebate.file && rebate.file.id ? [{id: rebate.file.id}] : []),
    ...(rebate.fileMonthly && rebate.fileMonthly.id ? [{id: rebate.fileMonthly.id}] : []),
    ]
  )
}

export const FileButton: React.FC<{onClick: () => void, loading?: boolean}> = ({ children, onClick, loading }) => {
  const classes = useStyles({ loading })

  return (
    <BlueButton
      variant="contained"
      size="large"
      onClick={onClick}
      disabled={loading}
    >
      <span className={classes.buttonText}>{children}</span>
      {loading ? (
        <span className={classes.icon}>
          <CircularProgress color={'inherit'} size={'1rem'} />
        </span>
      ) : null}
    </BlueButton>
  )
}

const AnnualPdfFormDownload: React.FC<IProps> = (props) => {
  const { t } = useTranslation()

  const [rebate, setRebate] = useState<AnnualRebateWithFiles & { shouldHaveAnnualForm?: boolean, shouldHaveAdditionalForm?: boolean }>(props.annualRebate)
  const [loadingBull, setLoadingBull] = useState(false)

  const { startPolling, stopPolling } = useQuery<{ annualRebate: AnnualRebateWithFiles }>(CHECK_ANNUAL_PDF_FORM, {
    variables: { where: { id: rebate.id } },
    skip: !loadingBull,
    fetchPolicy: 'no-cache',
    pollInterval: 2500,
    notifyOnNetworkStatusChange: true,
    onError(e){
      console.error(e)
    },
    onCompleted(response) {
      const annualRebate = response && response.annualRebate

      if (annualRebate && hasSomeFile(annualRebate) && hasDifferentFiles(rebate, annualRebate)) {
        stopPolling()
        setLoadingBull(false)
        setRebate({
          ...annualRebate,
          shouldHaveAnnualForm: true,
          shouldHaveAdditionalForm: true
        })
        download(annualRebate)
      }
    },
  })

  const [requestGenerateForms] = useMutation(GENERATE_ANNUAL_PDF_FORM, {
    variables: {where: { id: props.annualRebate.id }},
    onError: () => {
      setLoadingBull(false)
    },
    onCompleted: () => {
      startPolling(2500)
    }
  })

  const generateForms = async () => {
    setLoadingBull(true)
    await requestGenerateForms()
  }

  return (
    <Observer>
      {() => (
          <>
            {rebate && rebate.shouldHaveAnnualForm && rebate.file && rebate.file.id && !loadingBull &&
              <FileButton
                onClick={() => rebate.file && handleDownload({ id: rebate.file.id })}
              >
                {t('taxRebates.downloadButton')}
              </FileButton>
            }

            {rebate && rebate.shouldHaveAdditionalForm && rebate.fileMonthly && rebate.fileMonthly.id && !loadingBull &&
              <FileButton
                onClick={() => rebate.fileMonthly && handleDownload({ id: rebate.fileMonthly.id })}
              >
                {t('taxRebates.downloadButtonXD')}
              </FileButton>
            }

            {rebate && rebate.shouldHaveAnnualForm && !hasSomeFile(rebate) &&
              <FileButton
                loading={loadingBull}
                onClick={async () => await generateForms()}
              >
                {t('taxRebates.downloadButton')}
              </FileButton>
            }
          </>
        )
      }
    </Observer>
  )
}

export default AnnualPdfFormDownload
