import React from "react"
import { useTranslation } from "react-i18next"
import { inject } from "mobx-react"

import { Store, TRebateName } from "../Store"
import InfoBox from "../../../../../../components/InfoBox"

interface CancelNotificationProps {
    rebateName: Exclude<TRebateName, 'taxDomicilDocumentApproval' | 'residenceAuthorisationDocumentApproval'>
    store?: Store
}

const _CancelNotification: React.FC<CancelNotificationProps> = ({ rebateName, store }) => {
    const { t } = useTranslation()

    if (!store) {
        return null
    }

    const data = store.getData()

    if (!data[rebateName]) {
        return null
    }

    if (data[rebateName].status !== 'CANCELED') {
        return null
    }

    return (
        <InfoBox title={t('common.notice')} type='warning' mt={2}>
            {t('settlementRequest.canceledMessage')}
        </InfoBox>
    )
}

export const CancelNotification = inject('store')(_CancelNotification)
