import {ICustomerData, IRoleData, IUserDeleteData, NEW_CUSTOMER_ROLES, ROLES, TUser, USER_DELETE,} from './gql'
import {Role} from '../../../../generated/globalTypes'
import {createNewCustomer} from '../../../../generated/createNewCustomer'
import {superadminUsers, superadminUsers_superadminUsers,} from '../../../../generated/superadminUsers'
import {makeStyles} from '@material-ui/core/styles'
import {Observer} from 'mobx-react'
//import { useMutation } from 'react-apollo-hooks'
import {useMutation} from 'react-apollo'
import {useTranslation} from 'react-i18next'
import Dialog from '../../../../components/Dialog'
import DialogActions from '../../../../components/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../../../../components/DialogTitle'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import SubmitButton from '../../../../components/form/SubmitButton'
import useForm from '../../../../hooks/useForm'
import {Typography} from '@material-ui/core'
import {Center, IconCircle} from '../../../../components/CommonBox'
import {OpravduSmazat} from '../../../../components/icons/KubikIcons'
import useUser from '../../../../hooks/useUser'

const useStyles = makeStyles((theme) => ({
  errorButton: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
}))

type TRole = Omit<superadminUsers_superadminUsers, '__typename'>

interface IRoleRemoveDialogProps {
  open: boolean
  role: TRole | null
  customer:
    | ICustomerData['customer']
    | createNewCustomer['createNewCustomer']
    | undefined
    | null
  onClose: () => void
}
const RoleRemoveDialog: React.FC<IRoleRemoveDialogProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { onClose, open, role } = props
  const id = role && role.id
  const { user } = useUser()

  const isNewCustomer = user.isSuperadmin()

  const [roleDelete] = useMutation<IUserDeleteData>(USER_DELETE, {
    update: (store, { data }) => {
      if (!isNewCustomer) {
        const cache = store.readQuery<IRoleData>({ query: ROLES })

        if (data && cache && Array.isArray(cache.roles)) {
          cache.roles = cache.roles.filter((u) => u.id !== data.deleteUser.id)
          store.writeQuery({ query: ROLES, data: cache })
        }
      } else {
        const cache = store.readQuery<superadminUsers>({
          query: NEW_CUSTOMER_ROLES,
          variables: {
            where: {
              customerId: (props.customer && props.customer.id) || '',
              role_not: Role.EMPLOYEE,
            },
          },
        })
        if (data && cache && Array.isArray(cache.superadminUsers)) {
          cache.superadminUsers = cache.superadminUsers.filter(
            (u) => u.id !== data.deleteUser.id,
          )

          store.writeQuery({
            query: NEW_CUSTOMER_ROLES,
            data: cache,
            variables: {
              where: {
                customerId: (props.customer && props.customer.id) || '',
                role_not: Role.EMPLOYEE,
              },
            },
          })
        }
      }
    },
    refetchQueries: ['customer'],
  })

  const { form } = useForm<TUser>(
    { id },
    {},
    {
      variables: { where: { id } },
      async onSubmit(formData, form) {
        try {
          const { data } = await roleDelete({
            variables: form.options.variables,
          })
          if (data) {
            onClose()
          }
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" scroll="body">
      <DialogTitle form={form} onClose={onClose}>
        <Center mb={2}>
          <IconCircle fontSize="3rem">
            <OpravduSmazat fontSize="inherit" color="primary" />
          </IconCircle>
        </Center>
        {t('customerOptions.removeRoleDialogHeading')}
      </DialogTitle>

      <DialogContent>
        <Observer>
          {() => (
            <Grid container direction="column" spacing={1}>
              <Grid sm item>
                <Typography align="center">
                  „{role && `${role.firstname} ${role.lastname}`}“
                </Typography>
              </Grid>
            </Grid>
          )}
        </Observer>
      </DialogContent>

      <DialogActions dividerProps={{ mt: 1 }}>
        <SubmitButton
          classes={{ button: classes.errorButton }}
          form={form}
          label={t('common.remove')}
        />
      </DialogActions>
    </Dialog>
  )
}

export default RoleRemoveDialog
