import {DanoveResidenstvi, Finish, Person} from '../../../../components/icons/KubikIcons'
import {styled} from '@material-ui/core/styles'
import {useTranslation} from 'react-i18next'
import InsideLayout from '../../../../components/layouts/InsideLayout'
import React, {useState} from 'react'
import PersonalInfo from './PersonalInfo'
import Completion from './Completion'
import ResidentialInfo from "./ResidentialInfo";
import PercentageStepper from "../../../../components/PercentageStepper";

const Container = styled('div')({
  maxWidth: '100%',
  marginRight: 'auto',
  marginLeft: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const EmployeeEntryForm: React.FC = () => {
  const { t } = useTranslation()
  const [activeStep, setActiveStep] = useState(0)

  function handleNext() {
    setActiveStep((prevActiveStep) =>
      prevActiveStep === 2 ? 2 : prevActiveStep + 1,
    )
  }

  function handlePrev() {
    setActiveStep((prevActiveStep) =>
      prevActiveStep === 0 ? 0 : prevActiveStep - 1,
    )
  }

  return (
    <InsideLayout>
      <Container>
        <PercentageStepper activeStep={activeStep} steps={[
          {label: t('entryform.personalInfoHeading'), icon: <Person fontSize="default" />},
          {label: t('entryform.residentialInfoHeading'), icon: <DanoveResidenstvi fontSize="default" />},
          {label: t('entryform.step2Heading'), icon: <Finish fontSize="default" />},
        ]} />
        {activeStep === 0 && <PersonalInfo nextStep={handleNext} />}
        {activeStep === 1 && <ResidentialInfo prevStep={handlePrev} nextStep={handleNext} />}
        {activeStep === 2 && <Completion prevStep={handlePrev} />}
      </Container>
    </InsideLayout>
  )
}

export default EmployeeEntryForm
