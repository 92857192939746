import {FINISH_OWN_ENTRY_FORM, IFinishOwnEntryFormData,} from '../EntryForm/gql'
import {Divider, Green} from '../../../../components/CommonBox'
import {Link} from 'react-router-dom'
import {makeStyles} from '@material-ui/core/styles'
import {NexusGenEnums} from '../../../../../../server/src/generated/nexus'
import {Trans, useTranslation} from 'react-i18next'
import {useMutation} from 'react-apollo'
import {Vitejte} from '../../../../components/icons/KubikIcons'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import InsideLayout from '../../../../components/layouts/InsideLayout'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import React from 'react'
import ScrollToTop from '../../../../components/ScrollToTop'
import Typography from '@material-ui/core/Typography'
import useRouter from '../../../../hooks/useRouter'
import useUser from '../../../../hooks/useUser'
import useAccount from "../../../../hooks/useAccount";

const useStyles = makeStyles((theme) => ({
  paper: {
    alignItems: 'center',
    borderRadius: '5px',
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.25)',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 1080,
    maxWidth: '100%',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  listItem: {
    fontWeight: 'bold',
    '&::before': {
      color: '#000000',
      content: '"\\2022"',
      display: 'flex',
      fontSize: '1.7rem',
      marginLeft: '-1rem',
      marginRight: theme.spacing(0.8),
      verticalAlign: 'middle',
      alignSelf: 'baseline',
    },
  },
  listItemText: {
    fontWeight: 'bold',
    fontSize: '0.9rem',
  },
  noVerticalPadding: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}))

const Start: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useUser()
  const { history } = useRouter()
  const {refetch: refetchAccount} = useAccount()

  const [finishOwnEntryForm] = useMutation<IFinishOwnEntryFormData>(
    FINISH_OWN_ENTRY_FORM,
  )

  const listItemClasses = {
    primary: classes.listItemText,
    dense: classes.noVerticalPadding,
  }
  const texts: { [key in NexusGenEnums['Role']]: string[] } = {
    EMPLOYEE: [
      'checkStatus',
      'makeRequests',
      'submitChanges',
      'downloadForms',
      'uploadDocuments',
      'contactAccountant',
      'manageProfile',
    ],
    ACCOUNTANT: [
      'manageSettlementRequests',
      'manageEmployees',
      'uploadDocuments',
      'contactEmployees',
      'watchStatistics',
      'addEmployees',
    ],
    KEEPER: [
      'manageSettlementRequests',
      'manageEmployees',
      'uploadDocuments',
      'contactEmployees',
      'watchStatistics',
      'addEmployees',
      'manageCustomer',
    ],
    VIEWER: [
      'manageSettlementRequests',
      'manageEmployees',
      'uploadDocuments',
      'contactEmployees',
      'watchStatistics',
      'addEmployees',
    ],
    IMPLEMENTATOR: [
      'uploadDocuments',
      'watchStatistics',
      'addEmployees',
      'manageCustomer',
    ],
    SUPPORT: [
    ],
  }

  const closeButtonTo = (url: string) => {
    const handleClose = async (
      e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ) => {
      e.preventDefault()
      if (!user.isEmployee() && !user.data.entryFormDoneAt) {
        await finishOwnEntryForm()
        await refetchAccount()
      }
      history.push(url)
    }

    return {
      onClick: handleClose,
      component: Link,
      to: url,
    }
  }

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <Box className={classes.paper}>
        <Typography variant="h1" align="center">
          {t('start.heading')}
        </Typography>
        <Vitejte fontSize="large" title={t('start.heading')} />
        <Box width="43%">
          <Typography variant="h3" align="center">
            <Trans i18nKey="start.subheading">
              Nyní máte volný přístup do celé aplikace. <br />
              Při každém přihlášení se vždy ocitnete na Přehledu, kde zjistíte,
              co na Vás čeká a je potřeba provést.
            </Trans>
          </Typography>
        </Box>
        <Box width="65%">
          <Divider my={4} />
          <Typography variant="h3">
            <Green>
              {t('start.actions.heading', { context: user.data.role })}:
            </Green>
          </Typography>
          <List dense>
            {texts[user.data.role].map((text, i) => (
              <ListItem key={i} classes={{ root: classes.listItem }}>
                <ListItemText classes={listItemClasses}>
                  {t(`start.actions.${text}`, {
                    context: user.data.role,
                  })}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Box>
        <Divider my={4} width="95%" />
        <Button
          color="primary"
          variant="contained"
          {...closeButtonTo(`/${user.data.id}/overview`)}
        >
          {t('common.close')}
        </Button>
      </Box>
    </InsideLayout>
  )
}

export default Start
