class SelectedUser {
  private static instance: SelectedUser
  private _userId = localStorage.getItem('selectedUserId') || ''

  public get userId() {
    return this._userId
  }

  public set userId(userId: string) {
    this._userId = userId
  }

  public setUserId(userId?: string) {
    this.userId = userId || ''
    localStorage.setItem('selectedUserId', this.userId)
  }

  public resetLocalStorage() {
    localStorage.setItem('selectedUserId', '')
  }

  private constructor() {}

  public static getInstance(): SelectedUser {
    if (!SelectedUser.instance) {
      SelectedUser.instance = new SelectedUser()
    }

    return SelectedUser.instance
  }
}

export default SelectedUser
