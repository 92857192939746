import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const OpenIdLogo: React.FC<Props> = ({
  viewBox = '0 0 120 120',
  ...props
}: Props) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      {...props}
      component={(svgProps: Props) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width={120} height={120} {...svgProps}>
            <g>
              <path
                d="M 108.17204,46.863826 C 96.774195,39.053174 80.860216,34.201104 63.440861,34.201104 28.387097,34.201104 0,53.491044 0,77.27803 0,99.053179 23.655916,116.923 54.301077,119.99993 V 107.45554 C 33.655915,104.61531 18.172046,92.189272 18.172046,77.27803 c 0,-16.923081 20.215052,-30.769234 45.268815,-30.769234 12.473118,0 23.763443,3.431953 31.935485,8.994083 L 83.763444,63.431875 H 120 V 38.816488 Z"
                fill="#cccccc"/>
              <path
                d="m 54.301077,12.899327 v 94.556213 12.54439 L 72.473119,107.45554 V -8.2816444e-5 Z"
                fill="#ff6200"/>
            </g>
          </svg>
        )
      }}
    />
  )
}

export default OpenIdLogo
