import {makeStyles, Theme} from '@material-ui/core/styles'
import {Observer} from 'mobx-react'
import {
  CUSTOMER_UPDATE,
  CUSTOMER_UPDATE_SUPERADMIN,
  ICustomerData,
  ICustomerUpdateData,
  ICustomerUpdateVariables,
  NEW_CUSTOMER_EMPLOYEES,
  NEW_CUSTOMER_UNITS,
  TUnit,
} from './gql'
import {Role} from '../../../../generated/globalTypes'
import {createNewCustomer} from '../../../../generated/createNewCustomer'
import {
  customerUpdateSuperadmin,
  customerUpdateSuperadminVariables,
} from '../../../../generated/customerUpdateSuperadmin'
import {superadminUnits, superadminUnitsVariables,} from '../../../../generated/superadminUnits'
import {
  superadminEmployees,
  superadminEmployees_superadminUsers,
  superadminEmployeesVariables,
} from '../../../../generated/superadminEmployees'
import {NexusGenFieldTypes} from 'kubik-server'
import {useMutation, useQuery} from 'react-apollo'
import {Trans, useTranslation} from 'react-i18next'
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconPaper from '../../../../components/IconPaper'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import useForm from '../../../../hooks/useForm'
import {HromadnePridat, ManualnePridat, PridatWhite, StahnoutSoubor,} from '../../../../components/icons/KubikIcons'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import Divider from '../../../../components/Divider'
import Employees from './Employees'
import Box from '@material-ui/core/Box'
import {Green} from '../../../../components/CommonBox'
import ScrollToTop from '../../../../components/ScrollToTop'
import {CANCEL_IMPORT, IMPORT_EMPLOYEES, IMPORTLOGS, REMOVE_IMPORT,} from '../CustomerSettings/gql'
import FileImport from '../../../../components/FileImport'
import useUser from '../../../../hooks/useUser'

const useStyles = makeStyles((theme: Theme) => ({
  marginBottom4: {
    marginBottom: theme.spacing(4),
  },
  backBtnContanier: {
    textAlign: 'right',
    color: '#898989',
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: '#4ebe4e',
    fontWeight: 'bold',
  },
  iconContainer1: {
    fontSize: '2.5rem',
  },
  iconContainer2: {
    fontSize: '3.3rem',
  },
  pridatIconContainer: {
    fontSize: '0.9rem',
    marginRight: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(2),
    fontSize: theme.typography.pxToRem(26),
  },
}))

interface IProps {
  nextStep: (newData?: createNewCustomer['createNewCustomer']) => void
  prevStep: () => void
  data?: ICustomerData
  customer:
    | ICustomerData['customer']
    | createNewCustomer['createNewCustomer']
    | undefined
    | null
}

type TSuperadminEmployee = Omit<
  superadminEmployees_superadminUsers,
  '__typename'
>
type TSuperadminEmployees = TSuperadminEmployee[]

const width = 753

const StepThree: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useUser()

  const isNewCustomer = user.isSuperadmin()

  let customer:
    | ICustomerData['customer']
    | createNewCustomer['createNewCustomer']
    | undefined
  let units: TUnit[] = []
  let roles: NexusGenFieldTypes['User'][] = []
  let employees: NexusGenFieldTypes['User'][] | TSuperadminEmployees = []

  const [createDialogOpen, setCreateDialogOpen] = React.useState(false)

  const { data: unitsData } = useQuery<
    superadminUnits,
    superadminUnitsVariables
  >(NEW_CUSTOMER_UNITS, {
    variables: {
      where: { customerId: (props.customer && props.customer.id) || '' },
    },
    skip: !isNewCustomer || !props.customer,
  })

  const { data: employeesData } = useQuery<
    superadminEmployees,
    superadminEmployeesVariables
  >(NEW_CUSTOMER_EMPLOYEES, {
    variables: {
      where: {
        customerId: (props.customer && props.customer.id) || '',
        role: Role.EMPLOYEE,
      },
    },
    skip: !isNewCustomer || !props.customer,
  })

  if (isNewCustomer) {
    customer = props.customer
    units = (unitsData && unitsData.superadminUnits) || []
    employees = ((employeesData && employeesData.superadminUsers) ||
      []) as TSuperadminEmployees
  } else {
    customer = props.data ? props.data.customer : undefined
    units = props.data ? props.data.units : []
    employees = (props.data ? props.data.employees : []) as TSuperadminEmployees
  }

  const manualEmployees = employees.filter(
    (employee: TSuperadminEmployee) => employee.importLog === null,
  )

  const [customerUpdate] = useMutation<
    ICustomerUpdateData,
    ICustomerUpdateVariables
  >(CUSTOMER_UPDATE)

  const [customerUpdateSuperadmin] = useMutation<
    customerUpdateSuperadmin,
    customerUpdateSuperadminVariables
  >(CUSTOMER_UPDATE_SUPERADMIN)

  const { form } = useForm<
    ICustomerUpdateVariables['data'] | customerUpdateSuperadminVariables['data']
  >(
    {},
    {},
    {
      async onSubmit(formData, form) {
        try {
          if (!isNewCustomer) {
            const { data } = await customerUpdate({
              variables: { data: { activationFormStep: 3 } },
            })
            if (data && data.customerUpdate) {
              form.onSuccess(data.customerUpdate)
              props.nextStep()
            }
          } else {
            const { data } = await customerUpdateSuperadmin({
              variables: {
                data: { activationFormStep: 3 },
                where: { id: (customer && customer.id) || '' },
              },
            })
            if (data && data.customerUpdateSuperadmin) {
              form.onSuccess(data.customerUpdateSuperadmin)
              props.nextStep(data.customerUpdateSuperadmin)
            }
          }
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  return (
    <Observer>
      {() => {
        return (
          <>
            <ScrollToTop />
            <Box maxWidth="100%" width={width}>
              <Typography align="center" variant="h1" gutterBottom>
                {t('customerActivation.step3Heading')}
              </Typography>

              <Typography
                gutterBottom
                align="center"
                className={classes.marginBottom4}
              >
                <Trans i18nKey="customerActivation.step3SubHeading">
                  Už jste skoro u konce! <br />
                  Nyní už zbývá pouze přidat Vaše zaměstnance do aplikace. To
                  můžete provést dvěma způsoby, manuálně po jednom nebo hromadně
                  pomocí našeho importního dokumentu.
                  <br />
                  <br />
                  Založit zaměstnance do aplikace můžete kdykoliv později a nyní
                  tento
                  <span className={classes.link} onClick={form.submit}>
                    krok přeskočit.
                  </span>
                </Trans>
              </Typography>

              <Grid container spacing={3}>
                <Grid item xs={6} container>
                  <IconPaper
                    icon={
                      <span className={classes.iconContainer1}>
                        <ManualnePridat fontSize="inherit" />
                      </span>
                    }
                  >
                    <Typography variant="h2" align="center" gutterBottom>
                      {t('customerActivation.createUserHeading')}
                    </Typography>
                    <Typography align="center">
                      <Trans i18nKey="customerActivation.createUserDescription">
                        Chcete-li založit zaměstnance manuálně, tak klikněte na
                        “<Green>Přidat zaměstnance</Green>” . Vyplníte několik
                        nutných údajů k zaměstnanci (email, datum nástupu...) a
                        rozešlou se pozvánky k registraci. Tento způsob založení
                        zaměstnanců doporučujeme, pokud máte menší počet
                        zaměstnanců.
                      </Trans>
                    </Typography>
                  </IconPaper>
                </Grid>
                <Grid item xs={6} container>
                  <IconPaper
                    icon={
                      <span className={classes.iconContainer2}>
                        <HromadnePridat fontSize="inherit" />
                      </span>
                    }
                  >
                    <Typography variant="h2" align="center" gutterBottom>
                      {t('customerActivation.bulkCreateUserHeading')}
                    </Typography>
                    <Typography align="center">
                      <Trans i18nKey="customerActivation.bulkCreateUserDescription">
                        Chcete-li založit zaměstnance hromadně, tak si stáhněte
                        dokument “<Green>PAYMINATOR import</Green>” ke stažení
                        níže, vyplňte údaje o zaměstnancích a nahrajte vyplněný
                        dokument zpět do aplikace (“Nahrát zaměstnance”).
                        Zaměstnancům se rozešlou pozvánky a v registraci pouze
                        zkontrolují své nahrané osobní údaje. Tento způsob
                        založení zaměstnanců doporučujeme, pokud máte větší
                        počet zaměstnanců.
                      </Trans>
                    </Typography>
                  </IconPaper>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    onClick={() => setCreateDialogOpen(true)}
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    <span className={classes.pridatIconContainer}>
                      <PridatWhite fontSize="inherit" />
                    </span>{' '}
                    {t('employee.addEmployee')}
                  </Button>
                </Grid>
                <Grid item xs={6} container justifyContent="center">
                  <a
                    href={
                      process.env.PUBLIC_URL +
                      '/documents/Payminator_employees_import_template.csv'
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Box
                      mb={3}
                      display="flex"
                      alignItems="center"
                      fontFamily="fontFamily"
                    >
                      <StahnoutSoubor
                        color="primary"
                        className={classes.icon}
                      />
                      {t('common.documentTemplate')}
                    </Box>
                  </a>
                  <FileImport
                    newCustomerId={props.customer && props.customer.id}
                    type="EMPLOYEES"
                    gql={{
                      importLogs: IMPORTLOGS,
                      mutation: IMPORT_EMPLOYEES,
                      removeImport: REMOVE_IMPORT,
                      cancelImport: CANCEL_IMPORT,
                      refetchQueries: ['customer'],
                    }}
                    snackbar={{
                      successMessage: 'companySettings.employeesImportSuccess',
                      failedMessage: 'TBD failed', // TBD
                    }}
                    onlyOne={true}
                    row={t('common.row')}
                    columns={[
                      {
                        field: 'personalNumber',
                        label: t('employee.personalNumber'),
                        align: 'right',
                      },
                      {
                        field: 'employmentType',
                        label: t('employee.employmentType'),
                        align: 'center',
                      },
                      { field: 'email', label: t('common.email') },
                      {
                        field: 'firstname',
                        label: t('common.firstname'),
                      },
                      { field: 'lastname', label: t('common.lastname') },
                      { field: 'unit', label: t('common.unit') },
                      {
                        field: 'signedConfirmation',
                        label: t('employee.signedConfirmation'),
                        align: 'center',
                      },
                      {
                        field: 'dateOfEmployment',
                        label: t('employee.dateOfEmployment'),
                        align: 'right',
                      },
                      {
                        field: 'dateOfQuit',
                        label: t('employee.dateOfQuit'),
                        align: 'right',
                      },
                      {
                        field: 'permanentAddress',
                        label: t('common.permanentAddress'),
                      },
                      {
                        field: 'language',
                        label: t('common.language'),
                      },
                      {
                        field: 'gender',
                        label: t('common.gender'),
                      },
                      {
                        field: 'birthdate',
                        label: t('common.birthdate'),
                      },
                      {
                        field: 'nationality',
                        label: t('common.nationality'),
                      },
                      {
                        field: 'czechTaxResidence',
                        label: t('common.czechTaxResidence'),
                      },
                      {
                        field: 'nationalIDNumber',
                        label: t('common.nationalIDNumber'),
                      },
                      {
                        field: 'foreignIDType',
                        label: t('common.foreignIDType'),
                      },
                      {
                        field: 'foreignIDNumber',
                        label: t('common.foreignIDNumber'),
                      },
                      {
                        field: 'foreignIDCountry',
                        label: t('common.foreignIDCountry'),
                      },
                      {
                        field: 'foreignTaxIdentification',
                        label: t('common.foreignTaxIdentification'),
                      },
                      {
                        field: 'foreignTaxResidentOfState',
                        label: t('common.foreignTaxResidentOfState'),
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            </Box>

            {employees && customer && units && (
              <>
                {manualEmployees.length > 0 && <Divider spacing={6} />}
                <Box maxWidth="100%" width={width}>
                  <Employees
                    customer={customer}
                    employees={manualEmployees}
                    units={units}
                    createDialogOpen={createDialogOpen}
                    setCreateDialogOpen={setCreateDialogOpen}
                  />
                </Box>
              </>
            )}

            <Divider spacing={6} />

            <Grid container alignItems="center" spacing={1}>
              <Grid item xs className={classes.backBtnContanier}>
                <Button onClick={props.prevStep} color="inherit">
                  <ArrowLeftIcon />
                  {t('common.back')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={form.submit}
                  color="primary"
                  size="large"
                  variant="contained"
                >
                  {t('common.continue')}
                  <KeyboardArrowRight />
                </Button>
              </Grid>
              <Grid item xs />
            </Grid>
          </>
        )
      }}
    </Observer>
  )
}

export default StepThree
