import {DatatableStateContext, IDatatableVariables,} from '../containers/DatatableStateProvider'
import {useCallback, useContext, useEffect, useMemo} from 'react'

const useDatatableVariables = (
  globalKey: string,
  initValue?: IDatatableVariables,
) => {
  const { state, setState } = useContext(DatatableStateContext)

  const defaultVariables = useMemo(
    () => ({
      ...(initValue || {
        skip: 0,
        first: 20,
      }),
    }),
    [initValue],
  )

  /**
   * Set default variables
   */
  useEffect(() => {
    if (state.variables[globalKey] === undefined) {
      setState(prev => ({
        ...prev,
        variables: {
          ...prev.variables,
          [globalKey]: defaultVariables,
        },
      }))
    }
  }, [state, globalKey, defaultVariables, setState])

  const set = useCallback(
    (
      setNewVariables: (previous: IDatatableVariables) => IDatatableVariables,
      options?: { resetSearch?: boolean },
    ) => {
      setState(prev => {
        return {
          ...prev,
          variables: {
            ...prev.variables,
            [globalKey]: setNewVariables(
              prev.variables[globalKey] || defaultVariables,
            ),
          },

          // Clean search glob state
          ...(options &&
            options.resetSearch && {
              state: {
                ...prev.state,
                [globalKey]: {
                  search: '',
                },
              },
            }),
        }
      })
    },
    [defaultVariables, globalKey, setState],
  )

  const datatableVariables = state.variables[globalKey] || initValue

  return [datatableVariables, set] as [
    IDatatableVariables | undefined,
    typeof set,
  ]
}
export default useDatatableVariables
