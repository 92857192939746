import { gql } from 'apollo-boost'
import { NexusGenArgTypes } from 'kubik-server'

export interface ISetMonthlyRebateSettings {
    allowSendAnythingOnce: boolean
}

export type ISetMonthlyRebateSettingsVariables = NexusGenArgTypes['Mutation']['setMonthlyRebateSettings']

export const SET_MONTHLY_REBATE_SETTINGS = gql`
    mutation setMonthlyRebateSettings (
        $id: ID!
        $allowSendAnythingOnce: Boolean!
    ) {
        setMonthlyRebateSettings(id: $id, allowSendAnythingOnce: $allowSendAnythingOnce) 
    }
`
