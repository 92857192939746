import {Divider} from '../../../../components/CommonBox'
import {Link} from 'react-router-dom'
import {makeStyles} from '@material-ui/core/styles'
import {useTranslation} from 'react-i18next'
import {Vitejte} from '../../../../components/icons/KubikIcons'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import InsideLayout from '../../../../components/layouts/InsideLayout'
import React from 'react'
import ScrollToTop from '../../../../components/ScrollToTop'
import Typography from '@material-ui/core/Typography'
import useRouter from '../../../../hooks/useRouter'
import useUser from '../../../../hooks/useUser'

const useStyles = makeStyles((theme) => ({
  paper: {
    alignItems: 'center',
    borderRadius: '5px',
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.25)',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 1080,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  listItem: {
    fontWeight: 'bold',
    '&::before': {
      color: '#000000',
      content: '"\\2022"',
      display: 'flex',
      fontSize: '1.7rem',
      marginLeft: '-1rem',
      marginRight: theme.spacing(0.8),
      verticalAlign: 'middle',
      alignSelf: 'baseline',
    },
  },
  listItemText: {
    fontWeight: 'bold',
    fontSize: '0.9rem',
  },
  noVerticalPadding: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}))

const Superadmin: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useUser()
  const { history } = useRouter()

  const listItemClasses = {
    primary: classes.listItemText,
    dense: classes.noVerticalPadding,
  }

  const closeButtonTo = (url: string) => {
    const handleClose = async (
      e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ) => {
      e.preventDefault()
      history.push(url)
    }

    return {
      onClick: handleClose,
      component: Link,
      to: url,
    }
  }

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <Box className={classes.paper}>
        <Typography variant="h1" align="center">
          {t('superadmin.title')}
        </Typography>
        <Vitejte fontSize="large" title={t('start.heading')} />
        <Box width="43%">
          <Typography variant="h3" align="center">
            {t('superadmin.subheading')}
          </Typography>
        </Box>
        <Box width="65%">
          <Divider my={4} />
          <Typography variant="h3"></Typography>
        </Box>
        <Divider my={4} width="95%" />
        <Button
          color="primary"
          variant="contained"
          {...closeButtonTo(`/${user.data.id}/superadmin/customers`)}
        >
          {t('common.close')}
        </Button>
      </Box>
    </InsideLayout>
  )
}

export default Superadmin
