import {gql} from 'apollo-boost'
import {NexusGenFieldTypes} from 'kubik-server'

export interface IStatisticsData {
  statistics: NexusGenFieldTypes['Statistics']
}

export const STATISTICS = gql`
  query statistics {
    statistics {
      annualRebates {
        numberOfActiveEmployees
        numberOfRegisteredEmployees
        numberOfApprovedApplications
        numberOfDeniedApplications
        numberOfPendingApplications
        numberOfUnsentApplications
        numberOfPozpApplications
        debug {
          usersWithConfirmedApplications {
            id
          }
          usersWithDeniedApplications {
            id
          }
          usersWithPendingApplications {
            id
          }
          usersWithUnsentApplications {
            id
          }
        }
      }
      monthlyRebates {
        numberOfActiveEmployees
        numberOfRegisteredEmployees
        numberOfApprovedApplications
        numberOfDeniedApplications
        numberOfPendingApplications
        numberOfUnsentApplications
        numberOfMonthlyDontCreateApplications
        debug {
          usersWithConfirmedApplications {
            id
          }
          usersWithDeniedApplications {
            id
          }
          usersWithPendingApplications {
            id
          }
          usersWithUnsentApplications {
            id
          }
          usersWithMonthlyDontCreateApplications {
            id
          }
        }
      }
    }
  }
`

export const MONTHLY_STATISTICS = gql`
  query monthlyStatistics {
    statistics {
      monthlyRebates {
        numberOfActiveEmployees
        numberOfRegisteredEmployees
        numberOfApprovedApplications
        numberOfDeniedApplications
        numberOfPendingApplications
        numberOfUnsentApplications
        numberOfMonthlyDontCreateApplications
        debug {
          usersWithConfirmedApplications {
            id
          }
          usersWithDeniedApplications {
            id
          }
          usersWithPendingApplications {
            id
          }
          usersWithUnsentApplications {
            id
          }
          usersWithMonthlyDontCreateApplications {
            id
          }
        }
      }
    }
  }
`

export const ANNUAL_STATISTICS = gql`
  query annualStatistics {
    statistics {
      annualRebates {
        numberOfActiveEmployees
        numberOfRegisteredEmployees
        numberOfApprovedApplications
        numberOfDeniedApplications
        numberOfPendingApplications
        numberOfUnsentApplications
        numberOfPozpApplications
        debug {
          usersWithConfirmedApplications {
            id
          }
          usersWithDeniedApplications {
            id
          }
          usersWithPendingApplications {
            id
          }
          usersWithUnsentApplications {
            id
          }
        }
      }
    }
  }
`

export interface ICustomersData {
  customers: NexusGenFieldTypes['CustomerList'][]
}
export const CUSTOMERS = gql`
  query customers {
    customersList {
      id
      name
    }
  }
`
