import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Button, Grid, Paper, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { NexusGenFieldTypes } from "kubik-server";

import { Bold, Divider, SpaceBetween } from "../../../../../components/CommonBox";
import QuestionReadonly from "../../../../../shared/Questionnaire/QuestionReadonly";
import Upravit from "../../../../../components/icons/Upravit";
import { useDisabilityLevelEnum, useUser } from "../../../../../hooks";
import MonthsRange from "../../../../../shared/MonthsRange";
import Deti from "../../../../../components/icons/Deti";
import Poplatnik from "../../../../../components/icons/Poplatnik";
import Invalida from "../../../../../components/icons/Invalida";
import ZTPP from "../../../../../components/icons/ZTPP";

type TEmployerSummary = Pick<
    NexusGenFieldTypes['PrevEmployersSummaryInfo'],
    | "usedBasicRebate"
    | "usedBasicRebateInMonths"
    | "usedChildrenRebate"
    | "usedChildren"
    | "usedDisabilityRebate"
    | "usedDisabilityRebateInMonths"
    | "usedDisabilityRebateLevel"
    | "usedZtppRebate"
    | "usedZtppRebateInMonths"
>


interface PrevEmployerSummaryViewProps {
    data?: TEmployerSummary | null
}

export const PrevEmployerSummaryView: React.FC<PrevEmployerSummaryViewProps> = ({ data }) => {
    const { t } = useTranslation()
    const { user } = useUser()
    const theme = useTheme()
    const mdDown = theme.breakpoints.down('md')
    const compactScreen = useMediaQuery(mdDown)
    const { translate: translateDisabilityLevel } = useDisabilityLevelEnum()

    if (!data) {
        return null
    }

    return (
        <Box mt={4}>
            <SpaceBetween mb={4}>
                <Typography variant="h2" color="primary">
                    {t('common.taxRebates')}
                </Typography>
                <Button color="primary" component={Link} to={`/${user.data.id}/annual-rebates/previousEmployers`}>
                    {t('common.edit')}
                    <Box ml={1} display="flex" component="span">
                        <Upravit color="primary" fontSize="small" />
                    </Box>
                </Button>
            </SpaceBetween>

            <Box position="relative">
                <Box position="absolute" left={-60} top={0} fontSize="2.5rem">
                    <Poplatnik fontSize="inherit" />
                </Box>

                <QuestionReadonly
                    noMargin
                    label={t('rebate.basicRebate')}
                    value={data.usedBasicRebate}
                />

                {data.usedBasicRebate ? (
                    <Grid container spacing={1}>
                        <Grid item xs={compactScreen ? 12 : 5}>
                            <Bold>{t('common.monthOfApply2')}</Bold>
                        </Grid>
                        <Grid item xs={compactScreen ? 12 : 7}>
                            <Typography>
                                <MonthsRange value={data.usedBasicRebateInMonths} />
                            </Typography>
                        </Grid>
                    </Grid>
                ) : null}
            </Box>

            <Divider my={4} />

            <Box position="relative">
                <Box position="absolute" left={-60} top={0} fontSize="2.5rem">
                    <Deti fontSize="inherit" />
                </Box>

                <QuestionReadonly
                    noMargin
                    label={t('rebate.childrenRebate')}
                    value={data.usedChildrenRebate}
                />

                {data.usedChildrenRebate && data.usedChildren ? (
                    data.usedChildren.map((child, i) => {
                        return (
                            <Paper variant='outlined' style={{ padding: 32, marginTop: i === 0 ? 16 : 0, marginBottom: i !== (data.usedChildren || []).length ? 16 : 0 }} key={i}>
                                <Grid container spacing={1}>
                                    <Grid item xs={compactScreen ? 12 : 5}>
                                        <Bold>{i + 1}. {t('common.child')}</Bold>
                                    </Grid>
                                    <Grid item xs={compactScreen ? 12 : 7} />

                                    <Grid item xs={compactScreen ? 12 : 5}>
                                        <Bold>{t('common.monthOfApply2')}</Bold>
                                    </Grid>
                                    <Grid item xs={compactScreen ? 12 : 7}>
                                        <Typography>
                                            <MonthsRange value={child.usedInMonths} />
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={compactScreen ? 12 : 5}>
                                        <Bold>{t('common.ztpp')}</Bold>
                                    </Grid>
                                    <Grid item xs={compactScreen ? 12 : 7}>
                                        <Typography>
                                            {child.ztpp ? t('common.yes') : t('common.no')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        )
                    })
                ) : null}
            </Box>

            <Divider my={4} />

            <Box position="relative">
                <Box position="absolute" left={-60} top={0} fontSize="2.5rem">
                    <Invalida fontSize="inherit" />
                </Box>

                <QuestionReadonly
                    noMargin
                    label={t('rebate.disabilityRebate')}
                    value={data.usedDisabilityRebate}
                />

                {data.usedDisabilityRebate && data.usedDisabilityRebateInMonths ? (
                    <Grid container spacing={1}>
                        <Grid item xs={compactScreen ? 12 : 5}>
                            <Bold>{t('common.monthOfApply2')}</Bold>
                        </Grid>
                        <Grid item xs={compactScreen ? 12 : 7}>
                            <Typography>
                                <MonthsRange value={data.usedDisabilityRebateInMonths} />
                            </Typography>
                        </Grid>

                        <Grid item xs={compactScreen ? 12 : 5}>
                            <Bold>{t('common.disabilityLevel')}</Bold>
                        </Grid>
                        <Grid item xs={compactScreen ? 12 : 7}>
                            <Typography>
                                {translateDisabilityLevel(data.usedDisabilityRebateLevel)}
                            </Typography>
                        </Grid>
                    </Grid>
                ) : null}
            </Box>

            <Divider my={4} />

            <Box position="relative">
                <Box position='absolute' left={-60} top={0} fontSize="2.5rem">
                    <ZTPP fontSize="inherit" />
                </Box>

                <QuestionReadonly
                    noMargin
                    label={t('rebate.ztppRebate')}
                    value={data.usedZtppRebate}
                />

                {data.usedZtppRebate && data.usedZtppRebateInMonths ? (
                    <Grid container spacing={1}>
                        <Grid item xs={compactScreen ? 12 : 5}>
                            <Bold>{t('common.monthOfApply2')}</Bold>
                        </Grid>
                        <Grid item xs={compactScreen ? 12 : 7}>
                            <Typography>
                                <MonthsRange value={data.usedZtppRebateInMonths} />
                            </Typography>
                        </Grid>
                    </Grid>
                ) : null}
            </Box>

            <Divider my={4} />
        </Box>
    )
}
