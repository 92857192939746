
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import { Observer } from 'mobx-react'
import { useLazyQuery } from '@apollo/react-hooks'
import { useTranslation, Trans } from 'react-i18next'
import {withRouter, RouteComponentProps, Link} from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import React, { useState } from 'react'
import TextField from '../../../components/form/TextField'
import Typography from '@material-ui/core/Typography'
import useForm from '../../../hooks/useForm'
import Divider from '../../../components/Divider'
import ErrorSnackbar from '../../../components/ErrorSnackbar'
import Fade from '@material-ui/core/Fade'
import { Zamecek } from '../../../components/icons/KubikIcons'
import logoPng from '../../../assets/logo.png'
import AppState from '../../../components/AppState'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import BoxBackground from '../../../components/BoxBackground'
import prihlaseniLeft from '../../../assets/images/Ilustrace-mzdova-ucetni.svg'
import prihlaseniRight from '../../../assets/images/Ilustrace-zamestnanec.svg'
import {Error, Green} from "../../../components/CommonBox";
import {useRouter} from "../../../hooks";
import {OPENID_CUSTOMER, OPENID_NONCE, OPENID_STATE} from "./LoginOpenID";
import {useMutation} from "react-apollo";
import {NexusGenArgTypes, NexusGenFieldTypes} from "kubik-server";
import {gql} from "apollo-boost";
import CircularProgress from "@material-ui/core/CircularProgress";

interface IStylesProps {
  compactScreen?: boolean
  isNotSmallScreen?: boolean
}

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) => ({
  container: {
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.15)',
    zIndex: 1000,
    width: '100%',
    backgroundColor: '#f8f8f8',
  },
  backgroundFooter: {
    display: (props) => (props.compactScreen ? 'none' : undefined),
    background: '#f8f8f8',
    width: '100%',
    height: 90,
  },
  logo: {
    marginBottom: theme.spacing(3),
  },
  backgroundGray: {
    backgroundColor: '#f8f8f8',
  },
}))

interface Props extends RouteComponentProps<{code?: string}> {}

/**
 * After redirect from external service
 * @param props
 * @param context
 * @constructor
 */
const LoginOpenIDAfter: React.FC<Props> = (props, context) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const classes = useStyles({ compactScreen, isNotSmallScreen })
  const [openIDError, setOPENIDError] = useState<string | null>()
  const params = new URLSearchParams(props.location.search)
  const code = params.get('code')
  const state = params.get('state')
  const nonce = localStorage.getItem(OPENID_NONCE)
  const statesMatch = state === localStorage.getItem(OPENID_STATE)
  const customerId = localStorage.getItem(OPENID_CUSTOMER)
  const [openIDErrors, setOPENIDErrors] = useState<string[]>([])

  const [loginWithOpenID, { loading, called }] = useMutation<{loginWithOpenID: NexusGenFieldTypes['OpenIdLoginPayload'] }, NexusGenArgTypes['Mutation']['loginWithOpenID']>(gql`
      mutation loginWithOpenID($code: String!, $nonce: String!, $customer: ID!) {
          loginWithOpenID(code: $code, nonce: $nonce, customer: $customer) {
              loginSuccess
              errors
          }
      }
  `, {
    onCompleted(data) {
      if(!data) {
        return
      }
      if (data.loginWithOpenID.loginSuccess) {
        window.location.assign('/')
      } else {
        setOPENIDErrors(data.loginWithOpenID.errors || [])
      }
    },
    onError(data) {
      setOPENIDErrors([data.message])
    }
  })
  const validParams = statesMatch && nonce && code && customerId
  if(!called && validParams) {
    loginWithOpenID({variables: {code, nonce, customer: customerId}})
  }

  return (
    <Observer>
      {() => (
        <>
          <BoxBackground left={prihlaseniLeft} right={prihlaseniRight} topBackgroundColor={'#ffffff'} bottomBackgroundColor={'#f8f8f8'}>
            <Box
              display="flex"
              justifyContent="center"
              flex-wrap="nowrap"
              flexDirection="row"
            >

              <Box p={6} textAlign="center" className={classes.container}>
                {!validParams && <>
                  <Error><Trans i18nKey={'openID.statesDoesNotMatch'}></Trans></Error>
                </>}
                {validParams && <>
                  {loading && <CircularProgress size={24} />}
                </>}

                {!!openIDErrors && !!openIDErrors.length && <>{openIDErrors.map(errCode => <Error>{t('openID.' + errCode)}</Error>)}<br />
                  <Link to={'/openid'}><Trans i18nKey={'common.back'}></Trans></Link>
                </>}
                <Divider spacing={4} />

                <Box textAlign="center" fontFamily="fontFamily">
                  <Trans i18nKey="common.needHelp">
                    Potřebujete pomoct?
                    <a href="mailto:helpdesk@payminator.cz">Napište nám.</a>
                  </Trans>
                </Box>
              </Box>
            </Box>
          </BoxBackground>
        </>
      )}
    </Observer>
  )
}

export default withRouter(LoginOpenIDAfter)
