import {
  Dary2,
  Exekuce,
  Hypoteka,
  Manzel,
  PenzijniPojisteni,
  ZivotniPojisteni
} from '../../../../../../components/icons/KubikIcons'
import {Divider, Green, UpperHint,} from '../../../../../../components/CommonBox'
import {
  updateAnnualRebateWizardStep,
  updateAnnualRebateWizardStepVariables,
} from '../../../../../../generated/updateAnnualRebateWizardStep'
import {AnnualWizardStep} from '../../../../../../generated/globalTypes'
import {OWN_ANNUAL_REBATE} from './gql'
import {
  userWithAnnualRebate,
  userWithAnnualRebate_user_annualRebate
} from '../../../../../../generated/userWithAnnualRebate'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { getRebatesWithExtra, TRebate, TRebateName } from '../../../../../../fragments/utils'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useTranslation, Trans } from 'react-i18next'
import { UPDATE_ANNUAL_WIZARD_STEP } from '../../gql/updateAnnualWizardStepMutation'
import AnnualStepper from '../../components/Stepper'
import AppState from '../../../../../../components/AppState'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ButtonStepContainer from '../../../../../../components/ButtonStepContainer'
import Fade from '../../../../../../components/Fade'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import React, {useMemo} from 'react'
import RebateTile from '../../components/RebateTile'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import Typography from '@material-ui/core/Typography'
import useRouter from '../../../../../../hooks/useRouter'
import useUser from '../../../../../../hooks/useUser'
import {useChildrenRebateStats} from '../../../../../../shared/Children'
import InfoBox from '../../../../../../components/InfoBox'
import {getRebateStats} from '../../../../../../pages/Inside/pages/MonthlyRebates/utils'
import {TAnnualRebateName} from "kubik-server";
import Pece from "../../../../../../components/icons/Pece";
import Investice from "../../../../../../components/icons/Investice";

const useStyles = makeStyles(() => ({
  collapseContainer: {
    width: '100%',
  },
  showMonthly: {
    cursor: 'pointer',
    textAlign: 'center',
    width: 'fit-content',
  },
  tileContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '1rem',
    maxWidth: 650,
    "& > div": {
      width: 'calc(33% - 2rem)',
      minWidth: 175,
      aspectRatio: '1/1'
    }
  }
}))
export interface TSharedLimits {
  currentRebates: {
    [key: string]: number
  },
  totalMax: number
}

export const getSharedLimits = (annualRebate: userWithAnnualRebate_user_annualRebate | null, rebateNames: TAnnualRebateName[]): TSharedLimits => {
  let currentRebates: {
    [key: string]: number
  } = {}
  if (!annualRebate) {
    return {
      currentRebates,
      totalMax: 100000
    }
  }
  for (let rebateName of rebateNames) {
    const [,{ prevRebate, prevPositiveRebate }] = getRebatesWithExtra(annualRebate, rebateName)

    const isNewlyApplied = get(annualRebate, rebateName + 's[0].status') === 'APPLY'
    const isCancelled = prevRebate && prevRebate.status === 'CANCELED'
    const isRemoved = isNewlyApplied && get(annualRebate, rebateName + 's[0].type') === 'REMOVE'
    const isProcessedFromBefore = (get(annualRebate, rebateName + 's[1].status') === 'CONFIRMED' || get(annualRebate, rebateName + 's[1].status') === 'DENIED') && get(annualRebate, rebateName + 's[1].type') !== 'REMOVE'

    if (isCancelled && prevPositiveRebate) {
      // @ts-ignore
      currentRebates[rebateName] = prevPositiveRebate.amount
    } else if (isRemoved) {
      currentRebates[rebateName] = 0
    } else if(isNewlyApplied) {
      currentRebates[rebateName] = get(annualRebate, rebateName + 's[0].feeAmount') || get(annualRebate, rebateName + 's[0].amount') || 0
    } else if(isProcessedFromBefore) {
      currentRebates[rebateName] = get(annualRebate, rebateName + 's[1].feeAmount') || get(annualRebate, rebateName + 's[1].amount') || 0
    } else {
      currentRebates[rebateName] = 0
    }
  }

  return {
    currentRebates,
    totalMax: annualRebate.limits.pensionShared? (annualRebate.limits.pensionShared.max || 100000) : 100000
  }
}

export const calculateSharedMaximumFor = (rebateName: TAnnualRebateName, limits: TSharedLimits): number => {
  let resultMaximum = limits.totalMax
  for (let limitName in limits.currentRebates) {
    if (limitName === rebateName) { // vyloučení aktuální
      continue
    }
    resultMaximum -= limits.currentRebates[limitName] || 0
  }
  return Math.max(0, resultMaximum)
}

const Root: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useUser()
  const { history } = useRouter()

  const [updateAnnualWizardStep] = useMutation<
    updateAnnualRebateWizardStep,
    updateAnnualRebateWizardStepVariables
  >(UPDATE_ANNUAL_WIZARD_STEP)

  const { data, loading, error } = useQuery<userWithAnnualRebate>(
    OWN_ANNUAL_REBATE,
    {
      fetchPolicy: 'network-only',
      onCompleted(response) {
        const wizardStep = get(response, 'user.annualRebate.wizardStep')
        if (wizardStep !== 'REBATE') {
          updateAnnualWizardStep({
            variables: { wizardStep: AnnualWizardStep.REBATE },
          })
        }
      },
    },
  )
  // pokud jsem se na stránku dostal jinak než z dotazníků nebo taxResidence
  if (
    history.location.state &&
    history.location.state.from !== 'questionnaire' &&
    history.location.state.from !== 'taxResidence' &&
    history.location.state.from !== 'previousEmployers'
  ) {
    // tak uživatele směřujeme zpět, aby zodpovědět
    // otázku, že chce udělat Daně, tzn. vypnne POZP
    if (
      user.data.annualRebate.settlementRequest &&
      user.data.annualRebate.settlementRequest.type === 'ANNUAL_POZP'
    ) {
      history.push(`/${user.data.id}/annual-rebates/questionnaire`)
    }
  }
  const year = get(data, 'user.annualRebate.year') || ''
  const foreignerNoFiles = user.isForeignerWithNoFiles()
  const annualRebate = data && data.user && data.user.annualRebate

  const parseRebate = (rebate: TRebateName) =>
    get(data, `user.annualRebate.${rebate}s`) as TRebate[]

  const prevEmployerDenied = Boolean(
    annualRebate &&
    annualRebate.prevEmployers &&
    annualRebate.prevEmployers.some(({ status }) => status === 'DENIED'),
  )

  const allowSummary = useMemo(() => {
    if (!annualRebate) {
      return false
    }
    if (!annualRebate.wizardFinishedAt || !annualRebate.settlementRequest) {
      return true
    }
    if (
      get(annualRebate, 'basicRebates[0].status') === 'APPLY' ||
      get(annualRebate, 'studentRebates[0].status') === 'APPLY' ||
      get(annualRebate, 'childrenRebates[0].status') === 'APPLY' ||
      get(annualRebate, 'disabilityRebates[0].status') === 'APPLY' ||
      get(annualRebate, 'ztppRebates[0].status') === 'APPLY' ||
      get(annualRebate, 'lifeInsuranceRebates[0].status') === 'APPLY' ||
      get(annualRebate, 'pensionInsuranceRebates[0].status') === 'APPLY' ||
      get(annualRebate, 'unionRebates[0].status') === 'APPLY' ||
      get(annualRebate, 'foreclosureRebates[0].status') === 'APPLY' ||
      get(annualRebate, 'investmentRebates[0].status') === 'APPLY' ||
      get(annualRebate, 'longTermCareRebates[0].status') === 'APPLY' ||
      get(annualRebate, 'examRebates[0].status') === 'APPLY' ||
      get(annualRebate, 'giftRebates[0].status') === 'APPLY' ||
      get(annualRebate, 'preschoolRebates[0].status') === 'APPLY' ||
      get(annualRebate, 'spouseRebates[0].status') === 'APPLY' ||
      get(annualRebate, 'loanRebates[0].status') === 'APPLY' ||
      get(annualRebate, 'taxQuestionnaire.status') === 'APPLY' ||
      user.hasNewResidenceFiles()
    ) {
      return true
    }
    
    return Boolean(
      annualRebate.prevEmployers &&
        annualRebate.prevEmployers.some(({ status }) => status === 'APPLY'),
    )
  }, [annualRebate])

  const [,giftStats] = getRebatesWithExtra(
    get(data, 'user.annualRebate'), 'giftRebate'
  )

  const [,spouseStats] = getRebatesWithExtra(
    get(data, 'user.annualRebate'), 'spouseRebate'
  )

  const [,lifeInsuranceStats] = getRebatesWithExtra(
    get(data, 'user.annualRebate'), 'lifeInsuranceRebate'
  )

  const [,pensionInsuranceStats] = getRebatesWithExtra(
    get(data, 'user.annualRebate'), 'pensionInsuranceRebate'
  )

  const [,loanStats] = getRebatesWithExtra(
    get(data, 'user.annualRebate'), 'loanRebate'
  )

  const [,foreclosureStats] = getRebatesWithExtra(
    get(data, 'user.annualRebate'), 'foreclosureRebate'
  )

  const [,investmentStats] = getRebatesWithExtra(
    get(data, 'user.annualRebate'), 'investmentRebate'
  )

  const [,longTermCareStats] = getRebatesWithExtra(
    get(data, 'user.annualRebate'), 'longTermCareRebate'
  )

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <AppState loading={loading} />
      <GraphQLErrorSnackbar error={error} />

      {data && (
        <Fade>
          <Box
            maxWidth="100%"
            ml="auto"
            mr="auto"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <UpperHint>
              {t('common.form')} {t('common.annualRebates')} {year}
            </UpperHint>

            <AnnualStepper activeStep={5} />

            <Typography variant="h1" align="center" gutterBottom>
              {t('annualRebateWizard.heading')}
            </Typography>

            <Box maxWidth={500}>
              <Typography variant="body1" align="center">
                <Trans i18nKey='annualRebateWizard.description' components={[<Green />]} />
              </Typography>
            </Box>

            {prevEmployerDenied && (
              <InfoBox title={t('annualRebateWizard.stepper.previousEmployer')} type='warning' maxWidth={480} mt={4}>
                <Trans i18nKey={'annualRebateWizard.prevEmployerDenied'}>
                  Z důvodu zamítnutí předchozího zaměstnavatele, nelze provést
                  roční zúčtování. Proveďte prosím opravu v kroku
                  <Link
                    to={`/${user.data.id}/annual-rebates/previousEmployers`}
                  >
                    Předchozí zaměstnavatel
                  </Link>
                  .
                </Trans>
              </InfoBox>
            )}

            {foreignerNoFiles && (
              <InfoBox title={t('annualRebateWizard.stepper.taxResidence')} type='warning' maxWidth={480} mt={4}>
                <Trans
                  i18nKey={
                    user.noProvidedFiles()
                      ? 'annualRebateWizard.foreignerNoFiles'
                      : 'annualRebateWizard.foreignerDeniedFiles'
                  }
                  tOptions={{ context: 'MONTHLY' }}
                  components={[<Link to={`/${user.data.id}/annual-rebates/taxResidence`} />]}
                />
              </InfoBox>
            )}

            <Divider maxWidth={180} mt={4} mb={3} />

            <Box maxWidth="100%" width={680} mt={2}>
              <div className={classes.tileContainer}>
                  <RebateTile
                    to={`/${user.data.id}/annual-rebates/gift-rebate`}
                    icon={<Dary2 />}
                    label={t('rebate.giftRebate')}
                    data={parseRebate('giftRebate')}
                    locked={foreignerNoFiles || prevEmployerDenied}
                    confirmed={giftStats.overallConfirmed}
                  />

                  {/*<RebateTile*/}
                  {/*  to={`/${user.data.id}/annual-rebates/preschool-rebate`}*/}
                  {/*  icon={<Skolkovne />}*/}
                  {/*  label={t('rebate.preschoolRebate')}*/}
                  {/*  data={parseRebate('preschoolRebate')}*/}
                  {/*  locked={foreignerNoFiles || prevEmployerDenied}*/}
                  {/*  confirmed={preschoolStats.overallConfirmed}*/}
                  {/*/>*/}

                  <RebateTile
                    to={`/${user.data.id}/annual-rebates/spouse-rebate`}
                    icon={<Manzel />}
                    label={t('rebate.spouseRebate')}
                    data={parseRebate('spouseRebate')}
                    locked={foreignerNoFiles || prevEmployerDenied}
                    confirmed={spouseStats.overallConfirmed}
                  />

                  <RebateTile
                    to={`/${user.data.id}/annual-rebates/life-insurance-rebate`}
                    icon={<ZivotniPojisteni />}
                    label={t('rebate.lifeInsuranceRebate')}
                    data={parseRebate('lifeInsuranceRebate')}
                    locked={foreignerNoFiles || prevEmployerDenied}
                    confirmed={lifeInsuranceStats.overallConfirmed}
                  />

                  <RebateTile
                    to={`/${user.data.id}/annual-rebates/pension-insurance-rebate`}
                    icon={<PenzijniPojisteni />}
                    label={t('rebate.pensionInsuranceRebate')}
                    data={parseRebate('pensionInsuranceRebate')}
                    locked={foreignerNoFiles || prevEmployerDenied}
                    confirmed={pensionInsuranceStats.overallConfirmed}
                  />

                  <RebateTile
                    to={`/${user.data.id}/annual-rebates/loan-rebate`}
                    icon={<Hypoteka />}
                    label={t('rebate.loanRebate')}
                    data={parseRebate('loanRebate')}
                    locked={foreignerNoFiles || prevEmployerDenied}
                    confirmed={loanStats.overallConfirmed}
                  />

                  {/*<RebateTile*/}
                  {/*    to={`/${user.data.id}/annual-rebates/union-rebate`}*/}
                  {/*    icon={<Odbory />}*/}
                  {/*    label={t('rebate.unionRebate')}*/}
                  {/*    data={parseRebate('unionRebate')}*/}
                  {/*    locked={foreignerNoFiles || prevEmployerDenied}*/}
                  {/*/>*/}

                  {/*<RebateTile*/}
                  {/*  to={`/${user.data.id}/annual-rebates/exam-rebate`}*/}
                  {/*  icon={<Vzdelani />}*/}
                  {/*  label={t('rebate.examRebate')}*/}
                  {/*  data={parseRebate('examRebate')}*/}
                  {/*  locked={foreignerNoFiles || prevEmployerDenied}*/}
                  {/*/>*/}

                  <RebateTile
                    to={`/${user.data.id}/annual-rebates/foreclosure-rebate`}
                    icon={<Exekuce />}
                    label={t('rebate.foreclosureRebate')}
                    data={parseRebate('foreclosureRebate')}
                    locked={foreignerNoFiles || prevEmployerDenied}
                    confirmed={foreclosureStats.overallConfirmed}
                  />


                <RebateTile
                  to={`/${user.data.id}/annual-rebates/investment-rebate`}
                  icon={<Investice />}
                  label={t('rebate.investmentRebate')}
                  data={parseRebate('investmentRebate')}
                  locked={foreignerNoFiles || prevEmployerDenied}
                  confirmed={investmentStats.overallConfirmed}
                />

                <RebateTile
                  to={`/${user.data.id}/annual-rebates/long-term-care-rebate`}
                  icon={<Pece />}
                  label={t('rebate.longTermCareRebate')}
                  data={parseRebate('longTermCareRebate')}
                  locked={foreignerNoFiles || prevEmployerDenied}
                  confirmed={longTermCareStats.overallConfirmed}
                />
              </div>
            </Box>

            <Divider maxWidth={1080} mb={4} mt={8} position="relative">
              <Box
                position="absolute"
                left={5}
                fontSize={14}
                fontWeight="fontWeightBold"
                bottom={16}
                fontFamily="fontFamily"
                color="text.hint"
              >
                {t('common.annualRebates2')}
              </Box>
            </Divider>

            <ButtonStepContainer
               backButton={{
                to: `/${user.data.id}/annual-rebates/rootMonthly`,
                text: t('annualRebateWizard.stepper.monthly'),
              }}
            >
              <Button
                color="primary"
                size="large"
                variant="contained"
                component={Link}
                to={`/${user.data.id}/annual-rebates/summary`}
                disabled={!allowSummary}
              >
                {t('common.summary')}
              </Button>
            </ButtonStepContainer>
          </Box>
        </Fade>
      )}
    </InsideLayout>
  )
}

export default Root
