import { gql } from 'apollo-boost'
import { NexusGenFieldTypes } from 'kubik-server'

export type TInvestmentRebate = NexusGenFieldTypes['InvestmentRebate']

export const INVESTMENT_REBATE_FULL_FRAGMENT = gql`
    fragment InvestmentRebateFull on InvestmentRebate {
        id
        createdAt
        status
        type
        commentOfDenied
        amount
        confirmationAgreementFiles {
            id
            filename
            size
            createdAt
        }
        confirmationPaymentFiles {
            id
            filename
            size
            createdAt
        }
        settlementRequest {
            id
            requestedAt
            submittedAt
        }
    }
`
