import React from 'react'
import {useTranslation} from 'react-i18next'
import {makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableRow,} from '@material-ui/core'

import {Bold} from '../../../../../components/CommonBox'
import {apiUrl} from '../../../../../utils'

interface IProps {
  documentId: string
  file: {
    id: string
    filename: string
    size: number
  }
  documentName: string
  oldDocumentName: string
}

const useStyles = makeStyles({
  tableContainer: {
    marginTop: '2rem',
  },
  table: {
    width: '100%',
    tableLayout: 'fixed',
  },
})

export const UpdateDocument: React.FC<IProps> = ({
  documentId,
  file,
  documentName,
  oldDocumentName,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableBody>
          <TableRow>
            <TableCell>
              <Bold>{t('documents.id')}</Bold>
            </TableCell>
            <TableCell>{documentId}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Bold>Starý název dokumentu</Bold>
            </TableCell>
            <TableCell>{oldDocumentName}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Bold>Nový název dokumentu</Bold>
            </TableCell>
            <TableCell>{documentName}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Bold>{t('common.file')}</Bold>
            </TableCell>
            <TableCell>
              <a
                href={apiUrl(`api/file/${file.id}`)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {file.filename}
              </a>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
