import {Bold, Divider, Green, UpperHint,} from '../../../../../../components/CommonBox'
import {FINISH_MONTHLY_HISTORY_WIZARD, IFinishMonthlyHistoryWizardData,} from './gql'
import {DokonceniMonthly} from '../../../../../../components/icons/KubikIcons'
import {useMutation} from 'react-apollo'
import {useRouter, useUser} from '../../../../../../hooks'
import {Trans, useTranslation} from 'react-i18next'
import AppState from '../../../../../../components/AppState'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import MonthlyStepper from '../../components/Stepper'
import React from 'react'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import Typography from '@material-ui/core/Typography'
import {useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const Root: React.FC = () => {
  const { t } = useTranslation()
  const { user, refetch: refetchUser } = useUser()
  const { history } = useRouter()
  const year = get(user, 'data.yearOfMonthlyHistory')

  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const breakpoint = theme.breakpoints.up('lg')
  const lgScreen = useMediaQuery(breakpoint)

  const [submitMonthlyRebate, { loading, error }] = useMutation<
    IFinishMonthlyHistoryWizardData
  >(FINISH_MONTHLY_HISTORY_WIZARD, {
    onCompleted() {
      refetchUser().then(() => {
        if (user.data.allowAnnual) {
          history.push(`/${user.data.id}/monthly-history`)
        } else {
          history.push(`/${user.data.id}/rebates`, { monthly: true })
        }
      })
    },
    onError() {},
  })
  const handleFinishButton = () => submitMonthlyRebate()

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <AppState loading={loading} />
      <GraphQLErrorSnackbar error={error} />

      <Box
        maxWidth="100%"
        width={880}
        display="flex"
        flexDirection="column"
        alignItems="center"
        mx="auto"
      >
        <UpperHint mb={2} mt={lgScreen ? -2 : 2}>
          {`${t('common.form')} ${t('common.monthlyRebates')} ${year} (${String(
            t('common.history'),
          ).toLowerCase()})`}
        </UpperHint>
        <MonthlyStepper activeStep={3} />
        <Typography variant="h1" align="center">
          <Trans i18nKey="rebateFinish.heading">
            Blahopřejeme k úspěšnému dokončení <br /> Průvodce daňových slev
          </Trans>
        </Typography>

        <Box display="flex" justifyContent="center">
          <DokonceniMonthly fontSize="large" />
        </Box>

        <Box mb={4}>
          <Typography variant="h2" align="center">
            <Trans i18nKey="rebateFinish.subHeading">
              V tuto chvíli máte hotovo s daňovými slevami, Vaše <br /> žádost
              odešla mzdové účetní ke schválení a jakmile <br /> bude vyřízena,
              tak dostanete oznámení.
            </Trans>
          </Typography>
        </Box>

        <Divider my={6} maxWidth="100%" width={780} />

        <Box maxWidth="100%" width={780}>
          <Typography variant="h2">
            <Green>{t('rebateFinish.text0')}</Green>
          </Typography>
        </Box>

        <Box maxWidth="100%" width={780} component="ul" pl="20px" mt={0}>
          <Bold component="li" my={2}>
            <Trans i18nKey="rebateFinish.text1">
              {t('rebateFinish.text1')}
            </Trans>
          </Bold>
          <Bold component="li" my={2}>
            <Trans i18nKey="rebateFinish.text2">
              Mzdové účetní můžete vždy kontaktovat přes sekci
              <Green>Pomoc</Green> či
              <Green>Zprávy</Green>.
            </Trans>
          </Bold>
          <Bold component="li" my={2}>
            <Trans i18nKey="rebateFinish.text3Monthly">
              Víte-li, že jste
              <Green>
                provedl/a chybu či chcete provést změnu v Průvodci daňových
                slev, tak můžete znovu vstoupit do Průvodce
              </Green>
              (sekce Daňové slevy - Současné daňové slevy - Vstoupit) a poslat
              si opravenou žádost.
            </Trans>
          </Bold>
          <Bold component="li" my={2}>
            <Trans i18nKey="rebateFinish.text5Monthly">
              <Green>
                Daňové slevy se promítnou do Vaší následující či další
                následující mzdové výplaty
              </Green>
              podle situace.
            </Trans>
          </Bold>
          <Bold component="li" my={2}>
            <Trans i18nKey="rebateFinish.text7Monthly">
              <Green>Payminator</Green> Vám oznámí, pokud se platnost potvrzení
              slevy blíží ke konci. Pokud však dojde k náhlé změně (náhlé
              ukončení platnosti, změna osobních údajů nebo vznik nároku slevu),
              tak je potřeba se přihlásit do aplikace a vstoupit do Průvodce,
              kde provedete změny.
            </Trans>
          </Bold>
          <Bold component="li" my={2}>
            <Trans i18nKey="rebateFinish.text6">
              Jakmile bude Vaše <Green>žádost vyřízena</Green>, tak dostanete
              <Green>oznámení do Zpráv</Green> (sekce Zprávy)
              <Green>i na e-mail</Green>.
            </Trans>
          </Bold>
          <Bold component="li" my={2}>
            <Trans i18nKey="rebateFinish.text7">
              V případě, že <Green>Mzdová účetní Vaší žádost zamítne</Green>,
              tak je potřeba znovu vstoupit do Průvodce (sekce Daňové slevy -
              Současné daňové slevy - Vstoupit), provést nutné změny a poslat si
              novou žádost.
            </Trans>
          </Bold>
        </Box>

        <Divider my={6} />
        <Box display="flex" justifyContent="center">
          <Button
            color="primary"
            size="large"
            variant="contained"
            onClick={handleFinishButton}
          >
            {t('common.understand')}
          </Button>
        </Box>
      </Box>
    </InsideLayout>
  )
}

export default Root
