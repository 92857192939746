import {
  updateMonthlyTaxQuestionnaire,
  updateMonthlyTaxQuestionnaireVariables,
} from '../../../../../../generated/updateMonthlyTaxQuestionnaire'
import {
  MonthlyTaxQuestionnaireUpdateInput,
  MonthlyWizardStep,
  TaxQuestionnaireStatus
} from '../../../../../../generated/globalTypes'
import {
  userWithMonthlyTaxQuestionnaire,
  userWithMonthlyTaxQuestionnaire_user_monthlyRebate_taxQuestionnaire,
} from '../../../../../../generated/userWithMonthlyTaxQuestionnaire'
import {DeniedRebateNotice} from '../../../../../../components/layouts/RebateLayout'
import {Center, Divider, Green, UpperHint} from '../../../../../../components/CommonBox'
import {Observer} from 'mobx-react'
import {OWN_MONTHLY_TAX_QUESTIONNAIRE, UPDATE_MONTHLY_TAX_QUESTIONNAIRE,} from './gql'
import {Question, QuestionnaireReadonlyView} from '../../../../../../shared/QuestionnaireMonthly'
import {UPDATE_MONTHLY_WIZARD_STEP} from '../../gql/updateMonthlyWizardStepMutation'
import {useForm, useRouter, useUser} from '../../../../../../hooks'
import {useMutation, useQuery} from 'react-apollo'
import {Trans, useTranslation} from 'react-i18next'
import MonthlyStepper from '../../components/Stepper'
import AppState from '../../../../../../components/AppState'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ButtonStepContainer from '../../../../../../components/ButtonStepContainer'
import Fade from '../../../../../../components/Fade'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import omit from 'lodash/omit'
import PrevRebate from '../../../AnnualRebates/components/PrevRebate'
import React, {useCallback, useMemo, useState} from 'react'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import Typography from '@material-ui/core/Typography'
import InfoIcon from "@material-ui/icons/Info";
import {makeStyles} from '@material-ui/core/styles'
import {BlueButton} from '../../../../../../components/BlueButton'
import MonthlyRebateSupport from "../../../Support/components/MonthlyRebateSupport";
import { computed } from 'mobx'

const useQuestionnaireFieldProp = (monthlyRebateYear: number) => {
  const { user } = useUser()

  return useCallback(
    (
      field: keyof updateMonthlyTaxQuestionnaireVariables['data'],
      data: updateMonthlyTaxQuestionnaireVariables['data'],
    ): { required: boolean; omit: boolean; hide: boolean } => {
      const { createByActualEmployer } = data

      let required = false,
        omit = false,
        hide = false

      switch (field) {
        case 'createByActualEmployer':
          required = true
          omit = !required
          hide = omit
          break
        case 'madeByAnotherEmployer':
          required = createByActualEmployer === true
          omit = !required
          hide = createByActualEmployer === false
          break
      }
      return { required, omit, hide }
    },
    [user, monthlyRebateYear],
  )
}

const useNavigateToNextStep = (monthlyRebateYear: number) => {
  const { user } = useUser()
  const { history } = useRouter()

  const navigateToNextStep = useCallback(
    (taxQuestionnaire: {
      createByActualEmployer?: boolean | null
      madeByAnotherEmployer?: boolean | null
    }) => {
      const { createByActualEmployer, madeByAnotherEmployer } = taxQuestionnaire

      const dontWantToCreate = createByActualEmployer === false

      const cannotCreate =
        createByActualEmployer === true && madeByAnotherEmployer === true

      if (dontWantToCreate || cannotCreate) {
        history.push(`/${user.data.id}/monthly-rebates/summary`)
      } else {
        if (user.shouldHaveResidenceStep()) {
          history.push(`/${user.data.id}/monthly-rebates/taxResidence`, {
            from: 'questionnaire',
          })
        } else {
          history.push(`/${user.data.id}/monthly-rebates/root`, {
            from: 'questionnaire',
          })
        }
      }
    },
    [user, history, monthlyRebateYear],
  )
  return navigateToNextStep
}


const useStyles = makeStyles((theme) => ({
  laborOffice: {
    display: 'flex',
    justifyContent: 'center',
  },
  label: {
    display: 'flex',
    minWidth: 'fit-content',
    marginRight: theme.spacing(2),
    lineHeight: `${theme.spacing(5.3)}px`,
  },
  infoIcon: {
    verticalAlign: 'top',
  }
}))

const Questionnaire: React.FC = () => {
  const classes = useStyles()
  const { user } = useUser()
  const { t } = useTranslation()

  const [status, setStatus] = useState<TaxQuestionnaireStatus | null>(null)

  const isConfirmed = status === TaxQuestionnaireStatus.CONFIRMED

  const isFirstRound = user.firstRound()

  const [updateMonthlyWizardStep] = useMutation(UPDATE_MONTHLY_WIZARD_STEP)

  const { data, loading, error, refetch: refetchRebate } = useQuery<userWithMonthlyTaxQuestionnaire>(
    OWN_MONTHLY_TAX_QUESTIONNAIRE,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted(response) {
        setStatus(get(response, 'user.monthlyRebate.taxQuestionnaire.status') || null)

        const wizardStep = get(response, 'user.monthlyRebate.wizardStep')

        if (wizardStep !== 'QUESTIONNAIRE') {
          updateMonthlyWizardStep({
            variables: { wizardStep: MonthlyWizardStep.QUESTIONNAIRE },
          })
        }
      },
    },
  )

  const monthlyRebate = data && data.user && data.user.monthlyRebate
  const taxQuestionnaire = monthlyRebate && monthlyRebate.taxQuestionnaire

  const year = (monthlyRebate && monthlyRebate.year) || Infinity
  const snap =
    taxQuestionnaire && taxQuestionnaire.history && taxQuestionnaire.history[0]

  const questionProps = useQuestionnaireFieldProp(year)
  const navigateToNextStep = useNavigateToNextStep(year)

  const [
    updateQuestionnaire,
    { loading: updateLoading, error: updateError },
  ] = useMutation<
    updateMonthlyTaxQuestionnaire,
    updateMonthlyTaxQuestionnaireVariables
  >(UPDATE_MONTHLY_TAX_QUESTIONNAIRE)

  const { bind, form } = useForm<
    Partial<userWithMonthlyTaxQuestionnaire_user_monthlyRebate_taxQuestionnaire>
  >(
    (taxQuestionnaire && omit(taxQuestionnaire, 'history')) || {},
    {
      createByActualEmployer: {
        rule: (data) =>
          questionProps('createByActualEmployer', data).required
            ? 'required'
            : '',
        label: t('monthlyRebateQuestionnaire.createByActualEmployer', {
          company: user.data.customer.name,
        }),
        help: t('monthlyRebateQuestionnaire.createByActualEmployerHelp', { context: "2023" }),
      },
      madeByAnotherEmployer: {
        rule: (data) =>
          questionProps('madeByAnotherEmployer', data).required
            ? 'required'
            : '',
        label: t('monthlyRebateQuestionnaire.madeByAnotherEmployer'),
        help: t('monthlyRebateQuestionnaire.madeByAnotherEmployerHelp', { context: `2023_${user.data.gender}` })
      },
    },
    {
      async onSubmit(formData) {
        function hasChanged() {
          if (status === TaxQuestionnaireStatus.ALTER || status === TaxQuestionnaireStatus.DENIED) {
            return true
          }
          const values = ['createByActualEmployer', 'madeByAnotherEmployer']

          function isValidKey(key: string): key is keyof MonthlyTaxQuestionnaireUpdateInput {
            return values.includes(key)
          }

          return Object.keys(formData).some(key => {
            if (isValidKey(key)) {
              return (
                taxQuestionnaire &&
                taxQuestionnaire[key] !== formData[key]
              )
            }
          })
        }

        if (taxQuestionnaire && !hasChanged()) {
          navigateToNextStep(taxQuestionnaire)

          return
        }

        const result = await updateQuestionnaire({
          variables: {
            data: formData,
          },
        })

        if (result && result.data && result.data.updateMonthlyTaxQuestionnaire) {
          navigateToNextStep(result.data.updateMonthlyTaxQuestionnaire)
        } else {
          form.onFail('Nepovedlo se to') // TBD Translate?
        }
      },
    },
  )

  const handleAlter = () => {
    setStatus(TaxQuestionnaireStatus.ALTER)
  }

  const noApplyingRebates = computed(() => {
    const formData = form.getData()

    const dontWantToCreate = formData.createByActualEmployer === false
    const cannotCreate = formData.createByActualEmployer === true && formData.madeByAnotherEmployer === true

    return dontWantToCreate || cannotCreate
  })

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <AppState loading={loading || updateLoading} />
      <GraphQLErrorSnackbar error={error || updateError} />
      <Box
        maxWidth="100%"
        width={1080}
        ml="auto"
        mr="auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <UpperHint>
          {t('common.form')} {t('common.monthlyRebates')} {year}
        </UpperHint>

        <Observer>
          {() => <MonthlyStepper activeStep={1} noApplyingRebates={noApplyingRebates.get()} />}
        </Observer>
        {!!monthlyRebate && <MonthlyRebateSupport monthlyRebate={monthlyRebate} refetch={refetchRebate}/>}

        <Box maxWidth="100%">
          <Typography variant="h1" align="center" gutterBottom>
            {t('monthlyRebateQuestionnaire.heading')}
          </Typography>
          <Typography align="center">
            <Trans i18nKey={'monthlyRebateQuestionnaire.subheading'}>
              Odpovězte na otázky níže, zdali Vám mohou být uplatňované <Green>měsíční slevy na dani</Green>. Pro <Green>více informací</Green> k otázkám vyberte <InfoIcon className={classes.infoIcon} color="primary" />.
            </Trans>
          </Typography>
        </Box>

        <Divider mt={4} mb={1.5} maxWidth={180} />

        {!loading && (
          <Fade>
            <Box maxWidth="100%" width={850}>
              {snap && snap.status === 'DENIED' && (
                <DeniedRebateNotice variant="employer" fullwidth>
                  {snap.commentOfDenied}
                </DeniedRebateNotice>
              )}

              <Observer>
                {() => {
                  const data = form.getData()

                  return (
                    <>
                      {isConfirmed ? (
                        <Center mt={2}>
                          <BlueButton
                            color="primary"
                            size="large"
                            variant="contained"
                            onClick={handleAlter}
                            >
                            {t('rebateChanges.wantToMakeChange')}
                          </BlueButton>
                        </Center>
                      ) : null}

                      <Question
                        helpStyle="collapse"
                        {...bind('createByActualEmployer')}
                        hide={
                          questionProps('createByActualEmployer', data).hide
                        }
                        hideDivider
                        disabled={isConfirmed}
                      />
                      <Question
                        helpStyle="collapse"
                        {...bind('madeByAnotherEmployer')}
                        hide={questionProps('madeByAnotherEmployer', data).hide}
                        disabled={isConfirmed}
                      />
                    </>
                  )
                }}
              </Observer>

              {taxQuestionnaire && taxQuestionnaire.history && (
                <ArchiveChanges boxProps={{ mb: 2 }}>
                  {taxQuestionnaire.history.map((questionnaireSnap) => (
                    <PrevRebate
                      key={questionnaireSnap.id}
                      status={questionnaireSnap.status}
                      settlementRequest={questionnaireSnap.settlementRequest}
                      commentOfDenied={questionnaireSnap.commentOfDenied}
                    >
                      <QuestionnaireReadonlyView
                        data={questionnaireSnap}
                        year={year}
                        dateOfEmployment={user.data.dateOfEmployment}
                        customerName={user.data.customer.name}
                        employeeGender={user.data.gender}
                      />
                    </PrevRebate>
                  ))}
                </ArchiveChanges>
              )}
            </Box>
          </Fade>
        )}

        <Divider mb={4} mt={1.5} />

        {!loading && (
          <Fade>
            <ButtonStepContainer
              {...(isFirstRound && {
                backButton: {
                  to: `/${user.data.id}/monthly-rebates/intro`,
                },
              })}
            >
              <Observer>
                {() => {
                  return (
                    <Button
                      color="primary"
                      size="large"
                      variant="contained"
                      onClick={form.submit}
                    >
                      {t('common.continue')}
                    </Button>
                  )
                }}
              </Observer>
            </ButtonStepContainer>
          </Fade>
        )}
      </Box>
    </InsideLayout>
  )
}

export default Questionnaire
