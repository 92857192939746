import {
  IOwnMonthlyHistoryDisabilityRebateData,
  IResetOwnMonthlyHistoryDisabilityRebateData,
  IUpdateOwnMonthlyHistoryDisabilityRebateData,
  OWN_MONTHLY_HISTORY_DISABILITY_REBATE,
  RESET_OWN_MONTHLY_HISTORY_DISABILITY_REBATE,
  TUpdateOwnMonthlyHistoryDisabilityRebateVariables,
  UPDATE_OWN_MONTHLY_HISTORY_DISABILITY_REBATE,
} from './gql'
import {Center, Divider,} from '../../../../../../components/CommonBox'
import ChangeRebateButton, {ChangeRebateChoice,} from '../../../AnnualRebates/components/ChangeRebateButton'
import {FormProvider} from '../../../../../../hooks/useForm'
import {getPrevRebates, getRebateStats} from '../../utils'
import {Observer} from 'mobx-react'
import {pickId, yearRange} from '../../../../../../utils'
import {TDisabilityRebate} from '../../../../../../fragments/DisabilityRebate'
import {useForm, useRouter, useUser} from '../../../../../../hooks'
import {useMutation, useQuery} from 'react-apollo'
import {Trans, useTranslation} from 'react-i18next'
import AppState from '../../../../../../components/AppState'
import Box from '@material-ui/core/Box'
import CancelRebateDialog from '../../../../../../components/CancelRebateDialog'
import CancelRebateSection from '../../../AnnualRebates/components/CancelRebateSection'
import DisabilityRebateOverview from '../../../AnnualRebates/components/DisabilityRebateOverview'
import Fade from '../../../../../../components/Fade'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import Invalida from '../../../../../../components/icons/Invalida'
import PrevRebates from '../../../AnnualRebates/components/PrevRebates'
import React from 'react'
import DisabilityWithPeriodsFormSection from '../../../../../../shared/DisabilityWithPeriodsFormSection'
import RebateActions from '../../../AnnualRebates/components/RebateActions'
import RebateLayout from '../../../../../../components/layouts/RebateLayout'
import ScrollToTop from '../../../../../../components/ScrollToTop'

const DisabilityRebate: React.FC = () => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { history } = useRouter()

  const [updateRebate, { loading: updateLoading, error: updateError }] =
    useMutation<
      IUpdateOwnMonthlyHistoryDisabilityRebateData,
      TUpdateOwnMonthlyHistoryDisabilityRebateVariables
    >(UPDATE_OWN_MONTHLY_HISTORY_DISABILITY_REBATE)

  const [resetRebate, { loading: resetLoading, error: resetError }] =
    useMutation<IResetOwnMonthlyHistoryDisabilityRebateData>(
      RESET_OWN_MONTHLY_HISTORY_DISABILITY_REBATE,
    )

  const { data, loading, error } =
    useQuery<IOwnMonthlyHistoryDisabilityRebateData>(
      OWN_MONTHLY_HISTORY_DISABILITY_REBATE,
      { fetchPolicy: 'network-only' },
    )

  const year = get(data, 'user.monthlyHistory.year')
  const [yearStart, yearEnd, prevYearStart, nextYearEnd] = yearRange(year)
  const formData = get(data, 'user.monthlyHistory.disabilityRebate') || {}

  const { bind, form } = useForm<
    TUpdateOwnMonthlyHistoryDisabilityRebateVariables['data']
  >(
    formData,
    {
      prevYearPeriods: {
        type: 'multi_relation',
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
      },
      disabilityConfirmationFiles: {
        type: 'multi_relation',
        label: t('common.disabilityConfirmation'),
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
        isFileRelation: true,
      },
    },
    {
      async onSubmit(data, form) {
        try {
          await updateRebate({
            variables: { data: { ...data, status: 'APPLY' } },
          })
          history.push(`/${user.data.id}/monthly-history`)
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  const prevRebates = getPrevRebates<TDisabilityRebate>(
    get(data, 'user.monthlyHistory.disabilityRebates'),
  )

  const {
    isFirstRequest,
    isPrevDenied,
    showingCommentOfDenied,
    isCancelable,
    isPrevRemoved,
  } = getRebateStats<TDisabilityRebate>(
    get(data, 'user.monthlyHistory.disabilityRebates'),
  )

  const cleanRebate = async () => {
    if (formData) {
      const files = formData.disabilityConfirmationFiles || []
      const otherFiles = formData.disabilityOtherFiles || []
      const prevYearPeriods = formData.prevYearPeriods || []

      await updateRebate({
        variables: {
          data: {
            status: 'NEW',
            type: 'NEW',
            prevYearPeriods: {
              delete: prevYearPeriods.map(pickId),
            },
            disabilityConfirmationFiles: {
              delete: files.map(pickId),
            },
            disabilityOtherFiles: {
              delete: otherFiles.map(pickId),
            },
          },
        },
      })
    }
  }

  const onCancelRebate = async () => {
    if (formData) {
      const type = form.getValue('type')
      const alreadyRemoved = type === 'REMOVE'

      if (isPrevDenied && !alreadyRemoved) {
        await updateRebate({
          variables: {
            data: {
              status: 'APPLY',
              type: 'REMOVE',
            },
          },
        })
      } else {
        await cleanRebate()
      }
      history.push(`/${user.data.id}/monthly-history/root`)
    }
  }

  const handleAlter = async () => {
    await resetRebate()
    form.setField('type', 'ALTER')
    form.setField('status', 'APPLY')
  }
  const handleRemove = async () => {
    await cleanRebate()
    form.setField('type', 'REMOVE')
    form.setField('status', 'APPLY')
  }

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <AppState loading={loading || updateLoading || resetLoading} />
      <GraphQLErrorSnackbar error={error || updateError || resetError} />
      {data && (
        <Fade>
          <RebateLayout
            commentOfDenied={showingCommentOfDenied}
            sideHint={
              <Trans i18nKey={'monthlyDisabilityRebate.sideHint'}>
                <strong>Slevu na invaliditu</strong> můžete uplatňovat <strong>měsíčně</strong> nebo <strong>jednou ročně v rámci ročního zúčtování</strong> (formulář Roční zúčtování > krok Měsíční slevy dodatečné uplatnění). Sleva je ve výši <strong>210 Kč měsíčně</strong> neboli <strong>2 520 Kč ročně u I. a II. stupně</strong>, a <strong>420 Kč měsíčně</strong> neboli <strong>5 040 Kč ročně u III. stupně</strong>.
              </Trans>
            }
            upperHint={`${t('common.monthlyRebates')} ${year} (${String(
              t('common.history'),
            ).toLowerCase()})`}
            heading={t('monthlyDisabilityRebate.heading')}
            icon={<Invalida fontSize="large" />}
          >
            {isCancelable && (
              <Box display="flex" justifyContent="center" mb={4}>
                <CancelRebateDialog onSubmit={onCancelRebate} />
              </Box>
            )}

            <Observer>
              {() => {
                const type = form.getValue('type')
                const status = form.getValue('status')

                if (type === 'REMOVE') {
                  return (
                    <CancelRebateSection
                      onUndo={() => form.setField('type', 'NEW')}
                    >
                      {t('rebateChanges.cancelDisability')}
                    </CancelRebateSection>
                  )
                }

                if (
                  isFirstRequest ||
                  isPrevDenied ||
                  isPrevRemoved ||
                  status === 'DENIED' ||
                  type === 'ALTER'
                ) {
                  return (
                    <FormProvider form={form}>
                      <DisabilityWithPeriodsFormSection
                        bind={bind}
                        dateLimiters={{
                          fromMinDates: [
                            { date: prevYearStart },
                            {
                              date: user.data.dateOfEmployment,
                              message: t('error.minDateOfEmploment'),
                            },
                          ],
                          toMinDates: [
                            {
                              date: yearStart,
                              message: t('error.minDateAnnualYearStart'),
                            },
                            {
                              date: user.data.dateOfEmployment,
                              message: t('error.minDateOfEmploment'),
                            },
                          ],
                          fromMaxDates: [
                            {
                              date: yearEnd,
                              message: t('error.maxDateMonthlyYearEnd'),
                            },
                          ],
                          toMaxDates: [
                            {
                              date: nextYearEnd,
                            },
                          ],
                        }}
                      />
                    </FormProvider>
                  )
                }

                return (
                  <Center maxWidth="100%" width={680}>
                    <ChangeRebateButton>
                      <ChangeRebateChoice
                        variant="ALTER"
                        onClick={handleAlter}
                      />
                      <ChangeRebateChoice
                        variant="REMOVE"
                        onClick={handleRemove}
                      />
                    </ChangeRebateButton>
                  </Center>
                )
              }}
            </Observer>

            <Observer>
              {() => {
                const type = form.getValue('type')
                const status = form.getValue('status')

                const showFormSection =
                  isFirstRequest ||
                  isPrevDenied ||
                  isPrevRemoved ||
                  status === 'DENIED' ||
                  type === 'ALTER'
                return (
                  <PrevRebates
                    rebates={prevRebates}
                    overviewComponent={DisabilityRebateOverview}
                    expand={!showFormSection}
                  />
                )
              }}
            </Observer>

            <Divider my={6} />

            <Observer>
              {() => (
                <RebateActions
                  backTo={`/${user.data.id}/monthly-history`}
                  onSubmit={form.submit}
                  isDirty={form.state.isDirty}
                  formLoading={form.state.loading}
                />
              )}
            </Observer>
          </RebateLayout>
        </Fade>
      )}
    </InsideLayout>
  )
}

export default DisabilityRebate
