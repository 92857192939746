import {
  IOwnAnnualBasicRebateData,
  IResetOwnAnnualBasicData,
  IUpdateOwnAnnualBasicRebateData,
  OWN_ANNUAL_BASIC_REBATE,
  RESET_OWN_ANNUAL_BASIC_REBATE,
  TUpdateOwnAnnualBasicRebateVariables,
  UPDATE_OWN_ANNUAL_BASIC_REBATE,
} from './gql'
import {getRebatesWithExtra} from '../../../../../../fragments/utils'
import {Observer} from 'mobx-react'
import {Bold, Center, Divider, Green} from '../../../../../../components/CommonBox'
import ChangeRebateButton, {ChangeRebateChoice} from '../../components/ChangeRebateButton'
import {pickId, yearRange} from '../../../../../../utils'
import {useMutation, useQuery} from 'react-apollo'
import {Trans, useTranslation} from 'react-i18next'
import AppState from '../../../../../../components/AppState'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import BasicRebateOverview from '../../components/BasicRebateOverview'
import BasicWithPeriodsFormSection from '../../../../../../shared/BasicWithPeriodsFormSection'
import Box from '@material-ui/core/Box'
import CancelRebateDialog from '../../../../../../components/CancelRebateDialog'
import CancelRebateSection from '../../components/CancelRebateSection'
import Fade from '../../../../../../components/Fade'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import Poplatnik from '../../../../../../components/icons/Poplatnik'
import PrevRebate from '../../components/PrevRebate'
import React from 'react'
import RebateActions from '../../components/RebateActions'
import RebateLayout from '../../../../../../components/layouts/RebateLayout'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import useForm, {FormProvider} from '../../../../../../hooks/useForm'
import useRouter from '../../../../../../hooks/useRouter'
import useUser from '../../../../../../hooks/useUser'
import {CANCEL_OWN_LAST_ANNUAL_REBATE, TCancelOwnLastAnnualRebateVariables} from '../../gql/cancelOwnLastAnnualRebate'
import {calculatePrevYearPeriods} from '../../utils'
import { AppliedRebates } from '../../../../../../pages/Inside/pages/MonthlyRebates/components/AppliedRebates'
import { computed } from 'mobx'

const BasicRebate: React.FC = () => {
  const { t } = useTranslation()
  const { user, refetch } = useUser()
  const { history } = useRouter()

  const [
    updateRebate,
    { loading: updateLoading, error: updateError },
  ] = useMutation<
    IUpdateOwnAnnualBasicRebateData,
    TUpdateOwnAnnualBasicRebateVariables
  >(UPDATE_OWN_ANNUAL_BASIC_REBATE, { onError() {} })

  const [
    cancelRebate,
    { loading: cancelLoading, error: cancelError },
  ] = useMutation<boolean, TCancelOwnLastAnnualRebateVariables>(CANCEL_OWN_LAST_ANNUAL_REBATE)

  const [
    resetRebate,
    { loading: resetLoading, error: resetError },
  ] = useMutation<IResetOwnAnnualBasicData>(RESET_OWN_ANNUAL_BASIC_REBATE, {
    onError() {},
  })

  const { data, loading, error } = useQuery<IOwnAnnualBasicRebateData>(
    OWN_ANNUAL_BASIC_REBATE,
    { fetchPolicy: 'network-only', onError() {} },
  )
  const year = get(data, 'user.annualRebate.year')
  const [yearStart, yearEnd] = yearRange(year)
  const formData = get(data, 'user.annualRebate.basicRebate') || {}

  const { bind, form } = useForm<TUpdateOwnAnnualBasicRebateVariables['data']>(
    formData,
    {
      prevYearPeriods: {
        type: 'multi_relation',
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : '')
      },
      affidavitFiles: {
        type: 'multi_relation',
        label: t('monthlyBasicRebate.uploadLabel'),
        help: t('common.uploadHelp')
      },
    },
    {
      async onSubmit(data, form) {
        try {
          const { errors } = await updateRebate({
            variables: {
              data: {
                ...data,
                status: 'APPLY',
              },
            },
          })
          if (!errors || (errors && errors.length > 0))
            history.push(`/${user.data.id}/annual-rebates/rootMonthly`)
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  const [
    prevRebates,
    {
      isFirstRequest,
      isPrevDenied,
      isPrevRemoved,
      showingCommentOfDenied,
      isCancelable,
      isPrevYear,
      overallConfirmed,
      isPrevCancelled,
      prevPositiveRebateWithoutPrevYear
    },
  ] = getRebatesWithExtra(data && data.user.annualRebate, 'basicRebate')

  const showForm = computed<boolean>(() => {
    const type = form.getValue('type')
    const status = form.getValue('status')

    return (
      !overallConfirmed ||
      status === 'DENIED' ||
      type === 'ALTER' ||
      type === 'NEW_PERIOD'
    )
  })

  const cleanRebate = async () => {
    if (formData) {
      const prevYearPeriods = formData.prevYearPeriods || []

      await updateRebate({
        variables: {
          data: {
            prevYearPeriods: {
              delete: prevYearPeriods.map(pickId),
            },
            status: 'NEW',
            type: 'NEW',
          },
        },
      })
    }
  }

  const onCancelRebate = async () => {
    if (get(data, 'user.annualRebate.basicRebate.status') !== 'APPLY') {
      await Promise.all([
        cancelRebate({
          variables: { rebate: 'BASIC' }
        }),
        refetch()
      ])
    }

    await cleanRebate()

    history.push(`/${user.data.id}/annual-rebates/rootMonthly`)
  }

  const handleApplyAgain = async () => {
    await cleanRebate()
    form.setField('type', 'NEW_PERIOD')
  }

  const handleAlter = async () => {
    await resetRebate()
    form.setField('type', 'ALTER')
  }

  const handleRemove = async () => {
    await resetRebate()
    form.setField('type', 'REMOVE')
  }

  // const prevYearRebate = prevRebates.find((r) =>
  //   Boolean(
  //     r.settlementRequest && r.settlementRequest.type === 'MONTHLY_PREV_YEAR',
  //   ),
  // )

  // let prevYearLastTo =
  //   prevYearRebate &&
  //   prevYearRebate.prevYearPeriods &&
  //   prevYearRebate.prevYearPeriods.length > 0 &&
  //   prevYearRebate.prevYearPeriods[prevYearRebate.prevYearPeriods.length - 1].to

  // if (prevYearLastTo && moment(prevYearLastTo).isValid()) {
  //   prevYearLastTo = moment(prevYearLastTo).add(1, 'day')
  // }

  // const lastRemoveIndex = prevRebates.findIndex(rebate => rebate.type === "REMOVE")
  // const prevYearIntervals = prevRebates
  // // NOTE: Filter out all rebates that are after the last REMOVE rebate, except the MONTHLY_PREV_YEAR.
  // .filter((r, i) => (i < lastRemoveIndex) || (r.settlementRequest && r.settlementRequest.type === "MONTHLY_PREV_YEAR"))
  // .filter(rebate => rebate.status === "CONFIRMED")
  // .map(rebate => {
  //   if (!rebate.prevYearPeriods) {
  //     return []
  //   }

  //   return rebate.prevYearPeriods.map(period => {
  //     return {
  //       from: moment(period.from),
  //       to: moment(period.to)
  //     }
  //   })
  // })
  // .flat()

  const prevYearIntervals = calculatePrevYearPeriods(prevRebates)

  return (
    <InsideLayout sidebar moveLoversLeft={-190}>
      <ScrollToTop />
      <AppState loading={loading || updateLoading || resetLoading || cancelLoading} />
      <GraphQLErrorSnackbar error={error || updateError || resetError || cancelError} />

      {data && data.user && (
        <Fade>
          <RebateLayout
            commentOfDenied={showingCommentOfDenied}
            sideHint={
              <Trans i18nKey="monthlyBasicRebate.sideHint" tOptions={{ context: "2023" }}>
                <Bold>Základní slevu na poplatníka</Bold> můžete uplatňovat <Bold>měsíčně</Bold> nebo <Bold>jednou ročně v rámci ročního zúčtování</Bold> (formulář Roční zúčtování &gt; krok Měsíční slevy dodatečné uplatnění). Daňové sleva je ve výši <Bold>2 570 Kč měsíčně</Bold> neboli <Bold>30 840 Kč ročně</Bold>.
              </Trans>
            }
            upperHint={`${t('common.annualRebates')} ${year}`}
            heading={t('monthlyBasicRebate.heading')}
            subHeading={<Trans i18nKey="monthlyBasicRebate.subHeading">Chcete-li uplatňovat Základní slevu na poplatníka, <Green>vyplňte datum uplatňování</Green></Trans>}
            icon={<Poplatnik fontSize="large" />}
          >
            {isCancelable && (
              <Box display="flex" justifyContent="center" mb={4}>
                <CancelRebateDialog onSubmit={onCancelRebate} />
              </Box>
            )}

            <Observer>
              {() => {
                const type = form.getValue('type')
                // const status = form.getValue('status')
                // const affidavitFiles = form.getValue('affidavitFiles')
                // const hasAffidavit = affidavitFiles && affidavitFiles.length > 0

                if (type === 'REMOVE') {
                  return (
                    <CancelRebateSection
                      onUndo={() => form.setField('type', 'NEW')}
                    >
                      {t('rebateChanges.cancelBasic')}
                    </CancelRebateSection>
                  )
                }

                if (showForm.get()) {
                  return (
                    <FormProvider form={form}>
                      <BasicWithPeriodsFormSection
                        excludeIntervals={prevYearIntervals}
                        bind={bind}
                        year={year}
                        minDates={[
                          {
                            date: yearStart,
                            message: t('error.minDateAnnualYearStart'),
                          }
                        ]}
                        maxDates={[
                          {
                            date: yearEnd,
                            message: t('error.maxDateAnnualYearEnd'),
                          },
                        ]}
                      />
                    </FormProvider>
                  )
                }

                return (
                  <Center maxWidth="100%" width={680} flexDirection="column">
                    <ChangeRebateButton>
                      {isPrevYear ? (
                        <ChangeRebateChoice
                        variant="APPLY_AGAIN"
                        onClick={handleApplyAgain}
                      />
                      ) : (
                        <ChangeRebateChoice
                          variant="REMOVE"
                          onClick={handleRemove}
                        />
                      )}
                      {!isPrevYear ? (
                        <ChangeRebateChoice
                          label={t('rebateChanges.alterChange', { context: user.allowAlter() ? "2023" : "" })}
                          onClick={handleAlter}
                        />
                      ) : null}
                    </ChangeRebateButton>

                    {!isPrevRemoved && prevPositiveRebateWithoutPrevYear && prevPositiveRebateWithoutPrevYear.prevYearPeriods ? (
                      <AppliedRebates
                        type="ANNUAL"
                        rebates={
                          prevPositiveRebateWithoutPrevYear.prevYearPeriods.map(period => ({
                            from: period.from,
                            to: period.to
                          }))
                        }
                      />
                    ) : null}

                    {prevYearIntervals.length > 0 ? (
                      <AppliedRebates
                        type="PREV_YEAR"
                        rebates={prevYearIntervals.map(period => ({
                          from: period.from,
                          to: period.to
                        }))}
                      />
                    ) : null}
                  </Center>
                )
              }}
            </Observer>

            <Observer>
              {() => {
                if (prevRebates.length < 1) return null

                const type = form.getValue('type')
                const status = form.getValue('status')
                const showFormSection =
                  isFirstRequest ||
                  isPrevRemoved ||
                  isPrevDenied ||
                  status === 'DENIED' ||
                  type === 'ALTER' ||
                  type === 'NEW_PERIOD' ||
                  type === 'REMOVE'

                return (
                  <Center maxWidth="100%" width={680} mt={3}>
                    <ArchiveChanges expand={!showFormSection}>
                      {prevRebates.map((rebate) => (
                        <PrevRebate
                          key={rebate.id}
                          settlementRequest={rebate.settlementRequest}
                          status={rebate.status}
                          commentOfDenied={rebate.commentOfDenied}
                        >
                          <BasicRebateOverview data={rebate} isHistory/>
                        </PrevRebate>
                      ))}
                    </ArchiveChanges>
                  </Center>
                )
              }}
            </Observer>

            <Divider maxWidth={1080} my={4} />

            <Observer>
              {() => {
                const type = form.getValue('type')

                const submitToBack = (
                  !showForm.get() &&
                  type !== 'REMOVE'
                )

                return (
                  <RebateActions
                    backTo={`/${user.data.id}/annual-rebates/rootMonthly`}
                    onSubmit={form.submit}
                    isDirty={form.state.isDirty}
                    submitToBack={submitToBack}
                    formLoading={form.state.loading}
                  />
                )
              }}
            </Observer>
          </RebateLayout>
        </Fade>
      )}
    </InsideLayout>
  )
}

export default BasicRebate
