import {Center, Error, IconCircle} from '../../../../../components/CommonBox'
import {Link} from 'react-router-dom'
import {makeStyles, Theme, useTheme} from '@material-ui/core/styles'
import {useTranslation} from 'react-i18next'
import BackButtonDialog from '../../../../../components/BackButtonDialog'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import useTempState from '../../../../../hooks/useTempState'
import Loader from '../../../../../components/Loader'
import {OpravduSmazat} from '../../../../../components/icons/KubikIcons'
import useMediaQuery from '@material-ui/core/useMediaQuery'

type TStylesProps = IProps & {
  compactScreen?: boolean
  isNotSmallScreen?: boolean
}

const useStyles = makeStyles<Theme, TStylesProps>((theme: Theme) => ({
  error: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: -40,
  },
  '@keyframes shake': {
    '8%, 41%': {
      transform: 'translateX(-7px)',
    },
    '25%, 58%': {
      transform: 'translateX(7px)',
    },
    '75%': {
      transform: 'translateX(-3px)',
    },
    '92%': {
      transform: 'translateX(3px)',
    },
    '0%, 100%': {
      transform: 'translateX(0)',
    },
  },
  invalid: {
    outlineColor: 'red',
    animation: '$shake .5s linear',
  },
  order2: {
    // order: (props) => (props.isNotSmallScreen ? undefined : 2),
  },
}))

interface IProps {
  backTo: string
  submitToBack?: boolean
  onSubmit: () => Promise<boolean | string | null | void> | boolean
  submitLabel?: React.ReactElement
  isDirty: boolean
  formLoading?: boolean
}

const RebateActions: React.FC<IProps> = (props) => {
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const classes = useStyles({ compactScreen, isNotSmallScreen, ...props })

  const [shake, setShake] = useTempState(false)
  const [error, setError] = useTempState<boolean | string>(false, 7000)
  const { t } = useTranslation()
  const {
    backTo,
    onSubmit,
    isDirty,
    submitToBack,
    submitLabel = t('common.submit'),
    formLoading,
  } = props

  const handleOnSubmit = async () => {
    const submitResult = await onSubmit()

    if (submitResult === false || typeof submitResult === 'string') {
      setShake(true)
      setError(typeof submitResult === 'string' ? submitResult : true)
    }
  }

  return (
    <>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        flexDirection={isNotSmallScreen ? 'row' : 'column'}
      >
        <Box
          flex="1 1 1px"
          display="flex"
          justifyContent={isNotSmallScreen ? 'flex-end' : 'center'}
          color="#898989"
          className={classes.order2}
        >
          <BackButtonDialog
            to={backTo}
            showDialog={isDirty}
            dialogTitle={
              <>
                <Center mb={2}>
                  <IconCircle fontSize="3rem">
                    <OpravduSmazat fontSize="inherit" color="primary" />
                  </IconCircle>
                </Center>
                {t('common.leaveEvenDirtyHeading')}
              </>
            }
            dialogContent={
              <Typography>{t('common.leaveEvenDirty')}</Typography>
            }
          />
        </Box>
        <Box mx={1} position="relative">
          {submitToBack ? (
            <Button
              component={Link}
              to={backTo}
              color="primary"
              size="large"
              variant="contained"
            >
              {submitLabel}
            </Button>
          ) : (
            <Button
              className={shake ? classes.invalid : ''}
              onClick={handleOnSubmit}
              color="primary"
              size="large"
              variant="contained"
            >
              {submitLabel}
            </Button>
          )}
        </Box>
        <Box flex="1 1 1px" />
      </Box>
      <Box width="100%" display="flex" justifyContent="center">
        <Loader loading={formLoading || false} />
      </Box>
      {error && (
        <Error textAlign="center">
          {typeof error === 'string' ? error : t('error.fillTheForm')}
        </Error>
      )}
    </>
  )
}

export default RebateActions
