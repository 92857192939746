import {Redirect, Route, Switch} from 'react-router-dom'
import {useUser} from '../../../../hooks'
import BasicRebate from './pages/BasicRebate'
import DisabilityRebate from './pages/DisabilityRebate'
import FinishWizard from './pages/FinishWizard'
import ChildrenRebate from './pages/ChildrenRebate'
import Intro from './pages/Intro'
import React from 'react'
import Root from './pages/Root'
import StudentRebate from './pages/StudentRebate'
import Summary from './pages/Summary'
import ZtppRebate from './pages/ZtppRebate'
import Welcome from '../../../../shared/Welcome'

const Rebates = () => {
  const { user } = useUser()
  const monthlyHistory = user.data.monthlyHistory

  if (
    monthlyHistory &&
    monthlyHistory.settlementRequest &&
    monthlyHistory.settlementRequest.status === 'APPLY'
  ) {
    return (
      <Switch>
        <Route
          path="/:userId/monthly-history/finish-wizard"
          component={FinishWizard}
        />
        <Route path="/:userId/monthly-history">
          <Welcome settlementStatusIsApply />
        </Route>

        <Redirect to="/:userId/monthly-history" />
      </Switch>
    )
  }

  const redirectTo = () => {
    let redirect = '/:userId/monthly-history'
    if (!monthlyHistory) {
      redirect += '/intro'
    } else {
      redirect += '/root'
    }
    return redirect
  }

  return (
    <Switch>
      <Route
        path="/:userId/monthly-history/basic-rebate"
        component={BasicRebate}
      />
      <Route
        path="/:userId/monthly-history/student-rebate"
        component={StudentRebate}
      />
      <Route
        path="/:userId/monthly-history/disability-rebate"
        component={DisabilityRebate}
      />
      <Route
        path="/:userId/monthly-history/children-rebate"
        component={ChildrenRebate}
      />
      <Route
        path="/:userId/monthly-history/ztpp-rebate"
        component={ZtppRebate}
      />
      <Route path="/:userId/monthly-history/summary" component={Summary} />
      <Route
        path="/:userId/monthly-history/finish-wizard"
        component={FinishWizard}
      />
      <Route path="/:userId/monthly-history/root" component={Root} />
      <Route path="/:userId/monthly-history/intro" component={Intro} />

      <Redirect to={redirectTo()} />
    </Switch>
  )
}

export default Rebates
