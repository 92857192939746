import {Dotaznik, MesicniSlevy, RocniZuctovani2,} from '../../../../components/icons/KubikIcons'
import {
  CANCEL_ANNUAL_SETTLEMENT_REQUEST,
  CANCEL_MONTHLY_HISTORY_SETTLEMENT_REQUEST,
  CANCEL_MONTHLY_SETTLEMENT_REQUEST,
  ICancelAnnualSettlementRequestData,
  ICancelMonthlyHistorySettlementRequestData,
  ICancelMonthlySettlementRequestData,
  IOwnMonthlyHistoryData,
  IOwnMonthlyRebateData,
  OWN_ANNUAL_REBATE_OVERVIEW,
  OWN_MONTHLY_HISTORY_OVERVIEW,
  OWN_MONTHLY_REBATE_OVERVIEW,
  TFile,
  TMonthlyRebate,
} from './gql'
import {RebateRow, RebatesOverview, RebatesOverviewPure,} from '../../../../shared/RebatesOverview'
import {userWithAnnualRebateOverview} from '../../../../generated/userWithAnnualRebateOverview'
import {apiUrl} from '../../../../utils'
import {Center, Divider} from '../../../../components/CommonBox'
import {NexusGenEnums} from 'kubik-server'
import {styled, useTheme} from '@material-ui/core/styles'
import {useRouter, useUser} from '../../../../hooks'
import {useMutation, useQuery} from 'react-apollo'
import {useTranslation} from 'react-i18next'
import AppState from '../../../../components/AppState'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CancelRequestDialog from './components/CancelRequestDialog'
import Fade from '../../../../components/Fade'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../components/GraphQLErrorSnackbar'
import InsideLayout from '../../../../components/layouts/InsideLayout'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import PrevEmployers from './components/PrevEmployers'
import React, {useEffect, useState} from 'react'
import ScrollToTop from '../../../../components/ScrollToTop'
import Summary, {useCalcSummaryStatus} from './components/Summary'
import TaxResidence from './components/TaxResidence'
import Toggle, {ToggleItem} from '../../../../components/Toggle'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {AnnualInfoDialog} from "./components/AnnualInfoDialog"
import {MonthlyInfoDialog} from "../../pages/MonthlyRebates/pages/Intro/components/MonthlyInfoDialog"
import AnnualPdfFormDownload from "../../../../shared/AnnualPdfDownload/AnnualPdfFormDownload";
import MonthlyPdfFormDownload from "../../../../shared/MonthlyPdfDownload";

const Container = styled(Box)({
  boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.15)',
})

const Subheading = styled(Typography)({
  marginTop: 8,
})

export const BlueButton = styled(Button)({
  backgroundColor: 'white',
  boxShadow: 'none',
  color: '#09A4E4',
  padding: '14px 26px',
  margin: '0 8px',
  border: '2px solid #09A4E4',
  '&:hover': {
    backgroundColor: '#09A4E4',
    color: 'white',
  },
})

export const handleDownload = (
  ...files: (Pick<TFile, 'id'> | null | undefined)[]
) => {
  files.forEach((file) => {
    if (file) {
      const url = apiUrl(`api/file/${file.id}`)
      var win = window.open(url, '_blank')
      win && win.focus()
    }
  })
}

const TaxRebates: React.FC = () => {
  const { history } = useRouter()
  const { user, refetch } = useUser()
  const { t } = useTranslation()

  const annualPeriod = user.data.allowAnnual

  const [toggle, setToggle] = useState<'MONTHLY' | 'ANNUAL'>('ANNUAL')

  const { yearOfMonthlyRebates, yearOfAnnualRebates } = user.data.customer

  const monthlyHistoryRequired = user.data.monthlyHistoryRequired

  useEffect(() => {
    if (history.location.state && history.location.state.monthly) {
      if (toggle !== 'MONTHLY') {
        setToggle('MONTHLY')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.state, setToggle])

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <Container
        maxWidth="100%"
        width={1077}
        px={2}
        py={4}
        mx="auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {annualPeriod &&
          !user.blockedAnnualOverride() &&
          !monthlyHistoryRequired && (
            <Toggle value={toggle} onChange={setToggle}>
              <ToggleItem value="ANNUAL">
                {t('common.annualRebates')} {yearOfAnnualRebates}
              </ToggleItem>
              <ToggleItem value="MONTHLY">
                {t('common.monthlyRebates')} {yearOfMonthlyRebates}
              </ToggleItem>
            </Toggle>
          )}

        {(annualPeriod && toggle === 'ANNUAL') ||
        user.blockedAnnualOverride() ? (
          <AnnualRebates />
        ) : monthlyHistoryRequired ? (
          <MonthlyHistory />
        ) : (
          <MonthlyRebates />
        )}
      </Container>
    </InsideLayout>
  )
}
export default TaxRebates

const AnnualRebates: React.FC = () => {
  const { t } = useTranslation()
  const { history } = useRouter()
  const { user } = useUser()

  const theme = useTheme()
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)

  const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
  const closeCancelDialog = () => setCancelDialogOpen(false)

  const [helpDialogOpen, setHelpDialogOpen] = useState(false)
  const closeHelpDialog = () => setHelpDialogOpen(false)

  const { data, loading, error, refetch } = useQuery<userWithAnnualRebateOverview>(OWN_ANNUAL_REBATE_OVERVIEW, {
    fetchPolicy: 'cache-and-network',
  })

  const annualRebate = data && data.user && data.user.annualRebate
  const settlementRequest = annualRebate && annualRebate.settlementRequest
  const year = annualRebate && annualRebate.year
  const overview = annualRebate && annualRebate.overview
  const pozp = overview && overview.pozp
  const prevEmployerDenied =
    annualRebate &&
    annualRebate.prevEmployers &&
    annualRebate.prevEmployers.some((empl) => empl.status === 'DENIED')
  const annualQuestionnaireStatus =
    overview && overview.questionnaire && overview.questionnaire.status

  const summaryStatus = useCalcSummaryStatus(settlementRequest && settlementRequest.status, annualRebate)

  const monthlyDenied =
    annualRebate &&
    annualRebate.overview &&
    [
      get(annualRebate.overview.basicRebate, 'status'),
      get(annualRebate.overview.childrenRebate, 'status'),
      get(annualRebate.overview.disabilityRebate, 'status'),
      get(annualRebate.overview.ztppRebate, 'status')
    ].some(status => status === 'DENIED')

  const [cancelAnnualSettlementRequest, mutationState] = useMutation<
    ICancelAnnualSettlementRequestData
  >(CANCEL_ANNUAL_SETTLEMENT_REQUEST, {
    onCompleted() {
      // closeCancelDialog()
      if (pozp) {
        history.push(`/${user.data.id}/annual-rebates/questionnaire`)
      } else {
        history.push(`/${user.data.id}/annual-rebates/root`)
      }
    },
    onError() {
      closeCancelDialog()
      refetch()
    },
  })

  const toWizard = () => {
    // TBD když není settlementRequest, prokliklo se nejspíš hned po registraci přes menu nahoře na správu profilu a snaží se dostat zpět. Ošetřit ještě nějaký speciálním redirectem, když není settlementRequest?
    if (settlementRequest && settlementRequest.status === 'APPLY') {
      setCancelDialogOpen(true)
    } else {
      if (pozp || annualQuestionnaireStatus === 'DENIED') {
        history.push(`/${user.data.id}/annual-rebates/questionnaire`)
      } else if (prevEmployerDenied) {
        history.push(`/${user.data.id}/annual-rebates/previousEmployers`)
      } else if (
        user.shouldHaveResidenceStep() &&
        (user.noProvidedFiles() ||
          (user.prevDocumentsIsDenied() && !user.hasNewResidenceFiles()))
      ) {
        history.push(`/${user.data.id}/annual-rebates/taxResidence`)
      } else if (monthlyDenied) {
        history.push(`/${user.data.id}/annual-rebates/rootMonthly`)
      } else {
        history.push(`/${user.data.id}/annual-rebates/root`)
      }
    }
  }

  return (
    <Fade>
      <>
        <AppState loading={loading || mutationState.loading} />
        <GraphQLErrorSnackbar error={error || mutationState.error} />

        <CancelRequestDialog
          open={cancelDialogOpen}
          onClose={closeCancelDialog}
          onSubmit={cancelAnnualSettlementRequest}
        />

        <Center my={5} flexDirection="column">
          <Typography variant="h1" align="center">
            {t('taxRebates.form')} {t('common.annualRebates')} {year}
          </Typography>

          <Subheading variant="body2">{t('taxRebates.subHeading', { context: "2023" })}</Subheading>

          <Box my={2} display="flex" justifyContent="center">
            <RocniZuctovani2 fontSize="large" />
          </Box>

          <Button
            onClick={toWizard}
            variant="contained"
            color="primary"
            size="large"
          >
            {t('common.enterToForm')}{' '}
            <KeyboardArrowRight viewBox="0 -1 24 24" />
          </Button>
        </Center>

        {settlementRequest && annualRebate && (
          <>
            <Summary status={summaryStatus} />
            {/* <Attachments data={settlementRequest} /> */}

            {pozp ? (
              <>
                <Box maxWidth="100%" width={820} mt={4}>
                  <RebateRow
                    label={t('taxRebates.annual.questionnaire.labelPozp')}
                    subHeading={t('taxRebates.annual.questionnaire.subHeading')}
                    help={t('taxRebates.annual.questionnaire.help')}
                    icon={<Dotaznik />}
                    status={pozp.status}
                  />

                  <Divider my={4} />

                  <Typography variant="h2" align="center">{t('taxRebates.rebatesTitle', { context: "2023" })}</Typography>

                  <Divider my={4} />

                  <Box mt={10} mb={6}>
                    <Typography variant="body1" align="center" style={{
                      fontWeight: 700,
                    }}>
                      {t('taxRebates.noApplyingRebates', { context: "2023" })}
                    </Typography>
                  </Box>
                </Box>

                <Divider my={4} />
              </>
            ) : (
              <>
                <Box maxWidth="100%" width={820} mt={4}>
                  <RebateRow
                    label={t('taxRebates.annual.questionnaire.label')}
                    subHeading={t('taxRebates.annual.questionnaire.subHeading')}
                    help={t('taxRebates.annual.questionnaire.help')}
                    icon={<Dotaznik />}
                    status={annualQuestionnaireStatus}
                  />

                <PrevEmployers
                  data={annualRebate}
                  settlementRequestStatus={settlementRequest.status}
                />

                <TaxResidence />

                {overview && (
                    <>
                      <Divider my={4} />
                      <Box maxWidth="100%" width={820}>
                        <RebatesOverviewPure
                          data={overview}
                          heading={t('taxRebates.rebatesTitle', { context: "2023" })}
                        />
                      </Box>
                    </>
                  )}

                </Box>

                <Divider my={4} />
              </>
            )}

            <Box
              maxWidth="100%"
              width={820}
              display="flex"
              flexDirection={isNotSmallScreen ? 'row' : 'column'}
              ml={isNotSmallScreen ? -1 : 0}
              style={isNotSmallScreen ? undefined : { gap: '8px' }}
            >
              <AnnualInfoDialog
                isOpen={helpDialogOpen}
                onClose={closeHelpDialog}
              />
              <BlueButton
                onClick={() => { history.push(`/${user.data.id}/guide`) }}
                variant="contained"
                size="large"
              >
                {t('common.instructions')}
              </BlueButton>
              <BlueButton
                onClick={() => setHelpDialogOpen(true)}
                variant="contained"
                size="large"
              >
                {t('taxRebates.helpButton', { context: "2023" })}
              </BlueButton>
              {((annualRebate.settlementRequest
                  && ['ANNUAL', 'ANNUAL_CHANGE'].includes(annualRebate.settlementRequest.type))
                || (annualRebate.file && annualRebate.file.id)) && (
                <AnnualPdfFormDownload annualRebate={annualRebate} />
              )}
            </Box>
          </>
        )}
      </>
    </Fade>
  )
}

const MonthlyRebates: React.FC = () => {
  const { t } = useTranslation()
  const { history } = useRouter()
  const { user } = useUser()

  const theme = useTheme()
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)

  const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
  const closeCancelDialog = () => setCancelDialogOpen(false)

  const [helpDialogOpen, setHelpDialogOpen] = useState(false)
  const closeHelpDialog = () => setHelpDialogOpen(false)

  const { data, loading, error, refetch } = useQuery<IOwnMonthlyRebateData>(
    OWN_MONTHLY_REBATE_OVERVIEW,
    { fetchPolicy: 'cache-and-network' },
  )

  const settlementRequest: IOwnMonthlyRebateData['user']['monthlyRebate']['settlementRequest'] = get(
    data,
    'user.monthlyRebate.settlementRequest',
  )
  // const monthlyRebate: TMonthlyRebate = get(data, 'user.monthlyRebate')
  const monthlyRebate = data && data.user.monthlyRebate
  const year: number = get(data, 'user.monthlyRebate.year')
  const status: NexusGenEnums['SettlementRequestStatus'] = get(
    settlementRequest,
    'status',
  )
  const overview = monthlyRebate && monthlyRebate.overview
  const monthlyDontCreate = overview && overview.dontCreate
  const monthlyQuestionnaireStatus =
    overview && overview.questionnaire && overview.questionnaire.status

  const summaryStatus = useCalcSummaryStatus(status, monthlyRebate)

  const [cancelMonthlySettlementRequest, mutationState] = useMutation<
    ICancelMonthlySettlementRequestData
  >(CANCEL_MONTHLY_SETTLEMENT_REQUEST, {
    onCompleted() {
      if (monthlyDontCreate) {
        history.push(`/${user.data.id}/monthly-rebates/questionnaire`)
      } else {
        if (user.shouldHaveResidenceStep()) {
          history.push(`/${user.data.id}/monthly-rebates/taxResidence`)
        } else {
          history.push(`/${user.data.id}/monthly-rebates/root`)
        }
      }
    },
    onError() {
      closeCancelDialog()
      refetch()
    },
  })

  const toWizard = () => {
    // TBD když není settlementRequest, prokliklo se nejspíš hned po registraci přes menu nahoře na správu profilu a snaží se dostat zpět. Ošetřit ještě nějaký speciálním redirectem, když není settlementRequest?
    if (settlementRequest && settlementRequest.status === 'APPLY') {
      setCancelDialogOpen(true)
    } else {
      if (monthlyQuestionnaireStatus === 'DENIED' || monthlyDontCreate) {
        history.push(`/${user.data.id}/monthly-rebates/questionnaire`)
      } else if (
        user.shouldHaveResidenceStep() &&
        (user.noProvidedFiles() ||
          (user.prevDocumentsIsDenied() && !user.hasNewResidenceFiles()))
      ) {
        history.push(`/${user.data.id}/monthly-rebates/taxResidence`)
      } else {
        history.push(`/${user.data.id}/monthly-rebates/root`)
      }
    }
  }

  return (
    <Fade>
      <>
        <AppState loading={loading || mutationState.loading} />
        <GraphQLErrorSnackbar error={error || mutationState.error} />

        <CancelRequestDialog
          open={cancelDialogOpen}
          onClose={closeCancelDialog}
          onSubmit={cancelMonthlySettlementRequest}
        />

        <Center my={5} flexDirection="column">
          <Typography variant="h1" align="center">
            {t('taxRebates.form')} {t('common.monthlyRebate')} {year}
          </Typography>

          <Subheading variant="body2">{t('taxRebates.subHeading', { context: "2023" })}</Subheading>

          <Box my={2} display="flex" justifyContent="center">
            <MesicniSlevy fontSize="large" />
          </Box>

          <Button
            onClick={toWizard}
            variant="contained"
            color="primary"
            size="large"
          >
            {t('common.enterToForm')}{' '}
            <KeyboardArrowRight viewBox="0 -1 24 24" />
          </Button>
        </Center>

        {settlementRequest && monthlyRebate && (
          <>
            <Summary status={summaryStatus} />

            {monthlyDontCreate ? (
              <>
                <Box maxWidth="100%" width={820} mt={4}>
                  <RebateRow
                    label={t('taxRebates.questionnaire.labelDontCreate')}
                    subHeading={t('taxRebates.questionnaire.subHeading')}
                    help={t('taxRebates.questionnaire.help')}
                    icon={<Dotaznik />}
                    status={monthlyDontCreate.status}
                  />

                  <Divider my={4} />

                  <Typography variant="h2" align="center">{t('taxRebates.rebatesTitle', { context: "2023" })}</Typography>

                  <Divider my={4} />

                  <Box mt={10} mb={6}>
                    <Typography variant="body1" align="center" style={{
                      fontWeight: 700,
                    }}>
                      {t('taxRebates.noApplyingRebates', { context: "2023" })}
                    </Typography>
                  </Box>
                </Box>

                <Divider my={4} />
              </>
            ) : (
              <>
                <Box maxWidth="100%" width={820} mt={4}>
                  <RebateRow
                    label={t('taxRebates.questionnaire.label')}
                    subHeading={t('taxRebates.questionnaire.subHeading')}
                    help={t('taxRebates.questionnaire.help')}
                    icon={<Dotaznik />}
                    status={monthlyQuestionnaireStatus}
                  />

                  <TaxResidence />
                </Box>

                {overview && (
                  <>
                    <Divider my={4} maxWidth={820} />
                    <Box maxWidth="100%" width={820}>
                      <RebatesOverviewPure
                        data={overview}
                        heading={t('taxRebates.rebatesTitle', { context: "2023" })}
                      />
                    </Box>
                  </>
                )}

                <Divider my={4} />
              </>
            )}

            <Box
              maxWidth="100%"
              width={820}
              display="flex"
              flexDirection={isNotSmallScreen ? 'row' : 'column'}
              ml={isNotSmallScreen ? -1 : 0}
              style={isNotSmallScreen ? undefined : { gap: '8px' }}
            >
              <MonthlyInfoDialog isOpen={helpDialogOpen} user={user} onClose={closeHelpDialog} />
              <BlueButton
                onClick={() => { history.push(`/${user.data.id}/guide`) }}
                variant="contained"
                size="large"
              >
                {t('common.instructions')}
              </BlueButton>
              <BlueButton
                onClick={() => setHelpDialogOpen(true)}
                variant="contained"
                size="large"
              >
                {t('taxRebates.helpButton', { context: "2023" })}
              </BlueButton>
              {(
                monthlyRebate.settlementRequest ||
                (monthlyRebate.file && monthlyRebate.file.id)) && (
                  <MonthlyPdfFormDownload monthlyRebate={monthlyRebate} />
              )}
            </Box>
          </>
        )}
      </>
    </Fade>
  )
}

const MonthlyHistory: React.FC = () => {
  const { t } = useTranslation()
  const { history } = useRouter()
  const { user } = useUser()

  const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
  const closeCancelDialog = () => setCancelDialogOpen(false)

  const { data, loading, error, refetch } = useQuery<IOwnMonthlyHistoryData>(
    OWN_MONTHLY_HISTORY_OVERVIEW,
    { fetchPolicy: 'cache-and-network' },
  )

  const [cancelMonthlyHistorySettlementRequest, mutationState] = useMutation<
    ICancelMonthlyHistorySettlementRequestData
  >(CANCEL_MONTHLY_HISTORY_SETTLEMENT_REQUEST, {
    onCompleted() {
      history.push(`/${user.data.id}/monthly-history/root`)
    },
    onError() {
      closeCancelDialog()
      refetch()
    },
  })

  const settlementRequest: IOwnMonthlyRebateData['user']['monthlyRebate']['settlementRequest'] = get(
    data,
    'user.monthlyHistory.settlementRequest',
  )
  const monthlyHistory: TMonthlyRebate = get(data, 'user.monthlyHistory')
  const year: number = get(data, 'user.monthlyHistory.year')
  const status: NexusGenEnums['SettlementRequestStatus'] = get(
    settlementRequest,
    'status',
  )

  const toWizard = () => {
    // TBD když není settlementRequest, prokliklo se nejspíš hned po registraci přes menu nahoře na správu profilu a snaží se dostat zpět. Ošetřit ještě nějaký speciálním redirectem, když není settlementRequest?
    if (settlementRequest && settlementRequest.status === 'APPLY') {
      setCancelDialogOpen(true)
    } else {
      history.push(`/${user.data.id}/monthly-history/root`)
    }
  }

  return (
    <Fade>
      <>
        <AppState loading={loading || mutationState.loading} />
        <GraphQLErrorSnackbar error={error || mutationState.error} />

        <CancelRequestDialog
          open={cancelDialogOpen}
          onClose={closeCancelDialog}
          onSubmit={cancelMonthlyHistorySettlementRequest}
        />

        <Center my={5} flexDirection="column">
          <Typography variant="h1" align="center">
            {`${t('common.monthlyRebate')} ${year} (${String(
              t('common.history'),
            ).toLowerCase()})`}
          </Typography>

          <Subheading variant="body2">{t('taxRebates.subHeading')}</Subheading>

          <Box my={2} display="flex" justifyContent="center">
            <MesicniSlevy fontSize="large" />
          </Box>

          <Button
            onClick={toWizard}
            variant="contained"
            color="primary"
            size="large"
          >
            {t('common.enterToForm')}{' '}
            <KeyboardArrowRight viewBox="0 -1 24 24" />
          </Button>
        </Center>

        {settlementRequest && (
          <>
            <Summary status={status} />

            <Box maxWidth="100%" width={820} px={3} pb={2} mt={5}>
              <Typography variant="h3">
                {t('taxRebates.rebatesTitle')}
              </Typography>
            </Box>
            <Box maxWidth="100%" width={820}>
              <RebatesOverview data={settlementRequest} />
            </Box>

            <Divider my={4} />

            <Box maxWidth="100%" width={820} display="flex" ml={-1}>
              <BlueButton
                onClick={() =>
                  history.push(`/${user.data.id}/rebates/monthly-history/help`)
                }
                variant="contained"
                size="large"
              >
                {t('taxRebates.helpButton')}
              </BlueButton>
              {monthlyHistory.file && (
                <BlueButton
                  variant="contained"
                  size="large"
                  onClick={() => handleDownload(monthlyHistory.file)}
                >
                  {t('taxRebates.downloadButton')}
                </BlueButton>
              )}
            </Box>
          </>
        )}
      </>
    </Fade>
  )
}
