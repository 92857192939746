import {NexusGenArgTypes, NexusGenEnums, NexusGenRootTypes,} from 'kubik-server'
import {gql} from 'apollo-boost'
import {useEffect, useState} from 'react'
import {useMutation} from 'react-apollo'
import {useTranslation} from 'react-i18next'
import Validator from 'validatorjs'
import moment from 'moment'

export type TClientLanguage = NexusGenEnums['Language'] | 'SOURCE'

export const SET_OWN_LANGUAGE = gql`
  mutation setOwnLanguage($language: Language!) {
    setOwnLanguage(language: $language) {
      id
      language
    }
  }
`

export const SET_ANONYMOUS_LANGUAGE = gql`
  mutation setAnonymousLanguage(
    $language: Language!
    $anonymousToken: String!
  ) {
    setAnonymousLanguage(language: $language, anonymousToken: $anonymousToken)
  }
`

const useLanguage = (
  lng: NexusGenEnums['Language'],
  anonymousToken: string | null,
  isLoggedIn: boolean,
) => {
  const { i18n } = useTranslation()

  const [setOwnLanguage] = useMutation<
    { setOwnLanguage: NexusGenRootTypes['Account'] },
    NexusGenArgTypes['Mutation']['setOwnLanguage']
  >(SET_OWN_LANGUAGE)

  const [setAnonymousLanguage] = useMutation<
    { setAnonymousLanguage: boolean },
    NexusGenArgTypes['Mutation']['setAnonymousLanguage']
  >(SET_ANONYMOUS_LANGUAGE)

  const [language, setLanguage] = useState<TClientLanguage>(lng)
  useEffect(() => {
    setLanguage(lng)
  }, [lng])
  useEffect(() => {
    if (language !== 'SOURCE') {
      moment.locale(language.toLowerCase())
      window.localStorage.setItem('x-pym-language', language)
      Validator.useLang(language.toLowerCase())

      if (isLoggedIn && language !== lng) {
        setOwnLanguage({ variables: { language } })
      } else if (anonymousToken) {
        setAnonymousLanguage({ variables: { language, anonymousToken } })
      }

      // @ts-ignore
      if (window.smartsupp) {
        // @ts-ignore
        window.smartsupp('language', language.toLowerCase())
      }
    }

    i18n.changeLanguage(language)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, i18n, isLoggedIn, setOwnLanguage, setAnonymousLanguage])

  return { language, setLanguage }
}

export default useLanguage

export interface TLanguageObject {
  value: TClientLanguage
  text: string
}
export const languages: TLanguageObject[] = [
  { value: 'CS', text: 'Czech' },
  { value: 'EN', text: 'English' },
  ...(process.env.NODE_ENV !== 'production'
    ? [{ value: 'SOURCE' as 'SOURCE', text: 'Zdroják' }]
    : []),
]
