import {NexusGenArgTypes, NexusGenEnums, NexusGenFieldTypes, NexusGenRootTypes,} from 'kubik-server'
import {CHILDREN_REBATE_FULL_FRAGMENT, TChildrenRebate,} from '../../../../../../fragments/ChildrenRebate'
import {gql} from 'apollo-boost'

export type TChild = NexusGenRootTypes['Child']
export type TChildWithPrev = TChild & {
  prevClosePeriodAt?: any
  prevExists?: boolean
}
export type TPartialChild = TChild & { id?: string }
export type TNewChild = Pick<TChild, Exclude<keyof TChild, 'id'>>
export type TMonth = NexusGenEnums['Month']
export const isNewChild = (child: TChild | TNewChild): child is TNewChild => {
  return !('id' in child)
}
export const isExistingChild = (child: TChild | TNewChild): child is TChild => {
  return 'id' in child
}
export const isConfirmedChild = (
  child: TChildWithPrev | TNewChild,
): child is TChildWithPrev => {
  return 'prevExists' in child && !!child.prevExists
}

export interface IOwnMonthlyHistoryChildrenRebateData {
  user: {
    monthlyHistory: NexusGenFieldTypes['MonthlyRebate'] & {
      childrenRebate: TChildrenRebate
      childrenRebates: TChildrenRebate[]
    }
  }
}
export const OWN_MONTHLY_HISTORY_CHILDREN_REBATE = gql`
  query ownMonthlyHistoryChildrenRebate {
    user {
      id
      monthlyHistory {
        id
        year
        childrenRebate {
          ...ChildrenRebateFull
        }
        childrenRebates {
          ...ChildrenRebateFull
        }
      }
    }
  }

  ${CHILDREN_REBATE_FULL_FRAGMENT}
`

export interface IUpdateOwnMonthlyHistoryChildrenRebateData {
  updateOwnMonthlyHistoryChildrenRebate: NexusGenFieldTypes['ChildrenRebate']
}
export type TUpdateOwnMonthlyHistoryChildrenRebateVariables = NexusGenArgTypes['Mutation']['updateOwnMonthlyHistoryChildrenRebate']
export const UPDATE_OWN_MONTHLY_HISTORY_CHILDREN_REBATE = gql`
  mutation updateOwnMonthlyHistoryChildrenRebate(
    $data: OwnChildrenRebateUpdateInput!
  ) {
    updateOwnMonthlyHistoryChildrenRebate(data: $data) {
      ...ChildrenRebateFull
    }
  }
  ${CHILDREN_REBATE_FULL_FRAGMENT}
`

export interface IResetOwnMonthlyHistoryChildrenRebateData {
  resetOwnMonthlyHistoryChildrenRebate: TChildrenRebate
}
export const RESET_OWN_MONTHLY_HISTORY_CHILDREN_REBATE = gql`
  mutation resetOwnMonthlyHistoryChildrenRebate {
    resetOwnMonthlyHistoryChildrenRebate {
      ...ChildrenRebateFull
    }
  }
  ${CHILDREN_REBATE_FULL_FRAGMENT}
`
