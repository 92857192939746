import {
  SETTLEMENT_REQUEST,
  ISettlementRequestData,
  TSettlementRequestVariables,
} from './gql'
import { Link, RouteComponentProps } from 'react-router-dom'
import { Divider } from '../../../../../components/CommonBox'
import { settlementRequestType } from '../../../../../utils'
import { useGenderEnum, useCountryEnum, useLanguageEnum } from '../../../../../hooks/useEnumList'
import { useQuery } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import AppState from '../../../../../components/AppState'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CenteredTextFields from '../../../../../components/CenteredTextFields'
import GraphQLErrorSnackbar from '../../../../../components/GraphQLErrorSnackbar'
import Grid from '@material-ui/core/Grid'
import InsideLayout from '../../../../../components/layouts/InsideLayout'
import moment from 'moment'
import POZP from './components/POZP'
import { MonthlyDontCreate } from './components/MonthlyTaxQuestionnaire'
import React from 'react'
import Rebates from './Rebates'
import ScrollToTop from '../../../../../components/ScrollToTop'
import Typography from '@material-ui/core/Typography'
import useUser from '../../../../../hooks/useUser'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'
import BackButton from "../../../../../components/BackButton";

const Detail: React.FC<RouteComponentProps<{ requestId: string }>> = (
  props,
) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { translate: translateLanguage } = useLanguageEnum()
  const { translate: translateGender } = useGenderEnum()
  const { translate: translateCountry } = useCountryEnum()
  const settlementRequestId = props.match.params.requestId

  const { data, loading, error } = useQuery<
    ISettlementRequestData,
    TSettlementRequestVariables
  >(SETTLEMENT_REQUEST, {
    fetchPolicy: 'network-only',
    variables: {
      where: { id: settlementRequestId },
    },
  })

  const mightMissHistory = () => {
    const sr = data && data.settlementRequest
    let result = false
    if(moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD') >= moment(user.data.customer.activationAt).format('YYYY-MM-DD')){
      return false
    }
    if (sr) {
      const isAnnualRebate = sr.annualRebate
      const hasMonthly = sr.annualRebate && sr.annualRebate.hasMonthlyInSameYear

      // hrajeme na slevy, o které žádá na aktuálním settlementRequestu
      const hasMonthlyRebates =
        sr.basicRebate ||
        sr.childrenRebate ||
        sr.disabilityRebate ||
        sr.ztppRebate ||
        sr.studentRebate

      result = Boolean(isAnnualRebate && hasMonthlyRebates && !hasMonthly)
    }

    return result
  }

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <AppState loading={loading} />
      <GraphQLErrorSnackbar error={error} />
      {data && data.settlementRequest && (
        <Box
          maxWidth="100%"
          width={1077}
          ml="auto"
          mr="auto"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          {settlementRequestType(data.settlementRequest.type) ===
            'MONTHLY_HISTORY' && (
            <Typography variant="h1">
              {t('common.monthlyRebate')}{' '}
              {data.settlementRequest.monthlyRebate &&
                data.settlementRequest.monthlyRebate.year}
              {` (${String(t('common.history')).toLowerCase()})`}
            </Typography>
          )}
          {settlementRequestType(data.settlementRequest.type) === 'MONTHLY' && (
            <Typography variant="h1">
              {t(
                data.settlementRequest.type === 'MONTHLY_DONT_CREATE'
                  ? 'settlementRequest.requestMonthlyDontCreate'
                  : 'common.monthlyRebate',
              )}{' '}
              {data.settlementRequest.monthlyRebate &&
                data.settlementRequest.monthlyRebate.year}
            </Typography>
          )}
          {settlementRequestType(data.settlementRequest.type) === 'ANNUAL' && (
            <Typography variant="h1">
              {t(
                data.settlementRequest.type === 'ANNUAL_POZP'
                  ? 'settlementRequest.requestPOZP'
                  : 'common.annualRebates',
              )}{' '}
              {data.settlementRequest.annualRebate &&
                data.settlementRequest.annualRebate.year}
            </Typography>
          )}

          <Box maxWidth="100%" width={800} mt={6} mb={2}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={4}
            >
              <Typography variant="h2">
                {t('common.personalInformationOfEmployee')}
              </Typography>
              <Button
                color="primary"
                component={Link}
                to={{
                  pathname: `/${user.data.id}/employees/${data.settlementRequest.employee.id}/profile`,
                  state: {
                    from: `/${user.data.id}/settlement-requests/${settlementRequestId}`,
                  },
                }}
              >
                {t('common.userProfile')}
              </Button>
            </Box>

            <Grid container spacing={2}>
              {data.settlementRequest.employee.personalNumber ? (
                  <CenteredTextFields
                    label={t('employee.personalNumber')}
                    text={data.settlementRequest.employee.personalNumber}
                  />
              ) : null}
              <CenteredTextFields
                label={t('common.firstname')}
                text={data.settlementRequest.employee.firstname}
              />
              <CenteredTextFields
                label={t('common.lastname')}
                text={data.settlementRequest.employee.lastname}
              />
              <CenteredTextFields
                label={t('common.gender')}
                text={translateGender(data.settlementRequest.employee.gender)}
              />
              <CenteredTextFields
                label={t('common.nationalIDNumber')}
                text={data.settlementRequest.employee.nationalIDNumber}
              />
              <CenteredTextFields
                label={t('common.permanentAddress2')}
                text={data.settlementRequest.employee.permanentAddress}
              />
              <CenteredTextFields
                label={t('common.nationality')}
                text={translateCountry(
                  data.settlementRequest.employee.nationality,
                )}
              />
              <CenteredTextFields
                label={t('common.czechTaxResidence')}
                text={
                  data.settlementRequest.employee.czechTaxResidence
                    ? t('common.yes')
                    : t('common.no')
                }
              />
              <CenteredTextFields
                label={t('employee.dateOfEmployment')}
                text={moment(
                  data.settlementRequest.employee.dateOfEmployment,
                ).format('DD.MM.YYYY')}
              />
              {data.settlementRequest.employee.language ? <CenteredTextFields
                label={t('common.language')}
                text={translateLanguage(data.settlementRequest.employee.language)}
              /> : null}
              {data.settlementRequest.employee.unit ? (
                <CenteredTextFields
                  label={t('common.unit')}
                  text={data.settlementRequest.employee.unit.name}
                />
              ) : null}
              {settlementRequestType(data.settlementRequest.type) ===
                'ANNUAL' &&
                data.settlementRequest.annualRebate &&
                data.settlementRequest.annualRebate.firstRequestAt && (
                  <CenteredTextFields
                    label={t('common.firstRequestAt')}
                    text={moment(
                      data.settlementRequest.annualRebate.firstRequestAt,
                    ).format('DD.MM.YYYY')}
                  />
                )}
              {settlementRequestType(data.settlementRequest.type) ===
                'MONTHLY' &&
                data.settlementRequest.monthlyRebate &&
                data.settlementRequest.monthlyRebate.firstRequestAt && (
                  <CenteredTextFields
                    label={t('common.firstRequestAt')}
                    text={moment(
                      data.settlementRequest.monthlyRebate.firstRequestAt,
                    ).format('DD.MM.YYYY')}
                  />
                )}
            </Grid>
          </Box>

          {mightMissHistory()  && (
            <Box>
              <Typography variant="h3" gutterBottom>
                <PriorityHighIcon
                  style={{ verticalAlign: 'bottom', color: '#ff0000' }}
                />
                {t('settlementRequest.noPreviousHistory', {
                  context: data.settlementRequest.employee.gender,
                })}
              </Typography>
            </Box>
          )}

          <Divider my={4} />

          {data &&
          data.settlementRequest.type === 'ANNUAL_POZP' &&
          data.settlementRequest.annualRebate &&
          data.settlementRequest.annualRebate.taxQuestionnaire ? (
            <POZP
              status={data.settlementRequest.status}
              settlementRequestId={settlementRequestId}
              data={data.settlementRequest}
            />
          ) : data &&
            data.settlementRequest.type === 'MONTHLY_DONT_CREATE' &&
            data.settlementRequest.monthlyRebate &&
            data.settlementRequest.monthlyRebate.taxQuestionnaire ? (
            <MonthlyDontCreate
              status={data.settlementRequest.status}
              settlementRequestId={settlementRequestId}
              data={data.settlementRequest}
            />
          ) : (
              <>
                <Rebates
                  status={data.settlementRequest.status}
                  data={data.settlementRequest}
                  settlementRequestId={settlementRequestId}
              />
                {data && ['CONFIRMED', 'DENIED'].includes(data.settlementRequest.status) &&
                  <div style={{color: '#898989'}}>
                    <BackButton to={`/${user.data.id}/settlement-requests`}/>
                  </div>
                }
              </>
          )}




        </Box>
      )}
    </InsideLayout>
  )
}

export default Detail
