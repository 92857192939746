import { NexusGenEnums } from 'kubik-server'
import {IMeData, LOGOUT} from '../pages/gql'
import useLanguage, { TClientLanguage } from '../hooks/useLanguage'
import React, { useState } from 'react'
import {useMutation} from "react-apollo";
import {REINCARNATE, TReincarnateData, TReincarnateVariables} from "../pages/Inside/pages/Employees/gql";

interface IAccountContext {
  data: IMeData['me'] | null
  language: TClientLanguage
  isLoggedIn: () => boolean
  setLanguage: (language: TClientLanguage) => void
  anonymousToken: string | null
  setAnonymousToken: React.Dispatch<React.SetStateAction<string | null>>
  refetch: () => Promise<void>
  logout: () => void
  reincarnate: (vars: TReincarnateVariables) => void
}


const AccountContext = React.createContext<IAccountContext>({
  data: null,
  language: 'CS',
  isLoggedIn: () => false,
  setLanguage: () => {},
  anonymousToken: null,
  setAnonymousToken: () => {},
  refetch: async () => {},
  logout: () => {},
  reincarnate: (vars: TReincarnateVariables) => {}
})

interface Props {
  account?: IMeData['me'],
  refetch?: () => Promise<void>,
  afterLogout?: () => void,
  afterReincarnate?: (reincarnatedId: string) => void,
}
const AccountProvider: React.FC<Props> = props => {
  const isLoggedIn = () => Boolean(props.account && props.account.id)

  const lng: NexusGenEnums['Language'] =
    (props.account && props.account.language) ||
    (window.localStorage.getItem(
      'x-pym-language',
    ) as NexusGenEnums['Language']) ||
    'CS'

  const [anonymousToken, setAnonymousToken] = useState<string | null>(null)
  const [reincarnatedId, setReincarnatedId] = useState<string | null>(null)

  const [logout] = useMutation(LOGOUT, {
    onCompleted() {
      if(props.afterLogout) {
        props.afterLogout()
      }
    }
  })


  const [reincarnate] = useMutation<TReincarnateData, TReincarnateVariables>(
    REINCARNATE,
    {
      onCompleted(data) {
        if(props.afterReincarnate && reincarnatedId) {
          props.afterReincarnate(reincarnatedId)
        }
      }
    }
  )

  const { language, setLanguage } = useLanguage(
    lng,
    anonymousToken,
    isLoggedIn(),
  )

  return (
    <AccountContext.Provider
      value={{
        data: props.account || null,
        isLoggedIn,
        language,
        setLanguage,
        anonymousToken,
        setAnonymousToken,
        refetch: props.refetch || (() => {}),
        logout,
        reincarnate: (vars) => {
          setReincarnatedId(vars.userId)
          reincarnate({variables: vars})
        }
      }}
    >
      {props.children}
    </AccountContext.Provider>
  )
}

const AccountConsumer = AccountContext.Consumer
export { AccountProvider, AccountConsumer, AccountContext }
