import React from "react"
import { useTranslation } from "react-i18next";

import { List, ListItem, ListItemText } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import Expand from "../../components/Expand";

type RebateConditionItem = JSX.Element | WithSupportText | WithSubitems
interface RebateConditionsProps {
    items: RebateConditionItem[];
}

type WithSupportText = {
    primaryText: JSX.Element;
    supportText: JSX.Element;
}
type WithSubitems = {
    title: JSX.Element;
    subitems: RebateConditionItem[];
}

function hasSupportText(item: RebateConditionsProps["items"][number]): item is WithSupportText {
    return "supportText" in item;
}
function hasSubitems(item: RebateConditionsProps["items"][number]): item is WithSubitems {
    return (item as WithSubitems).subitems && (item as WithSubitems).subitems.length > 0
}

export const useStyles = makeStyles(() => ({
    list: { padding: "0.5rem 2rem", "& li": {paddingTop: "0rem !important", paddingBottom: "0rem !important"} },
    sublistContainer: { padding: "0 0 0 1rem" }
}))

export const RebateExpandable: React.FC = ({ children }) => {
    const { t } = useTranslation()

    return (
        <Expand title={t('common.rebateConditions')} expand>
            {children}
        </Expand>
    )
}

export const RebateConditions: React.FC<RebateConditionsProps> = ({ items }) => {
    const classes = useStyles()

    return (
        <List className={classes.list + " list"}>
            {items.map((item, i) => {
                if (hasSubitems(item)){
                    return (
                      <>
                          <ListItem key={i}>
                              <ListItemText primary={item.title}/>
                          </ListItem>
                          <div className={classes.sublistContainer}><RebateConditions items={item.subitems}/></div>
                      </>)
                }

                if (hasSupportText(item)) {
                    return (
                      <ListItem key={i}>
                            <ListItemText primary={item.primaryText} secondary={item.supportText} />
                        </ListItem>
                    )
                }
                
                return (
                    // NOTE: This might have performance implications. However, from the nature of component usage it's good to assume it's ok.
                    // https://react.dev/learn/rendering-lists#why-does-react-need-keys
                    <ListItem key={i}>
                        <ListItemText primary={item} />
                    </ListItem>
                )
            })}
        </List>
    )
}
