import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(60),
  },
  fontSizeDefault: {
    fontSize: theme.typography.pxToRem(75),
  },
  fontSizeLarge: {
    fontSize: theme.typography.pxToRem(115)
  }
}))

const Dary2: React.FC<Props> = ({ viewBox = '0 0 319 196', fontSize, ...props }) => {
  const classes = useStyles()

  return (
    <SvgIcon
      viewBox={viewBox}
      className={classnames({
        [classes.fontSizeDefault]: fontSize === 'default' || !fontSize,
        [classes.fontSizeSmall]: fontSize === 'small',
        [classes.fontSizeLarge]: fontSize === 'large',
        [classes.inherit]: fontSize === 'inherit'
      })}
      fontSize={fontSize}
      xmlSpace='preserve'
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 10,
      }}
      {...props}
    >
        <path fill="#53a1e0" fillRule="nonzero" d="M173.25 108.708h138.917v74.417a6.615 6.615 0 0 1-6.625 6.625H179.875a6.615 6.615 0 0 1-6.625-6.625v-74.417Z"/>
        <path fill="#53a1e0" d="M318.042 65.625A6.629 6.629 0 0 0 311.417 59H174.042a6.628 6.628 0 0 0-6.625 6.625v36.458a6.627 6.627 0 0 0 6.625 6.625h137.375a6.628 6.628 0 0 0 6.625-6.625V65.625Z"/>
        <path fill="#4692c6" d="M173.292 108.708h138.875v8.5H173.292z"/>
        <path fill="#86c2ea" fillRule="nonzero" d="M181.792 117.208v72.542h-1.917a6.615 6.615 0 0 1-6.625-6.625v-65.917h8.542ZM175 59h-4.042a3.538 3.538 0 0 0-3.541 3.542v42.625a3.538 3.538 0 0 0 3.541 3.541H175V59Z"/>
        <path fill="#ef8181" d="M225.542 59h34.292v49.708h-34.292z"/>
        <path fill="#4692c6" d="M259.917 59h3.375v49.708h-3.375zM259.917 117.208h3.375v72.542h-3.375z"/>
        <path fill="#ef8181" d="M225.542 117.208h34.292v72.542h-34.292z"/>
        <path fill="#ce6a6a" d="M225.542 108.708h34.292v8.5h-34.292z"/>
        <path fill="#efdf6c" fillRule="nonzero" d="M225.542 57.375s-32.084-5.125-36.25-26.583c-4.167-21.459 11.291-36.917 22.125-28.459 10.875 8.459 30.291 33.209 29.041 44.417"/>
        <path fill="#e0cb4c" fillRule="nonzero" d="M188.625 23.792s4.167-11.5 15.583-7.959c7.625 2.375 23.084 18.084 27.875 30.375 0 0-6.5 1.917-8 10.875 0 0-17.958-3-28.916-14.75-5.292-5.916-6.25-12.416-6.542-18.541Z"/>
        <path fill="#fff" fillRule="nonzero" d="M228.958 48.042c-.25.25-8.125-4.334-15.916-12.834-7.792-8.5-4.75-18.583 1.041-12.875 5.792 5.709 12.834 11.792 18 23.917 0 0-1.25-.125-3.125 1.833v-.041Z"/>
        <path fill="#efdf6c" fillRule="nonzero" d="M259.875 57.375s32.083-5.125 36.25-26.583C300.292 9.333 284.833-6.125 274 2.333c-10.875 8.459-30.292 33.209-29.042 44.417"/>
        <path fill="#e0cb4c" fillRule="nonzero" d="M253.625 25.417c7.667-8.792 32.292-17 34.458-1.292 1.667 11.958-1.041 25.125-26.75 32.958l-16.291-13.208s.666-5.333 4.208-11.208c1.875-3.292 3.292-5.959 4.375-7.25Z"/>
        <path fill="#fff" fillRule="nonzero" d="M256.458 48.042c.25.25 8.125-4.334 15.917-12.834s4.75-18.583-1.042-12.875c-5.791 5.709-12.833 11.792-18 23.917 0 0 1.25-.125 3.125 1.833v-.041Z"/>
        <path fill="#efdf6c" fillRule="nonzero" d="M223.958 59c0-8.958 8.417-16.208 18.75-16.208 10.334 0 18.75 7.25 18.75 16.208"/>
        <path fill="#e0cb4c" fillRule="nonzero" d="M233.333 59c0-4.5 4.209-8.125 9.375-8.125 5.167 0 9.375 3.625 9.375 8.125"/>
        <path fill="#f7a6a6" d="M225.542 59h3.375v49.708h-3.375zM225.542 117.208h3.375v72.542h-3.375z"/>
        <path fill="none" stroke="#b2b2b2" strokeWidth="2.08" d="M139.792 6.958v188.709"/>
        <path fill="#ef8181" fillRule="nonzero" d="M113.625 110.167 66.167 11c-2.584-5.375-10.25-5.375-12.792 0L5.917 110.167c-18.959 39.625 9.916 85.458 53.875 85.458 43.958 0 72.833-45.833 53.875-85.458h-.042Z"/>
        <path fill="#ce6a6a" fillRule="nonzero" d="m22.625 110.167 45.5-95.125L66.167 11c-2.584-5.375-10.25-5.375-12.792 0L5.917 110.167c-18.959 39.625 9.916 85.458 53.875 85.458 2.833 0 5.625-.208 8.333-.583-39.125-5.375-63.25-47.834-45.5-84.917v.042Z"/>
        <path fill="#fff" fillRule="nonzero" d="M78.792 124.708h-12.75v-12.75a6.242 6.242 0 0 0-6.25-6.25 6.241 6.241 0 0 0-6.25 6.25v12.75h-12.75a6.241 6.241 0 0 0-6.25 6.25 6.24 6.24 0 0 0 6.25 6.25h12.75v12.75a6.24 6.24 0 0 0 6.25 6.25 6.241 6.241 0 0 0 6.25-6.25v-12.75h12.75a6.241 6.241 0 0 0 6.25-6.25 6.242 6.242 0 0 0-6.25-6.25Z" />
    </SvgIcon>
  )
}

export default Dary2
