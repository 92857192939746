import {LAST_BULK_PROCESSINGS, TLastBulkProcessings,} from './gql'
import {makeStyles, Theme, useTheme} from '@material-ui/core/styles'
import {useQuery} from 'react-apollo'
import {useTranslation} from 'react-i18next'
import React, {useEffect, useState} from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import FileLink from "../../../../components/FileLink";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {EXPORT_POLL_INTERVAL} from "./BulkExportButtonDialog";

interface IStylesProps {
}

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) => {
  return {
    container: {
      marginBottom: '1rem',
      overflowY: 'auto',
      maxHeight: '260px'
    }
  }
})
interface IProps {
  autoRefresh?: boolean
}


const LastExports: React.FC<IProps> = (props, context) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const classes = useStyles({ compactScreen, isNotSmallScreen })
  const [polling, setPolling] = useState(false)

  const { data, loading, error, startPolling, stopPolling } = useQuery<TLastBulkProcessings>(
    LAST_BULK_PROCESSINGS,
    { fetchPolicy: 'no-cache' },
  )
  useEffect(() => {
    if (props.autoRefresh && !polling) {
      startPolling(EXPORT_POLL_INTERVAL)
      setPolling(true)
    }
    if (!props.autoRefresh && polling) {
      setTimeout(() => {
        stopPolling()
        setPolling(false)
      }, EXPORT_POLL_INTERVAL + 50)
    }
  }, [props.autoRefresh]);

  if (loading || !data || !data.lastBulkProcessings || !data.lastBulkProcessings.length) {
    return <></>
  }


  return (
    <div className={classes.container}>
       <h3>{t('archive.yourOldExports')}</h3>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.lastBulkProcessings.map((pr) => {
            const args: {year: number, rebateType?: 'ALL' | 'MONTHLY' | 'ANNUAL'} = JSON.parse(pr.metaArgs)
            return (
              <TableRow key={pr.id}>
                <TableCell>{pr.file && <FileLink label={t('common.download')} file={{...pr.file, size: undefined, createdAt: undefined}}/>}</TableCell>
                <TableCell>
                  {args.year || ''}
                </TableCell>
                <TableCell>
                  {args.rebateType === 'ALL' && t('common.all')}
                  {args.rebateType === 'MONTHLY' && t('common.monthlyRebates')}
                  {args.rebateType === 'ANNUAL' && t('common.annualRebates')}
                </TableCell>
                <TableCell>{pr.file && <>{Math.round((pr.file.size / (1024 * 1024)) * 100) / 100} MB</>}</TableCell>
                <TableCell>{pr.progress === 100 && pr.status === 'IN_PROGRESS' ? `100%, vytváření archivu...` : pr.progress + '%'}</TableCell>
                <TableCell>
                  {pr.status === 'SUCCESS' && <CheckCircleIcon color="primary" style={{position: 'relative', top: '1px'}} />}
                  {pr.status === 'IN_PROGRESS' && <CircularProgress size={16} style={{position: 'relative', top: '1px'}} />}
                </TableCell>
                <TableCell>{pr.createdAt && moment(pr.createdAt).format('DD.MM. YYYY HH:mm')}</TableCell>
              </TableRow>
          )})}
        </TableBody>
      </Table>
    </div>
  )
}

export default LastExports
