import {gql} from 'apollo-boost'
import {NexusGenFieldTypes} from 'kubik-server'

export type TUser = NexusGenFieldTypes['User']
export type TUnit = NexusGenFieldTypes['Unit']

export type TRebateEnding = NexusGenFieldTypes['RebateEnding']
export const GET_REBATE_ENDINGS = gql`
    query rebateEndings($month: Date!) {
        rebateEndings(month: $month) {
            user {
                id
                email
                firstname
                lastname
                personalNumber
            }
            children {
                id
                name
                type
                endingAt
            }
            student {
                updateDoctoralStudies
                endingAt
            }
            ztpp
            disability
        }
    }
`