import { TRebateName, TDocumentName, Store } from '../Store'
import { inject, observer } from 'mobx-react'
import { NexusGenEnums } from 'kubik-server'
import { useTranslation } from 'react-i18next'
import React from 'react'
import TextField from '../../../../../../components/form/TextField'

type TStatus = NexusGenEnums['SettlementRequestStatus']

interface IProps {
  status: TStatus
  settlementRequestStatus: TStatus
  commentOfDenied: string
  onChange: (value: string) => void
  onBlur: (value: string | number) => void
}

export const CommentOfDeniedView: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { status, settlementRequestStatus, commentOfDenied, onChange, onBlur } =
    props

  if (status !== 'DENIED' && status !== 'CANCELED') {
    return <React.Fragment />
  }

  return (
    <TextField
      autoFocus
      fullWidth
      multiline
      showValidationUntouched
      rows={3}
      disabled={settlementRequestStatus !== 'APPLY'}
      value={commentOfDenied}
      error={!commentOfDenied && t('common.fillTheNote')}
      onChange={(e) => onChange(String(e))}
      onBlur={onBlur}
      label={t('settlementRequest.commentOfDenied')}
      placeholder={t('settlementRequest.commentOfDenied') + '...'}
    />
  )
}

export const CommentOfDeniedReadonly: React.FC<{
  commentOfDenied: string | null | undefined
}> = (props) => {
  const { t } = useTranslation()
  return (
    <TextField
      fullWidth
      multiline
      showValidationUntouched
      rows={3}
      disabled={true}
      value={props.commentOfDenied}
      label={t('settlementRequest.commentOfDenied')}
    />
  )
}

interface ICommentOfDeniedWrapperProps {
  rebateName: TRebateName | TDocumentName
  store?: Store
  propagateDenyComment?: boolean
}
const CommentOfDeniedWrapper: React.FC<ICommentOfDeniedWrapperProps> = (
  props,
) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const store = props.store!

  const handleBlur = (value: string | number) => {
    if (props.propagateDenyComment) {
      return store.denyWithComment(value + '')
    }
  }

  const rebateData = store.getRebateData(props.rebateName)
  const status = (rebateData && rebateData.status) || 'APPLY'
  const commentOfDenied = (rebateData && rebateData.commentOfDenied) || ''
  const settlementRequestStatus = store.status

  const onChange = (commentOfDenied: string) => {
    store.updateRebateData(props.rebateName, { commentOfDenied })
  }

  return (
    <CommentOfDeniedView
      status={status}
      settlementRequestStatus={settlementRequestStatus}
      commentOfDenied={commentOfDenied}
      onChange={onChange}
      onBlur={handleBlur}
    />
  )
}

export default inject('store')(observer(CommentOfDeniedWrapper))
