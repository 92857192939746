import {
  ICustomerData,
  IUnitsData,
  IUnitUpdateData,
  NEW_CUSTOMER_UNITS,
  TUnit,
  TUpdateUnitVariables,
  UNIT_UPDATE,
  UNITS,
} from './gql'
import {superadminUnits} from '../../../../generated/superadminUnits'
import {createNewCustomer} from '../../../../generated/createNewCustomer'
import {makeStyles, Theme, useTheme} from '@material-ui/core/styles'
import {Observer} from 'mobx-react'
//import { useMutation } from 'react-apollo-hooks'
import {useMutation} from 'react-apollo'
import {useTranslation} from 'react-i18next'
import Dialog from '../../../../components/Dialog'
import DialogActions from '../../../../components/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../../../../components/DialogTitle'
import Grid from '@material-ui/core/Grid'
import NativColorPicker from '../../../../components/form/NativColorPicker'
import React from 'react'
import SubmitButton from '../../../../components/form/SubmitButton'
import TextField from '../../../../components/form/TextField'
import useForm from '../../../../hooks/useForm'
import {Box} from '@material-ui/core'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import {PridatStredisko40} from '../../../../components/icons/KubikIcons'
import useUser from '../../../../hooks/useUser'

const useStyles = makeStyles((theme: Theme) => ({
  iconContainer: {
    fontSize: '3rem',
    marginBottom: theme.spacing(2),
  },
}))

interface IUnitUpdateDialogProps {
  open: boolean
  unit: TUnit | null
  onClose: () => void
  customer:
    | ICustomerData['customer']
    | createNewCustomer['createNewCustomer']
    | undefined
    | null
}
const UnitUpdateDialog: React.FC<IUnitUpdateDialogProps> = (props) => {
  const { t } = useTranslation()
  const { onClose, open, unit } = props
  const theme = useTheme()
  const classes = useStyles()
  const { user } = useUser()

  const isNewCustomer = user.isSuperadmin()

  const [updateUnit] = useMutation<IUnitUpdateData, TUpdateUnitVariables>(
    UNIT_UPDATE,
    {
      update: (store, { data }) => {
        if (!isNewCustomer) {
          const cache = store.readQuery<IUnitsData>({ query: UNITS })

          if (data && cache && Array.isArray(cache.units)) {
            cache.units = cache.units.map((u) => {
              if (u.id === data.updateUnit.id) {
                return data.updateUnit
              }
              return u
            })
            store.writeQuery({ query: UNITS, data: cache })
          }
        } else {
          const cache = store.readQuery<superadminUnits>({
            query: NEW_CUSTOMER_UNITS,
            variables: {
              where: {
                customerId: (props.customer && props.customer.id) || '',
              },
            },
          })
          if (data && cache && Array.isArray(cache.superadminUnits)) {
            cache.superadminUnits = cache.superadminUnits.map((u) => {
              if (u.id === data.updateUnit.id) {
                return data.updateUnit
              }
              return u
            })

            store.writeQuery({
              query: NEW_CUSTOMER_UNITS,
              data: cache,
              variables: {
                where: {
                  customerId: (props.customer && props.customer.id) || '',
                },
              },
            })
          }
        }
      },
    },
  )

  const { bind, form } = useForm<TUpdateUnitVariables['data']>(
    unit || { name: '', color: '#3388FF' },
    {
      name: {
        label: t('common.unitName'),
        help: t('common.unitNameHelp'),
        placeholder: t('common.unitName') + '...',
        rule: 'required',
      },
      color: {
        label: t('common.change'),
      },
    },
    {
      variables: { where: { id: unit && unit.id } },
      async onSubmit(formData, form) {
        try {
          const { data } = await updateUnit({
            variables: { data: formData, ...form.options.variables },
          })
          if (data) {
            onClose()
          }
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" scroll="body">
      <DialogTitle form={form} onClose={onClose}>
        <Box
          display="flex"
          justifyContent="center"
          className={classes.iconContainer}
        >
          <Step completed={false}>
            <StepLabel icon={<PridatStredisko40 fontSize="inherit" />}>
              {' '}
            </StepLabel>
          </Step>
        </Box>
        <Box display="flex" justifyContent="center">
          {t('customerOptions.updateUnit')}
        </Box>
      </DialogTitle>

      <DialogContent>
        <Observer>
          {() => (
            <Grid container spacing={1}>
              <Grid xs={12} item>
                <TextField
                  fullWidth
                  gutterBottom
                  inputProps={{ maxLength: 20 }}
                  {...bind('name')}
                />
              </Grid>
              <Grid
                xs={12}
                item
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  fontFamily="fontFamily"
                  borderRadius={6}
                  px={2}
                  py={1}
                  bgcolor={form.getValue('color')}
                  color={theme.palette.getContrastText(form.getValue('color'))}
                >
                  {form.getValue('name') || t('common.unit')}
                </Box>
                <NativColorPicker {...bind('color')} />
              </Grid>
            </Grid>
          )}
        </Observer>
      </DialogContent>

      <DialogActions dividerProps={{ mt: 1 }}>
        <SubmitButton form={form} label={t('customerOptions.saveChanges')} />
      </DialogActions>
    </Dialog>
  )
}

export default UnitUpdateDialog
