import {
  TEmployee as TUser,
  TUserInviteData as TEmployeeInviteData,
  TUserInviteVariables as TEmployeeInviteVariables,
  USER_INVITE as EMPLOYEE_INVITE,
} from './gql'
import {Center, IconCircle} from '../../../../components/CommonBox'
import {Observer} from 'mobx-react'
import {useMutation} from 'react-apollo'
import {useTranslation} from 'react-i18next'
import {Zpravy} from '../../../../components/icons/KubikIcons'
import Dialog from '../../../../components/Dialog'
import DialogActions from '../../../../components/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../../../../components/DialogTitle'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import SubmitButton from '../../../../components/form/SubmitButton'
import Typography from '@material-ui/core/Typography'
import useForm from '../../../../hooks/useForm'
import useSnackbar from '../../../../hooks/useSnackbar'

interface IProps {
  open: boolean
  employee: TUser | null
  onClose: () => void
}

const EmployeeInvite: React.FC<IProps> = props => {
  const { t } = useTranslation()
  const { addMessage } = useSnackbar()
  const { open, onClose, employee } = props
  const { id } = employee || { id: '' }

  const [reinviteEmployee] = useMutation<
    TEmployeeInviteData,
    TEmployeeInviteVariables
  >(EMPLOYEE_INVITE, {
    refetchQueries: ['employees'],
    onCompleted({ inviteUser: { firstname, lastname } }) {
      addMessage(
        t('employee.reinvite', {
          context: 'SUCCESS',
          name: `${firstname} ${lastname}`,
        }),
      )
    },
  })

  const { form } = useForm<TUser>(
    { id },
    {},
    {
      variables: {
        where: { id },
      },
      async onSubmit(_, form) {
        try {
          const { data } = await reinviteEmployee({
            variables: {
              ...form.options.variables,
            },
          })
          if (data) {
            onClose()
          }
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" scroll="body">
      <DialogTitle form={form} onClose={onClose}>
        <Center mb={2}>
          <IconCircle fontSize="3rem">
            <Zpravy fontSize="inherit" color="primary" />
          </IconCircle>
        </Center>
        {t('employee.reinvite')}
      </DialogTitle>

      <DialogContent>
        <Observer>
          {() => (
            <Grid container direction="column" spacing={1}>
              <Grid sm item>
                <Typography align="center">
                  „{employee && `${employee.firstname} ${employee.lastname}`}“
                  <br />
                  {employee && employee.email}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Observer>
      </DialogContent>

      <DialogActions dividerProps={{ mt: 2 }}>
        <SubmitButton form={form} label={t('employee.reinviteButton')} />
      </DialogActions>
    </Dialog>
  )
}

export default EmployeeInvite
