import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  'bleel-1': { fill: '#ffccb8' },
  'bleel-2': { fill: '#eaa992' },
  'bleel-3': { fill: '#e6e7e8' },
  'bleel-4': { fill: '#d6d6d6' },
  'bleel-5': { fill: '#53a1e0' },
  'bleel-6': { fill: '#ff92c6' },
  'bleel-7': { fill: '#f2bba5' },
  'bleel-8': { fill: '#4fff30' },
  'bleel-9': { fill: '#fff' },
  'bleel-10': { fill: '#efdf6c' },
  'bleel-11': { fill: '#e0cb4c' },
  'bleel-12': { fill: '#86c2ea' },

  /* viz https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/SvgIcon/SvgIcon.js */
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(60),
  },
  fontSizeDefault: {
    fontSize: theme.typography.pxToRem(75),
  },
  fontSizeLarge: {
    fontSize: theme.typography.pxToRem(115),
  },
}))

const Investice: React.FC<Props> = ({
  viewBox = '0 0 61.36 75.64',
  fontSize,
  ...props
}: Props) => {
  const classes = useStyles()
  return (
    <SvgIcon
      viewBox={viewBox}
      className={classnames({
        [classes.fontSizeDefault]: fontSize === 'default' || !fontSize,
        [classes.fontSizeSmall]: fontSize === 'small',
        [classes.fontSizeLarge]: fontSize === 'large',
      })}
      fontSize={fontSize}
      {...props}
    >
      <>
        <title>Investice</title>
        <defs>
          <style>{".instyl-1{fill:#ef9616;}.instyl-2{fill:#ccc;}.instyl-3{fill:#f08261;}.instyl-4{fill:#f9be53;}.instyl-5{fill:#d0544a;}.instyl-6,.instyl-7{fill:#95c11f;}.instyl-7{opacity:.15;}.instyl-8{fill:#e6e7e8;}.instyl-9{fill:#9d9d9c;}.instyl-10{fill:#afafaf;}.instyl-11{fill:#a03d3c;}"}</style>
        </defs>
        <g id="Nové_ikony">
          <path className="instyl-7"
                d="M2.37,75.64h0c-1.31,0-2.37-1.06-2.37-2.37V18.35h4.75v54.92c0,1.31-1.06,2.37-2.37,2.37Z"/>
          <path className="instyl-7"
                d="M9.44,13.4h4.75v59.86c0,1.31-1.06,2.37-2.37,2.37h0c-1.31,0-2.37-1.06-2.37-2.37V13.4h0Z"/>
            <path className="instyl-7"
                  d="M18.87,16.12h4.75v57.15c0,1.31-1.06,2.37-2.37,2.37h0c-1.31,0-2.37-1.06-2.37-2.37V16.12h0Z"/>
            <path className="instyl-7"
                  d="M28.31,7.66h4.75v65.6c0,1.31-1.06,2.37-2.37,2.37h0c-1.31,0-2.37-1.06-2.37-2.37V7.66h0Z"/>
            <path className="instyl-7"
                  d="M37.74,11.4h4.75v61.87c0,1.31-1.06,2.37-2.37,2.37h0c-1.31,0-2.37-1.06-2.37-2.37V11.4h0Z"/>
            <path className="instyl-7"
                  d="M47.18,9.79h4.75v63.47c0,1.31-1.06,2.37-2.37,2.37h0c-1.31,0-2.37-1.06-2.37-2.37V9.79h0Z"/>
            <path className="instyl-7"
                  d="M56.61,1.09h4.75v72.18c0,1.31-1.06,2.37-2.37,2.37h0c-1.31,0-2.37-1.06-2.37-2.37V1.09h0Z"/>
            <path className="instyl-6"
                  d="M52.05,5.06c.39-.33.42-.57.06-.92-.35-.35-.64-.76-.98-1.13-.55-.61-.76-1.3-.41-2.06C51.06.21,51.72,0,52.47,0c1.95,0,3.89.01,5.84.02.5,0,1,0,1.49,0,1.09.06,1.69.71,1.53,1.79-.36,2.46-.76,4.92-1.15,7.38-.12.72-.5,1.28-1.24,1.47-.72.18-1.34-.05-1.82-.63-.35-.42-.73-.81-1.05-1.25-.32-.45-.57-.42-.97-.07l-8.14,6.5c-2.47,2.08-5.25,1.84-7.36-.62-1.46-1.7-2.91-3.41-4.37-5.11-.79-.93-1.36-.97-2.3-.17-6.15,5.25-5,3.98-11.15,9.23-1.96,1.67-4.51,1.75-6.33.16-.58-.5-1.05-1.13-1.55-1.71-.54-.61-1.05-1.25-1.6-1.85-.69-.75-1.28-.79-2.06-.13-1.99,1.68-4.17,3.57-6.15,5.26-.77.65-1.62.9-2.58.5-1.62-.66-2.04-2.75-.73-3.91,2.39-2.11,5-4.41,7.47-6.44,2.27-1.85,5.01-1.53,6.94.7.9,1.05,1.8,2.1,2.69,3.15q.63.74,1.4.09c6.22-5.31,5.14-4.1,11.35-9.41.9-.77,1.86-1.36,3.07-1.48,1.71-.16,3.11.46,4.22,1.74,1.54,1.78,3.06,3.58,4.59,5.37.77.89,1.34.95,2.25.18l7.3-5.7Z"/>
            <path className="instyl-10"
                  d="M20.89,42.81s0-.04,0-.06c0-.02,0-.04,0-.06s0-.04,0-.06c0-.02,0-.04-.01-.06,0-.02,0-.04-.01-.06,0-.02-.01-.04-.02-.06s-.01-.04-.02-.06c0-.02-.01-.04-.02-.06,0-.02-.02-.04-.02-.06,0-.02-.02-.04-.03-.06s-.02-.04-.03-.05c-.01-.02-.02-.04-.03-.05s-.02-.03-.03-.05c-.01-.02-.02-.03-.04-.05-.2-.26-.5-.43-.8-.45-.26-.01-.56.07-.77.26-.1.08-.18.19-.27.28-.33.27-.71.34-1.11.27-.44-.08-.63-.32-1-.54-.21-.13-.44-.2-.68-.22-.38-.04-.77.01-1.11.21-.14.08-.27.19-.4.28-.03.02-.06.04-.1.06-.03.02-.07.04-.1.05-.03.02-.07.03-.1.05s-.07.03-.11.04c-.04.01-.07.02-.11.03-.04,0-.07.01-.11.02s-.07,0-.11,0c-.04,0-.07,0-.11,0-.27-.02-.52-.1-.75-.26-.14-.09-.25-.22-.38-.32-.24-.18-.6-.27-.88-.18-.03,0-.06.02-.08.03-.03.01-.05.02-.08.04-.03.01-.05.03-.08.05-.02.02-.05.04-.07.05-.29.24-.47.65-.52,1.05-.04.34,0,.62.19.9.1.15.21.29.32.43l.58.77c.42.56.83,1.15,1.27,1.69.57-.11,1.16-.08,1.74-.08h2.26c.17,0,.34,0,.51,0,.26,0,.51.03.77.07.07-.1.14-.19.22-.28l1.39-1.81c.16-.21.32-.41.47-.62.1-.13.21-.26.29-.41.08-.15.12-.33.13-.51,0-.02,0-.04,0-.06,0-.02,0-.04,0-.06Z"/>
            <path className="instyl-10"
                  d="M23.77,55.4c-.13-.34-.26-.68-.41-1.01-.22-.47-.46-.92-.72-1.37-.13-.17-.24-.39-.35-.57-.31-.49-.73-1.14-1.1-1.54-.08-.14-.26-.31-.37-.43-.5-.58-1.03-1.12-1.53-1.7-.21-.23-.41-.47-.6-.72.18-.12.2-.27.26-.48-.02-.06,0-.36-.01-.46-.02-.07-.03-.14-.06-.21-.1-.2-.28-.29-.46-.35-.26-.04-.51-.07-.77-.07-.17,0-.34,0-.51,0h-2.26c-.57,0-1.17-.02-1.74.09-.19.09-.36.19-.44.42-.02.04-.03.09-.04.13-.02.25-.05.54.1.75.06.09.14.14.23.2-.22.22-.43.46-.64.68-.48.49-.95,1-1.41,1.53-.1.12-.24.25-.31.38-.59.66-1.11,1.44-1.59,2.2-1.16,1.97-2.33,4.96-1.92,7.39.24,1.44,1.29,3.09,2.33,3.9.16.12.34.23.49.36.08.01.56.29.67.36.55.34,2.07.71,9.82.18.44-.06.86-.39,1.27-.58h.01c.11-.1.24-.18.35-.26,1.19-.9,2.16-2.28,2.45-3.91.29-1.66-.15-3.41-.72-4.92Z"/>
            <path className="instyl-10"
                  d="M23.57,47.03c-.06-.1-.18-.15-.28-.16-.16,0-.59.24-.78.31-.91.3-1.66.23-2.58.08-.35-.12-.7-.2-1.05-.33.51-.06,1.13-.14,1.61-.37.53-.16,1.04-.56,1.45-.95.34-.35,1.08-1.29,1.1-1.81,0-.1-.03-.19-.09-.27-.06-.08-.16-.13-.25-.13-.08,0-.19.03-.24.1-.1.12-.16.31-.23.46-.09.2-.2.38-.32.56-.46.67-1.17,1.15-1.86,1.42-.18.07-.44.11-.6.19-.28.05-.57.08-.85.15-.07.09-.15.19-.22.28-.26-.04-.51-.07-.77-.07-.17,0-.34,0-.51,0h-2.26c-.57,0-1.17-.02-1.74.09-.19.09-.36.19-.44.42-.02.04-.03.09-.04.13-.02.25-.05.54.1.75.06.09.14.14.23.2.51.19,1.06.26,1.59.32.26.03.53.04.8.05.3.02.61.04.91,0,.25-.01.5-.03.75-.05.55-.07,1.14-.14,1.67-.35.18-.12.2-.27.26-.48.39.18.83.4,1.25.45.02,0,.03.01.05.02.21.05.43.08.64.09.75.06,1.47-.02,2.17-.33.13-.06.28-.11.4-.2.09-.07.17-.16.19-.28.02-.1,0-.22-.06-.3Z"/>
            <path className="instyl-10"
                  d="M38.14,41.04s0-.05,0-.08c0-.03,0-.05,0-.08s0-.05.01-.08c0-.03,0-.05.01-.08,0-.03.01-.05.02-.07,0-.02.01-.05.02-.07s.01-.05.02-.07c0-.02.02-.05.03-.07,0-.02.02-.05.03-.07,0-.02.02-.05.03-.07s.02-.04.03-.07c.01-.02.02-.04.04-.06s.03-.04.04-.06c.01-.02.03-.04.04-.06.23-.31.57-.52.91-.54.3-.02.64.08.88.31.11.1.2.23.31.34.38.33.81.41,1.26.33.51-.1.72-.38,1.14-.65.24-.15.51-.24.78-.27.43-.05.88.01,1.27.25.16.1.31.23.46.34.04.03.07.05.11.08.04.02.08.05.12.07.04.02.08.04.12.06s.08.03.12.04c.04.01.08.02.13.03.04,0,.08.02.13.02s.09,0,.13.01c.04,0,.09,0,.13,0,.31-.02.59-.12.86-.31.16-.11.29-.26.44-.39.28-.22.68-.32,1.01-.22.03.01.06.02.09.04.03.01.06.03.09.05.03.02.06.04.09.06.03.02.06.04.08.07.33.29.54.78.6,1.26.05.41-.01.75-.22,1.08-.11.18-.24.35-.36.52l-.66.93c-.48.68-.95,1.38-1.45,2.04-.65-.13-1.33-.1-1.99-.1h-2.58c-.2,0-.39,0-.59,0-.3,0-.59.04-.88.09-.08-.12-.16-.23-.25-.34l-1.59-2.18c-.18-.25-.36-.5-.54-.75-.11-.16-.24-.31-.33-.49-.09-.18-.14-.4-.15-.61,0-.03,0-.05,0-.08,0-.03,0-.05,0-.08Z"/>
            <path className="instyl-10"
                  d="M34.85,56.22c.15-.41.3-.82.47-1.22.25-.57.53-1.11.82-1.65.15-.2.27-.47.4-.69.35-.59.84-1.37,1.26-1.86.09-.16.3-.37.42-.52.58-.69,1.18-1.35,1.75-2.05.24-.28.47-.57.69-.86-.2-.15-.23-.32-.3-.57.03-.08,0-.43.02-.56.02-.09.03-.17.07-.25.12-.24.32-.35.53-.42.3-.05.58-.08.88-.09.2,0,.39,0,.59,0h2.58c.66,0,1.34-.02,1.99.11.22.11.41.23.51.51.02.05.03.1.04.16.02.3.05.65-.12.91-.07.11-.16.17-.26.24.25.27.49.55.73.82.55.59,1.09,1.21,1.61,1.85.11.14.27.3.36.46.67.79,1.27,1.74,1.82,2.65,1.32,2.38,2.67,5.98,2.2,8.92-.28,1.73-1.47,3.73-2.67,4.71-.18.15-.39.28-.56.43-.1.02-.64.35-.76.44-.63.41-2.37.86-11.23.22-.51-.07-.99-.47-1.45-.7h-.02c-.13-.12-.27-.21-.41-.32-1.36-1.08-2.47-2.75-2.8-4.72-.34-2,.17-4.11.83-5.94Z"/>
            <path className="instyl-10"
                  d="M35.07,46.13c.07-.12.2-.18.32-.19.18,0,.67.29.89.37,1.04.36,1.9.27,2.95.09.4-.14.8-.24,1.2-.4-.58-.07-1.3-.17-1.84-.45-.6-.19-1.19-.67-1.66-1.15-.39-.43-1.24-1.56-1.26-2.19,0-.12.03-.23.1-.32.07-.09.18-.15.29-.16.09,0,.21.04.28.13.11.15.18.38.26.55.11.24.23.46.37.67.53.81,1.33,1.39,2.13,1.72.21.08.5.14.68.23.32.06.65.1.97.18.09.11.17.23.25.34.3-.05.58-.08.88-.09.2,0,.39,0,.59,0h2.58c.66,0,1.34-.02,1.99.11.22.11.41.23.51.51.02.05.03.1.04.16.02.3.05.65-.12.91-.07.11-.16.17-.26.24-.58.23-1.21.31-1.82.39-.3.03-.61.05-.91.07-.34.02-.7.05-1.04,0-.29-.02-.57-.04-.86-.06-.63-.09-1.3-.17-1.91-.42-.2-.15-.23-.32-.3-.57-.44.22-.95.48-1.44.55-.02,0-.03.01-.05.02-.24.07-.49.09-.73.11-.86.07-1.68-.02-2.49-.39-.15-.07-.32-.13-.45-.24-.1-.08-.19-.19-.21-.34-.02-.12,0-.26.07-.37Z"/>
            <path className="instyl-5"
                  d="M39.56,35.21c.38-.17,1.01-.26,1.45-.4,1.69-.57,3.4-1.59,4.51-3,.29-.37.55-.76.78-1.18.17-.31.31-.7.55-.97.14-.15.39-.22.59-.22.23,0,.46.11.61.27.15.15.22.35.21.56-.04,1.1-1.84,3.07-2.67,3.82-1,.82-2.25,1.67-3.52,2l-.04-.03c-.76.22-1.55.47-2.33.58-.09.01-.21.03-.31,0-.35-.09-1.11-.47-1.39-.69-.1-.08-.18-.15-.19-.28.2-.37,1.42-.2,1.72-.44,0,0,.01-.02.02-.02Z"/>
            <path className="instyl-11"
                  d="M37.5,35.53c.67-.14,1.38-.2,2.06-.32,0,0-.01.02-.02.02-.3.24-1.52.08-1.72.44.01.13.09.2.19.28.28.22,1.04.6,1.39.69.09.02.21,0,.31,0,.78-.11,1.58-.37,2.33-.58l.04.03c-1.15.48-2.66.66-3.9.78.84.27,1.7.45,2.55.7l-.05.03c-.54-.08-1.83-.55-2.19-.54-.08,0-.14-.02-.21-.04h-.03c.03.28.13.52.09.79-.02.13-.04.26,0,.38.67.36,1.52.64,2.26.82.25.06.51.09.76.16l-.03.03c-1.02-.11-2.1-.57-3.04-.95-.06-.14,0-.75-.03-.97-.04-.15-.07-.3-.15-.44-.25-.42-.67-.61-1.12-.73.17-.2.34-.4.52-.6Z"/>
            <path className="instyl-5"
                  d="M40.73,37.58c2.22.32,4.05.47,6.25-.16.46-.13,1.5-.66,1.88-.64.24,0,.53.13.67.33.13.18.19.42.15.64-.05.26-.23.46-.45.6-.29.18-.64.3-.96.42-1.71.64-3.46.81-5.27.69-.51-.04-1.05-.08-1.55-.19-.04,0-.08-.02-.11-.03l.03-.03c-.25-.07-.51-.1-.76-.16-.74-.19-1.59-.47-2.26-.82-.04-.12-.02-.26,0-.38.03-.27-.07-.51-.09-.78h.03c.07,0,.14.03.21.03.36-.01,1.65.45,2.19.54l.05-.03Z"/>
            <path className="instyl-2"
                  d="M41.86,75.08c1.08-.13,2.09-.82,3.07-1.23h.04c.27-.21.58-.37.86-.55,2.88-1.89,5.24-4.81,5.93-8.23.71-3.49-.36-7.17-1.76-10.36-.31-.72-.63-1.43-1-2.12-.53-1-1.13-1.94-1.75-2.88-.31-.35-.57-.82-.85-1.2-.74-1.02-1.77-2.4-2.67-3.24.23.51.89,1.39,1.22,1.9.8,1.26,1.57,2.57,2.2,3.93,1.52,3.31,2.54,6.74,1.21,10.32-1.53,4.12-5.22,7.02-9.1,8.8-5.53,2.53-12.54,3.05-18.28.92-3.24-1.21-6.13-3.5-7.58-6.69-1.82-4-1.27-8.25.35-12.22.26-.65.55-1.29.86-1.91.31-.63.65-1.24,1.02-1.84.44-.7.89-1.4,1.36-2.09.23-.34,1-1.26,1.09-1.56,0-.02.01-.04.02-.06-1.42,1.38-2.68,3.03-3.85,4.63-2.81,4.15-5.65,10.45-4.66,15.56.59,3.02,3.12,6.5,5.66,8.21.39.26.82.48,1.19.76.2.03,1.35.62,1.62.76,1.33.72,5.03,1.49,23.81.39Z"/>
            <path className="instyl-2"
                  d="M37.87,26.68c.22-.19.42-.41.65-.59.51-.39,1.24-.57,1.87-.54.73.04,1.45.4,1.94.94.03.03.06.07.09.1.03.04.06.07.08.11.03.04.05.07.08.11.02.04.05.08.07.11.02.04.04.08.07.12.02.04.04.08.06.12.02.04.04.08.05.12.02.04.03.08.05.13.01.04.03.09.04.13.01.04.02.09.04.13.01.04.02.09.03.13,0,.04.02.09.02.13,0,.04.01.09.02.13,0,.04,0,.09,0,.13,0,.04,0,.09,0,.14,0,.05,0,.09,0,.13-.02.37-.12.75-.32,1.06-.19.31-.46.59-.69.86-.38.44-.76.88-1.15,1.31l-3.36,3.81c-.18.19-.35.39-.52.6-.63-.09-1.24-.14-1.87-.15-.42,0-.83,0-1.25,0h-5.48c-1.39.01-2.84-.04-4.21.18-1.05-1.16-2.05-2.38-3.07-3.57l-1.4-1.62c-.26-.3-.54-.59-.77-.91-.43-.58-.56-1.18-.46-1.89.12-.84.56-1.7,1.26-2.21.06-.04.11-.08.17-.12s.12-.07.18-.1c.06-.03.13-.06.19-.08.07-.02.13-.04.2-.06.69-.18,1.55,0,2.14.38.32.21.6.48.93.67.56.33,1.17.51,1.82.54.09,0,.18,0,.27,0,.09,0,.18,0,.27-.02.09,0,.18-.02.27-.04.09-.02.18-.04.27-.06s.17-.05.26-.08c.09-.03.17-.06.25-.1s.17-.07.25-.12c.08-.04.16-.09.24-.13.33-.2.64-.43.98-.6.83-.41,1.77-.53,2.69-.44.58.06,1.14.2,1.66.47.9.47,1.35.97,2.42,1.14.96.15,1.89,0,2.68-.57Z"/>
            <path className="instyl-8"
                  d="M22.37,28.98c.34.16.65.37,1,.52,1.12.49,2.41.62,3.57.22,1.68-.57,1.83-1.8,3.87-1.79.57,0,1.13.1,1.65.35.54.26.87.7,1.32,1.06,1.24.99,3.07,1.13,4.54.62.29-.1.58-.23.85-.38.5-.28,1.49-1,2.1-.77.03.01.04.02.06.04-.08.26-4.31,5.04-4.85,5.63-.24.27-1.25,1.3-1.36,1.49-.42,0-.83,0-1.25,0-1.02-.14-2.07-.29-3.06-.55-.09-.03-.19-.05-.28-.08-.09-.03-.19-.06-.28-.08-.09-.03-.19-.06-.28-.09-.09-.03-.18-.06-.28-.1-.09-.03-.18-.07-.27-.1-.09-.03-.18-.07-.27-.11-.09-.04-.18-.07-.27-.11-.09-.04-.18-.08-.27-.12s-.18-.08-.26-.12c-.09-.04-.18-.09-.26-.13-.09-.04-.17-.09-.26-.13-.09-.05-.17-.09-.26-.14-.08-.05-.17-.1-.25-.14-.08-.05-.17-.1-.25-.15-.08-.05-.17-.1-.25-.16-.08-.05-.16-.11-.24-.16-.08-.05-.16-.11-.24-.17-.08-.06-.16-.11-.24-.17-.08-.06-.16-.12-.23-.17-.08-.06-.15-.12-.23-.18-.08-.06-.15-.12-.23-.18-.07-.06-.15-.13-.22-.19-.07-.06-.15-.13-.22-.19-.07-.07-.14-.13-.21-.2-.07-.07-.14-.13-.21-.2-.07-.07-.14-.14-.21-.21-.07-.07-.14-.14-.2-.21-.07-.07-.13-.14-.2-.22-.07-.07-.13-.15-.19-.22-.06-.07-.13-.15-.19-.22-.06-.08-.12-.15-.18-.23-.06-.08-.12-.15-.18-.23-.31-.41-.61-.86-.82-1.32Z"/>
            <path className="instyl-8"
                  d="M33.86,35.97c.42,0,.83.01,1.25,0,.64.01,1.24.06,1.87.15.45.12.87.31,1.12.73.08.13.11.29.15.44.03.22-.02.83.03.97-.14.44-.2.74-.63,1,.48.51.97,1.01,1.46,1.51,1.22,1.22,2.49,2.37,3.72,3.58.26.25.71.62.89.91.23.51.89,1.39,1.22,1.9.8,1.26,1.57,2.57,2.2,3.93,1.52,3.31,2.54,6.74,1.21,10.32-1.53,4.12-5.22,7.02-9.1,8.8-5.53,2.53-12.54,3.05-18.28.92-3.24-1.21-6.13-3.5-7.58-6.69-1.82-4-1.27-8.25.35-12.22.26-.65.55-1.29.86-1.91.31-.63.65-1.24,1.02-1.84.44-.7.89-1.4,1.36-2.09.23-.34,1-1.26,1.09-1.56,0-.02.01-.04.02-.06.18-.28.51-.56.75-.8,1.1-1.11,2.24-2.19,3.42-3.22.52-.47,1.03-.97,1.56-1.43-.21-.11-.4-.23-.56-.41-.36-.44-.3-1.05-.25-1.58.02-.1.05-.19.09-.28.21-.49.61-.7,1.07-.89,1.38-.22,2.82-.17,4.21-.17h5.48Z"/>
            <path className="instyl-2"
                  d="M23.81,39.31c1.23.4,2.57.54,3.85.68-.34.52-.68,1.01-.98,1.54l-.03.02c-.94-.04-3.58-.41-4.39-.81.52-.47,1.03-.97,1.56-1.43Z"/>
            <path className="instyl-2"
                  d="M33.61,40c1.34-.15,2.76-.29,4.05-.73.48.51.97,1.01,1.46,1.51-.79.34-3.56.7-4.5.77h-.03c-.29-.55-.67-1.03-.99-1.55Z"/>
            <path className="instyl-2"
                  d="M34.59,41.53h.03c.97,2.03,1.2,3.71,1.09,5.91-.54-.06-1.08-.11-1.62-.15.04-2.37-.04-3.45-1.2-5.63v-.02c.57-.07,1.15-.08,1.71-.12Z"/>
            <path className="instyl-2"
                  d="M26.68,41.53c.58.08,1.18.09,1.76.14-.19.29-.35.63-.5.95-.72,1.57-.69,3-.68,4.67-.54.08-1.1.11-1.64.17-.05-2.43.04-3.66,1.03-5.92l.03-.02Z"/>
            <path className="instyl-2"
                  d="M27.67,39.99c.64.05,1.29.09,1.93.11.72.03,1.48.08,2.2,0,.61-.03,1.21-.07,1.82-.11.31.52.7,1,.99,1.54-.57.04-1.14.05-1.71.12v.02c-1.48.06-2.97.13-4.44,0-.58-.05-1.18-.06-1.76-.14.31-.54.65-1.03.98-1.54Z"/>
            <path className="instyl-9"
                  d="M31.79,40.11c.61-.03,1.21-.07,1.82-.11.31.52.7,1,.99,1.54-.57.04-1.14.05-1.71.12-.15-.13-.87-1.26-1.1-1.55Z"/>
            <path className="instyl-9"
                  d="M27.67,39.99c.64.05,1.29.09,1.93.11-.42.43-.89,1.03-1.15,1.57-.58-.05-1.18-.06-1.76-.14.31-.54.65-1.03.98-1.54Z"/>
            <path className="instyl-3"
                  d="M33.86,35.97c.42,0,.83.01,1.25,0,.64.01,1.24.06,1.87.15.45.12.87.31,1.12.73.08.13.11.29.15.44.03.22-.02.83.03.97-.14.44-.2.74-.63,1-1.28.44-2.71.58-4.05.73-.6.04-1.21.08-1.82.11-.71.08-1.48.03-2.2,0-.64-.02-1.29-.06-1.93-.11-1.28-.14-2.62-.28-3.85-.68-.21-.11-.4-.23-.56-.41-.36-.44-.3-1.05-.25-1.58.02-.1.05-.19.09-.28.21-.49.61-.7,1.07-.89,1.38-.22,2.82-.17,4.21-.17h5.48Z"/>
            <path className="instyl-5"
                  d="M38.25,37.3c.03.22-.02.83.03.97-.14.44-.2.74-.63,1-1.28.44-2.71.58-4.05.73-.6.04-1.21.08-1.82.11-.71.08-1.48.03-2.2,0-.64-.02-1.29-.06-1.93-.11-1.28-.14-2.62-.28-3.85-.68-.21-.11-.4-.23-.56-.41-.36-.44-.3-1.05-.25-1.58.14.22.28.39.51.54.55.35,1.25.46,1.88.58,2.85.54,5.96.55,8.83.27.77-.08,1.56-.16,2.32-.34.73-.17,1.29-.41,1.69-1.07Z"/>
            <path className="instyl-4"
                  d="M41.31,58.71c0-.14-.01-.28-.03-.43s-.03-.28-.04-.42-.04-.28-.06-.42-.05-.28-.08-.42-.06-.28-.1-.42c-.03-.14-.07-.27-.11-.41-.79-2.62-2.72-4.76-5.11-6.03-2.51-1.34-5.63-1.66-8.35-.83-2.74.84-4.96,2.7-6.3,5.23-.04.07-.08.15-.11.22-.04.07-.07.15-.11.23-.03.08-.07.15-.1.23-.03.08-.07.15-.1.23s-.06.16-.09.23c-.03.08-.06.16-.09.24-.03.08-.05.16-.08.24-.03.08-.05.16-.07.24s-.05.16-.07.24c-.02.08-.04.16-.06.24-.02.08-.04.16-.06.24-.02.08-.03.16-.05.25-.02.08-.03.16-.04.25-.01.08-.03.17-.04.25s-.02.17-.03.25-.02.17-.03.25c0,.08-.01.17-.02.25,0,.08-.01.17-.01.25,0,.08,0,.17,0,.25,0,.08,0,.17,0,.25s0,.17,0,.25c0,.08,0,.17.01.25,0,.08,0,.17.02.25,0,.08.01.17.02.25,0,.08.02.17.03.25.01.08.02.17.03.25.01.08.03.17.04.25.01.08.03.16.05.25.02.08.03.16.05.25s.04.16.06.24.04.16.06.24c.02.08.05.16.07.24.8,2.62,2.77,4.9,5.18,6.18,2.51,1.33,5.43,1.68,8.16.84,2.67-.82,5.02-2.69,6.32-5.16.11-.21.22-.43.31-.65s.18-.45.25-.68c.05-.13.1-.27.15-.4.05-.13.09-.27.13-.41.04-.14.08-.27.11-.41.03-.14.07-.28.1-.42.03-.14.05-.28.08-.42.02-.14.04-.28.06-.42s.03-.28.04-.42c.01-.14.02-.28.03-.43,0-.14,0-.28,0-.43,0-.14,0-.28,0-.43Z"/>
            <path className="instyl-1"
                  d="M26.34,60.81l-.89.95v2.11h-2.36v-8.44h2.36v3.51s3.27-3.51,3.27-3.51h2.63s-3.45,3.74-3.45,3.74l3.63,4.7h-2.77s-2.41-3.06-2.41-3.06Z"/>
            <path className="instyl-1"
                  d="M33.19,63.54c-.57-.29-1.01-.69-1.33-1.21-.32-.51-.48-1.1-.48-1.75s.16-1.23.48-1.74.76-.91,1.33-1.19c.57-.29,1.22-.43,1.94-.43s1.38.16,1.92.48c.54.32.92.76,1.14,1.32l-1.77.9c-.3-.59-.73-.88-1.3-.88-.41,0-.75.14-1.02.41-.27.27-.4.65-.4,1.13s.13.87.4,1.15c.27.28.61.42,1.02.42.57,0,1-.29,1.3-.88l1.77.9c-.22.56-.61,1-1.14,1.32-.54.32-1.18.48-1.92.48s-1.37-.15-1.94-.43ZM37.44,54.72l-1.57,1.83h-1.98s-1.57-1.83-1.57-1.83h1.52s1.04.92,1.04.92l1.04-.92h1.52Z"/>
          </g>

        </>
    </SvgIcon>
)
}

export default Investice
