import { Divider } from '../../components/CommonBox'
import { Observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import PrevPeriods, { IDateLimiters } from './PrevPeriods'
import React from 'react'
import Uploader from '../../components/form/Uploader'
import { DisabilityRebateConditions } from "./DisabilityRebateConditions";
import Expand from '../../components/Expand'
import moment from "moment"

const DisabilityWithPeriodsFormSection: React.FC<{
  bind: (fieldPath: string) => any
  dateLimiters: IDateLimiters
  excludeIntervals?: { from: moment.Moment, to: moment.Moment }[]
}> = (props) => {
  const { t } = useTranslation()
  const { bind, dateLimiters, excludeIntervals = [] } = props

  return (
    <>
      <Box maxWidth="100%" width={680}>
        <DisabilityRebateConditions />
      </Box>

      <Observer>
        {() => (
          <PrevPeriods
            {...bind('prevYearPeriods')}
            label={t('monthlyDisabilityRebate.validityPeriodLabel')}
            dateLimiters={dateLimiters}
            excludeIntervals={excludeIntervals}
          />
        )}
      </Observer>

      <Divider maxWidth="100%" width={680} my={4} />

      <Box maxWidth="100%" width={680}>
        <Observer>
          {() => <Uploader {...bind('disabilityConfirmationFiles')} showLabel multiple />}
        </Observer>

        <Box mt={4}>
          <Observer>
            {() => <Uploader {...bind('disabilityOtherFiles')} showLabel multiple />}
          </Observer>
        </Box>
      </Box>
    </>
  )
}

export default DisabilityWithPeriodsFormSection
