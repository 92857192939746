import Props from '../../../../icons/iconsType'
import React, { forwardRef } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Rozpracovano = forwardRef<SVGSVGElement, Props>(({
  viewBox = '0 0 15.38 18',
  fill = 'orange',
  ...props
}, ref) => (
  <SvgIcon viewBox={viewBox} {...props} ref={ref}>
    <>
      <g id="Vrstva_2" data-name="Vrstva 2">
        <g id="Vrstva_1-2" data-name="Vrstva 1">
          <path
            fill={fill}
            d="M14.51,1.32,10.84.07A1.22,1.22,0,0,0,10.43,0,1.31,1.31,0,0,0,9.2.87L8.49,3h0l-.24.71h0L5.17,12.74a1.32,1.32,0,0,0,0,.71L6,17H.53A.53.53,0,1,0,.53,18H7.38A1.33,1.33,0,0,0,8,17.71l2.83-2.31a1.24,1.24,0,0,0,.4-.58L15.31,3A1.28,1.28,0,0,0,14.51,1.32Zm-3.95.24,3.26,1.11-.64,1.9L9.91,3.46ZM10.29,13a5.19,5.19,0,0,0-1.19-.67l2.56-7.52,1.27.43ZM11,4.61,8.39,12.13A5.66,5.66,0,0,0,7,11.93L9.67,4.17ZM7.35,16.33l-.74-3.17.16-.48A5,5,0,0,1,10,13.79l-.17.49Z"
          />
        </g>
      </g>
    </>
  </SvgIcon>
))

Rozpracovano.displayName = 'Rozpracovano'

export default Rozpracovano
