import {gql} from 'apollo-boost'
import {NexusGenFieldTypes} from 'kubik-server'

export interface IOwnMonthlyHistoryData {
  user: {
    monthlyHistory: NexusGenFieldTypes['MonthlyRebate']
  }
}
export const OWN_MONTHLY_HISTORY = gql`
  query userWithMonthlyHistory {
    user {
      id
      monthlyHistory {
        id
        year
        basicRebates(orderBy: createdAt_DESC) {
          id
          status
          type
          createdAt
          closePeriodAt
          applyFrom
        }
        studentRebates(orderBy: createdAt_DESC) {
          id
          status
          type
          createdAt
          closePeriodAt
          confirmationValidityTo
        }
        childrenRebates(orderBy: createdAt_DESC) {
          id
          status
          type
          createdAt
          otherParentStatus
          children {
            id
            applyInMonths
            status
            type
          }
        }
        disabilityRebates(orderBy: createdAt_DESC) {
          id
          status
          type
          createdAt
          closePeriodAt
          confirmationValidityTo
        }
        ztppRebates(orderBy: createdAt_DESC) {
          id
          status
          type
          createdAt
          closePeriodAt
          confirmationValidityTo
        }
      }
    }
  }
`
