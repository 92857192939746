import {
  KalendarInput,
  PomocGrey1,
  PridatStredisko40 as PredchoziZamestnavatel,
  RegistraceZamestnance as DanoveRezidenstvi,
  Shrnuti,
  UdajeSpolecnosti as Dotaznik,
} from '../../../../../components/icons/KubikIcons'
import {styled} from '@material-ui/core/styles'
import {userWorkedWholeYear} from '../../../../../utils'
import {useTranslation} from 'react-i18next'
import Box from '@material-ui/core/Box'
import React, {useEffect, useMemo, useRef} from 'react'
import useUser from '../../../../../hooks/useUser'
import PercentageStepper, {PercentageStep} from "../../../../../components/PercentageStepper";
import {useQuery} from 'react-apollo'
import {IOwnAnnualRebateData, OWN_ANNUAL_REBATE_OVERVIEW} from '../../TaxRebates/gql'
import {get} from 'lodash'

const Container = styled(Box)(({ theme }) => ({
  maxWidth: '100vw',
  display: 'flex'
}))

interface IProps {
  activeStep: 1 | 2 | 3 | 4 | 5 | 6
  linear?: boolean
  width?: number | string
  isPozp?: boolean
}

const Stepper: React.FC<IProps> = (props) => {
  const stepperRef = useRef<HTMLDivElement>(null)
  const activeRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const { user } = useUser()
  const { activeStep, isPozp } = props

  const workedWholeYear = userWorkedWholeYear(
    user.data,
    user.data.customer.yearOfAnnualRebates,
  )

  let muiActiveStep = activeStep - 1
  if (workedWholeYear && activeStep >= 2) {
    muiActiveStep = muiActiveStep - 1
  }
  if (!user.shouldHaveResidenceStep() && activeStep >= 3) {
    muiActiveStep = muiActiveStep - 1
  }

  const { data } = useQuery<IOwnAnnualRebateData>(
    OWN_ANNUAL_REBATE_OVERVIEW,
    {
      fetchPolicy: 'cache-and-network',
      skip: !user || !user.data.annualRebate || !user.data.annualRebate.settlementRequest
    },
  )

  useEffect(() => {
    const active = activeRef.current
    const stepper = stepperRef.current
    const container = stepper && stepper.parentElement

    if (active && stepper && container) {
      if (container.scrollWidth > container.clientWidth) {
        const containerCenter = container.clientWidth / 2
        const activeCenter = active.offsetLeft + active.clientWidth / 2
        const diff = activeCenter - containerCenter

        container.scrollTo(diff, 0)
      }
    }
  }, [stepperRef, activeRef])

  const statuses = useMemo(() => {
    if (!data) {
      return null
    }

    const overview = data.user.annualRebate.overview
    const prevEmployers = data.user.annualRebate.prevEmployers

    if (!overview || !data.user.annualRebate.settlementRequest) {
      return null
    }

    return {
      questionnaire: get(overview.questionnaire, 'status') === 'DENIED' ? 'DENIED' : null,
      taxResidence: user.getLastResidenceFilesStatus() === 'DENIED' ? 'DENIED' : user.hasNewResidenceFiles() ? 'CONFIRMED' : null,
      prevEmployers: prevEmployers && prevEmployers.length > 0 ? (
        prevEmployers
          .map(employer => employer.status)
          .some(status => status === 'DENIED')
      ) ? 'DENIED' : 'CONFIRMED' : 'CONFIRMED',
      monthlyRebates: [
        get(overview.basicRebate, 'status'),
        get(overview.childrenRebate, 'status'),
        get(overview.disabilityRebate, 'status'),
        get(overview.ztppRebate, 'status')
      ].some(status => status === 'DENIED') ? 'DENIED' : 'CONFIRMED',
      annualRebates: [
        get(overview.giftRebate, 'status'),
        get(overview.preschoolRebate, 'status'),
        get(overview.spouseRebate, 'status'),
        get(overview.lifeInsuranceRebate, 'status'),
        get(overview.pensionInsuranceRebate, 'status'),
        get(overview.loanRebate, 'status'),
        get(overview.foreclosureRebate, 'status'),
        get(overview.longTermCareRebate, 'status'),
        get(overview.investmentRebate, 'status'),
      ].some(status => status === 'DENIED') ? 'DENIED' : 'CONFIRMED',
    }
  }, [data])

  const steps = useMemo<PercentageStep[]>(() => {
    let steps: PercentageStep[] = [
      {
        label: t('annualRebateWizard.stepper.questionnaire'),
        icon: <Dotaznik fontSize="default" />,
        state: get(statuses, 'questionnaire'),
        link: `/${user.data.id}/annual-rebates/questionnaire`
      }
    ];

    if (!isPozp) {
      if(!workedWholeYear){
        steps.push({
          label: t('annualRebateWizard.stepper.previousEmployer'),
          icon: <PredchoziZamestnavatel fontSize="default" />,
          state: get(statuses, 'prevEmployers'),
          link: `/${user.data.id}/annual-rebates/previousEmployers`
        })
      }

      if(user.shouldHaveResidenceStep()){
        steps.push({
          label: t('annualRebateWizard.stepper.taxResidence'),
          icon: <DanoveRezidenstvi fontSize="default" />,
          state: get(statuses, 'taxResidence'),
          link: `/${user.data.id}/annual-rebates/taxResidence`
        })
      }

      steps.push({
        label: t('annualRebateWizard.stepper.monthly'),
        icon: <PomocGrey1 fontSize="default" />,
        state: get(statuses, 'monthlyRebates'),
        link: `/${user.data.id}/annual-rebates/rootMonthly`
      })

      steps.push({
        label: t('annualRebateWizard.stepper.rebateSelect'),
        icon: <KalendarInput fontSize="default" />,
        state: get(statuses, 'annualRebates'),
        link: `/${user.data.id}/annual-rebates/root`
      })
    }

    steps.push({
      label: t('annualRebateWizard.stepper.summary'),
      icon: <Shrnuti fontSize="default" />
    })

    return steps;
  }, [user, t, statuses, isPozp])

  return (
    <Container>
      <PercentageStepper
        steps={steps}
        activeStep={muiActiveStep}
        ref={stepperRef}
        nonLinear={!!statuses}
      />
    </Container>
  )
}

export default Stepper
