import {
  IResetPasswordTokenData,
  RESET_PASSWORD_TOKEN,
  IResetPasswordConfirmData,
  IResetPasswordConfirmVariables,
  RESET_PASSWORD_CONFIRM,
} from '../gql'
import { Link } from 'react-router-dom'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Observer } from 'mobx-react'
import { RouteComponentProps } from 'react-router'
import { useMutation } from 'react-apollo-hooks'
import { useQuery } from 'react-apollo'
import { useTranslation, Trans } from 'react-i18next'
import AppState from '../../../components/AppState'
import ArrowIcon from '@material-ui/icons/PlayCircleFilled'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import classnames from 'classnames'
import Countdown from '../../../components/Countdown'
import Divider from '../../../components/Divider'
import Fade from '@material-ui/core/Fade'
import get from 'lodash/get'
import CheckIcon from '@material-ui/icons/CheckCircle'
import logoPng from '../../../assets/logo.png'
import ProgressContainer from '../../../components/ProgressContainer'
import React, { useState, useEffect } from 'react'
import TextField from '../../../components/form/TextField'
import Typography from '@material-ui/core/Typography'
import useAccount from '../../../hooks/useAccount'
import useForm from '../../../hooks/useForm'
import useGlobalState from '../../../hooks/useGlobalState'
import BoxBackground from '../../../components/BoxBackground'
import prihlaseniLeft from '../../../assets/images/Ilustrace-mzdova-ucetni.svg'
import prihlaseniRight from '../../../assets/images/Ilustrace-zamestnanec.svg'
import useUser from "../../../hooks/useUser";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.15)',
  },
  greenBold: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  passwordRule: {
    width: 300,
    display: 'flex',
    transition: theme.transitions.create('color'),
    paddingLeft: theme.spacing(1),
    color: theme.palette.text.hint,
    '& > svg': {
      marginRight: theme.spacing(1),
    },
  },
  red: { color: theme.palette.error.main },
  green: { color: theme.palette.primary.main },
  logo: {
    marginBottom: theme.spacing(3),
  },
}))

interface Props extends RouteComponentProps<{ token: string }> {}

interface TFormData {
  password: string
  passwordAgain: string
}

const ResetPasswordConfirm: React.FC<Props> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const token = props.match.params.token
  const { setLanguage } = useAccount(token)
  const [expired, setExpired] = useState(false)
  const {refetch: refetchUser } = useUser()

  const resetPassword = useMutation<
    IResetPasswordConfirmData,
    IResetPasswordConfirmVariables
  >(RESET_PASSWORD_CONFIRM, {
    // refetchQueries: [{ query: ME }],
  })

  const { data, error, loading } = useQuery<IResetPasswordTokenData>(
    RESET_PASSWORD_TOKEN,
    {
      variables: { token },
      onCompleted(data) {
        if (
          data &&
          data.resetPasswordToken &&
          data.resetPasswordToken.language
        ) {
          setLanguage(data.resetPasswordToken.language)
        }
      },
    },
  )
  const tokenExpiration =
    (data &&
      data.resetPasswordToken &&
      data.resetPasswordToken.tokenExpiration) ||
    ''

  const companyName = get(data, 'resetPasswordToken.companyNames[0]') || ''

  const { setState } = useGlobalState()
  useEffect(() => {
    setState((state: any) => ({ ...state, lovers: companyName }))
  }, [companyName, setState])

  const { bind, form } = useForm<TFormData>(
    {},
    {
      password: {
        label: t('common.password'),
        placeholder: t('common.newPasswordPlaceholder'),
        rule: [
          'required',
          'min:8',
          'regex:/[a-z]/',
          'regex:/[A-Z]/',
          'regex:/[0-9]/',
        ],
      },
      passwordAgain: {
        label: t('common.passwordAgain'),
        placeholder: t('common.passwordAgainPlaceholder'),
        rule: 'required|same:password',
        help: t('newCompany.passwConfirmHelp'),
      },
    },
    {
      variables: { token },
      async onSubmit({ password }, form) {
        try {
          const result = await resetPassword({
            variables: {
              password,
              ...form.options.variables,
            },
          })
          await refetchUser()

          if (result && result.data) {
            props.history.push(
              `/loginconfirm/${result.data.resetPasswordConfirm.token}`,
            )
          }
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  return (
    <BoxBackground left={prihlaseniLeft} right={prihlaseniRight} topBackgroundColor={'#ffffff'} bottomBackgroundColor={'#f8f8f8'}>
      <ProgressContainer loading={loading}>
        <Observer>
          {() => {
            const password = form.getValue('password') || ''
            const passwordLength = password && password.length > 7
            const passwordHasDigit = (new RegExp('[0-9]', 'g')).test(password)
            const passwordHasLowerUpper =
              (new RegExp('[a-z]', 'g')).test(password) && (new RegExp('[A-Z]', 'g')).test(password) 
            const passwordTouched = form.touched.get('password')

            return (
              <Box p={6} className={classes.container}>
                <AppState loading={form.state.loading} />

                <Box display="flex" justifyContent="center">
                  <img
                    alt=""
                    className={classes.logo}
                    src={logoPng}
                    width={250}
                    height={48}
                  />
                </Box>

                <Typography align="center" variant="h1" gutterBottom>
                  {t('resetPasswordConfirm.heading')}
                </Typography>

                {expired && (
                  <Typography gutterBottom align="center">
                    {t('resetPasswordConfirm.codeExpired')}
                  </Typography>
                )}
                {error && (
                  <Typography gutterBottom align="center">
                    {t('resetPasswordConfirm.codeInvalid')}
                  </Typography>
                )}

                {(expired || error) && (
                  <Typography align="center">
                    <Link to="/resetpassword" className={classes.green}>
                      {t('resetPassword.resetPassword')}
                    </Link>
                  </Typography>
                )}

                {!expired && !error && (
                  <>
                    <Box textAlign="center" fontFamily="fontFamily" mb={4}>
                      <Trans i18nKey="resetPasswordConfirm.content1">
                        Zadejte nové heslo, které chcete používat ke vstupu do
                        <strong className={classes.green}>Payminatora</strong>.
                      </Trans>
                    </Box>
                    <Box textAlign="center" fontFamily="fontFamily" mb={4}>
                      <Trans i18nKey="resetPasswordConfirm.content2">
                        Po kliknutí na tlačítko
                        <strong className={classes.green}>Potvrdit</strong> se
                        Vaše heslo resetuje a bude platit heslo nově vytvořené.
                        Doporučujeme si heslo dobře uschovat a nesdílet.
                      </Trans>
                    </Box>

                    <Box textAlign="center" fontFamily="fontFamily" mb={4}>
                      {t('resetPassword.countdown')}{' '}
                      <Countdown
                        expiration={new Date(tokenExpiration)}
                        onExpired={() => setExpired(true)}
                      />
                    </Box>

                    <Box display="flex" justifyContent="center">
                      <Box width={300}>
                        <TextField
                          fullWidth
                          gutterBottom
                          showValidationIcon
                          hideErrorText
                          type="password"
                          {...bind('password')}
                        />
                        <Typography
                          className={classnames(classes.passwordRule, {
                            [classes.green]: passwordLength,
                            [classes.red]: !passwordLength && passwordTouched,
                          })}
                        >
                          {passwordLength ? <CheckIcon /> : <ArrowIcon />}
                          {t('common.passwordRuleLength')}
                        </Typography>
                        <Typography
                          className={classnames(classes.passwordRule, {
                            [classes.green]: passwordHasLowerUpper,
                            [classes.red]:
                              !passwordHasLowerUpper && passwordTouched,
                          })}
                        >
                          {passwordHasLowerUpper ? <CheckIcon /> : <ArrowIcon />}
                          {t('common.passwordRuleUpperLower')}
                        </Typography>
                        <Typography
                          gutterBottom
                          className={classnames(classes.passwordRule, {
                            [classes.green]: passwordHasDigit,
                            [classes.red]: !passwordHasDigit && passwordTouched,
                          })}
                        >
                          {passwordHasDigit ? <CheckIcon /> : <ArrowIcon />}
                          {t('common.passwordRuleDigit')}
                        </Typography>

                        <TextField
                          fullWidth
                          gutterBottom
                          showValidationIcon
                          type="password"
                          {...bind('passwordAgain')}
                        />
                      </Box>
                    </Box>

                    <Fade in={form.state.errors.length > 0}>
                      <Box mb={2}>
                        {form.state.errors.map((err, idx) => (
                          <Typography
                            align="center"
                            color="error"
                            key={err + idx}
                          >
                            <strong>{err}</strong>
                          </Typography>
                        ))}
                      </Box>
                    </Fade>

                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Button
                        onClick={form.submit}
                        size="large"
                        color="primary"
                        variant="contained"
                      >
                        {t('common.submit')}
                      </Button>
                    </Box>
                  </>
                )}
                <Divider spacing={4} />

                <Box textAlign="center" fontFamily="fontFamily" /*mb={2}*/>
                  <Trans i18nKey="common.needHelp">
                    Potřebujete pomoct?
                    <a href="mailto:helpdesk@payminator.cz">Napište nám.</a>
                  </Trans>
                </Box>

                {/* <Typography align="center">
                  <Trans i18nKey="login.createAccount">
                    Nemáte ještě vytvořený účet?
                    <Link to="/newcompany">Vytvořit účet v aplikaci</Link>
                  </Trans>
                </Typography> */}
              </Box>
            )
          }}
        </Observer>
      </ProgressContainer>
    </BoxBackground>
  )
}

export default ResetPasswordConfirm
