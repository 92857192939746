import {
  MESSAGE_CREATE,
  MESSAGE_USERS,
  TMessage,
  TMessageCreateData,
  TMessageCreateVariables,
  TMessageUsersData,
  TUser,
} from './gql'
import {useMutation, useQuery} from 'react-apollo'
import {useTranslation} from 'react-i18next'
import GraphQLErrorSnackbar from '../../../../components/GraphQLErrorSnackbar'
import Message, {IProps as IMessageProps} from './components/Message'
import moment from 'moment'
import React, {useMemo} from 'react'
import useForm from '../../../../hooks/useForm'
import useSnackbar from '../../../../hooks/useSnackbar'
import useUser from '../../../../hooks/useUser'

export interface IProps extends IMessageProps {}

interface IMessageData {
  to?: { connect: { id: string } }
  copy?: { connect: { id: string } }
  subject?: TMessage['subject']
  text?: string
}

const NewMessage: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { message, ...restProps } = props
  const { addMessage } = useSnackbar()
  const { data, error: errorUsers } = useQuery<TMessageUsersData>(
    MESSAGE_USERS,
    {
      fetchPolicy: 'cache-and-network',
    },
  )
  const [createMessage, { error: errorCreate }] = useMutation<
    TMessageCreateData,
    TMessageCreateVariables
  >(MESSAGE_CREATE, {
    refetchQueries: ['messages'],
    onCompleted() {
      addMessage(t('message.sent', { context: 'SUCCESS' }))
    },
  })

  const messageUsers = useMemo<TUser[]>(() => {
    if (!data) {
      return []
    }

    let message = props.message

    if (message && message.action === 'RESPOND') {
      const from = data.messageUsers.find(
        (user) => user.id === message.data.from.id,
      )

      if (!from) {
        return [...data.messageUsers, message.data.from as TUser]
      }
    }

    return data.messageUsers
  }, [data, props.message])

  const divider =
    '------------------------------------------------------------------------------------------'
  const getInitialData = () => {
    let messageData: IMessageData = {}
    if (message && message.action) {
      switch (message.action) {
        case 'RESPOND':
          messageData = {
            ...(user.data.id !== message.data.from.id &&
              messageUsers.find((u) => u.id === message.data.from.id) && {
                to: { connect: { id: message.data.from.id } },
              }),
            ...(message.data.copy &&
              messageUsers.find((u) => u.id === message.data.copy.id) && {
                copy: { connect: { id: message.data.copy.id } },
              }),
            subject: `RE: ${message.data.subject}`,
          }
          break
        case 'RESEND':
          messageData = {
            subject: `FW: ${message.data.subject}`,
          }
          break
        case 'TO':
          messageData = {
            to: { connect: { id: message.data.to.id } },
          }
          break
      }
      if (message && message.action !== 'TO') {
        messageData.text = `\n\n${divider}\n${t(
          'message.originalMessage',
        )}:\n${t('message.time')}: ${moment(message.data.createdAt).format(
          'DD.MM.YYYY, HH:mm',
        )}\n${t('message.from')}: ${message.data.from.fullname}\n${t(
          'message.to',
        )}: ${message.data.to.fullname}\n${
          message.data.copy
            ? `${t('message.copy')}: ${message.data.to.fullname}\n`
            : ''
        }${t('message.subject')}: ${message.data.subject}\n${t(
          'message.originalText',
        )}:\n${message.data.text}\n${divider}`
      }
    }
    return messageData
  }

  const newMessageForm = useForm<TMessageCreateVariables['data']>(
    getInitialData(),
    {
      to: {
        label: t('message.to'),
        rule: 'required',
        type: 'single_relation',
        list: messageUsers,
      },
      copy: {
        label: t('message.copy'),
        type: 'single_relation',
        list: messageUsers,
      },
      subject: { label: t('message.subject'), rule: 'required' },
      text: { label: t('message.text'), rule: 'required' },
    },
    {
      submitWholeModel: message ? true : false,
      async onSubmit(formdata) {
        try {
          const { data, errors } = await createMessage({
            variables: {
              data: formdata,
            },
          })
          if (data) {
            newMessageForm.form.onSuccess({})
            props.actions.close()
          } else {
            newMessageForm.form.onFail(errors)
          }
        } catch (err) {
          newMessageForm.form.onFail(err)
        }
      },
    },
  )

  return (
    <>
      <Message useForm={newMessageForm} {...restProps} />
      <GraphQLErrorSnackbar error={errorUsers || errorCreate} />
    </>
  )
}

export default NewMessage
