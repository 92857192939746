import {
  CANCEL_ANNUAL_SETTLEMENT_REQUEST,
  CANCEL_MONTHLY_SETTLEMENT_REQUEST,
  ICancelAnnualSettlementRequestData,
  ICancelMonthlySettlementRequestData,
} from '../../../pages/Inside/pages/TaxRebates/gql'
import { useMutation } from 'react-apollo'
import { useRouter, useUser } from '../../../hooks'
import CancelRequestDialog from '../../../pages/Inside/pages/TaxRebates/components/CancelRequestDialog'
import React from 'react'

export type TCancelRequestDialogOpen = 'ANNUAL' | 'MONTHLY' | null

interface ICancelRequestDialogProps {
  open: TCancelRequestDialogOpen
  onClose: () => void
}

const CancelRequestDialogNavigation: React.FC<
  ICancelRequestDialogProps
> = props => {
  const { history } = useRouter()
  const { user } = useUser()
  const { open, onClose } = props

  const [cancelAnnualSettlementRequest] = useMutation<
    ICancelAnnualSettlementRequestData
  >(CANCEL_ANNUAL_SETTLEMENT_REQUEST, {
    onCompleted() {
      history.push(`/${user.data.id}/annual-rebates`)
    },
    onError() {
      onClose()
    },
  })

  const [cancelMonthlySettlementRequest] = useMutation<
    ICancelMonthlySettlementRequestData
  >(CANCEL_MONTHLY_SETTLEMENT_REQUEST, {
    onCompleted() {
      history.push(`/${user.data.id}/monthly-rebates`)
    },
    onError() {
      onClose()
    },
  })

  const handleSubmit = async () => {
    switch (open) {
      case 'ANNUAL':
        await cancelAnnualSettlementRequest()
        break
      case 'MONTHLY':
        await cancelMonthlySettlementRequest()
        break
    }
  }

  return (
    <CancelRequestDialog
      open={!!open}
      onClose={onClose}
      onSubmit={handleSubmit}
      type={!!open ? open : undefined}
    />
  )
}

export default CancelRequestDialogNavigation
