import {DOCUMENT_DELETE, TDocument, TDocumentDeleteData, TDocumentDeleteVariables,} from './gql'
import {Center, IconCircle} from '../../../../components/CommonBox'
import {makeStyles} from '@material-ui/core/styles'
import {Observer} from 'mobx-react'
import {OpravduSmazat} from '../../../../components/icons/KubikIcons'
import {useMutation} from 'react-apollo'
import {useTranslation} from 'react-i18next'
import AppState from '../../../../components/AppState'
import Button from '@material-ui/core/Button'
import Dialog from '../../../../components/Dialog'
import DialogActions from '../../../../components/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../../../../components/DialogTitle'
import GraphQLErrorSnackbar from '../../../../components/GraphQLErrorSnackbar'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  errorButton: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
}))

interface IProps {
  document: TDocument | null
  onClose: () => void
  documentsQuery: string
}

const RemoveDocument: React.FC<IProps> = props => {
  const classes = useStyles()
  const { onClose, document, documentsQuery: DOCUMENTS } = props
  const { t } = useTranslation()
  const [deleteDocument, { loading, error }] = useMutation<
    TDocumentDeleteData,
    TDocumentDeleteVariables
  >(DOCUMENT_DELETE, { refetchQueries: [DOCUMENTS] })

  const open = Boolean(document)

  const handleDelete = () => {
    onClose()
    if (document) {
      deleteDocument({ variables: { where: { id: document.id } } })
    }
  }

  return (
    <>
      <AppState loading={loading} />
      <GraphQLErrorSnackbar error={error} />
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
        scroll="body"
      >
        <DialogTitle onClose={onClose}>
          <Center mb={2}>
            <IconCircle fontSize="3rem">
              <OpravduSmazat fontSize="inherit" color="primary" />
            </IconCircle>
          </Center>
          {t('documents.removeDialogHeading')}
        </DialogTitle>

        <DialogContent>
          <Observer>
            {() => (
              <Grid container direction="column" spacing={1}>
                <Grid sm item>
                  <Typography align="center">
                    „{document && document.name}“
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Observer>
        </DialogContent>

        <DialogActions dividerProps={{ mt: 2 }} gutterBottom>
          <Button
            className={classes.errorButton}
            onClick={handleDelete}
            variant="contained"
            color="primary"
          >
            {t('common.remove')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default RemoveDocument
