import { Bold, Divider } from '../../../../../../components/CommonBox'
import { ClosePeriod, Normal, Remove } from './Type'
import { Hypoteka } from '../../../../../../components/icons/KubikIcons'
import { NexusGenFieldTypes } from 'kubik-server'
import { TRebateType } from '../gql'
import { useMonthShortcutEnum } from '../../../../../../hooks/useEnumList'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import CommentOfDenied from './CommentOfDenied'
import FileLink from '../../../../../../components/FileLink'
import Grid from '@material-ui/core/Grid'
import PrevRebate from '../../../AnnualRebates/components/PrevRebate'
import React from 'react'
import StatusSwitcher, { StatusView, StatusViewText } from './StatusSwitcher'
import Typography from '@material-ui/core/Typography'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import moment from 'moment'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { CancelNotification } from './CancelNotification'

const LoanView: React.FC<{
  data: NexusGenFieldTypes['LoanRebate']
  hideComment?: boolean
  rebateType: TRebateType
}> = (props) => {
  const { t } = useTranslation()
  const { translate } = useMonthShortcutEnum()
  const { data, hideComment, rebateType } = props
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)

  return (
    <Grid container spacing={1}>
      <ClosePeriod data={data}>
        {t(
          `${
            rebateType === 'MONTHLY' ? 'monthly' : 'annual'
          }LoanRebate.closePeriodLabel_ACCOUNTANT`,
        )}
      </ClosePeriod>

      <Remove data={data} />

      <Normal data={data}>
        <Grid item xs={compactScreen ? 12 : 5}>
          <Bold>{t('annualLoanRebate.purchaseDateLabel')}</Bold>
        </Grid>
        <Grid item xs={compactScreen ? 12 : 7}>
          <Typography>
            {moment(data.purchaseDate).format('DD.MM.YYYY')}
          </Typography>
        </Grid>

        <Grid item xs={compactScreen ? 12 : 5}>
          <Bold>{t('annualLoanRebate.interestAmountDescription')}</Bold>
        </Grid>
        <Grid item xs={compactScreen ? 12 : 7}>
          <Typography>{data.interestAmount} Kč</Typography>
        </Grid>

        <Grid item xs={compactScreen ? 12 : 5}>
          <Bold>{t('annualLoanRebate.applyInMonthsLabel')}</Bold>
        </Grid>
        <Grid item xs={compactScreen ? 12 : 7}>
          <Typography>
            {data.applyInMonths &&
              data.applyInMonths.map((month) => translate(month)).join(', ')}
          </Typography>
        </Grid>

        {data.addresses &&
          data.addresses.map((address, idx) => (
            <React.Fragment key={idx}>
              <Grid item xs={compactScreen ? 12 : 5}>
                <Bold>
                  {idx === 0
                    ? t('annualLoanRebate.addressesDescription2')
                    : t('annualLoanRebate.addressesDescription3')}
                </Bold>
              </Grid>
              <Grid item xs={compactScreen ? 12 : 7}>
                <Typography>{address}</Typography>
              </Grid>
            </React.Fragment>
          ))}

        <Grid item xs={compactScreen ? 12 : 5}>
          <Bold>{t('annualLoanRebate.otherPersonApplying')}</Bold>
        </Grid>
        <Grid item xs={compactScreen ? 12 : 7}>
          <Typography>
            {data.otherPersonApplying ? t('common.yes') : t('common.no')}
          </Typography>
        </Grid>
        {data.otherPersonApplying && (
          <>
            <Grid item xs={compactScreen ? 12 : 5}>
              <Bold>{t('annualLoanRebate.otherPersonFullname')}</Bold>
            </Grid>
            <Grid item xs={compactScreen ? 12 : 7}>
              <Typography>{data.otherPersonFullname}</Typography>
            </Grid>
            <Grid item xs={compactScreen ? 12 : 5}>
              <Bold>{t('annualLoanRebate.otherPersonNationalIDNumber')}</Bold>
            </Grid>
            <Grid item xs={compactScreen ? 12 : 7}>
              <Typography>{data.otherPersonNationalIDNumber}</Typography>
            </Grid>
            <Grid item xs={compactScreen ? 12 : 5}>
              <Bold>{t('annualLoanRebate.otherAddress')}</Bold>
            </Grid>
            <Grid item xs={compactScreen ? 12 : 7}>
              <Typography>{data.otherAddress}</Typography>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Bold mb={1} display="block">
            {t('common.uploadedConfirmations')}
          </Bold>
          <Box pl={compactScreen ? 0 : 4}>
            {data.interestAmountConfirmationFiles &&
              data.interestAmountConfirmationFiles.map((file) => (
                <FileLink key={file.id} file={file} />
              ))}
            {data.loanContractFiles &&
              data.loanContractFiles.map((file) => (
                <FileLink key={file.id} file={file} />
              ))}
            {data.propertyConfirmationFiles &&
              data.propertyConfirmationFiles.map((file) => (
                <FileLink key={file.id} file={file} />
              ))}
            {data.startConfirmationFiles &&
              data.startConfirmationFiles.map((file) => (
                <FileLink key={file.id} file={file} />
              ))}
          </Box>
        </Grid>
      </Normal>

      {!hideComment && (
        <Grid item xs={12}>
          <CommentOfDenied rebateName="loanRebate" />
        </Grid>
      )}
    </Grid>
  )
}

interface IProps {
  data: NexusGenFieldTypes['LoanRebate']
  previous: NexusGenFieldTypes['LoanRebate'][]
  rebateType: TRebateType
  bottomDivider?: boolean
}

export const LoanRebatePrevious: React.FC<Omit<IProps, 'data'>> = (props) => {
  const { previous, rebateType, bottomDivider } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)

  const [first] = previous

  if (!first) return null

  return (
    <Box width={780} my={2} position="relative" maxWidth="100%">
      {!compactScreen ? (
        <>
          <Box position="absolute" left={-60} top={0} fontSize="2.5rem">
            <Hypoteka fontSize="inherit" />
          </Box>
          <StatusView status={first.status}>
            {t('rebate.loanRebate')}
          </StatusView>
        </>
      ) : (
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box fontSize="2.5rem">
              <Hypoteka fontSize="inherit" />
            </Box>
            <StatusView status={first.status} compactView />
          </Box>
          <Box>
            <StatusViewText status={first.status}>
              {t('rebate.loanRebate')}
            </StatusViewText>
          </Box>
        </Box>
      )}
      <ArchiveChanges>
        {previous.map((data) => (
          <PrevRebate
            key={data.id}
            status={data.status}
            settlementRequest={data.settlementRequest}
            commentOfDenied={data.commentOfDenied}
          >
            <LoanView data={data} rebateType={rebateType} hideComment />
          </PrevRebate>
        ))}
      </ArchiveChanges>

      {bottomDivider && <Divider my={6} />}
    </Box>
  )
}

const LoanRebate: React.FC<IProps> = (props) => {
  const { data, previous, rebateType, bottomDivider } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)

  return (
    <Box width={780} position="relative" maxWidth="100%">
      {!compactScreen ? (
        <>
          <Box position="absolute" left={-60} top={0} fontSize="2.5rem">
            <Hypoteka fontSize="inherit" />
          </Box>

          <StatusSwitcher rebateName="loanRebate">
            {t('rebate.loanRebate')}
          </StatusSwitcher>
        </>
      ) : (
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box fontSize="2.5rem">
              <Hypoteka fontSize="inherit" />
            </Box>
            <StatusSwitcher rebateName="loanRebate" compactView>
              {t('rebate.loanRebate')}
            </StatusSwitcher>
          </Box>
          <Box>
            <StatusViewText status={'APPLY'}>
              {t('rebate.loanRebate')}
            </StatusViewText>
          </Box>
        </Box>
      )}

      <LoanView data={data} rebateType={rebateType} />

      <ArchiveChanges>
        {previous.map((data) => (
          <PrevRebate
            key={data.id}
            status={data.status}
            settlementRequest={data.settlementRequest}
            commentOfDenied={data.commentOfDenied}
          >
            <LoanView data={data} hideComment rebateType={rebateType} />
          </PrevRebate>
        ))}
      </ArchiveChanges>

      <CancelNotification rebateName='loanRebate' />

      {bottomDivider && <Divider my={6} />}
    </Box>
  )
}

export default LoanRebate
