import {Dispatch, SetStateAction, useEffect, useState} from 'react'

const useTempState = <T>(defaultState: T, time: number = 1000) => {
  const [state, setState] = useState<T>(defaultState)

  useEffect(() => {
    if (state !== defaultState) {
      const resetState = () => setState(defaultState)
      const timeout = setTimeout(resetState, time)
      return () => clearTimeout(timeout)
    }
  }, [state, defaultState, time, setState])

  return [state, setState] as [T, Dispatch<SetStateAction<T>>]
}

export default useTempState
