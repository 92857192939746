import {Bold} from '../../../../../components/CommonBox'
import {NexusGenFieldTypes} from 'kubik-server'
import {useTranslation} from 'react-i18next'
import {styled, useTheme} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import FileLink from '../../../../../components/FileLink'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const TableContainer = styled('div')(({ theme }) => ({
  width: 580,
  marginBottom: 6,
  marginLeft: 'auto',
  marginRight: 'auto',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    overflowY: 'scroll',
  },
}))

const PreschoolRebateOverview: React.FC<{
  data: NexusGenFieldTypes['PreschoolRebate']
}> = ({ data }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)

  if (data.type === 'REMOVE') {
    return (
      <Grid container spacing={1}>
        <Grid item xs={compactScreen ? 12 : 5}>
          <Bold>{t('rebateChanges.removeRebate')}</Bold>
        </Grid>
        <Grid item xs={compactScreen ? 12 : 7}>
          <Typography>{t('common.yes')}</Typography>
        </Grid>
      </Grid>
    )
  }
  return (
    <>
      {data.preschools && data.preschools.length > 0 && (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('common.firstname')}</TableCell>
                <TableCell>{t('common.lastname')}</TableCell>
                <TableCell>{t('common.nationalIDNumber')}</TableCell>
                <TableCell>
                  {t('annualPreschoolRebate.feeAmountLabel')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.preschools.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.firstname}</TableCell>
                  <TableCell>{item.lastname}</TableCell>
                  <TableCell>{item.nationalIDNumber}</TableCell>
                  <TableCell>{item.feeAmount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Bold mb={1} display="block">
            {t('common.uploadedConfirmations')}
          </Bold>
        </Grid>
        <Box pl={compactScreen ? 0 : 4}>
          {data.feeConfirmationFiles &&
            data.feeConfirmationFiles.map((file) => (
              <FileLink key={file.id} file={file} />
            ))}
        </Box>
      </Grid>
    </>
  )
}

export default PreschoolRebateOverview
