import {ICustomerData, TUnit, TUser} from './gql'
import {createNewCustomer} from '../../../../generated/createNewCustomer'
import {makeStyles} from '@material-ui/core/styles'
import {useTranslation} from 'react-i18next'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import EmployeeCreateDialog from './EmployeeCreate'
import EmployeeUpdateDialog from './EmployeeUpdate'
import EmployeeRemoveDialog from './EmployeeRemove'
import Typography from '@material-ui/core/Typography'
import Divider from '../../../../components/Divider'

const useStyles = makeStyles((theme) => ({
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  marginBetween: {
    '& > *': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
}))

interface IProps {
  customer: ICustomerData['customer'] | createNewCustomer['createNewCustomer']
  employees: ICustomerData['employees']
  units: TUnit[]
  createDialogOpen: boolean
  setCreateDialogOpen: (open: boolean) => void
  superadminUpdate?: boolean
}

const Employees: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  // const { data } = useQuery<IEmployeesWithData>(EMPLOYEES_UNITS)
  // const employees = (data && data.users) || []

  const { customer, units, employees } = props

  const handleCreateDialogOpen = () => props.setCreateDialogOpen(true)
  const handleCreateDialogClose = () => props.setCreateDialogOpen(false)

  const [removeEmployee, setRemoveDialogOpen] = React.useState<TUser | null>(
    null,
  )
  const factoryHandleRemoveDialogOpen = (role: TUser) => () =>
    setRemoveDialogOpen(role)
  const handleRemoveDialogClose = () => setRemoveDialogOpen(null)

  const [updateEmployee, setUpdateDialogOpen] = React.useState<TUser | null>(
    null,
  )
  const factoryHandleUpdateDialogOpen = (role: TUser) => () =>
    setUpdateDialogOpen(role)
  const handleUpdateDialogClose = () => setUpdateDialogOpen(null)

  return (
    <Grid direction="column" item container spacing={0}>
      {employees.length > 0 && (
        <>
          <Typography>
            {!props.superadminUpdate
              ? t('customerActivation.createdUsersHeading')
              : t('customerActivation.createdUsersHeadingSuperadmin')}
          </Typography>
          <Divider spacing={4} />
        </>
      )}

      {employees.map((employee) => (
        <Grid
          item
          key={employee.id}
          container
          justifyContent="space-between"
          alignItems="center"
          direction="row"
        >
          <Grid xs={4} item>
            <Typography>
              {`${employee.firstname} ${employee.lastname}`}
            </Typography>
          </Grid>
          <Grid xs={4} item>
            <Typography variant="body2" color="textSecondary">
              {employee.email}
            </Typography>
          </Grid>
          <Grid xs={4} item>
            <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <Button onClick={factoryHandleUpdateDialogOpen(employee)}>
                  {t('common.edit').toLowerCase()}
                  <EditIcon className={classes.marginLeft} />
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={factoryHandleRemoveDialogOpen(employee)}>
                  {t('common.remove').toLowerCase()}
                  <CloseIcon className={classes.marginLeft} />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
      {employees.length > 0 && (
        <Grid item>
          <Button
            variant="outlined"
            color="default"
            onClick={handleCreateDialogOpen}
            fullWidth
          >
            <AddIcon /> {t('employee.addEmployee')}
          </Button>
        </Grid>
      )}

      <EmployeeCreateDialog
        units={units}
        customer={customer}
        employees={employees}
        open={props.createDialogOpen}
        onClose={handleCreateDialogClose}
      />

      <EmployeeUpdateDialog
        open={Boolean(updateEmployee)}
        customer={customer}
        employee={updateEmployee}
        units={units}
        onClose={handleUpdateDialogClose}
      />

      <EmployeeRemoveDialog
        open={Boolean(removeEmployee)}
        employee={removeEmployee}
        onClose={handleRemoveDialogClose}
        customer={customer}
      />
    </Grid>
  )
}

export default Employees
