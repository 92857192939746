import { TRebateState } from '../rebateState'
import { useTranslation } from 'react-i18next'
import AppliedIcon from './Odeslano'
import ConfirmedIcon from './Schvaleno'
import DeniedIcon from './Zamitnuto'
import InProgressIcon from './Rozpracovano'
import NewIcon from './Prazdne'
import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { NexusGenEnums } from 'kubik-server'
import { makeStyles } from '@material-ui/core'

const styles = makeStyles({
  icon: {
    fontSize: '0.875rem',
    height: '1.25em',
    width: '1.25em',
  }
})

const RebateStateIcon: React.FC<{ state: TRebateState | NexusGenEnums['SettlementRequestStatus'] | 'UNFINISHED' }> = ({ state }) => {
  const { t } = useTranslation()
  const title = t(`rebateState.${state.toLowerCase()}`) as string
  const { icon } = styles()

  switch (state) {
    case 'APPLIED':
    case 'APPLY':
      return (
        <Tooltip placement="right" title={title}>
          <AppliedIcon className={icon} />
        </Tooltip>
      )
    case 'CONFIRMED':
      return (
        <Tooltip placement="right" title={title}>
          <ConfirmedIcon className={icon} />
        </Tooltip>
      )
    case 'DENIED':
      return (
        <Tooltip placement="right" title={title}>
          <DeniedIcon className={icon} />
        </Tooltip>
      )
    case 'INPROGRESS':
    case 'UNFINISHED':
      return (
        <Tooltip placement="right" title={title}>
          <InProgressIcon className={icon} />
        </Tooltip>
      )
    case 'NEW':
      return (
        <Tooltip placement="right" title={title}>
          <NewIcon className={icon} />
        </Tooltip>
      )
    default:
      return null
  }
}

export default RebateStateIcon
