import {History} from 'history'
import {Link} from 'react-router-dom'
import {makeStyles, Theme} from '@material-ui/core/styles'
import {PokracovatBtnColor} from '../../../../components/icons/KubikIcons'
import {useTranslation} from 'react-i18next'
import Box from '@material-ui/core/Box'
import classnames from 'classnames'
import React from 'react'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles<Theme, IProps>(theme => {
  return {
    gutterBottom: {
      marginBottom: theme.spacing(2),
    },
    item: props => ({
      width: '100%',
      padding: theme.spacing(3),
      border: `2px solid ${props.denied ? '#C51313' : '#00BF43'}`,
      color: 'inherit',
      borderRadius: '6px',
      fontFamily: 'sans-serif',
      transition: 'background-color 250ms, box-shadow 250ms',
      '&:hover': {
        '-moz-box-shadow': '5px 5px 10px 0px rgba(0,0,0,0.2)',
        '-webkit-box-shadow': '5px 5px 10px 0px rgba(0,0,0,0.2)',
        backgroundColor: props.denied ? '#FFD7D7' : '#F1FAF1', //() => '#F1FAF1',
        boxShadow: '5px 5px 10px 0px rgba(0,0,0,0.2)',
      },
      '& ul': {
        padding: 0,
      },
      '& ul li': {
        padding: 0,
        display: 'list-item',
        '& div': {
          display: 'inline-table',
          margin: 0,
        },
        '&::before': {
          color: 'inherit',
          content: '"\\2022"',
          display: 'inline-block',
          fontSize: '1rem',
          marginLeft: '-0.6rem',
          verticalAlign: 'middle',
          width: '0.6rem',
        },
      },
    }),
    heading: {
      display: 'flex',
      flex: '1',
    },
    title: { flex: '1', marginRight: theme.spacing(1) },
    link: props => ({
      display: 'flex',
      color: props.denied ? '#C51313' : '#00BF43',
      textDecoration: 'none',
      fontSize: '0.9rem',
      alignItems: 'center',
      '& svg': {
        marginLeft: theme.spacing(0.25),
      },
    }),
    content: {
      marginTop: theme.spacing(2),
    },
  }
})

interface IProps {
  title: string
  link: History.LocationDescriptor<object>
  gutterBottom?: boolean
  denied?: boolean
  disabled?: boolean
}

const Item: React.FC<IProps> = props => {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const { link, title, gutterBottom, disabled } = props
  return (
    <Box
      className={classnames(classes.item, {
        [classes.gutterBottom]: gutterBottom,
      })}
    >
      <Box className={classes.heading}>
        <Typography variant="h3" className={classes.title}>
          {title}
        </Typography>
        {!disabled && <Link to={link} className={classes.link}>
          {t('common.goTo')}
          <PokracovatBtnColor color="inherit" fontSize="inherit"/>
        </Link>}
      </Box>
      <Box className={classes.content}>{props.children}</Box>
    </Box>
  )
}

export default Item
