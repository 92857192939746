import {makeStyles, styled} from '@material-ui/core/styles'
import {isExistingPreschool, isNewPreschool, TNewPreschool, TPreschool,} from './gql'
import {useTranslation} from 'react-i18next'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import PreschoolDialog from './PreschoolDialog'
import React from 'react'
import RemovePreschoolDialog from './RemovePreschoolDialog'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Box from '@material-ui/core/Box'

const TableContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    overflowY: 'scroll',
  },
}))

const useStyles = makeStyles((theme) => ({
  addButton: {
    padding: "16px 48px",
    "& span": {
      gap: "4px"
    }
  },
  gap: {
    gap: "16px"
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  marginBetween: {
    '& > *': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  label: {
    fontWeight: 'bold',
  },
}))

interface IProps {
  value: any[]
  onRelationDelete: (id: string | number) => void
  onRelationCreate: (data: any) => void
  onRelationUpdate: (id: string | number, data: any) => void
}

const PreschoolTable: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { value, onRelationDelete, onRelationCreate, onRelationUpdate } = props

  const [create, setCreate] = React.useState<null | any>(null)
  const [edit, setEdit] = React.useState<null | any>(null)
  const [remove, setRemove] = React.useState<null | any>(null)

  const onDelete = () => {
    onRelationDelete(remove.id)
    setRemove(null)
  }

  const onSubmit = (preschool: TPreschool | TNewPreschool) => {
    if (isNewPreschool(preschool)) {
      onRelationCreate(preschool)
    }
    if (isExistingPreschool(preschool)) {
      const { id, ...rest } = preschool
      onRelationUpdate(id, rest)
    }
    setCreate(null)
    setEdit(null)
  }

  const onCreateClick = () => setCreate({})

  return (
    <Box display="flex" flexDirection="column" alignItems="center" pt={4} className={classes.gap}>
      {value.length > 0 && (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('common.firstname')}</TableCell>
                <TableCell>{t('common.lastname')}</TableCell>
                <TableCell>{t('common.nationalIDNumber')}</TableCell>
                <TableCell>
                  {t('annualPreschoolRebate.feeAmountLabel')}
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {value.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.firstname}</TableCell>
                  <TableCell>{item.lastname}</TableCell>
                  <TableCell>{item.nationalIDNumber}</TableCell>
                  <TableCell>{item.feeAmount}</TableCell>
                  <TableCell align="right">
                    <Button onClick={() => setEdit(item)}>
                      {t('common.edit').toLowerCase()}
                      <EditIcon className={classes.marginLeft} />
                    </Button>
                    <Button onClick={() => setRemove(item)}>
                      {t('common.remove').toLowerCase()}
                      <CloseIcon className={classes.marginLeft} />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Button
        variant="outlined"
        color='secondary'
        onClick={onCreateClick}
        className={classes.addButton}
        fullWidth
        style={{ border: 0 }}
      >
        <AddIcon fontSize='small' />
        {t('annualPreschoolRebate.addPreschool')}
      </Button>

      <RemovePreschoolDialog
        preschool={remove}
        onClose={() => setRemove(null)}
        onDelete={onDelete}
      />

      <PreschoolDialog
        preschool={create || edit}
        onClose={() => {
          setCreate(null)
          setEdit(null)
        }}
        onSubmit={onSubmit}
      />
    </Box>
  )
}

export default PreschoolTable
