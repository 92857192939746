import {gql} from 'apollo-boost'
import {NexusGenArgTypes, NexusGenFieldTypes} from 'kubik-server'
import {FORECLOSURE_REBATE_FULL_FRAGMENT} from '../../../../../../fragments/ForeclosureRebate'

export type TForeclosureRebate = NexusGenFieldTypes['ForeclosureRebate']

export interface IOwnAnnualForeclosureRebateData {
  user: {
    annualRebate: NexusGenFieldTypes['AnnualRebate'] & {
      foreclosureRebate: TForeclosureRebate
      foreclosureRebates: TForeclosureRebate[]
    }
  }
}
export const OWN_ANNUAL_FORECLOSURE_REBATE = gql`
  query ownAnnualForeclosureRebate {
    user {
      id
      annualRebate {
        id
        year
        foreclosureRebate {
          ...ForeclosureRebateFull
        }
        foreclosureRebates {
          ...ForeclosureRebateFull
        }
      }
    }
  }
  ${FORECLOSURE_REBATE_FULL_FRAGMENT}
`

export interface IUpdateOwnAnnualForeclosureRebateData {
  updateOwnAnnualForeclosureRebate: NexusGenFieldTypes['ForeclosureRebate']
}
export type TUpdateOwnAnnualForeclosureRebateVariables = NexusGenArgTypes['Mutation']['updateOwnAnnualForeclosureRebate']
export const UPDATE_OWN_ANNUAL_FORECLOSURE_REBATE = gql`
  mutation updateOwnAnnualForeclosureRebate($data: OwnForeclosureRebateUpdateInput!) {
    updateOwnAnnualForeclosureRebate(data: $data) {
      ...ForeclosureRebateFull
    }
  }
  ${FORECLOSURE_REBATE_FULL_FRAGMENT}
`

export interface IResetOwnAnnualForeclosureData {
  resetOwnAnnualForeclosureRebate: TForeclosureRebate
}
export const RESET_OWN_ANNUAL_FORECLOSURE_REBATE = gql`
  mutation resetOwnAnnualForeclosureRebate {
    resetOwnAnnualForeclosureRebate {
      ...ForeclosureRebateFull
    }
  }
  ${FORECLOSURE_REBATE_FULL_FRAGMENT}
`
