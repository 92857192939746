import {
  IOwnMonthlyZtppRebateData,
  IResetOwnMonthlyZtppRebateData,
  IUpdateOwnMonthlyZtppRebateData,
  OWN_MONTHLY_DISABILITY_REBATE,
  RESET_OWN_MONTHLY_ZTPP_REBATE,
  TUpdateOwnMonthlyZtppRebateVariables,
  UPDATE_OWN_MONTHLY_DISABILITY_REBATE,
} from './gql'
import { Center, Divider, Green } from '../../../../../../components/CommonBox'
import ChangeRebateButton, {
  ChangeRebateChoice,
} from '../../../AnnualRebates/components/ChangeRebateButton'
import { ILimiterWithMessage } from '../../../../../../components/form/DatePicker'
import YearMonthPicker from '../../../../../../components/form/YearMonthPicker'
import {
  DateLabel,
  DatepickerWrapper,
  DateWrapper,
  GridContainer,
  InlineLabel,
} from '../../../../../../shared/PrevPeriods/PrevPeriods'
import {Form} from '../../../../../../hooks/useForm'
import {getApplyFromInYear, getPrevRebates, getRebateStats} from '../../utils'
import {Observer} from 'mobx-react'
import {pickId, yearRange} from '../../../../../../utils'
import {TZtppRebate} from '../../../../../../fragments/ZtppRebate'
import {useForm, useRouter, useUser} from '../../../../../../hooks'
import {useMutation, useQuery} from 'react-apollo'
import {Trans, useTranslation} from 'react-i18next'
import AppState from '../../../../../../components/AppState'
import Box from '@material-ui/core/Box'
import CancelRebateDialog from '../../../../../../components/CancelRebateDialog'
import CancelRebateSection from '../../../AnnualRebates/components/CancelRebateSection'
import CloseMonthlyPeriodSection from '../../../AnnualRebates/components/CloseMonthlyPeriodSection'
import Fade from '../../../../../../components/Fade'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import Grid from '@material-ui/core/Grid'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import NextMonth from '../../../../../../shared/NextMonth/NextMonth'
import PrevRebates from '../../../AnnualRebates/components/PrevRebates'
import React from 'react'
import RebateActions from '../../../AnnualRebates/components/RebateActions'
import RebateLayout from '../../../../../../components/layouts/RebateLayout'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import Uploader from '../../../../../../components/form/Uploader'
import ZTPP from '../../../../../../components/icons/ZTPP'
import ZtppRebateOverview from '../../../AnnualRebates/components/ZtppRebateOverview'
import {ZtppRebateConditions} from '../../../../../../shared/ZtppWithPeriodsFormSection/ZtppRebateConditions'
import moment from 'moment'
import {
  CANCEL_OWN_LAST_MONTHLY_REBATE,
  TCancelOwnLastMonthlyRebateVariables,
} from '../../gql/cancelOwnLastMonthlyRebate'
import { ISetMonthlyRebateSettings, ISetMonthlyRebateSettingsVariables, SET_MONTHLY_REBATE_SETTINGS } from '../../../../../../pages/Inside/pages/Support/components/gql'
import { AppliedRebates } from '../../components/AppliedRebates'
import { computed } from 'mobx'

const ZtppRebate: React.FC = () => {
  const { t } = useTranslation()
  const { user, refetch } = useUser()
  const { history } = useRouter()

  const [
    updateRebate,
    { loading: updateLoading, error: updateError },
  ] = useMutation<
    IUpdateOwnMonthlyZtppRebateData,
    TUpdateOwnMonthlyZtppRebateVariables
  >(UPDATE_OWN_MONTHLY_DISABILITY_REBATE)

  const [
    cancelRebate,
    { loading: cancelLoading, error: cancelError },
  ] = useMutation<boolean, TCancelOwnLastMonthlyRebateVariables>(
    CANCEL_OWN_LAST_MONTHLY_REBATE,
  )

  const [
    resetRebate,
    { loading: resetLoading, error: resetError },
  ] = useMutation<IResetOwnMonthlyZtppRebateData>(RESET_OWN_MONTHLY_ZTPP_REBATE)

  const [
    setRebateSettings,
    { loading: settingsLoading, error: settingsError },
  ] = useMutation<ISetMonthlyRebateSettings, ISetMonthlyRebateSettingsVariables>(
    SET_MONTHLY_REBATE_SETTINGS
  )

  const { data, loading, error } = useQuery<IOwnMonthlyZtppRebateData>(
    OWN_MONTHLY_DISABILITY_REBATE,
    { fetchPolicy: 'network-only' },
  )

  const year = get(data, 'user.monthlyRebate.year')
  const [yearStart, yearEnd, prevYearStart, nextYearEnd] = yearRange(year)
  const formData = get(data, 'user.monthlyRebate.ztppRebate') || {}

  const { bind, form } = useForm<TUpdateOwnMonthlyZtppRebateVariables['data']>(
    formData,
    {
      confirmationValidityFrom: {
        label: t('common.from'),
        rule: (data) => (data.type !== 'CLOSE_PERIOD' ? 'required|date' : ''),
        placeholder: moment(`${year}-01-01`).format('MMMM YYYY'),
      },
      confirmationValidityTo: {
        label: t('common.to'),
        help: t('monthlyZtppRebate.validityPeriodHelp', { context: '2023' }),
        rule: (data) => (data.type !== 'CLOSE_PERIOD' ? 'required|date' : ''),
        placeholder: moment(`${year}-12-31`).format('MMMM YYYY'),
      },
      ztppConfirmationFiles: {
        type: 'multi_relation',
        label: t('monthlyZtppRebate.uploadLabel', { context: '2023' }),
        rule: (data) => (data.type !== 'CLOSE_PERIOD' ? 'required' : ''),
        isFileRelation: true,
        help: t('common.uploadHelp'),
      },
      socialConfirmationFiles: {
        type: 'multi_relation',
        label: t('monthlyZtppRebate.uploadLabelSocial', { context: '2023' }),
        rule: (data) => (data.type !== 'CLOSE_PERIOD' ? 'required' : ''),
        isFileRelation: true,
      },
    },
    {
      async onSubmit(data, form) {
        try {
          if (
            user.allowAlter() &&
            data.type === 'ALTER' &&
            user.data.monthlyRebate
          ) {
            await setRebateSettings({
              variables: {
                id: user.data.monthlyRebate.id,
                allowSendAnythingOnce: true
              }
            })
          }

          await updateRebate({
            variables: { data: { ...data, status: 'APPLY' } },
          })
          history.push(`/${user.data.id}/monthly-rebates/root`)
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  const prevRebates = getPrevRebates<TZtppRebate>(
    get(data, 'user.monthlyRebate.ztppRebates'),
  )

  const {
    isFirstRequest,
    isPrevDenied,
    isPrevCanceled,
    isPrevPositiveClosedPeriod,
    showingCommentOfDenied,
    isCancelable,
    prevPositiveRebate,
    prevPositiveRebates,
    confirmationValidityExpired,
    closePeriodTookEffect,
  } = getRebateStats<TZtppRebate>(get(data, 'user.monthlyRebate.ztppRebates'))

  const cleanRebate = async () => {
    if (formData) {
      const ztppConfirmationFiles = formData.ztppConfirmationFiles || []
      const socialConfirmationFiles = formData.socialConfirmationFiles || []

      await updateRebate({
        variables: {
          data: {
            status: 'NEW',
            type: 'NEW',
            confirmationValidityFrom: null,
            confirmationValidityTo: null,
            ztppConfirmationFiles: {
              delete: ztppConfirmationFiles.map(pickId),
            },
            socialConfirmationFiles: {
              delete: socialConfirmationFiles.map(pickId),
            },
          },
        },
      })
    }
  }

  const onCancelRebate = async () => {
    if (get(data, 'user.monthlyRebate.ztppRebate.status') !== 'APPLY') {
      await Promise.all(
        [
          cancelRebate({
            variables: {
              rebate: 'ZTPP',
            },
          }),
          refetch()
        ]
      )
    }

    await cleanRebate()

    history.push(`/${user.data.id}/monthly-rebates/root`)
  }

  const handleNewPeriod = async () => {
    await cleanRebate()
    form.setField('type', 'NEW_PERIOD')
  }

  const handleAlter = async () => {
    await resetRebate()
    form.setField('type', 'ALTER')
  }

  const handleClosePeriod = async () => {
    form.setField('type', 'CLOSE_PERIOD')
  }

  const showForm = computed<boolean>(() => {
    const type = form.getValue('type')

    return (
      isFirstRequest ||
      isPrevDenied ||
      isPrevCanceled ||
      confirmationValidityExpired ||
      closePeriodTookEffect ||
      type === 'ALTER' ||
      type === 'NEW_PERIOD'
    )
  })

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <AppState loading={loading || updateLoading || resetLoading || cancelLoading || settingsLoading} />
      <GraphQLErrorSnackbar error={error || updateError || resetError || cancelError || settingsError} />

      {data && (
        <Fade>
          <RebateLayout
            commentOfDenied={showingCommentOfDenied}
            sideHint={
              <Trans i18nKey="monthlyZtppRebate.sideHint">
                <strong>Slevu na ZTP/P</strong> můžete uplatňovat{' '}
                <strong>měsíčně</strong> v průběhu roku nebo{' '}
                <strong>jednou ročně v rámci ročního zúčtování</strong>{' '}
                (formulář Roční zúčtování &gt; krok Měsíční slevy do-uplatnění).
                Sleva je ve výši <strong>1 345 Kč měsíčně</strong> neboli{' '}
                <strong>16 140 Kč ročně</strong>.
              </Trans>
            }
            upperHint={`${t('common.monthlyRebates')} ${year}`}
            heading={t('monthlyZtppRebate.heading', { context: '2023' })}
            subHeading={
              <Trans
                i18nKey="monthlyZtppRebate.subHeading"
                tOptions={{ context: '2023' }}
              >
                Chcete-li uplatňovat Slevu na ZTP/P, tak je nutné
                <Green>vyplnit platnost potvrzení</Green> a
                <Green>nahrát potvrzení o průkazu ZTP/P</Green>
              </Trans>
            }
            icon={<ZTPP fontSize="large" />}
          >
            {isCancelable && (
              <Box display="flex" justifyContent="center" mb={4}>
                <CancelRebateDialog onSubmit={onCancelRebate} />
              </Box>
            )}

            <Observer>
              {() => {
                const type = form.getValue('type')

                if (type === 'REMOVE') {
                  return (
                    <CancelRebateSection
                      onUndo={() => form.setField('type', 'NEW')}
                    >
                      {t('rebateChanges.cancelZtpp')}
                    </CancelRebateSection>
                  )
                }

                const confirmationValidityFrom =
                  prevPositiveRebate &&
                  prevPositiveRebate.confirmationValidityFrom

                const confirmationValidityTo =
                  prevPositiveRebate &&
                  prevPositiveRebate.confirmationValidityTo

                if (type === 'CLOSE_PERIOD') {
                  return (
                    <CloseMonthlyPeriodSection
                      bind={bind}
                      yearStart={yearStart}
                      yearEnd={yearEnd}
                      confirmationValidityFrom={confirmationValidityFrom}
                      confirmationValidityTo={confirmationValidityTo}
                    >
                      {t('monthlyZtppRebate.closePeriodLabel')}
                    </CloseMonthlyPeriodSection>
                  )
                }

                let minApplyFrom = moment(getApplyFromInYear(user, year))

                // NOTE: Pokud uplatňujeme nové období a existuje předchozí schválená sleva, tak začínáme až po jejím konci.
                if (type === "NEW_PERIOD" && confirmationValidityTo) {
                  const confirmationValidityToEnd = moment(confirmationValidityTo).endOf('month')
                  minApplyFrom = confirmationValidityToEnd.isAfter(minApplyFrom) ? confirmationValidityToEnd.add(1, "month").startOf("month") : minApplyFrom
                }

                // NOTE: Pokud jsme předčasně ukončili slevu, tak chceme navazovat na slevu na dalším měsíci.
                if (prevPositiveRebate && prevPositiveRebate.type === 'CLOSE_PERIOD') {
                  minApplyFrom = moment(prevPositiveRebate.closePeriodAt).add(1, 'month').startOf('month')
                }

                if (showForm.get()) {
                  return (
                    <FormSection
                      bind={bind}
                      form={form}
                      dateLimiters={{
                        yearEnd: {
                          date: yearEnd,
                          message: t('error.maxDateMonthlyYearEnd'),
                        },
                        yearStart: {
                          date: yearStart,
                          message: t('error.minDateMonthlyYearStart'),
                        },
                        prevYearStart: { date: prevYearStart },
                        nextYearEnd: { date: nextYearEnd },
                        commonMin: {
                          date: minApplyFrom
                        },
                      }}
                    />
                  )
                }

                return (
                  <Center maxWidth="100%" width={680} flexDirection="column">
                    {isPrevPositiveClosedPeriod ? (
                      <ChangeRebateButton>
                        <ChangeRebateChoice
                          variant="NEW_PERIOD"
                          onClick={handleNewPeriod}
                        />
                      </ChangeRebateButton>
                    ) : (
                      <ChangeRebateButton>
                        <ChangeRebateChoice
                          label={t("common.monthlyRebateNewPeriod")}
                          onClick={handleNewPeriod}
                        />
                        <ChangeRebateChoice
                          variant="CLOSE_PERIOD"
                          onClick={handleClosePeriod}
                        />
                        {user.allowAlter() ? (
                          <ChangeRebateChoice
                            variant="ALTER"
                            onClick={handleAlter}
                          />
                        ) : null}
                      </ChangeRebateButton>
                    )}

                    <AppliedRebates
                      rebates={prevPositiveRebates.map(r => ({
                        from: r.confirmationValidityFrom,
                        to: r.confirmationValidityTo,
                        type: r.type,
                        closePeriodAt: r.closePeriodAt
                      }))}
                    />
                  </Center>
                )
              }}
            </Observer>

            <Observer>
              {() => {
                const type = form.getValue('type')
                const showFormSection =
                  isFirstRequest ||
                  isPrevDenied ||
                  confirmationValidityExpired ||
                  closePeriodTookEffect ||
                  type === 'ALTER' ||
                  type === 'NEW_PERIOD' ||
                  type === 'CLOSE_PERIOD' ||
                  type === 'REMOVE'

                if (prevRebates.length < 1) return null

                return (
                  <PrevRebates
                    rebates={prevRebates}
                    overviewComponent={ZtppRebateOverview}
                    expand={!showFormSection}
                  />
                )
              }}
            </Observer>

            <Divider maxWidth={1080} my={4} />

            <Observer>
              {() => {
                const type = form.getValue('type')

                const submitToBack = (
                  !showForm.get() &&
                  type !== 'CLOSE_PERIOD' &&
                  type !== 'REMOVE'
                )

                return (
                  <RebateActions
                    submitToBack={submitToBack}
                    backTo={`/${user.data.id}/monthly-rebates/root`}
                    onSubmit={form.submit}
                    isDirty={form.state.isDirty}
                    formLoading={form.state.loading}
                  />
                )
              }}
            </Observer>
          </RebateLayout>
        </Fade>
      )}
    </InsideLayout>
  )
}

export default ZtppRebate

interface IFormSectionProps {
  bind: (fieldPath: string) => any
  form: Form
  dateLimiters: {
    yearEnd: ILimiterWithMessage
    yearStart: ILimiterWithMessage
    prevYearStart: ILimiterWithMessage
    nextYearEnd: ILimiterWithMessage
    commonMin: ILimiterWithMessage
  }
}

const width = 680

const FormSection: React.FC<IFormSectionProps> = (props) => {
  const { t } = useTranslation()
  const { bind, form, dateLimiters } = props

  return (
    <>
      <Box maxWidth="100%" width={width}>
        <ZtppRebateConditions />
      </Box>

      <Divider maxWidth="100%" width={width} my={4} />

      <Box maxWidth="100%" width={width} display="flex" flexDirection="column">
        <GridContainer
          container
          alignItems="flex-start"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item xs>
            <InlineLabel>{t('common.validityPeriodLabel')}</InlineLabel>
          </Grid>

          <Observer>
            {() => (
              <DateWrapper
                item
                sm={12}
                md
                container
                alignItems="flex-start"
                spacing={1}
              >
                <Grid item sm={12} md={6} container wrap="nowrap">
                  <DateLabel>{t('common.from')}</DateLabel>

                  <DatepickerWrapper>
                    <YearMonthPicker
                      fullWidth
                      minDates={[
                        dateLimiters.commonMin,
                        dateLimiters.prevYearStart,
                      ]}
                      maxDates={[
                        dateLimiters.yearEnd,
                        { date: form.getValue('confirmationValidityTo') },
                      ]}
                      {...bind('confirmationValidityFrom')}
                    />
                  </DatepickerWrapper>
                </Grid>

                <Grid item sm={12} md={6} container wrap="nowrap">
                  <DateLabel>{t('common.to')}</DateLabel>

                  <DatepickerWrapper>
                    <YearMonthPicker
                      pickEndOfTheMonth
                      fullWidth
                      minDates={[
                        dateLimiters.commonMin,
                        dateLimiters.yearStart,
                        { date: form.getValue('confirmationValidityFrom') },
                      ]}
                      maxDates={[dateLimiters.nextYearEnd]}
                      {...bind('confirmationValidityTo')}
                    />
                  </DatepickerWrapper>
                </Grid>
              </DateWrapper>
            )}
          </Observer>
        </GridContainer>
        <Observer>
          {() => <NextMonth date={form.getValue('confirmationValidityFrom')} />}
        </Observer>
      </Box>

      <Divider maxWidth="100%" width={width} my={4} />

      <Box maxWidth="100%" width={width}>
        <Observer>
          {() => (
            <Uploader {...bind('ztppConfirmationFiles')} showLabel multiple />
          )}
        </Observer>

        <Box mt={4}>
          <Observer>
            {() => (
              <Uploader
                {...bind('socialConfirmationFiles')}
                showLabel
                multiple
              />
            )}
          </Observer>
        </Box>
      </Box>
    </>
  )
}
