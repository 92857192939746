import {gql} from 'apollo-boost'
import {NexusGenArgTypes, NexusGenFieldTypes} from 'kubik-server'
import {STUDENT_REBATE_FULL_FRAGMENT} from '../../../../../../fragments/StudentRebate'

export type TStudentRebate = NexusGenFieldTypes['StudentRebate']

export interface IOwnAnnualStudentRebateData {
  user: {
    annualRebate: NexusGenFieldTypes['AnnualRebate'] & {
      studentRebate: TStudentRebate
      studentRebates: TStudentRebate[]
    }
  }
}
export const OWN_ANNUAL_STUDENT_REBATE_STUDENT = gql`
  query ownAnnualStudentRebate {
    user {
      id
      annualRebate {
        id
        year
        studentRebate {
          ...StudentRebateFull
        }
        studentRebates {
          ...StudentRebateFull
        }
      }
    }
  }

  ${STUDENT_REBATE_FULL_FRAGMENT}
`

export type TUpdateOwnAnnualStudentRebateVariables = NexusGenArgTypes['Mutation']['updateOwnAnnualStudentRebate']
export interface IUpdateOwnAnnualStudentRebateData {
  updateOwnAnnualStudentRebate: NexusGenFieldTypes['StudentRebate']
}
export const UPDATE_OWN_ANNUAL_STUDENT_REBATE_STUDENT = gql`
  mutation updateOwnAnnualStudentRebate(
    $data: OwnStudentAnnualRebateUpdateInput!
  ) {
    updateOwnAnnualStudentRebate(data: $data) {
      ...StudentRebateFull
    }
  }
  ${STUDENT_REBATE_FULL_FRAGMENT}
`

export interface IResetOwnAnnualStudentData {
  resetOwnAnnualStudentRebate: TStudentRebate
}
export const RESET_OWN_ANNUAL_STUDENT_REBATE = gql`
  mutation resetOwnAnnualStudentRebate {
    resetOwnAnnualStudentRebate {
      ...StudentRebateFull
    }
  }
  ${STUDENT_REBATE_FULL_FRAGMENT}
`
