import {Divider, Green} from '../../../../components/CommonBox'
import {Trans, useTranslation} from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import PersonalForm from '../Profile/PersonalForm'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import {useScrollWindowToCenter} from '../../../../hooks'
import Box from '@material-ui/core/Box'
import InfoBox from "../../../../components/InfoBox";
import {ListItem} from "@material-ui/core";
import List from "@material-ui/core/List";

interface IProps {
  prevStep: () => void,
  nextStep: () => void
}

const ResidentalInfo: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  useScrollWindowToCenter()

  return (
    <Grid direction="column" container justifyContent="center" alignItems="center">
      <Typography variant="h1" gutterBottom>
        {t('entryform.residentialInfoHeading')}
      </Typography>

      <Typography align="center">
        <Trans i18nKey="entryform.residentialInfoSubHeading">
          Vyplňte a zkontrolujte své <Green>rezidenční údaje</Green>.
        </Trans>
      </Typography>
      
      <Divider maxWidth={180} my={4} />

      <PersonalForm
        onSuccess={() => props.nextStep()}
        onSubmitWithoutChanges={() => props.nextStep()}
        onBack={props.prevStep? () => props.prevStep() : null}
        excludeFields={['firstname', 'lastname', 'gender', 'nickname', 'nationalIDNumber', 'birthdate', 'nationality', 'gdprAgreementAt']}
        residentialOnly={true}
        disableForeignDetails={true}
        disableDomicilWarning={true}
        beforeControls={<>
            <Box marginBottom={"2rem"}>
                <Typography variant="h2" gutterBottom  align={"center"}>
                    <Trans i18nKey={'registration.taxExplanationHeading'}>
                      Daňovým rezidentem v České republice jste v případě,<br/> že splňujete alespoň jednu z podmínek níže:
                    </Trans>
                </Typography>
                <Box margin={"0 auto"} maxWidth={400}>
                        <List className={"list"} >
                            <Trans i18nKey={"registration.taxExplanationConditions"}>
                                <ListItem>
                                  Máte stále bydliště v České republice (pronajaté i vlastní bydlení)
                                </ListItem>
                                <ListItem>
                                  Po většinu roku se zdržujete v České republice (více než 183 dní v roce)
                                </ListItem>
                                <ListItem>
                                  Máte středisko svých životních zájmů v České republice (osobní a pracovní vazby)
                                </ListItem>
                                <ListItem>
                                  Máte státní občanství v České republice
                                </ListItem>
                            </Trans>
                        </List>
                </Box>
            </Box>
            <Box maxWidth={400}>
                <InfoBox title={t('registration.taxInfoHeading')}>
                    <Typography>
                        <Trans i18nKey={'registration.taxInfoText'}>
                            I v případě, že <strong>nejste daňovým rezidentem</strong> v České republice, tak <strong>můžete
                            pokračovat dále v aplikaci</strong>. Stále si můžete uplatňovat některé daňové slevy a
                            požádat o zpracování ročního zúčtování na dani.
                        </Trans>
                    </Typography>
                </InfoBox>
            </Box>
        </>}
        submitContent={
          <>
            {t('common.continue')}
            <KeyboardArrowRight viewBox="0 -1 24 24" />
          </>
        }
        gdpr
        disableSnackbar
      />
    </Grid>
  )
}

export default ResidentalInfo
