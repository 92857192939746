import {useContext, useEffect} from 'react'
import {AppStateContext} from '../containers/AppStateProvider'

const useAppState = () => useContext(AppStateContext)
export default useAppState

export const useLoadingEffect = (loading?: boolean) => {
  const { setLoading } = useAppState()

  useEffect(() => {
    setLoading(!!loading)
    return () => setLoading(false)
  }, [loading, setLoading])
}
