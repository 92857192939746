import {
  CANCEL_ANNUAL_SETTLEMENT_REQUEST,
  ICancelAnnualSettlementRequestData,
  IOwnAnnualRebateData,
  OWN_ANNUAL_REBATE_OVERVIEW,
} from '../../../TaxRebates/gql'
import {IMeData, ME} from '../../../../../gql'
import {Divider, Green} from '../../../../../../components/CommonBox'
import {matchPath} from 'react-router-dom'
import {IStartAnnualRebateWizardData, START_ANNUAL_REBATE_WIZARD} from './gql'
import {useMutation, useQuery} from 'react-apollo'
import {useRouter, useUser} from '../../../../../../hooks'
import {Trans, useTranslation} from 'react-i18next'
import AppState from '../../../../../../components/AppState'
import Button from '@material-ui/core/Button'
import ButtonStepContainer from '../../../../../../components/ButtonStepContainer'
import CancelRequestDialog from '../../../TaxRebates/components/CancelRequestDialog'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import IntroLayout from '../../../../../../components/layouts/IntroLayout'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import React from 'react'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import Typography from '@material-ui/core/Typography'
import {Information} from "./components/Information"
import {RocniZuctovani2} from '../../../../../../components/icons/KubikIcons'

const Intro: React.FC = () => {
  const { t } = useTranslation()
  const { user, update: updateUser } = useUser()
  const { history, location } = useRouter()
  const {
    annualRebate,
    monthlyRebate,
    customer: { yearOfAnnualRebates },
  } = user.data

  const [cancelDialogOpen, setCancelDialogOpen] = React.useState(false)
  const closeCancelDialog = () => setCancelDialogOpen(false)

  const { data, loading: ownRebateLoading, refetch } = useQuery<
    IOwnAnnualRebateData
  >(OWN_ANNUAL_REBATE_OVERVIEW, {
    fetchPolicy: 'cache-and-network',
  })

  const [cancelAnnualSettlementRequest, mutationState] = useMutation<
    ICancelAnnualSettlementRequestData
  >(CANCEL_ANNUAL_SETTLEMENT_REQUEST, {
    onCompleted() {
      history.push(`/${user.data.id}/annual-rebates/summary`)
    },
    onError() {
      closeCancelDialog()
      refetch()
    },
  })

  // const handleSkip = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  //   if (get(data, 'user.annualRebate.settlementRequest.status') === 'APPLY') {
  //     e.preventDefault()
  //     setCancelDialogOpen(true)
  //   }
  // }

  const allowWelcome = !monthlyRebate && !annualRebate
  const annualWizardLocation = Boolean(
    matchPath(location.pathname, '/:userId/annual-rebates'),
  )

  const [startWizard, { loading, error }] = useMutation<
    IStartAnnualRebateWizardData
  >(START_ANNUAL_REBATE_WIZARD, {
    onCompleted() {
      history.push(`/${user.data.id}/annual-rebates`)
    },
    update: (store, { data }) => {
      if(data) {
        updateUser({
          ...user.data, annualRebate: {
            ...user.data.annualRebate,
            ...data.startAnnualRebateWizard,
          }
        })
      }
    },
    onError() {},
  })

  const onSubmit = () => {
    if (annualRebate) {
      history.push(`/${user.data.id}/annual-rebates`)
    } else {
      startWizard()
    }
  }

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <AppState
        loading={loading || ownRebateLoading || mutationState.loading}
      />
      <GraphQLErrorSnackbar error={error} />
      <IntroLayout
        variant="intro"
        // sideHint={<SideHint />}
        upperHint={null}
        heading={`${t('common.form')} ${t('common.annualRebates')} ${yearOfAnnualRebates}`}
        icon={<RocniZuctovani2 fontSize="large" />}
        footer={
          <React.Fragment>
            <Divider my={4} />

            <ButtonStepContainer
              // Pokud nemá ani RZD ani MS, tak btn zpět naviguje na výběr průvodce
              {...(allowWelcome && {
                backButton: { to: `/${user.data.id}/welcome/select-rebate` },
              })}
              // !annualWizardLocation - nejde o součást průvodce ale HELP na stránce Daňové slev
              // nebo již má SR, tzn. už to prošlo přes mzdovku, AKA druhé kolo
              {...((!annualWizardLocation ||
                Boolean(annualRebate && annualRebate.settlementRequest)) && {
                backButton: { to: `/${user.data.id}/rebates` },
              })}
            >
              {annualWizardLocation && (
                <Button
                  onClick={onSubmit}
                  color="primary"
                  size="large"
                  variant="contained"
                  disabled={loading}
                >
                  {annualRebate
                    ? t('common.continue')
                    : t('common.beginForm')}
                  <KeyboardArrowRight viewBox="0 -1 24 24" />
                </Button>
              )}
            </ButtonStepContainer>
            <CancelRequestDialog
              open={cancelDialogOpen}
              onClose={closeCancelDialog}
              onSubmit={cancelAnnualSettlementRequest}
            />
          </React.Fragment>
        }
      >
        <Typography align="center" style={{ maxWidth: '450px' }}>
          <Trans i18nKey="annualRebate.text1">
            Tento formulář umožnuje požádat si o <Green>roční zúčtování daně za minulý rok</Green> (či o potvrzení pro vlastní daňové přiznání) a <Green>uplatnit si slevy na dani za minulý rok</Green>. Formulář začnete vyplňovat vybráním tlačítka <Green>Spustit</Green>.
          </Trans>
        </Typography>

        <Divider maxWidth={180} my={4} />

        <Typography align="center" variant="h2" gutterBottom>
          {t('annualRebate.basicFormInfo')}
        </Typography>

        <Information />
      </IntroLayout>
    </InsideLayout>
  )
}

export default Intro
