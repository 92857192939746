import {gql} from 'apollo-boost'
import {NexusGenArgTypes, NexusGenEnums, NexusGenFieldTypes, NexusGenRootTypes,} from 'kubik-server'

import {CHILDREN_REBATE_FULL_FRAGMENT, TChildrenRebate,} from '../../../../../../fragments/ChildrenRebate'

export type TChild = NexusGenRootTypes['Child'] & {
  studentPrevYearPeriods:
    | NexusGenRootTypes['StudentRebatePrevYearPeriod'][]
    | null
}
export type TChildWithPrev = TChild & {
  prevClosePeriodAt?: any
  prevExists?: boolean
}
export type TPartialChild = TChild & { id?: string }
export type TNewChild = Pick<TChild, Exclude<keyof TChild, 'id'>>
export type TMonth = NexusGenEnums['Month']
export const isNewChild = (child: TChild | TNewChild): child is TNewChild => {
  return !('id' in child)
}
export const isExistingChild = (child: TChild | TNewChild): child is TChild => {
  return 'id' in child
}
export const isConfirmedChild = (
  child: TChildWithPrev | TNewChild,
): child is TChildWithPrev => {
  return 'prevExists' in child && !!child.prevExists
}

export interface IOwnMonthlyChildrenRebateData {
  user: {
    monthlyRebate: NexusGenFieldTypes['MonthlyRebate'] & {
      childrenRebate: TChildrenRebate
      childrenRebates: TChildrenRebate[]
    }
  }
}
export const OWN_MONTHLY_CHILDREN_REBATE = gql`
  query ownMonthlyChildrenRebate {
    user {
      id
      monthlyRebate {
        id
        year
        childrenRebate {
          ...ChildrenRebateFull
        }
        childrenRebates {
          ...ChildrenRebateFull
        }
      }
    }
  }

  ${CHILDREN_REBATE_FULL_FRAGMENT}
`

export interface IUpdateOwnMonthlyChildrenRebateData {
  updateOwnMonthlyChildrenRebate: NexusGenFieldTypes['ChildrenRebate']
}
export type TUpdateOwnMonthlyChildrenRebateVariables = NexusGenArgTypes['Mutation']['updateOwnMonthlyChildrenRebate']
export const UPDATE_OWN_MONTHLY_CHILDREN_REBATE = gql`
  mutation updateOwnMonthlyChildrenRebate(
    $data: OwnChildrenRebateUpdateInput!
  ) {
    updateOwnMonthlyChildrenRebate(data: $data) {
      ...ChildrenRebateFull
    }
  }
  ${CHILDREN_REBATE_FULL_FRAGMENT}
`

export interface IResetOwnMonthlyChildrenRebateData {
  resetOwnMonthlyChildrenRebate: TChildrenRebate
}
export const RESET_OWN_MONTHLY_CHILDREN_REBATE = gql`
  mutation resetOwnMonthlyChildrenRebate {
    resetOwnMonthlyChildrenRebate {
      ...ChildrenRebateFull
    }
  }
  ${CHILDREN_REBATE_FULL_FRAGMENT}
`

export const CANCEL_OWN_LAST_MONTHLY_CHILDREN_REBATE = gql`
  mutation cancelOwnLastMonthlyChildrenRebate {
    cancelOwnLastMonthlyChildrenRebate
  }
`
