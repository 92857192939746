import { SSO_SETTINGS, IRequestLoginVariables } from '../gql'
import {
  ssoSettings,
  ssoSettingsVariables,
  ssoSettings_ssoSettings,
} from '../../../generated/ssoSettings'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import { Observer } from 'mobx-react'
import { useLazyQuery } from '@apollo/react-hooks'
import { useTranslation, Trans } from 'react-i18next'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import React, { useState } from 'react'
import TextField from '../../../components/form/TextField'
import Typography from '@material-ui/core/Typography'
import useForm from '../../../hooks/useForm'
import Divider from '../../../components/Divider'
import ErrorSnackbar from '../../../components/ErrorSnackbar'
import Fade from '@material-ui/core/Fade'
import { Zamecek } from '../../../components/icons/KubikIcons'
import logoPng from '../../../assets/logo.png'
import AppState from '../../../components/AppState'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { msalConfig } from './components/ssoAuthConfig'
import {
  PublicClientApplication,
  AccountInfo,
  InteractionRequiredAuthError,
  AuthenticationResult,
} from '@azure/msal-browser'
import BoxBackground from '../../../components/BoxBackground'
import prihlaseniLeft from '../../../assets/images/Ilustrace-mzdova-ucetni.svg'
import prihlaseniRight from '../../../assets/images/Ilustrace-zamestnanec.svg'

interface IStylesProps {
  compactScreen?: boolean
  isNotSmallScreen?: boolean
}

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) => ({
  container: {
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.15)',
    zIndex: 1000,
    width: '100%',
    backgroundColor: '#f8f8f8',
  },
  backgroundFooter: {
    display: (props) => (props.compactScreen ? 'none' : undefined),
    background: '#f8f8f8',
    width: '100%',
    height: 90,
  },
  green: { color: theme.palette.primary.main },
  greenBold: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  logo: {
    marginBottom: theme.spacing(3),
  },
  backgroundGray: {
    backgroundColor: '#f8f8f8',
  },
}))

interface Props extends RouteComponentProps {}

const LoginSSO: React.FC<Props> = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const classes = useStyles({ compactScreen, isNotSmallScreen })
  const [ssoError, setSSOError] = useState<string | null>()

  const callLoginApi = async (
    email: string,
    accessToken: string | undefined,
  ) => {
    const headers = new Headers()
    const bearer = `Bearer ${accessToken}`

    headers.append('Authorization', bearer)
    headers.append('Content-Type', 'application/json')

    const options = {
      method: 'POST',
      headers: headers,
      credentials: 'include',
      body: JSON.stringify({
        email,
      }),
    }

    const apiEndpoint = process.env.REACT_APP_API_URL
      ? process.env.REACT_APP_API_URL + '/api/sso-auth'
      : 'http://localhost:4000/api/sso-auth'
    try {
      // server - autorizuje proti AD, ověří existenci uživatele
      // vystaví vlastní cookie a pošle zpět do browseru
      const response = await fetch(apiEndpoint, options)
      // or check for response.status
      if (!response.ok) {
        throw new Error(`${await response.text()}`)
      }

      // získali jsme cookie, takže můžeme znovu ověřit uživatele
      // a dostat jej do contextu aplikace
      window.location.assign('/')
    } catch (error) {
      setSSOError(error.message)
    }
  }

  const requestSSOLogin = async (ssoSettings: ssoSettings_ssoSettings) => {
    const config = {
      ...msalConfig,
      auth: {
        ...msalConfig.auth,
        ...(process.env.REACT_APP_SSO_REDIRECT_URI && {
          redirectUri: process.env.REACT_APP_SSO_REDIRECT_URI,
        }),
        clientId: ssoSettings.ssoSettings.ssoClientId || '',
        authority:
          `https://login.microsoftonline.com/${ssoSettings.ssoSettings.ssoTenantId}` ||
          '',
        scopes: [
          `api://${ssoSettings.ssoSettings.ssoClientId}/${ssoSettings.ssoSettings.ssoScopes}` ||
            '',
        ],
      },
    }

    try {
      const msalInstance = new PublicClientApplication(config)
      let homeAccountId = null
      const handleResponse = (resp: AuthenticationResult) => {
        if (resp && resp.account) {
          homeAccountId = resp.account.homeAccountId // alternatively: resp.account.username
        } else {
          const currentAccounts = msalInstance.getAllAccounts()
          //console.log(currentAccounts)
          if (currentAccounts.length < 1) {
            // No cached accounts
            return
          } else if (currentAccounts.length > 1) {
            // Multiple account scenario
            // Add account selection code here
            const [filterdAccount] = currentAccounts.filter(
              (account) => account.username === ssoSettings.email,
            )
            homeAccountId = filterdAccount.homeAccountId
          } else if (currentAccounts.length === 1) {
            homeAccountId = currentAccounts[0].homeAccountId // Single account scenario
          }
        }
      }

      const loginResponse = await msalInstance.loginPopup()
      handleResponse(loginResponse)

      if (!homeAccountId) {
        throw new Error(
          t('account.ssoNoUserFound', { email: ssoSettings.email }),
        )
      }

      const account: AccountInfo | null = msalInstance.getAccountByHomeId(
        homeAccountId,
      )
      let accessToken

      if (account) {
        console.log(account)
        if (
          account.username.toLowerCase() !== ssoSettings.email.toLowerCase()
        ) {
          throw new Error(
            t('account.ssoAnotherUser', {
              email: ssoSettings.email,
              username: account.username,
            }),
          )
        }
        try {
          const response = await msalInstance.acquireTokenSilent({
            scopes: config.auth.scopes,
            account: account,
          })
          accessToken = response.accessToken
        } catch (error) {
          // in case if silent token acquisition fails, fallback to an interactive method
          if (error instanceof InteractionRequiredAuthError) {
            try {
              const response = await msalInstance.acquireTokenPopup({
                scopes: config.auth.scopes,
              })
              accessToken = response.accessToken
            } catch (error) {
              console.error(error)
              throw new Error(error.message)
            }
          }
        }
        callLoginApi(ssoSettings.email, accessToken)
      }
    } catch (error) {
      setSSOError(error.message)
    }
  }

  const [ssoLogin] = useLazyQuery<ssoSettings, ssoSettingsVariables>(
    SSO_SETTINGS,
    {
      onCompleted: (data) => {
        requestSSOLogin(data.ssoSettings)
      },
    },
  )

  const { bind, form } = useForm<IRequestLoginVariables>(
    {
      email: '',
    },
    {
      email: {
        label: t('common.email'),
        placeholder: t('common.emailPlaceholder'),
        rule: 'required',
      },
    },
    {
      async onSubmit(data, form) {
        if (!form.state.isValid) {
          form.setTouchedAll()
          return
        }

        try {
          ssoLogin({ variables: { email: data.email } })
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  return (
    <Observer>
      {() => (
        <>
          <BoxBackground left={prihlaseniLeft} right={prihlaseniRight} topBackgroundColor={'#ffffff'} bottomBackgroundColor={'#f8f8f8'}>
            <Box
              display="flex"
              justifyContent="center"
              flex-wrap="nowrap"
              flexDirection="row"
            >
              <Box p={6} className={classes.container}>
                <AppState loading={form.state.loading} />

                <Box display="flex" justifyContent="center">
                  <img
                    alt=""
                    className={classes.logo}
                    src={logoPng}
                    width={250}
                    height={48}
                  />
                </Box>

                <Typography align="center" variant="h1" gutterBottom>
                  {t('login.heading')}
                </Typography>

                <Box textAlign="center" fontFamily="fontFamily" mb={4}>
                  <Trans i18nKey="login.subHeadingSSO">
                    1<strong className={classes.green}>Payminatora</strong>
                    <br />3
                  </Trans>
                </Box>

                <TextField fullWidth gutterBottom {...bind('email')} />

                <Fade in={form.state.errors.length > 0}>
                  <Box mb={2}>
                    {form.state.errors.map((err, idx) => (
                      <Typography align="center" color="error" key={err + idx}>
                        <strong>{err}</strong>
                      </Typography>
                    ))}
                  </Box>
                </Fade>

                <Box display="flex" justifyContent="center">
                  <Button
                    onClick={form.submit}
                    size="large"
                    color="primary"
                    variant="contained"
                  >
                    {t('common.signin')}
                    <span
                      style={{
                        fontSize: '0.75rem',
                        marginLeft: '8px',
                        marginTop: '3px',
                      }}
                    >
                      <Zamecek fontSize="inherit" />
                    </span>
                  </Button>
                </Box>

                <Divider spacing={4} />

                <Box textAlign="center" fontFamily="fontFamily">
                  <Trans i18nKey="common.needHelp">
                    Potřebujete pomoct?
                    <a href="mailto:helpdesk@payminator.cz">Napište nám.</a>
                  </Trans>
                </Box>
              </Box>
            </Box>
          </BoxBackground>
          {ssoError && <ErrorSnackbar errors={[ssoError]} onClose={() => {}} />}
        </>
      )}
    </Observer>
  )
}

export default withRouter(LoginSSO)
