import {makeStyles, Theme} from '@material-ui/core/styles'
import {Observer} from 'mobx-react'
import {
  FINISH_CUSTOMER_REGISTRATION,
  ICustomerData,
  ICustomerUpdateVariables,
  IFinishCustomerRegistrationData,
} from './gql'
import {createNewCustomer} from '../../../../generated/createNewCustomer'
import {Green} from '../../../../components/CommonBox'
import {IlustraceDokonceni} from '../../../../components/icons/KubikIcons'
import {Trans, useTranslation} from 'react-i18next'
import AppState from '../../../../components/AppState'
import Box from '@material-ui/core/Box'
import Divider from '../../../../components/Divider'
import ErrorSnackbar from '../../../../components/ErrorSnackbar'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import useForm from '../../../../hooks/useForm'
import {useRouter, useUser} from '../../../../hooks'
import Checkbox from '../../../../components/form/Checkbox'
import {ME} from '../../../gql'
import {useMutation} from 'react-apollo'
import ScrollToTop from '../../../../components/ScrollToTop'
import TermsConditions from './TermsConditions'
import FormControls from "../../../../components/form/FormControls";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: 220,
  },
  marginBottom4: {
    marginBottom: theme.spacing(4),
  },
  marginTop3: {
    marginTop: theme.spacing(3),
  },
  backBtnContanier: {
    textAlign: 'right',
    color: '#898989',
  },
  label: {
    fontWeight: 'bold',
  },
  iconContainer: {
    fontSize: '12rem',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}))

interface IProps {
  nextStep: () => void
  prevStep: () => void
  data?: ICustomerData
  customer:
    | ICustomerData['customer']
    | createNewCustomer['createNewCustomer']
    | undefined
    | null
}

const width = 896

const StepFour: React.FC<IProps> = (props) => {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const { user, refetch: refetchUser } = useUser()
  const { history } = useRouter()

  const isNewCustomer = user.isSuperadmin()
  const createdBySuperadmin =
    props.data &&
    props.data.customer &&
    props.data.customer.createdBy &&
    props.data.customer.createdBy.role === 'SUPERADMIN'

  const [termsConditions, setTermsConditions] = React.useState<boolean>(false)

  const [finishRegistration] = useMutation<IFinishCustomerRegistrationData>(
    FINISH_CUSTOMER_REGISTRATION,
    {
      ...(isNewCustomer
        ? {
            variables: {
              where: {
                customerId: (props.customer && props.customer.id) || '',
              },
            },
          }
        : undefined),
      onCompleted: async () => {
        if(!isNewCustomer) {
          await refetchUser()
        }
      }
    },
  )

  const { bind, form } = useForm<ICustomerUpdateVariables['data']>(
    {},
    {
      vopAgreementAt: {
        label: '-',
        rule: () => (!isNewCustomer ? 'required|date' : ''),
        omitOnSubmit: isNewCustomer,
      },
    },
    {
      async onSubmit(formData, form) {
        try {
          if (!isNewCustomer) {
            const { data } = await finishRegistration({
              variables: {
                data: {
                  ...formData,
                },
              },
            })
            if (data && data.finishCustomerRegistration) {
              form.onSuccess(data.finishCustomerRegistration)
            }
          } else {
            const { data } = await finishRegistration()
            if (data && data.finishCustomerRegistration) {
              form.onSuccess(data.finishCustomerRegistration)
            }
            history.push(`/${user.data.id}/superadmin/customers`)
          }
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  return (
    <Observer>
      {() => (
        <Box maxWidth="100%" width={width}>
          <AppState loading={form.state.loading} />
          <ScrollToTop />

          <Typography
            align="center"
            variant="h1"
            color="primary"
            className={classes.marginTop3}
          >
            {createdBySuperadmin
              ? t('customerActivation.step4Finishing')
              : t('customerActivation.step4Heading')}
          </Typography>

          <Box
            display="flex"
            justifyContent="center"
            className={classes.iconContainer}
          >
            <IlustraceDokonceni fontSize="inherit" />
          </Box>

          <Typography
            gutterBottom
            align="center"
            className={classes.marginBottom4}
          >
            <Trans i18nKey="customerActivation.step4ContentKeeper">
              Tlačítkem
              <Green>Dokončit registraci</Green>
              potvrdíte vyplněné údaje a nastavení při registraci. Veškeré
              vyplněné údaje budete mít možnost v<Green>
                Payminatorovi
              </Green>{' '}
              kdykoliv změnit.
            </Trans>
          </Typography>

          {!isNewCustomer && (
            <Box display="flex" justifyContent="center">
              <Observer>
                {() => {
                  const { error, ...rest } = bind('vopAgreementAt')
                  return (
                    <Checkbox
                      {...rest}
                      timestamp
                      error={error ? t('entryform.gdprAgreementError') : ''}
                      label={
                        <Trans i18nKey="common.vopAgreementAt">
                          Souhlasím s{' '}
                          <Green
                            onClick={(e) => {
                              e.preventDefault()
                              setTermsConditions(true)
                            }}
                            style={{ textDecoration: 'underline' }}
                          >
                            Veřejnými obchodními podmínkami{' '}
                          </Green>
                          aplikace <Green>PAYMINATOR</Green>
                        </Trans>
                      }
                    />
                  )
                }}
              </Observer>
            </Box>
          )}

          <Divider spacing={6} />
          <FormControls
            onSubmit={form.submit}
            onBack={props.prevStep}
            submitContent={t('common.finishRegistration')}
          />
          <ErrorSnackbar
            errors={form.state.errors}
            onClose={() => (form.state.errors = [])}
          />
          <TermsConditions
            open={termsConditions}
            onClose={() => setTermsConditions(false)}
          />
        </Box>
      )}
    </Observer>
  )
}

export default StepFour
