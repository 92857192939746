import {gql} from 'apollo-boost'
import {NexusGenFieldTypes} from 'kubik-server'

export interface IOwnMonthlyRebateData {
  user: {
    monthlyRebate: NexusGenFieldTypes['MonthlyRebate']
  }
}
export const OWN_MONTHLY_REBATE = gql`
  query userWithMonthlyRebate {
    user {
      id
      monthlyRebate {
        id
        year
        allowSendAnythingOnce
        wizardStep
        wizardFinishedAt
        basicRebate {
            id
            status
        }
        basicRebates(orderBy: createdAt_DESC) {
          id
          status
          type
          createdAt
          closePeriodAt
          applyFrom
        }
        studentRebate {
            id
            status
        }
        studentRebates(orderBy: createdAt_DESC) {
          id
          status
          type
          createdAt
          closePeriodAt
          confirmationValidityTo
        }
        childrenRebate {
            id
            status
        }
        childrenRebates(orderBy: createdAt_DESC) {
          id
          status
          type
          createdAt
          otherParentStatus
          children {
            id
            applyInMonths
            status
            type
          }
        }
        disabilityRebate {
            id
            status
        }
        disabilityRebates(orderBy: createdAt_DESC) {
          id
          status
          type
          createdAt
          closePeriodAt
          confirmationValidityTo
        }
        ztppRebate {
            id
            status
        }
        ztppRebates(orderBy: createdAt_DESC) {
          id
          status
          type
          createdAt
          closePeriodAt
          confirmationValidityTo
        }
        taxQuestionnaire {
          id
          status
        }
      }
    }
  }
`
