import {gql} from 'apollo-boost'

export const UPDATE_ANNUAL_WIZARD_STEP = gql`
  mutation updateAnnualRebateWizardStep($wizardStep: AnnualWizardStep!) {
    updateAnnualRebateWizardStep(wizardStep: $wizardStep) {
      id
      wizardStep
    }
  }
`
