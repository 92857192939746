import {
  IOwnMonthlyHistoryStudentRebateData,
  IResetOwnMonthlyHistoryStudentRebateData,
  IUpdateOwnMonthlyHistoryStudentRebateData,
  OWN_MONTHLY_HISTORY_STUDENT_REBATE_STUDENT,
  RESET_OWN_MONTHLY_HISTORY_STUDENT_REBATE,
  TUpdateOwnMonthlyHistoryStudentRebateVariables,
  UPDATE_OWN_MONTHLY_HISTORY_STUDENT_REBATE_STUDENT,
} from './gql'
import {Center, Divider,} from '../../../../../../components/CommonBox'
import ChangeRebateButton, {ChangeRebateChoice,} from '../../../AnnualRebates/components/ChangeRebateButton'
import {pickId, yearRange} from '../../../../../../utils'
import {FormProvider} from '../../../../../../hooks/useForm'
import {getPrevRebates, getRebateStats} from '../../utils'
import {Observer} from 'mobx-react'
import {TStudentRebate} from '../../../../../../fragments/StudentRebate'
import {useForm, useRouter, useUser} from '../../../../../../hooks'
import {useMutation, useQuery} from 'react-apollo'
import {Trans, useTranslation} from 'react-i18next'
import AppState from '../../../../../../components/AppState'
import Box from '@material-ui/core/Box'
import CancelRebateDialog from '../../../../../../components/CancelRebateDialog'
import CancelRebateSection from '../../../AnnualRebates/components/CancelRebateSection'
import Fade from '../../../../../../components/Fade'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import moment from 'moment'
import React from 'react'
import RebateActions from '../../../AnnualRebates/components/RebateActions'
import RebateLayout from '../../../../../../components/layouts/RebateLayout'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import Student from '../../../../../../components/icons/Student'
import StudentRebateOverview from '../../../AnnualRebates/components/StudentRebateOverview'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import PrevRebate from '../../../AnnualRebates/components/PrevRebate'
import StudentWithPeriodsFormSection from '../../../../../../shared/StudentWithPeriodsFormSection'

const StudentRebate: React.FC = () => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { history } = useRouter()

  const [updateRebate, { loading: updateLoading, error: updateError }] =
    useMutation<
      IUpdateOwnMonthlyHistoryStudentRebateData,
      TUpdateOwnMonthlyHistoryStudentRebateVariables
    >(UPDATE_OWN_MONTHLY_HISTORY_STUDENT_REBATE_STUDENT)

  const [resetRebate, { loading: resetLoading, error: resetError }] =
    useMutation<IResetOwnMonthlyHistoryStudentRebateData>(
      RESET_OWN_MONTHLY_HISTORY_STUDENT_REBATE,
    )

  const { data, loading, error } =
    useQuery<IOwnMonthlyHistoryStudentRebateData>(
      OWN_MONTHLY_HISTORY_STUDENT_REBATE_STUDENT,
      { fetchPolicy: 'network-only' },
    )
  const year = get(data, 'user.monthlyHistory.year')
  const [yearStart, yearEnd, prevYearStart, nextYearEnd] = yearRange(year)
  const formData = get(data, 'user.monthlyHistory.studentRebate') || {}

  const { bind, form } = useForm<
    TUpdateOwnMonthlyHistoryStudentRebateVariables['data']
  >(
    formData,
    {
      prevYearPeriods: {
        type: 'multi_relation',
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
      },
      confirmationDoctoralStudies: {
        label: t('monthlyStudentRebate.doctoralStudies'),
        //help: t('monthlyStudentRebate.doctoralStudiesHelp'),
      },
      studentConfirmationFiles: {
        type: 'multi_relation',
        label: t('common.studentConfirmation'),
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
        isFileRelation: true,
      },
    },
    {
      async onSubmit(data, form) {
        try {
          await updateRebate({
            variables: { data: { ...data, status: 'APPLY' } },
          })
          history.push(`/${user.data.id}/monthly-history`)
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  const prevRebates = getPrevRebates<TStudentRebate>(
    get(data, 'user.monthlyHistory.studentRebates'),
  )

  const {
    isFirstRequest,
    isPrevDenied,
    showingCommentOfDenied,
    isCancelable,
    isPrevRemoved,
  } = getRebateStats<TStudentRebate>(
    get(data, 'user.monthlyHistory.studentRebates'),
  )

  const validatePrevYearPeriods = () => {
    const doc: boolean = form.getValue('confirmationDoctoralStudies')
    const prevYearPeriods: { from: any; to: any }[] =
      form.getValue('prevYearPeriods')
    const ageLimit = moment(user.data.birthdate)
      .add(doc ? 28 : 26, 'year')
      .endOf('month')
    return prevYearPeriods.every(
      (pp) => moment(pp.to).isValid() && moment(pp.to).isSameOrBefore(ageLimit),
    )
  }

  const cleanRebate = async () => {
    if (formData) {
      const studentConfirmationFiles = formData.studentConfirmationFiles || []
      const prevYearPeriods = formData.prevYearPeriods || []
      await updateRebate({
        variables: {
          data: {
            status: 'NEW',
            type: 'NEW',
            prevYearPeriods: {
              delete: prevYearPeriods.map(pickId),
            },
            studentConfirmationFiles: {
              delete: studentConfirmationFiles.map(pickId),
            },
          },
        },
      })
    }
  }

  const onCancelRebate = async () => {
    if (formData) {
      const type = form.getValue('type')
      const alreadyRemoved = type === 'REMOVE'

      if (isPrevDenied && !alreadyRemoved) {
        await updateRebate({
          variables: {
            data: {
              status: 'APPLY',
              type: 'REMOVE',
            },
          },
        })
      } else {
        await cleanRebate()
      }
      history.push(`/${user.data.id}/monthly-history/root`)
    }
  }

  const handleAlter = async () => {
    await resetRebate()
    form.setField('type', 'ALTER')
    form.setField('status', 'APPLY')
  }
  const handleRemove = async () => {
    await cleanRebate()
    form.setField('type', 'REMOVE')
    form.setField('status', 'APPLY')
  }

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <AppState loading={loading || updateLoading || resetLoading} />
      <GraphQLErrorSnackbar error={error || updateError || resetError} />

      {data && (
        <Fade>
          <RebateLayout
            commentOfDenied={showingCommentOfDenied}
            sideHint={<Trans i18nKey="monthlyStudentRebate.sideHint">
              <strong>Slevu na studenta</strong> můžete uplatňovat <strong>měsíčně</strong> nebo <strong>jednou ročně v rámci ročního zúčtování</strong> (formulář Roční zúčtování > krok Měsíční slevy dodatečné uplatnění). Daňová sleva je ve výši <strong>335 Kč měsíčně</strong> neboli <strong>4 020 Kč ročně</strong>.
            </Trans>}
            upperHint={`${t('common.monthlyRebates')} ${year} (${String(
              t('common.history'),
            ).toLowerCase()})`}
            heading={t('monthlyStudentRebate.heading')}
            icon={<Student fontSize="large" />}
          >
            {isCancelable && (
              <Box display="flex" justifyContent="center" mb={4}>
                <CancelRebateDialog onSubmit={onCancelRebate} />
              </Box>
            )}

            <Observer>
              {() => {
                const type = form.getValue('type')
                const status = form.getValue('status')
                const doc: boolean = form.getValue(
                  'confirmationDoctoralStudies',
                )
                const ageLimit = moment(user.data.birthdate)
                  .add(doc ? 28 : 26, 'year')
                  .endOf('month')
                  .toDate()
                const doctoralIsValid = validatePrevYearPeriods()

                if (type === 'REMOVE') {
                  return (
                    <CancelRebateSection
                      onUndo={() => form.setField('type', 'NEW')}
                    >
                      {t('rebateChanges.cancelStudent')}
                    </CancelRebateSection>
                  )
                }

                if (
                  isFirstRequest ||
                  isPrevDenied ||
                  isPrevRemoved ||
                  status === 'DENIED' ||
                  type === 'ALTER'
                ) {
                  return (
                    <FormProvider form={form}>
                      <StudentWithPeriodsFormSection
                        bind={bind}
                        doctoralIsValid={doctoralIsValid}
                        dateLimiters={{
                          fromMinDates: [
                            { date: prevYearStart },
                            {
                              date: user.data.dateOfEmployment,
                              message: t('error.minDateOfEmploment'),
                            },
                          ],
                          toMinDates: [
                            {
                              date: yearStart,
                              message: t('error.minDateMonthlyYearStart'),
                            },
                            {
                              date: user.data.dateOfEmployment,
                              message: t('error.minDateOfEmploment'),
                            },
                          ],
                          fromMaxDates: [
                            {
                              date: yearEnd,
                              message: t('error.maxDateMonthlyYearEnd'),
                            },
                            {
                              date: ageLimit,
                              message: t(
                                doc
                                  ? 'error.minDateAgeDocLimit'
                                  : 'error.minDateAgeLimit',
                              ),
                            },
                          ],
                          toMaxDates: [
                            {
                              date: nextYearEnd,
                            },
                            {
                              date: ageLimit,
                              message: t(
                                doc
                                  ? 'error.minDateAgeDocLimit'
                                  : 'error.minDateAgeLimit',
                              ),
                            },
                          ],
                        }}
                      />
                    </FormProvider>
                  )
                }

                return (
                  <Center maxWidth="100%" width={680}>
                    <ChangeRebateButton>
                      <ChangeRebateChoice
                        variant="ALTER"
                        onClick={handleAlter}
                      />
                      <ChangeRebateChoice
                        variant="REMOVE"
                        onClick={handleRemove}
                      />
                    </ChangeRebateButton>
                  </Center>
                )
              }}
            </Observer>

            <Observer>
              {() => {
                const type = form.getValue('type')
                const status = form.getValue('status')
                const showFormSection =
                  isFirstRequest ||
                  isPrevDenied ||
                  isPrevRemoved ||
                  status === 'DENIED' ||
                  type === 'ALTER'
                return (
                  <Box maxWidth="100%" width={680} mt={3}>
                    <ArchiveChanges expand={!showFormSection}>
                      {prevRebates.map((rebate) => (
                        <PrevRebate
                          key={rebate.id}
                          settlementRequest={rebate.settlementRequest}
                          status={rebate.status}
                          commentOfDenied={rebate.commentOfDenied}
                        >
                          <StudentRebateOverview data={rebate} />
                        </PrevRebate>
                      ))}
                    </ArchiveChanges>
                  </Box>
                )
              }}
            </Observer>

            <Divider my={6} />

            <Observer>
              {() => (
                <RebateActions
                  backTo={`/${user.data.id}/monthly-history`}
                  onSubmit={() => form.submit()}
                  isDirty={form.state.isDirty}
                  formLoading={form.state.loading}
                />
              )}
            </Observer>
          </RebateLayout>
        </Fade>
      )}
    </InsideLayout>
  )
}

export default StudentRebate
