import {gql} from 'apollo-boost'
import {NexusGenArgTypes, NexusGenFieldTypes} from 'kubik-server'
import {INVESTMENT_REBATE_FULL_FRAGMENT} from '../../../../../../fragments/InvestmentRebate'
import {LONGTERMCARE_REBATE_FULL_FRAGMENT} from "../../../../../../fragments/LongTermCareRebate";
import {PENSION_INSURANCE_REBATE_FULL_FRAGMENT} from "../../../../../../fragments/PensionInsuranceRebate";
import {LIFE_INSURANCE_REBATE_FULL_FRAGMENT} from "../../../../../../fragments/LifeInsuranceRebate";

export type TInvestmentRebate = NexusGenFieldTypes['InvestmentRebate']

export interface IOwnAnnualInvestmentRebateData {
  user: {
    annualRebate: NexusGenFieldTypes['AnnualRebate'] & {
      investmentRebate: TInvestmentRebate
      investmentRebates: TInvestmentRebate[]
    }
  }
}
export const OWN_ANNUAL_INVESTMENT_REBATE = gql`
  query ownAnnualInvestmentRebate {
    user {
      id
      annualRebate {
        id
        year
        investmentRebate {
            ...InvestmentRebateFull
        }
        investmentRebates(orderBy: createdAt_DESC) {
            ...InvestmentRebateFull
        }
        pensionInsuranceRebate {
            ...PensionInsuranceRebateFull
        }
        pensionInsuranceRebates(orderBy: createdAt_DESC) {
            ...PensionInsuranceRebateFull
        }
        lifeInsuranceRebate {
            ...LifeInsuranceRebateFull
        }
        lifeInsuranceRebates(orderBy: createdAt_DESC) {
            ...LifeInsuranceRebateFull
        }
        longTermCareRebate {
            ...LongTermCareRebateFull
        }
        longTermCareRebates(orderBy: createdAt_DESC) {
            ...LongTermCareRebateFull
        }
        limits {
            pensionShared {
                max
            }
        }
      }
    }
  }
  ${INVESTMENT_REBATE_FULL_FRAGMENT}
  ${LONGTERMCARE_REBATE_FULL_FRAGMENT}
  ${PENSION_INSURANCE_REBATE_FULL_FRAGMENT}
  ${LIFE_INSURANCE_REBATE_FULL_FRAGMENT}
`

export interface IUpdateOwnAnnualInvestmentRebateData {
  updateOwnAnnualInvestmentRebate: NexusGenFieldTypes['InvestmentRebate']
}
export type TUpdateOwnAnnualInvestmentRebateVariables = NexusGenArgTypes['Mutation']['updateOwnAnnualInvestmentRebate']
export const UPDATE_OWN_ANNUAL_INVESTMENT_REBATE = gql`
  mutation updateOwnAnnualInvestmentRebate($data: OwnInvestmentRebateUpdateInput!) {
    updateOwnAnnualInvestmentRebate(data: $data) {
      ...InvestmentRebateFull
    }
  }
  ${INVESTMENT_REBATE_FULL_FRAGMENT}
`

export interface IResetOwnAnnualInvestmentData {
  resetOwnAnnualInvestmentRebate: TInvestmentRebate
}
export const RESET_OWN_ANNUAL_INVESTMENT_REBATE = gql`
  mutation resetOwnAnnualInvestmentRebate {
    resetOwnAnnualInvestmentRebate {
      ...InvestmentRebateFull
    }
  }
  ${INVESTMENT_REBATE_FULL_FRAGMENT}
`
