import { gql } from 'apollo-boost'
import { NexusGenFieldTypes } from 'kubik-server'

export type TLoanRebate = NexusGenFieldTypes['LoanRebate']

export const LOAN_REBATE_FULL_FRAGMENT = gql`
  fragment LoanRebateFull on LoanRebate {
    id
    createdAt
    status
    type
    commentOfDenied
    interestAmount
    applyInMonths
    addresses
    otherPersonApplying
    otherPersonFullname
    otherPersonNationalIDNumber
    otherAddress
    purchaseDate
    interestAmountConfirmationFiles {
      id
      filename
      size
      createdAt
    }
    loanContractFiles {
      id
      filename
      size
      createdAt
    }
    propertyConfirmationFiles {
      id
      filename
      size
      createdAt
    }
    startConfirmationFiles {
      id
      filename
      size
      createdAt
    }
    settlementRequest {
      id
      requestedAt
      submittedAt
    }
  }
`
