import {gql} from 'apollo-boost'
import {NexusGenArgTypes, NexusGenFieldTypes} from 'kubik-server'
import {TZtppRebate, ZTPP_REBATE_FULL_FRAGMENT,} from '../../../../../../fragments/ZtppRebate'

export interface IOwnMonthlyZtppRebateData {
  user: {
    monthlyRebate: NexusGenFieldTypes['MonthlyRebate'] & {
      ztppRebate: TZtppRebate
      ztppRebates: TZtppRebate[]
    }
  }
}
export const OWN_MONTHLY_DISABILITY_REBATE = gql`
  query ownMonthlyZtppRebate {
    user {
      id
      monthlyRebate {
        id
        year
        ztppRebate {
          ...ZtppRebateFull
        }
        ztppRebates {
          ...ZtppRebateFull
        }
      }
    }
  }
  ${ZTPP_REBATE_FULL_FRAGMENT}
`

export interface IUpdateOwnMonthlyZtppRebateData {
  updateOwnMonthlyZtppRebate: NexusGenFieldTypes['ZtppRebate']
}
export type TUpdateOwnMonthlyZtppRebateVariables = NexusGenArgTypes['Mutation']['updateOwnMonthlyZtppRebate']
export const UPDATE_OWN_MONTHLY_DISABILITY_REBATE = gql`
  mutation updateOwnMonthlyZtppRebate($data: OwnZtppMonthlyRebateUpdateInput!) {
    updateOwnMonthlyZtppRebate(data: $data) {
      ...ZtppRebateFull
    }
  }
  ${ZTPP_REBATE_FULL_FRAGMENT}
`

export interface IResetOwnMonthlyZtppRebateData {
  resetOwnMonthlyZtppRebate: TZtppRebate
}
export const RESET_OWN_MONTHLY_ZTPP_REBATE = gql`
  mutation resetOwnMonthlyZtppRebate {
    resetOwnMonthlyZtppRebate {
      ...ZtppRebateFull
    }
  }
  ${ZTPP_REBATE_FULL_FRAGMENT}
`
