import {EMPLOYEES, TEmployeesData, TEmployeesVariables, TEmployeeType,} from './gql'
import {PridatWhite, Smazat2Grey, Upravit, Zablokovat, Zpravy,} from '../../../../components/icons/KubikIcons'
import {IDialog} from './index'
import {makeStyles, Theme, useTheme} from '@material-ui/core/styles'
import {useTranslation} from 'react-i18next'
import {useUserStatusEnum} from '../../../../hooks/useEnumList'
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import classnames from 'classnames'
import Datatable, {DatatableAction} from '../../../../components/Datatable'
import get from 'lodash/get'
import moment from 'moment'
import React from 'react'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import ScrollToTop from '../../../../components/ScrollToTop'
import Tabs, {Tab} from '../../../../components/Tabs'
import Typography from '@material-ui/core/Typography'
import useDatatableVariables from '../../../../hooks/useDatatableVariables'
import useUser from '../../../../hooks/useUser'
import useMediaQuery from '@material-ui/core/useMediaQuery'

interface IStylesProps {
  compactScreen?: boolean
  isNotSmallScreen?: boolean
}

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) => {
  return {
    actionIcon: {
      padding: theme.spacing(0.6),
      fontSize: '1.2rem',
      color: '#8a8a8a',
      fill: '#898989',
    },
    avatar: {
      backgroundColor: '#EEEEEE',
      color: theme.palette.common.black,
      fontWeight: 'bold',
      fontSize: '0.95rem',
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    invited: {
      color: '#F44336',
    },
    outlinedButton: {
      borderRadius: '28px !important',
      backgroundColor: 'transparent',
      fontWeight: 'bold',
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      borderWidth: '2px !important',
      '&:hover': {
        color: '#FFFFFF',
        backgroundColor: '#4EBE4E',
      },
    },
    responsiveButton: {
      minHeight: (props) => (props.compactScreen ? '49px' : undefined),
      minWidth: (props) => (props.compactScreen ? '100%' : undefined),
    },
    gridGap: {
      gridGap: (props) => (props.compactScreen ? theme.spacing(2) : 0),
    },
  }
})

const defaultVariables: TEmployeesVariables = {
  skip: 0,
  first: 10,
  type: 'ACTIVE',
}

interface IProps {
  setDialog: (args: IDialog) => void
  handleDialogClose: () => void
  globalKey: string
}

const Employees: React.FC<IProps> = (props) => {
  const { user } = useUser()
  const { setDialog, globalKey: GLOBAL_UNIQUE_KEY } = props

  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const classes = useStyles({ compactScreen, isNotSmallScreen })

  const [datatableVariables, setDatatableVariables] = useDatatableVariables(
    GLOBAL_UNIQUE_KEY,
    defaultVariables,
  )

  const { t } = useTranslation()
  const handleTabChange = (type: TEmployeeType) => {
    setDatatableVariables(
      () => ({
        ...defaultVariables,
        type,
      }),
      { resetSearch: true },
    )
  }
  const { translate } = useUserStatusEnum()

  const type: TEmployeeType = get(datatableVariables, 'type')

  return (
    <Box
      maxWidth="100%"
      width={1077}
      ml="auto"
      mr="auto"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <ScrollToTop />
      <Typography variant="h1">{t('common.employees')}</Typography>
      <br />
      <Tabs
        onChange={handleTabChange}
        value={type}
        boxProps={{ mb: 2, width: '100%', justifyContent: 'center' }}
      >
        <Tab label={t('employees.active')} value="ACTIVE" />
        <Tab label={t('employees.blocked')} value="BLOCKED" color="#F44336" />
      </Tabs>
      <Datatable<TEmployeesData['employees']['items'], TEmployeesVariables>
        globalKey={GLOBAL_UNIQUE_KEY}
        query={{
          query: EMPLOYEES,
          dataField: 'employees',
          options: {
            fetchPolicy: 'cache-and-network',
          },
        }}
        onRowClick={(row) => setDialog({ action: 'CHOICE', employee: row })}
        disableEven
        columns={[
          {
            key: 'inicials',
            shrink: true,
            align: 'center',
            render(row) {
              return <Avatar className={classes.avatar}>{row.initials}</Avatar>
            },
          },
          {
            key: 'user',
            label: t('common.userName'),
            render(row) {
              return (
                <Box
                  component="span"
                  whiteSpace="pre-line"
                  minHeight={40}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Box fontWeight="bold">{row.fullname}</Box>
                  {row.personalNumber && (
                    <Box color="#8c8c8c">@{row.personalNumber}</Box>
                  )}
                </Box>
              )
            },
          },
          {
            key: 'state',
            label: t('common.state'),
            shrink: true,
            render(row) {
              return (
                <Box
                  className={classnames({
                    [classes.invited]: row.state === 'INVITED',
                  })}
                >
                  {translate(row.state)}
                </Box>
              )
            },
          },
          {
            key: 'dateOfEmployment',
            label: t('employees.dateOfEmployment'),
            shrink: true,
            render(row) {
              return row.dateOfEmployment
                ? moment(row.dateOfEmployment).format('DD.MM.YYYY')
                : 'DD.MM.YYYY'
            },
          },
          {
            key: 'actions',
            align: 'right',
            shrink: true,
            render(row) {
              return (
                <Box
                  display="flex"
                  alignItems="normal"
                  justifyContent="flex-end"
                >
                  {row.state === 'INVITED' && (
                    <DatatableAction
                      tooltip={t('employee.reinviteButton')}
                      onClick={() =>
                        setDialog({ action: 'INVITE', employee: row })
                      }
                      className={classes.actionIcon}
                    >
                      <Zpravy fontSize="inherit" color="inherit" />
                    </DatatableAction>
                  )}
                  <DatatableAction
                    tooltip={t('common.edit')}
                    onClick={() =>
                      setDialog({ action: 'UPDATE', employee: row })
                    }
                    className={classes.actionIcon}
                  >
                    <Upravit fontSize="inherit" color="inherit" />
                  </DatatableAction>
                  <DatatableAction
                    tooltip={t(
                      row.state !== 'BLOCKED'
                        ? 'common.block'
                        : 'common.unblock',
                    )}
                    onClick={() =>
                      setDialog({ action: 'BLOCK', employee: row })
                    }
                    className={classes.actionIcon}
                  >
                    <Zablokovat fontSize="inherit" color="inherit" />
                  </DatatableAction>
                  {row.state === 'BLOCKED' && (
                    <DatatableAction
                      tooltip={t('employee.annualOverride', {
                        context: !!row.blockedAnnualOverride
                          ? 'UNBLOCK'
                          : 'BLOCK',
                      })}
                      onClick={() =>
                        setDialog({
                          action: 'BLOCK_ANNUAL_OVERRIDE',
                          employee: row,
                        })
                      }
                      style={{ padding: '3px' }}
                      // disabled={
                      //   !!!row.blockedAnnualOverride &&
                      //   !(
                      //     !!row.dateOfQuit &&
                      //     moment(row.dateOfQuit).year() >=
                      //       user.data.customer.yearOfAnnualRebates
                      //   )
                      // }
                    >
                      {!!row.blockedAnnualOverride ? (
                        <RemoveCircleIcon />
                      ) : (
                        <RemoveCircleOutlineIcon />
                      )}
                    </DatatableAction>
                  )}
                  <DatatableAction
                    tooltip={t('common.remove')}
                    onClick={() =>
                      setDialog({ action: 'DELETE', employee: row })
                    }
                    className={classes.actionIcon}
                    style={{ fontSize: '1rem' }}
                  >
                    <Smazat2Grey fontSize="inherit" />
                  </DatatableAction>
                </Box>
              )
            },
          },
        ]}
        renderToolbar={(elems) => (
          <Box
            display="flex"
            flexDirection={compactScreen ? 'column' : 'row'}
            justifyContent="space-between"
            mb={2}
            className={classnames(classes.gridGap)}
            id="toolbar-wrapper"
          >
            <Box
              display="flex"
              flexDirection={compactScreen ? 'column' : 'row'}
              maxWidth="100%"
              className={classnames(classes.gridGap)}
              id="toolbar-container"
            >
              <Button
                color="primary"
                variant="contained"
                onClick={() => setDialog({ action: 'CREATE', employee: null })}
                //className={classnames(classes.responsiveButton)}
              >
                <PridatWhite fontSize="inherit" />
                &nbsp;
                {t('employee.addEmployee')}
              </Button>
            </Box>
            <Box
              display="flex"
              justifyContent="flex-start"
              maxWidth="100%"
              ml={compactScreen ? undefined : 1}
              mr={compactScreen ? undefined : 1}
              minWidth={240}
            >
              {elems.orderByElement}
            </Box>
            <Box
                display="flex"
                justifyContent="end"
                alignItems="center"
                maxWidth="100%"
                ml={compactScreen ? undefined : 1}
                mr={compactScreen ? undefined : 1}

                style={{flex: '1 0'}}
            >
              <DatatableAction
                  tooltip={t('employee.reinviteButtonAll')}
                  onClick={() => {
                      setDialog({ action: 'INVITE_ALL', employee: null })
                  }}
                  className={classes.actionIcon}
                  style={{ fontSize: '1rem', width: '2rem', height: '2rem' }}
              >
                <Zpravy fontSize="inherit" color="inherit" />
              </DatatableAction>
            </Box>
            <Box
              display="flex"
              flex="1"
              justifyContent="flex-end"
              maxWidth="100%"
            >
              {elems.searchElement}
            </Box>
          </Box>
        )}
        orderByList={[
          { value: 'lastname_ASC', text: t('employees.lastname_ASC') },
          { value: 'lastname_DESC', text: t('employees.lastname_DESC') },
          {
            value: 'dateOfEmployment_ASC',
            text: t('employees.dateOfEmployment_ASC'),
          },
          {
            value: 'dateOfEmployment_DESC',
            text: t('employees.dateOfEmployment_DESC'),
          },
        ]}
        searchFields={['search_contains']}
      />
    </Box>
  )
}

export default Employees
