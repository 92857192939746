import { ApolloError } from 'apollo-boost'
import { Box } from '@material-ui/core'
import { Green } from '../../../components/CommonBox'
import {
  IRegisterData,
  IRegisterVariables,
  IRegistrationTokenData,
  REGISTER,
  REGISTRATION_TOKEN,
} from '../gql'
import { Link } from 'react-router-dom'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import { Observer } from 'mobx-react'
import { PokracovatBtn } from '../../../components/icons/KubikIcons'
import { useQuery, useMutation } from 'react-apollo'
import { useTranslation, Trans } from 'react-i18next'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import ApolloErrors from '../../../components/ApolloErrors'
import ArrowIcon from '@material-ui/icons/PlayCircleFilled'
import Button from '@material-ui/core/Button'
import classnames from 'classnames'
import Divider from '../../../components/Divider'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../components/GraphQLErrorSnackbar'
import ErrorSnackbar from '../../../components/ErrorSnackbar'
import CheckIcon from '@material-ui/icons/CheckCircle'
import InfoIcon from '@material-ui/icons/Info'
import logoPng from '../../../assets/logo.png'
import ProgressContainer from '../../../components/ProgressContainer'
import React, { useEffect, useState } from 'react'
import Select from '../../../components/form/Select'
import TextField from '../../../components/form/TextField'
import Typography from '@material-ui/core/Typography'
import useAccount from '../../../hooks/useAccount'
import useForm from '../../../hooks/useForm'
import useGlobalState from '../../../hooks/useGlobalState'
import InfoBox from "../../../components/InfoBox";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import prihlaseniLeft from "../../../assets/images/prihlaseni_left.png";
// import prihlaseniRight from "../../../assets/images/prihlaseni_right.png";
import prihlaseniLeft from '../../../assets/images/Ilustrace-mzdova-ucetni.svg'
import prihlaseniRight from '../../../assets/images/Ilustrace-zamestnanec.svg'
import BoxBackground from "../../../components/BoxBackground";
import { msalConfig } from './components/ssoAuthConfig'
import {
  PublicClientApplication,
  AccountInfo,
  InteractionRequiredAuthError,
  AuthenticationResult,
} from '@azure/msal-browser'
import useUser from "../../../hooks/useUser";

interface IStylesProps {
  compactScreen?: boolean
  isNotSmallScreen?: boolean
}

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) => ({
  container: {
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.15)',
    zIndex: 1000,
    width: (props) => (props.compactScreen ? '100%' : 483),
  },
  passwordRule: {
    display: 'flex',
    transition: theme.transitions.create('color'),
    paddingLeft: theme.spacing(1),
    color: theme.palette.text.hint,
    '& > svg': {
      marginRight: theme.spacing(1),
    },
  },
  green: { color: theme.palette.primary.main },
  red: { color: theme.palette.error.main },
  logo: {
    marginBottom: theme.spacing(3),
  }
}))

interface Props extends RouteComponentProps<{ token: string }> {}

const Registration: React.FC<Props> = props => {

  const theme = useTheme()
  const smDown = theme.breakpoints.down('sm')
  const minSmall = theme.breakpoints.up('xs')
  const { t } = useTranslation()
  const token = props.match.params.token
  const { setLanguage } = useAccount(token)
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const isNotSmallScreen = useMediaQuery(minSmall)
  const classes = useStyles({ compactScreen, isNotSmallScreen })
  const { refetch: refetchAccount } = useAccount()

  const genderList = [
    {
      id: 'FEMALE',
      text: t('common.female'),
    },
    {
      id: 'MALE',
      text: t('common.male'),
    },
  ]

  // const [showPasswordValidation, setShowPasswordValidation] = useState(false)
  const [register, { error: registerError }] = useMutation<
    IRegisterData,
    IRegisterVariables
  >(REGISTER, {
    // refetchQueries: [{ query: ME }],
    errorPolicy: 'all',
    onCompleted: () => {
      refetchAccount()
    },
    onError() {},
  })
  const { data, error, loading } = useQuery<IRegistrationTokenData>(
    REGISTRATION_TOKEN,
    {
      variables: { token },
      onCompleted(data) {
        if (data.registrationToken.language) {
          setLanguage(data.registrationToken.language)
        }
      },
    },
  )

  const companyName = get(data, 'registrationToken.companyNames[0]') || ''
  // Jakub nechtěl aby se zobrazovalo více firem.

  const roles = get(data, 'registrationToken.roles') || []
  const email = get(data, 'registrationToken.email') || ''
  const isRole =
    roles.includes('ACCOUNTANT') ||
    roles.includes('VIEWER') ||
    roles.includes('IMPLEMENTATOR') ||
    roles.includes('KEEPER')
  //const isEmployee = roles.includes('EMPLOYEE')
  const firstname = get(data, 'registrationToken.firstname')
  const lastname = get(data, 'registrationToken.lastname')
  const gender = get(data, 'registrationToken.gender')
  const ssoLogin = get(data, 'registrationToken.ssoLogin')
  const ssoSettings = get(data, 'registrationToken.ssoSettings')
  const [ssoUserName, setSsoUserName] = useState<string>('')
  const [accessToken, setAccessToken] = useState<string | null>(null)
  const [ssoError, setSSOError] = useState<string | null>()

  const { setState } = useGlobalState()
  useEffect(() => {
    setState((state: any) => ({ ...state, lovers: companyName }))
  }, [companyName, setState])

  const callRegisterApi = async (
    reqToken: string,
    gender: string,
    accessToken: string | null,
  ) => {
    const headers = new Headers()
    const bearer = `Bearer ${accessToken}`

    headers.append('Authorization', bearer)
    headers.append('Content-Type', 'application/json')

    const options = {
      method: 'POST',
      headers: headers,
      credentials: 'include',
      body: JSON.stringify({
        reqToken,
        gender,
        email,
      }),
    }

    const apiEndpoint = process.env.REACT_APP_API_URL
      ? process.env.REACT_APP_API_URL + '/api/sso-registration'
      : 'http://localhost:4000/api/sso-registration'
    try {
      // server - autorizuje proti AD, ověří existenci uživatele
      // vystaví vlastní cookie a pošle zpět do browseru
      const response = await fetch(apiEndpoint, options)
      // or check for response.status
      if (!response.ok) {
        throw new Error(`${await response.text()}`)
      }

      // získali jsme cookie, takže můžeme znovu ověřit uživatele
      // a dostat jej do contextu aplikace
      window.location.assign('/')
    } catch (error) {
      setSSOError(error.message)
    }
  }

  useEffect(() => {
    const trySSO = async () => {
      if (ssoLogin && email) {
        const config = {
          ...msalConfig,
          auth: {
            ...msalConfig.auth,
            ...(process.env.REACT_APP_SSO_REDIRECT_URI && {
              redirectUri: process.env.REACT_APP_SSO_REDIRECT_URI,
            }),
            clientId: ssoSettings.ssoClientId,
            authority: `https://login.microsoftonline.com/${ssoSettings.ssoTenantId}`,
            scopes: [
              `api://${ssoSettings.ssoClientId}/${ssoSettings.ssoScopes}`,
            ],
          },
        }
        // get log into console
        console.log(config)
        try {
          const msalInstance = new PublicClientApplication(config)
          let homeAccountId = null
          const handleResponse = (resp: AuthenticationResult) => {
            if (resp && resp.account) {
              homeAccountId = resp.account.homeAccountId // alternatively: resp.account.homeAccountId or resp.account.username
            } else {
              const currentAccounts = msalInstance.getAllAccounts()
              //console.log(currentAccounts)
              if (currentAccounts.length < 1) {
                // No cached accounts
                return
              } else if (currentAccounts.length > 1) {
                // Multiple account scenario
                // Add account selection code here
                const [filterdAccount] = currentAccounts.filter(
                  (account) => account.username === email,
                )
                homeAccountId = filterdAccount.homeAccountId
              } else if (currentAccounts.length === 1) {
                homeAccountId = currentAccounts[0].homeAccountId // Single account scenario
              }
            }
          }

          const loginResponse = await msalInstance.loginPopup()
          handleResponse(loginResponse)

          if (!homeAccountId) {
            throw new Error(t('account.ssoNoUserFound', { email }))
          }

          const account: AccountInfo | null = msalInstance.getAccountByHomeId(
            homeAccountId,
          )

          if (account) {
            console.log(account)
            if (account.username.toLowerCase() !== email.toLowerCase()) {
              throw new Error(
                t('account.ssoAnotherUser', {
                  email,
                  username: account.username,
                }),
              )
            }
            setSsoUserName(account.name || '')

            try {
              const response = await msalInstance.acquireTokenSilent({
                scopes: config.auth.scopes,
                account: account,
              })
              setAccessToken(response.accessToken)
            } catch (error) {
              // in case if silent token acquisition fails, fallback to an interactive method
              if (error instanceof InteractionRequiredAuthError) {
                try {
                  const response = await msalInstance.acquireTokenPopup({
                    scopes: config.auth.scopes,
                  })
                  setAccessToken(response.accessToken)
                } catch (error) {
                  throw new Error(error.message)
                }
              }
            }
          }
        } catch (error) {
          setSSOError(error.message)
        }
      }
    }
    trySSO()
  }, [ssoLogin, ssoSettings, email])

  const { bind, form } = useForm<IRegisterVariables>(
    {
      firstname,
      lastname,
      gender,
      ssoUserName,
    },
    {
      email: {
        label: t('common.email'),
        placeholder: t('common.emailPlaceholder'),
      },
      ssoUserName: {
        label: t('common.ssoUserName'),
        placeholder: t('common.ssoUserName'),
      },
      firstname: {
        label: t('common.firstname'),
        placeholder: t('common.firstnamePlaceholder'),
        rule: isRole && !ssoLogin ? 'required' : '',
      },
      lastname: {
        label: t('common.lastname'),
        placeholder: t('common.lastnamePlaceholder'),
        rule: isRole && !ssoLogin ? 'required' : '',
      },
      gender: {
        label: t('common.gender'),
        placeholder: t('common.genderPlaceholder'),
        list: genderList,
      },
      password: {
        label: t('common.password'),
        placeholder: t('common.passwordPlaceholder'),
        rule: ssoLogin
          ? ''
          : [
              'required',
              'min:8',
              'regex:/[a-z]/',
              'regex:/[A-Z]/',
              'regex:/[0-9]/',
            ],
      },
      passwordAgain: {
        label: t('common.passwordAgain'),
        placeholder: t('common.passwordAgainPlaceholder'),
        rule: ssoLogin ? '' : 'required|same:password',
        help: t('newCompany.passwConfirmHelp'),
      },
    },
    {
      variables: { token, email },
      submitWholeModel: true,
      async onSubmit(data, form) {
        try {
          if (!ssoLogin) {
            const result = await register({
              variables: { ...data, ...form.options.variables },
            })
            if (
              result &&
              result.data &&
              result.data.register &&
              result.data.register.token
            ) {
              props.history.push({
                pathname: `/loginconfirm/${result.data.register.token}`,
                state: {
                  from: props.location.pathname,
                },
              })
            }

            return result.data
          }

          if (ssoLogin) {
            callRegisterApi(token, data.gender, accessToken)
          }
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  const decodeError = (
    error?: ApolloError | unknown,
  ): { error: string; message: string }[] => {
    if (error instanceof ApolloError) {
      const errors = error.graphQLErrors.map((err) => {
        const error = get(err, 'data.error')
        const message = get(err, 'message')
        return { error, message }
      })
      return errors
    }
    return []
  }

  return (
    <ProgressContainer loading={loading}>
      <GraphQLErrorSnackbar error={registerError} />
      {ssoError && <ErrorSnackbar errors={[ssoError]} onClose={() => {}} />}
      {ssoLogin ? (
        <Observer>
          {() => {
            return (
              <Box
                p={compactScreen ? 1 : 6}
                pt={2}
                width={compactScreen ? '100%' : 1060}
                maxWidth="100%"
              >
                <Typography align="center" variant="h1" gutterBottom>
                  {isRole
                    ? t('registration.roleHeading')
                    : t('registration.employeeHeading')}
                </Typography>

                <Box textAlign="center" fontFamily="fontFamily" mb={4}>
                  {isRole ? (
                    <Trans i18nKey="registration.roleSubHeadingSSO">
                      Společnost <Green>{{ companyName }}</Green>
                      Vás žádá o registraci do aplikace
                      <Green>PAYMINATOR</Green>. Před vstupem do aplikace
                      ověříme vaše doménové přihlašovací údaje. Dále budeme
                      potřebovat pár
                      <Green>osobních údajů</Green>
                    </Trans>
                  ) : (
                    <Trans i18nKey="registration.employeeSubHeadingSSO">
                      Společnost
                      <Green> {{ companyName }}</Green>
                      Vás žádá o registraci do aplikace
                      <Green>PAYMINATOR</Green>. Než budete moci vstoupit do
                      aplikace, tak je nutné vytvořit Váš účet a trochu lépe Vás
                      poznat.
                    </Trans>
                  )}
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  {!compactScreen && <Box width={267} p={2} m={4} />}
                  <Box width={compactScreen ? '100%' : 300}>
                    <TextField
                      fullWidth
                      gutterBottom
                      {...bind('email')}
                      disabled
                      value={email}
                    />
                    <TextField
                      fullWidth
                      gutterBottom
                      {...bind('ssoUserName')}
                      disabled
                      value={ssoUserName}
                    />

                    <Select
                      fullWidth
                      gutterBottom
                      showValidationIcon
                      hideEmptyValue
                      {...bind('gender')}
                    />
                  </Box>
                  {!compactScreen && (
                    <Box
                      width={267}
                      bgcolor="#D6EEFF"
                      p={2}
                      m={4}
                      mt="30px"
                      borderRadius={6}
                      fontFamily="fontFamily"
                    >
                      <Box>
                        <InfoIcon className={classes.helpIcon} />
                      </Box>
                      <Trans i18nKey="registration.payminatorDescription">
                        <Green>PAYMINATOR</Green> je webová aplikace, která
                        pomáhá zaměstnancům s uplatňováním daňových slev a
                        ročním zúčtováním daně. Slouží jako pomocný nástroj pro
                        usnadnění daňové administrativy. Payminator je Váš
                        daňový asistent.
                      </Trans>
                    </Box>
                  )}
                </Box>

                <Divider spacing={4} />

                <Box display="flex" justifyContent="center">
                  <Button
                    onClick={form.submit}
                    color="primary"
                    size="large"
                    variant="contained"
                    disabled={!accessToken}
                  >
                    {t('common.continue')}
                    <span
                      style={{
                        fontSize: '0.75rem',
                        marginLeft: '8px',
                        marginTop: '3px',
                      }}
                    >
                      <PokracovatBtn fontSize="inherit" />
                    </span>
                  </Button>
                </Box>

                <Box display="flex" justifyContent="center" />
                <Box textAlign="center" fontFamily="fontFamily" mt={2}>
                  <Trans i18nKey="common.needHelp">
                    Potřebujete pomoct?
                    <a
                      href="mailto:helpdesk@payminator.cz"
                      onClick={(e) => {
                        const smartsupp: (arg1?: any, arg2?: any) => void =
                          //@ts-ignore
                          window.smartsupp
                        if (smartsupp) {
                          e.preventDefault()
                          smartsupp('chat:open')
                        }
                      }}
                    >
                      {t('common.contactUs')}.
                    </a>
                  </Trans>
                </Box>
              </Box>
            )
          }}
        </Observer>
      ) : (
        <Observer>
          {() => {
            const password = form.getValue('password') || ''
            const passwordLength = password && password.length > 7
            const passwordHasDigit = (new RegExp('[0-9]', 'g')).test(password)
            const passwordHasLowerUpper =
              (new RegExp('[a-z]', 'g')).test(password) && (new RegExp('[A-Z]', 'g')).test(password) 
            const passwordTouched = form.touched.get('password')

          return (

              <BoxBackground left={prihlaseniLeft} right={prihlaseniRight} topBackgroundColor={'#ffffff'} bottomBackgroundColor={'#f8f8f8'}>
            <Box p={6} className={classes.container}>
              {error && (
                <Box display="flex" justifyContent="center" flexDirection="column">
                  <Box display={"flex"} justifyContent={"center"}><img
                      alt=""
                      className={classes.logo}
                      src={logoPng}
                      width={250}
                      height={48}
                  /></Box>
                    {decodeError(error).map((err, index) => {
                      if (err && err.error) {
                        switch (err.error) {
                          case 'accountAlreadyRegistered':
                            return (
                              <div key={'ClientError' + index}>
                                <Typography
                                  align="center"
                                  variant="h1"
                                  gutterBottom
                                >
                                  {t('registration.accountAlreadyRegistered')}
                                </Typography>
                                <Box
                                  textAlign="center"
                                  fontFamily="fontFamily"
                                  mb={4}
                                  whiteSpace="pre-line"
                                >
                                  <Trans i18nKey="registration.accountAlreadyRegisteredSub">
                                    Klikněte na tlačítko níže a přihlaste se
                                    standardní cestou do aplikace. V případě, že
                                    potřebujete dokončit registraci u nové
                                    pracovní role (Správce, Mzdová účetní,
                                    Nahlížite a Implementátor) či se
                                    registrujete jako nový zaměstnanec s již
                                    vytvořeným účtem, tak se standartně
                                    přihlaště do aplikace a vyberte požadovaný
                                    profil, který chcete registrovat.
                                  </Trans>
                                </Box>
                                <Box display="flex" justifyContent="center">
                                  <Button
                                    component={Link}
                                    to="/"
                                    size="large"
                                    color="primary"
                                    variant="contained"
                                  >
                                    {t('common.backToLogin')}
                                  </Button>
                                </Box>
                              </div>
                            )
                          case 'invalidRegistrationToken':
                            return (
                              <Typography
                                align="center"
                                variant="h1"
                                gutterBottom
                                key={'ClientError' + index}
                              >
                                {t('registration.invalidRegistrationToken')}
                              </Typography>
                            )
                          default:
                            return (
                              <Box
                                textAlign="center"
                                key={'ClientError' + index}
                              >
                                <ApolloErrors error={error} />
                              </Box>
                            )
                        }
                      } else {
                        return (
                          <Box textAlign="center" key={'ClientError' + index}>
                            <ApolloErrors error={error} />
                          </Box>
                        )
                      }
                    })}
                    <Divider spacing={4} />
                    <Box textAlign="center" fontFamily="fontFamily" mt={2}>
                      <Trans i18nKey="common.needHelp">
                        Potřebujete pomoct?
                        <a href="mailto:helpdesk@payminator.cz">Napište nám.</a>
                      </Trans>
                  </Box>
                </Box>
              )}

                {!error && (
                  <>
                    <Box display="flex" justifyContent="center">
                    <img
                        alt=""
                        className={classes.logo}
                        src={logoPng}
                        width={250}
                        height={48}
                    />
                  </Box>
                  <Typography align="center" variant="h1" gutterBottom>
                      {isRole
                        ? t('registration.roleHeading')
                        : t('registration.employeeHeading')}
                    </Typography>

                  <Box textAlign="center" fontFamily="fontFamily" mb={4}>
                    {isRole ? (
                      <Trans i18nKey="registration.roleSubHeading">
                        Společnost<Green>{{ companyName }}</Green>
                        Vás žádá o registraci do aplikace
                        <Green>PAYMINATOR</Green>.
                        Nejdříve je nutné vytvořit Váš účet v aplikaci. Přihlašovací údaje slouží jako <Green>Váš
                        vstupní
                        klíč</Green>. Vytvořte si <Green>heslo</Green> a <Green>pokračujte</Green>.
                      </Trans>
                    ) : (
                      <Trans i18nKey="registration.employeeSubHeading">

                        Společnost
                        <Green>{{ companyName }}</Green>
                        Vás žádá o registraci do aplikace
                        <Green>PAYMINATOR</Green>.
                        Nejdříve je nutné vytvořit Váš účet v aplikaci. Přihlašovací údaje slouží jako <Green>Váš
                        vstupní
                        klíč</Green>. Vytvořte si <Green>heslo</Green> a <Green>pokračujte</Green>.

                      </Trans>
                    )}
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="flex-start"
                  >
                    <Box px={compactScreen? 0 : 4}>
                      <TextField
                        fullWidth
                        gutterBottom
                        {...bind('email')}
                        disabled
                        showDisabledIcon={true}
                        value={email}
                      />

                        {isRole && (
                          <>
                            <TextField
                              fullWidth
                              gutterBottom
                              showValidationIcon
                              {...bind('firstname')}
                            />
                            <TextField
                              fullWidth
                              gutterBottom
                              showValidationIcon
                              {...bind('lastname')}
                            />
                            <Select
                              fullWidth
                              gutterBottom
                              showValidationIcon
                              hideEmptyValue
                              {...bind('gender')}
                            />
                          </>
                        )}

                        <TextField
                          fullWidth
                          gutterBottom
                          showValidationIcon
                          hideErrorText
                          type="password"
                          {...bind('password')}
                        />

                        {(form.focused.get('password') ||
                          form.focused.get('passwordAgain')) && (
                          <>
                            <Typography
                              className={classnames(classes.passwordRule, {
                                [classes.green]: passwordLength,
                                [classes.red]:
                                  !passwordLength && passwordTouched,
                              })}
                            >
                              {passwordLength ? <CheckIcon /> : <ArrowIcon />}
                              {t('common.passwordRuleLength')}
                            </Typography>
                            <Typography
                              className={classnames(classes.passwordRule, {
                                [classes.green]: passwordHasLowerUpper,
                                [classes.red]:
                                  !passwordHasLowerUpper && passwordTouched,
                              })}
                            >
                              {passwordHasLowerUpper ? (
                                <CheckIcon />
                              ) : (
                                <ArrowIcon />
                              )}
                              {t('common.passwordRuleUpperLower')}
                            </Typography>
                            <Typography
                              gutterBottom
                              className={classnames(classes.passwordRule, {
                                [classes.green]: passwordHasDigit,
                                [classes.red]:
                                  !passwordHasDigit && passwordTouched,
                              })}
                            >
                              {passwordHasDigit ? <CheckIcon /> : <ArrowIcon />}
                              {t('common.passwordRuleDigit')}
                            </Typography>
                          </>
                        )}

                      <TextField
                        fullWidth
                        gutterBottom
                        showValidationIcon
                        type="password"
                        {...bind('passwordAgain')}
                      />
                    </Box>

                  </Box>
                    <InfoBox mt={'2rem'} title={<Trans i18nKey="registration.payminatorAbout">Co je aplikace Payminator?</Trans>}>
                      <Trans i18nKey="registration.payminatorDescription">
                        <strong>Payminator</strong> je webová aplikace,
                        která pomáhá zaměstnancům a firmám řešit daňovou administrativu jako <strong>roční
                        zúčtování</strong> či <strong>uplatňování daňových slev on-line</strong>, snadně a efektivně. Payminator je Váš daňový asistent.
                      </Trans>
                    </InfoBox>


                    <Box textAlign="center" fontFamily="fontFamily" mt={3}>
                      <Trans i18nKey="registration.securityNote">
                        Své přihlašovací údaje si
                        <Green>uschovejte</Green> a&nbsp;
                        <Green>nesdílejte</Green>.
                      </Trans>
                    </Box>

                    <Divider spacing={4} />

                    <Box display="flex" justifyContent="center">
                      <Button
                        onClick={form.submit}
                        color="primary"
                        size="large"
                        variant="contained"
                      >
                        {t('common.continue')}
                        <span
                          style={{
                            fontSize: '0.75rem',
                            marginLeft: '8px',
                            marginTop: '3px',
                          }}
                        >
                          <PokracovatBtn fontSize="inherit" />
                        </span>
                      </Button>
                    </Box>

                    <Box display="flex" justifyContent="center" />
                    <Box textAlign="center" fontFamily="fontFamily" mt={2}>
                      <Trans i18nKey="common.needHelp">
                        Potřebujete pomoct?
                        <a
                          href="mailto:helpdesk@payminator.cz"
                          onClick={(e) => {
                            const smartsupp: (arg1?: any, arg2?: any) => void =
                              //@ts-ignore
                              window.smartsupp
                            if (smartsupp) {
                              e.preventDefault()
                              smartsupp('chat:open')
                            }
                          }}
                        >
                          {t('common.contactUs')}.
                        </a>
                      </Trans>
                    </Box>
                  </>
                )}
              </Box>
            </BoxBackground>)
          }}
        </Observer>
      )}
    </ProgressContainer>
  )
}

export default withRouter(Registration)
