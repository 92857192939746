import {IMeUserData, IUpdateOwnUserData, IUpdateOwnUserVariables, ME_USER, UPDATE_OWN_USER,} from '../EntryForm/gql'
import {useCountryEnum, useGenderEnum, useIDTypeEnum,} from '../../../../hooks/useEnumList'
import {getBirthdateFromID, orderByCreatedAtDesc} from '../../../../utils'
import {Divider as Divider2, Green} from '../../../../components/CommonBox'
import {Observer} from 'mobx-react'
import {useMutation, useQuery} from 'react-apollo'
import {Trans, useTranslation} from 'react-i18next'
import AppState from '../../../../components/AppState'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import DatePicker from '../../../../components/form/DatePicker'
import Dialog from '../../../../components/Dialog'
import DialogActions from '../../../../components/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../../../../components/DialogTitle'
import Divider from '../../../../components/Divider'
import Fade from '../../../../components/Fade'
import FormControls from '../../../../components/form/FormControls'
import GraphQLErrorSnackbar from '../../../../components/GraphQLErrorSnackbar'
import Grid from '@material-ui/core/Grid'
import Checkbox from '../../../../components/form/Checkbox'
import {makeStyles} from '@material-ui/core/styles'
import PrevDocumentApprovals from '../AnnualRebates/components/PrevDocumentApprovals'
import React, {useState} from 'react'
import Select from '../../../../components/form/Select'
import Snackbars from '../../../../components/form/Snackbars'
import Switch from '../../../../components/form/Switch'
import TermsConditions from './TermsConditions'
import TextField from '../../../../components/form/TextField'
import Typography from '@material-ui/core/Typography'
import Uploader from '../../../../components/form/Uploader'
import useForm, {Form} from '../../../../hooks/useForm'
import useSnackbar from '../../../../hooks/useSnackbar'
import useUser from '../../../../hooks/useUser'
import ArchiveChanges from '../../../../components/ArchiveChanges'
import HelpAdornment from '../../../../components/form/HelpAdornment'
import Autocomplete from "../../../../components/form/Autocomplete";

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: theme.typography.fontWeightMedium,
    color: '#898989',
  },
  sectionHeading: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  smallDivider: {
    width: 200,
    margin: 'auto',
  },
  iconContainer1: {
    fontSize: '2.5rem',
  },
  footer: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(12),
  },
  formContainer: {
    "& > *:last-child": {
      margin: "0 !important"
    }
  }
}))

interface IProps {
  onSuccess: () => void
  onBack?: () => void
  onSubmitWithoutChanges?: () => void
  submitContent?: React.ReactNode
  gdpr?: boolean
  disableSnackbar?: boolean,
  excludeFields?: string[],
  residentialOnly?: boolean,
  beforeControls?: React.ReactNode,
  disableForeignDetails?: boolean,
  disableDomicilWarning?: boolean
}

const PersonalForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { user, refetch: refetchUser } = useUser()
  const { addMessage } = useSnackbar()
  const [domicilWarningOpen, setDomicilWarningOpen] = useState(false)
  const onCloseDomicilWarning = () => setDomicilWarningOpen(false)
  const onOpenDomicilWarning = () => setDomicilWarningOpen(true)
  const [domicilWarningSubmitted, setDomicilWarningSubmitted] = useState(!!props.disableDomicilWarning)
  const submitDomicilWarning = () => {
    setDomicilWarningSubmitted(true)
    onCloseDomicilWarning()
  }
  const classes = useStyles()

  const [termsConditions, setTermsConditions] = React.useState<boolean>(false)

  const { data, loading, error } = useQuery<IMeUserData>(ME_USER)

  const [updateOwnUser] = useMutation<
    IUpdateOwnUserData,
    IUpdateOwnUserVariables
  >(UPDATE_OWN_USER, {
    onCompleted() {
      if (!props.disableSnackbar)
        addMessage(t('common.change', { context: 'SUCCESS' }))
    },
  })

  const { list: genderList } = useGenderEnum()
  const { list: countryList, translate: translateCountry } = useCountryEnum()
  const { list: foreignIDTypeList } = useIDTypeEnum()
  const excluded = props.excludeFields || []

  const countryListWithoutCZ = countryList.filter(
    (country) => country.id !== 'CZ',
  )

  const notEmployee = (result?: string) => {
    if (user.isEmployee()) return { rule: result || '', omitOnSubmit: false }
    else return { rule: '', omitOnSubmit: true }
  }

  const getNationalIDRule = ({ nationality }: { nationality: string }) => {
    if (nationality === 'CZ') {
      return notEmployee('required_if:nationality,CZ|regex:/^([0-9]{9,10})$/')
    } else {
      notEmployee(
        'required_if:nationality,CZ|regex:/^(([0-9]{9,10})|(((0)[1-9]|[1-2][0-9]|(3)[0-1]).(((0)[1-9])|((1)[0-2])).((20)|(19)){1}[0-9]{2})){1}$/',
      )
    }
  }

  let fields = {
    firstname: {
      label: t('common.firstname'),
      placeholder: t('common.firstnamePlaceholder'),
      rule: 'required',
    },
    lastname: {
      label: t('common.lastname'),
      placeholder: t('common.lastnamePlaceholder'),
      rule: 'required',
    },
    nickname: {
      label: t('common.nickname'),
      placeholder: t('common.nickname'),
    },
    gender: {
      label: t('common.gender'),
      placeholder: t('common.genderPlaceholder'),
      rule: 'required',
      list: genderList,
      help: t('common.genderHelp'),
    },
    birthdate: {
      label: t('common.birthdate'),
      placeholder: t('common.birthdatePlaceholder'),
      ...notEmployee('required|date'),
    },
    czechTaxResidence: {
      label: props.residentialOnly ? t('common.czechTaxResidence2') : t('common.czechTaxResidence'),
      placeholder: t('common.czechTaxResidencePlaceholder'),
      rule: 'required',
      help: t('common.czechTaxResidenceHelp')
    },
    nationalIDNumber: {
      label: t('common.nationalIDNumber'),
      placeholder: (data) => {
        const isRequired = data.nationality === 'CZ' || !data.nationality
        return user.isEmployee() && isRequired
            ? t('common.nationalIDNumberPlaceholder')
            : `${t('common.notRequired')} ${t(
                'common.nationalIDNumberPlaceholder',
            )}`
      },
      messages: {
        // TBD lokalizace
        required_if: `:attribute musí být vyplněno pokud :other je ${translateCountry(
            'CZ',
        )}.`,
      },
      ...notEmployee('required_if:nationality,CZ|regex:/^([0-9]{9,10})$/'), // bez lomítka
      //rule: 'required|regex:/^([0-9]{6})\/([0-9]{3,4})$/', // s lomítkem
      // rule: (data) => {
      //   let rule = ''
      //   if (user.isEmployee()) {
      //     rule =
      //       data.nationality === 'CZ'
      //         ? 'required|regex:/^([0-9]{9,10})$/'
      //         : 'regex:/^(([0-9]{10})$/'
      //   }
      //   return rule
      // },
      // omitOnSubmit: () => {
      //   const omit = user.isEmployee() ? false : true
      //   return omit
      // },
      help: t('common.nationalIDNumberHelp'),
    },
    permanentAddress: {
      label: props.residentialOnly? t('common.permanentAddress2') : t('common.permanentAddress'),
      placeholder: props.residentialOnly? t('common.permanentAddress2') + '...' : t('common.permanentAddressPlaceholder'),
      ...notEmployee('required'),
      help: t('common.permanentAddressHelp'),
    },
    nationality: {
      label: t('common.nationality'),
      placeholder: t('common.nationalityPlaceholder'),
      ...notEmployee('required'),
      list: countryList,
      help: t('common.nationalityHelp'),
    },
    foreignIDNumber: {
      label: t('common.foreignIDNumber'),
      placeholder: t('common.foreignIDNumberPlaceholder'),
      rule: (data) =>
          user.isEmployee() &&
          data.nationality !== 'CZ' &&
          data.czechTaxResidence === false
          && !props.disableForeignDetails
              ? 'required'
              : '',
      omitOnSubmit: notEmployee().omitOnSubmit,
      help: t('common.foreignIDNumberHelp'),
    },
    foreignIDType: {
      label: t('common.foreignIDType'),
      placeholder: t('common.foreignIDTypePlaceholder'),
      rule: (data) =>
          user.isEmployee() &&
          data.nationality !== 'CZ' &&
          data.czechTaxResidence === false
          && !props.disableForeignDetails
              ? 'required'
              : '',
      omitOnSubmit: notEmployee().omitOnSubmit,
      list: foreignIDTypeList,
      help: t('common.foreignIDTypeHelp'),
    },
    foreignIDCountry: {
      label: t('common.foreignIDCountry'),
      placeholder: t('common.foreignIDCountryPlaceholder'),
      rule: (data) =>
          user.isEmployee() &&
          data.nationality !== 'CZ' &&
          data.czechTaxResidence === false
          && !props.disableForeignDetails
              ? 'required'
              : '',
      omitOnSubmit: notEmployee().omitOnSubmit,
      list: countryList,
      help: t('common.foreignIDCountryHelp'),
    },
    foreignTaxIdentification: {
      label: t('common.foreignTaxIdentification'),
      placeholder: `${t('common.notRequired')} ${t(
          'common.foreignTaxIdentificationPlaceholder',
      )}`,
      // rule: ({ data }) =>
      //   user.isEmployee() && data.nationality !== 'CZ' ? 'required' : '',
      omitOnSubmit: notEmployee().omitOnSubmit,
      help: t('common.foreignTaxIdentificationHelp'),
    },
    foreignTaxResidentOfState: {
      label: t('common.foreignTaxResidentOfState'),
      placeholder: t('common.foreignTaxResidentOfStatePlaceholder'),
      rule: (data) =>
          user.isEmployee() &&
          data.nationality !== 'CZ' &&
          data.czechTaxResidence === false
            && !props.disableForeignDetails
              ? 'required'
              : '',
      omitOnSubmit: notEmployee().omitOnSubmit,
      list: countryListWithoutCZ,
      help: t('common.foreignTaxResidentOfStateHelp'),
    },
    // taxDomicilFiles: {
      //   type: 'multi_relation',
      //   label: t('common.taxDomicil'),
      //   help: t('common.taxDomicil', { context: 'HELP' }),
      //   rule: (data, form) => {
      //     const f = form.getValue('residenceAuthorisationFiles')
      //     return user.isEmployee() &&
      //       data.nationality !== 'CZ' &&
      //       (!f || f.length === 0)
      //       ? '' // původně bylo 'required'
      //       : ''
      //   },
      //   omitOnSubmit: notEmployee().omitOnSubmit,
      //   isFileRelation: true,
      // },
      residenceAuthorisationFiles: {
        type: 'multi_relation',
        label: t('common.residenceAuthorisation'),
        help: t('common.residenceAuthorisation', {
          context: 'HELP',
        }),
        rule: (data, form) => {
          // const f = form.getValue('taxDomicilFiles')
          return user.isEmployee() &&
            data.nationality !== 'CZ'
        },
        omitOnSubmit: notEmployee().omitOnSubmit,
        isFileRelation: true,
      },
      gdprAgreementAt: {
        label: '-',
        rule: () => (props.gdpr ? 'required|date' : ''),
        omitOnSubmit: notEmployee().omitOnSubmit,

    },
  }

  for(let key of excluded) {
    if(fields[key]){
      delete fields[key]
    }
  }

  const { bind, form } = useForm<IUpdateOwnUserVariables['data']>(
    data && data.user,
    fields,
    {
      async onSubmit(formData, form) {
        if (!form.state.isDirty) {
          props.onSubmitWithoutChanges && props.onSubmitWithoutChanges()
        } else {
          try {
            const { data } = await updateOwnUser({
              variables: { data: {
                ...formData,
                  foreignIDType: formData.foreignIDType || undefined, // fix při vyplnění a následném odvyplnění
                  foreignIDCountry: formData.foreignIDCountry || undefined,
                  foreignTaxResidentOfState: formData.foreignTaxResidentOfState || undefined,
                } },
            })
            if (data && data.updateOwnUser) {
              form.onSuccess(data.updateOwnUser)
              props.onSuccess()
              await refetchUser()
            }
          } catch (err) {
            console.info(formData, form, err)
            form.onFail(err)
          }
        }
      },
      onFieldBlur(fieldPath, value, form, error) {
        if (fieldPath === 'nationalIDNumber' && !error) {
          const nationality = form.getValue('nationality')
          const birthdate = form.getValue('birthdate')
          const parsedBirthdate = getBirthdateFromID(value)
          if (!birthdate && parsedBirthdate && nationality === 'CZ') {
            form.setField('birthdate', parsedBirthdate)
          }
        }
        if (fieldPath === 'nationality' && !error && !props.disableForeignDetails) {
          if (value === 'CZ') {
            form.setField('czechTaxResidence', true)
          } else {
            form.setField('czechTaxResidence', false)
          }
        }
      },
    },
  )

  return (
    <>
      <Observer>
        {() => <AppState loading={form.state.loading || loading} />}
      </Observer>
      <GraphQLErrorSnackbar error={error} />
      <Snackbars form={form} />

      {!loading && data && (
        <Fade>
          <>
            {/* Fixed fileds */}
            <Box maxWidth="100%" width={400} className={classes.formContainer}>
              {!(excluded.includes('firstname')) && <Observer>
                {() => (
                    <TextField fullWidth gutterBottom {...bind('firstname')} />
                )}
              </Observer>}
              {!(excluded.includes('lastname')) && <Observer>
                {() => (
                    <TextField fullWidth gutterBottom {...bind('lastname')} />
                )}
              </Observer>}
              {!(excluded.includes('gender')) && <Observer>
                {() => (
                    <Select
                        fullWidth
                        gutterBottom
                        hideEmptyValue
                        {...bind('gender')}
                    />
                )}
              </Observer>}

              {!(excluded.includes('nickname')) && <Observer>
                {() =>
                    user.isAccountant() && (
                        <TextField fullWidth gutterBottom {...bind('nickname')} />
                    )
                }
              </Observer>}

              <Observer>
                {() =>
                  user.isEmployee() && (
                    <>
                      {!(excluded.includes('nationality')) && <Observer>
                        {() => (
                            <Autocomplete
                                fullWidth
                                gutterBottom
                                hideEmptyValue
                                {...bind('nationality')}
                            />
                        )}
                      </Observer>}
                      {!(excluded.includes('permanentAddress')) && props.residentialOnly && <Observer>
                        {() => (
                            <TextField
                                fullWidth
                                gutterBottom
                                {...bind('permanentAddress')}
                            />
                        )}
                      </Observer>}
                      {!(excluded.includes('czechTaxResidence')) && <Box my={2}>
                        <Observer>
                          {() => <Switch {...bind('czechTaxResidence')} />}
                        </Observer>
                      </Box>}
                      {!(excluded.includes('nationality')) && <Observer>
                        {() => (
                            <TextField
                                fullWidth
                                gutterBottom
                                {...bind('nationalIDNumber')}
                                data-dependency={bind('nationality').value}
                            />
                        )}
                      </Observer>}
                      {!(excluded.includes('birthdate')) && <Observer>
                        {() => (
                            <DatePicker
                                fullWidth
                                gutterBottom
                                autoOk
                                {...bind('birthdate')}
                            />
                        )}
                      </Observer>}
                      {!(excluded.includes('permanentAddress')) && !props.residentialOnly && <Observer>
                        {() => (
                            <TextField
                                fullWidth
                                gutterBottom
                                {...bind('permanentAddress')}
                            />
                        )}
                      </Observer>}
                    </>
                  )
                }
              </Observer>
            </Box>

            {/* Dynamic fileds */}
            <Observer>
              {() => {
                const nationality = form.getValue('nationality')
                const czechTaxResidence = form.getValue('czechTaxResidence')

                /* nemá daňový domicil v ČR, zobrazují se všechny údaje*/

                return !czechTaxResidence && !props.disableForeignDetails ? (
                  <>
                    <Box maxWidth="100%" width={400}>
                      <Observer>
                        {() => (
                          <TextField
                            fullWidth
                            gutterBottom
                            {...bind('foreignIDNumber')}
                            data-dependency={bind('nationality').value}
                          />
                        )}
                      </Observer>
                      <Observer>
                        {() => (
                          <Select
                            fullWidth
                            gutterBottom
                            {...bind('foreignIDType')}
                            data-dependency={bind('nationality').value}
                          />
                        )}
                      </Observer>
                      <Observer>
                        {() => (
                          <Select
                            fullWidth
                            gutterBottom
                            {...bind('foreignIDCountry')}
                            data-dependency={bind('nationality').value}
                          />
                        )}
                      </Observer>
                      <Observer>
                        {() => (
                          <TextField
                            fullWidth
                            gutterBottom
                            {...bind('foreignTaxIdentification')}
                            data-dependency={bind('nationality').value}
                          />
                        )}
                      </Observer>
                      <Observer>
                        {() => (
                          <Select
                            fullWidth
                            gutterBottom
                            {...bind('foreignTaxResidentOfState')}
                            data-dependency={bind('nationality').value}
                          />
                        )}
                      </Observer>
                    </Box>
                  </>
                ) : !!nationality &&
                  nationality !== 'CZ' &&
                  czechTaxResidence &&
                  !user.hasAppliedResidenceFlies() ? (
                  <>
                    {/*
                      Má daňový domicil v ČR, ale nemá státní příslušnost v ČR
                      a ještě si neposlal žádost o schválení domicil souborů:
                      Zobrazí se sekce pro import dokumentů, která je stejná jako
                      u daňového nerezidenta: SharedFormSection
                    */}
                    {!props.disableForeignDetails && <>
                      <Divider spacing={6} />
                      <SharedFormSection bind={bind} version="PERSONAL_FORM" />
                      </>
                    }
                  </>
                ) : (
                  <React.Fragment />
                )
              }}
            </Observer>

            {props.gdpr && !excluded.includes('gdprAgreementAt') && (
              <Observer>
                {() => {
                  const { error, ...rest } = bind('gdprAgreementAt')
                  return (
                    <Checkbox
                      {...rest}
                      timestamp
                      error={error ? t('entryform.gdprAgreementError') : ''}
                      label={
                        <Trans i18nKey="common.gdprAgreement">
                          Souhlasím se zpracováním osobních údajů a
                          <Green
                            onClick={(e) => {
                              e.preventDefault()
                              setTermsConditions(true)
                            }}
                            style={{ textDecoration: 'underline' }}
                          >
                            podmínkami
                          </Green>
                          aplikace <Green>PAYMINATOR</Green>
                        </Trans>
                      }
                    />
                  )
                }}
              </Observer>
            )}

            {props.beforeControls ? (
              <>
                <Divider2 maxWidth={360} my={4} />
                {props.beforeControls}
              </>
            ) : null}

            <Divider2 maxWidth={1080} my={4} />

            <Observer>
              {() => {
                return (
                  <FormControls
                    onSubmit={() => {
                      if (
                        user.isEmployee() &&
                        form.state.isValid &&
                        (excluded.includes('czechTaxResidence') || form.getValue('czechTaxResidence') === true) &&
                        (excluded.includes('nationality') || form.getValue('nationality') !== 'CZ')
                      ) {
                        const f1 = form.getValue('residenceAuthorisationFiles')
                        // const f2 = form.getValue('taxDomicilFiles')

                        if (
                          (!f1 || f1.length === 0) &&
                          // (!f2 || f2.length === 0) &&
                          !domicilWarningSubmitted &&
                          user.noProvidedFiles()
                        ) {
                          onOpenDomicilWarning()
                          return
                        }
                      }
                      form.submit()
                    }}
                    submitContent={
                      props.submitContent || t('common.saveChanges')
                    }
                    {...(props.onBack && { onBack: props.onBack })}
                  />
                )
              }}
            </Observer>

            <Dialog
              open={domicilWarningOpen}
              onClose={onCloseDomicilWarning}
              fullWidth
              maxWidth="sm"
              scroll="body"
            >
              <DialogTitle form={form} onClose={onCloseDomicilWarning}>
                {t('entryform.domicilWarningHeading')}
              </DialogTitle>

              <DialogContent>
                <Typography>{t('entryform.domicilWarning')}</Typography>
              </DialogContent>

              <DialogActions dividerProps={{ mt: 1 }} gutterBottom>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={submitDomicilWarning}
                >
                  {t('common.understand')}
                </Button>
              </DialogActions>
            </Dialog>
          </>
        </Fade>
      )}
      <TermsConditions
        open={termsConditions}
        onClose={() => setTermsConditions(false)}
      />
    </>
  )
}

export const SharedFormSection: React.FC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bind: (fieldPath: string) => any
  form?: Form
  version: 'PERSONAL_FORM' | 'TAX_RESIDENCE'
  isConfirmed?: boolean
}> = (props) => {
  const { t } = useTranslation()
  const { bind, version, isConfirmed } = props
  const { user } = useUser()
  const classes = useStyles()

  // const theme = useTheme()
  // const mdDown = theme.breakpoints.down('md')

  const domicilResidenceApplied = user!
    .data!.taxDomicilDocumentApprovals.concat(
      user.data.residenceAuthorisationDocumentApprovals,
    )
    .some((appr) => appr.status === 'APPLY')

  // const boxWidth = version === 'PERSONAL_FORM' ? 753 : 600
  // const docSelectWidth = version === 'PERSONAL_FORM' ? 540 : 426

  return (
    <Observer>
      {() => {
        return (
          <Box maxWidth={680} width="100%" mb={2}>
            {domicilResidenceApplied && (
              <React.Fragment>
                <Typography
                  variant="h3"
                  align="center"
                  className={classes.sectionHeading}
                >
                  {t('annualRebateTaxResidence.hasAppliedRequest')}
                </Typography>
                <Box textAlign="center" className={classes.smallDivider}>
                  <Divider2 my={3} />
                </Box>
              </React.Fragment>
            )}
            {user.prevDocumentsIsDenied() && (
              <React.Fragment>
                <Typography
                  variant="h3"
                  align="center"
                  className={classes.sectionHeading}
                >
                  {t('annualRebateTaxResidence.hasDeniedPrevRequest')}
                </Typography>
                <Box textAlign="center" className={classes.smallDivider}>
                  <Divider2 my={3} />
                </Box>
              </React.Fragment>
            )}
            {version === 'PERSONAL_FORM' ? (
              <React.Fragment>
                <Typography variant="h1" gutterBottom align="center">
                  {t('entryform.step1DomicilHeading')}
                </Typography>

                <Box fontFamily="fontFamily" mb={4} textAlign="center">
                  <Trans i18nKey="entryform.step1DomicilSubHeading">
                    V případě, že jako cizinec chcete uplatnit jiné daňové slevy
                    než
                    <Green>Základní slevu na poplatníka</Green>a
                    <Green>Slevu na dítě</Green>, tak je nutné dodat daňový
                    domicil nebo potvrzení o trvalém pobytu.
                  </Trans>
                </Box>
              </React.Fragment>
            ) : null}

            <Grid container spacing={3}>
              {/*// <Grid item xs={12} lg={6}>*/}
              {/*//   <Typography variant="h2" gutterBottom>*/}
              {/*//     {t('common.taxDomicil')}*/}
              {/*  </Typography>*/}
              {/*  <Uploader*/}
              {/*    {...bind('taxDomicilFiles')}*/}
              {/*    multiple*/}
              {/*    itemGridProps={{ xs: 12 }}*/}
              {/*    documentSelectWidth={docSelectWidth}*/}
              {/*  />*/}
              {/*</Grid>*/}
              {/*<Grid item xs={12} lg={6}>*/}
              {/*  <Box*/}
              {/*    textAlign={!compactScreen ? 'center' : 'left'}*/}
              {/*    fontFamily="fontFamily"*/}
              {/*    mt={!compactScreen ? 7 : -2}*/}
              {/*  >*/}
              {/*    <Help text={bind('taxDomicilFiles').help} absolute>*/}
              {/*      <a*/}
              {/*        href={*/}
              {/*          process.env.PUBLIC_URL +*/}
              {/*          '/documents/vzor_potvrzeni_o_danovem_domicilu.jpg'*/}
              {/*        }*/}
              {/*        target="_blank"*/}
              {/*        rel="noopener noreferrer"*/}
              {/*      >*/}
              {/*        {t('common.documentTemplate')}*/}
              {/*      </a>*/}
              {/*    </Help>*/}
              {/*  </Box>*/}
              {/*</Grid>*/}
              {/*<Grid item xs={12}>*/}
              {/*  {user.data.taxDomicilDocumentApprovals &&*/}
              {/*    user.data.taxDomicilDocumentApprovals*/}
              {/*      .sort(orderByCreatedAtDesc)*/}
              {/*      .filter(*/}
              {/*        (appr) =>*/}
              {/*          appr.status === 'CONFIRMED' || appr.status === 'DENIED',*/}
              {/*      )*/}
              {/*      .map((appr) => (*/}
              {/*        <PrevDocumentApprovals key={appr.id} data={appr} />*/}
              {/*      ))}*/}
              {/*</Grid>*/}

            <Grid item xs={12}>
              <Typography variant="h2" gutterBottom align="center">
                <Trans
                  i18nKey='annualRebateTaxResidence.residenceAuthorisationTitle'
                  components={[<Green />]}
                  defaults={`${t('common.residenceAuthorisation')} ${t('common.or')} ${t('common.taxDomicilAuthorisationFiles')}`}
                />
                <HelpAdornment text={t('annualRebateTaxResidence.residenceAuthorisationHelp')} inline style={{ verticalAlign: "sub" }} />
              </Typography>

              <Divider2 my={4} maxWidth={160} />
            </Grid>

            <Grid item xs={12} lg={12}>
              <Uploader
                {...bind('residenceAuthorisationFiles')}
                showLabel
                disabled={isConfirmed}
                multiple
                itemGridProps={{ xs: 12 }}
              />
            </Grid>
              {/*<Grid item xs={12} lg={6}>*/}
              {/*  <Box*/}
              {/*    textAlign={!compactScreen ? 'center' : 'left'}*/}
              {/*    fontFamily="fontFamily"*/}
              {/*    display="flex"*/}
              {/*    flexDirection="column"*/}
              {/*    alignItems={"center"}*/}

              {/*  >*/}
              {/*      <a*/}
              {/*        href={*/}
              {/*          process.env.PUBLIC_URL +*/}
              {/*          '/documents/vzor_potvrzeni_o_trvalem_pobytu.pdf'*/}
              {/*        }*/}
              {/*        target="_blank"*/}
              {/*        rel="noopener noreferrer"*/}
              {/*      >*/}
              {/*        {t('common.documentTemplate')} {t('common.residenceAuthorisation')}*/}
              {/*      </a><br />*/}
              {/*      <a*/}
              {/*          href={*/}
              {/*              process.env.PUBLIC_URL +*/}
              {/*              '/documents/vzor_potvrzeni_o_danovem_domicilu.jpg'*/}
              {/*          }*/}
              {/*          target="_blank"*/}
              {/*          rel="noopener noreferrer"*/}
              {/*      >*/}
              {/*        {t('common.documentTemplate')} {t('common.taxDomicilAuthorisationFiles')}*/}
              {/*      </a>*/}

              {/*  </Box>*/}
              {/*</Grid>*/}
              {user.data.residenceAuthorisationDocumentApprovals && user.data.residenceAuthorisationDocumentApprovals.length > 0 ? (
                <Grid item xs={12}>
                    <ArchiveChanges>
                      {user.data.residenceAuthorisationDocumentApprovals
                      .sort(orderByCreatedAtDesc)
                      .filter(appr => appr.status === 'CONFIRMED' || appr.status === 'DENIED')
                      .map(appr => <PrevDocumentApprovals key={appr.id} data={appr} />)}
                    </ArchiveChanges>
                </Grid>
              ) : null}
            </Grid>
          </Box>
        )
      }}
    </Observer>
  )
}

export default PersonalForm
