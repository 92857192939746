import { gql } from 'apollo-boost'
import { NexusGenFieldTypes } from 'kubik-server'

export type TLongTermCareRebate = NexusGenFieldTypes['LongTermCareRebate']

export const LONGTERMCARE_REBATE_FULL_FRAGMENT = gql`
  fragment LongTermCareRebateFull on LongTermCareRebate {
    id
    createdAt
    status
    type
    commentOfDenied
    amount
    confirmationAgreementFiles {
        id
        filename
        size
        createdAt
    }
    confirmationPaymentFiles {
        id
        filename
        size
        createdAt
    }
    settlementRequest {
      id
      requestedAt
      submittedAt
    }
  }
`
