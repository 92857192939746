
import React from 'react'
import {makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from '@material-ui/core';

interface IProps {
  children: React.ReactNode,
    left: string, // url of image
    right: string, // url of image
    topBackgroundColor?:string,
    bottomBackgroundColor?: string
}

const useStyles = makeStyles<Theme,IProps>((theme) => ({
    contentWrapper: (props: IProps) => ({
        background: (props.topBackgroundColor && props.bottomBackgroundColor) ? `linear-gradient(180deg, ${props.topBackgroundColor} 0%, ${props.topBackgroundColor} 25%, ${props.bottomBackgroundColor} 25%,  ${props.bottomBackgroundColor} 100%)` : "transparent",
        "& > div": {
            background: "white"
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    }),
    leftSide: {
        right: "100%",
        top: 0,
        textAlign: "right",
        justifyContent: "right"
    },
    rightSide: {
        left: "100%",
        top: 0,
        justifyContent: "left"
    },
    wrapper: {
        display: 'grid',
        gap: '1rem',
        gridTemplateColumns: '1fr 480px 1fr',
        alignContent: 'center',
        minHeight: "calc(100svh - 64px)",
        "& > div, img": {
            alignSelf: 'center'
        },
        [theme.breakpoints.down('xs')]: {
            display: 'flex'
        }
    },
    image: {
        width: '100%'
    }
}))

/**
 * Component showing background left and right of the box.
 */
const BoxBackground: React.FC<IProps> = (props) => {
    const classes = useStyles(props)
    const theme = useTheme()
    const mdDown = theme.breakpoints.down('xs')
    const compactScreen = useMediaQuery(mdDown)

    return (
        <div className={classes.wrapper}>
            {!compactScreen ? <img src={props.left} className={classes.image} /> : null}
            <div className={classes.contentWrapper}>{props.children}</div>
            {!compactScreen ? <img src={props.right} className={classes.image} /> : null}
        </div>
    )
}

export default BoxBackground
