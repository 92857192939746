import { gql } from 'apollo-boost'
import { BASIC_REBATE_FULL_FRAGMENT } from '../../../../../../fragments/BasicRebate'
import { ZTPP_REBATE_FULL_FRAGMENT } from '../../../../../../fragments/ZtppRebate'
import { CHILDREN_REBATE_FULL_FRAGMENT } from '../../../../../../fragments/ChildrenRebate'
import { DISABILITY_REBATE_FULL_FRAGMENT } from '../../../../../../fragments/DisabilityRebate'

export const OWN_ANNUAL_REBATE_MONTHLY = gql`
  query userWithAnnualRebateMonthly {
    user {
      id
      annualRebate {
        id
        year
        wizardStep
        wizardFinishedAt
        settlementRequest {
          id
        }
        basicRebate {
          ...BasicRebateFull
        }
        basicRebates(orderBy: createdAt_DESC) {
          id
          status
          type
          settlementRequest {
            id
            type
          }
        }
        studentRebates(orderBy: createdAt_DESC) {
          id
          status
          type
          settlementRequest {
            id
            type
          }
        }
        childrenRebate {
          ...ChildrenRebateFull
        }
        childrenRebates(orderBy: createdAt_DESC) {
          id
          status
          type
          otherParentStatus
          settlementRequest {
            id
            type
          }
          children {
            id
            applyInMonths
            status
            type
          }
        }
        disabilityRebate {
          ...DisabilityRebateFull
        }
        disabilityRebates(orderBy: createdAt_DESC) {
          id
          status
          type
          settlementRequest {
            id
            type
          }
        }
        ztppRebate {
          ...ZtppRebateFull
        }
        ztppRebates(orderBy: createdAt_DESC) {
          id
          status
          type
          settlementRequest {
            id
            type
          }
        }
        taxQuestionnaire {
          id
          status
        }
        prevEmployers {
          id
          status
        }
      }
    }
  }
  ${BASIC_REBATE_FULL_FRAGMENT}
  ${CHILDREN_REBATE_FULL_FRAGMENT}
  ${DISABILITY_REBATE_FULL_FRAGMENT}
  ${ZTPP_REBATE_FULL_FRAGMENT}
`
