import {
  Dary2,
  Deti, Exekuce,
  Hypoteka,
  Invalida,
  Manzel,
  Odbory,
  PenzijniPojisteni,
  Poplatnik,
  Skolkovne,
  Student,
  Vzdelani,
  ZivotniPojisteni,
  ZTPP,
} from '../../components/icons/KubikIcons'
import { someHasStatus } from '../../shared/Children'
import { IRebateData, IChildrenRebateData } from './types'
import { NexusGenEnums } from 'kubik-server'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import get from 'lodash/get'
import RebateRow from './RebateRow'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import useUser from '../../hooks/useUser'

interface IProps {
  data: {
    status?: NexusGenEnums['SettlementRequestStatus']
    annualRebate?: {
      basicRebates: IRebateData[]
      studentRebates: IRebateData[]
      childrenRebates: IChildrenRebateData[]
      disabilityRebates: IRebateData[]
      ztppRebates: IRebateData[]
      lifeInsuranceRebates: IRebateData[]
      pensionInsuranceRebates: IRebateData[]
      unionRebates: IRebateData[]
      foreclosureRebates: IRebateData[]
      examRebates: IRebateData[]
      giftRebates: IRebateData[]
      preschoolRebates: IRebateData[]
      spouseRebates: IRebateData[]
      loanRebates: IRebateData[]
    } | null
    monthlyRebate?: {
      basicRebates: IRebateData[]
      studentRebates: IRebateData[]
      childrenRebates: IChildrenRebateData[]
      disabilityRebates: IRebateData[]
      ztppRebates: IRebateData[]
    } | null
  }
}

const RebatesOverview: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { user } = useUser()

  const parseRebate = (rebate: string) => {
    const annual: IRebateData[] = get(props.data.annualRebate, rebate + 's')
    const monthly: IRebateData[] = get(props.data.monthlyRebate, rebate + 's')
    const found = annual || monthly || null

    if (found) {
      for (let i = 0; i < found.length; i++) {
        const monthlyPrevYear: boolean | undefined =
          get(found[i], 'settlementRequest.type') === 'MONTHLY_PREV_YEAR'
        if (!monthlyPrevYear && found[i].status !== 'NEW') {
          if (
            found[i].type !== 'REMOVE' ||
            (found[i].type === 'REMOVE' && found[i].status !== 'CONFIRMED')
          ) {
            if (found[i].status === 'APPLY' && !found[i].settlementRequest) {
              if (!found[i + 1]) {
                break
              }
            } else {
              return found[i]
            }
          } else {
            break
          }
        }
      }
    }
    return null
  }

  const parseChildrenRebate = () => {
    const found =
      get(props.data.monthlyRebate, 'childrenRebates') ||
      get(props.data.annualRebate, 'childrenRebates')

    if (found) {
      for (let i = 0; i < found.length; i++) {
        const monthlyPrevYear: boolean | undefined =
          get(found[i], 'settlementRequest.type') === 'MONTHLY_PREV_YEAR'

        if (!monthlyPrevYear /*&& found[i].status !== 'NEW'*/) {
          if (
            found[i].type !== 'REMOVE' ||
            (found[i].type === 'REMOVE' && found[i].status !== 'CONFIRMED')
          ) {
            if (
              /*found[i].status === 'APPLY' &&*/ !found[i].settlementRequest
            ) {
              if (!found[i + 1]) {
                break
              }
            } else {
              const status: NexusGenEnums['RebateStatus'] = someHasStatus(
                found[i],
                'APPLY',
              )
                ? 'APPLY'
                : someHasStatus(found[i], 'DENIED')
                ? 'DENIED'
                : someHasStatus(found[i], 'CONFIRMED')
                ? 'CONFIRMED'
                : 'NEW'

              return { status }
            }
          } else {
            break
          }
        }
      }
    }
    return null
  }

  const rebates = {
    basicRebate: parseRebate('basicRebate'),
    studentRebate: parseRebate('studentRebate'),
    disabilityRebate: parseRebate('disabilityRebate'),
    ztppRebate: parseRebate('ztppRebate'),
    giftRebate: parseRebate('giftRebate'),
    preschoolRebate: parseRebate('preschoolRebate'),
    spouseRebate: parseRebate('spouseRebate'),
    lifeInsuranceRebate: parseRebate('lifeInsuranceRebate'),
    pensionInsuranceRebate: parseRebate('pensionInsuranceRebate'),
    loanRebate: parseRebate('loanRebate'),
    unionRebate: parseRebate('unionRebate'),
    foreclosureRebate: parseRebate('foreclosureRebate'),
    investmentRebate: parseRebate('investmentRebate'),
    longTermCareRebate: parseRebate('longTermCareRebate'),
    examRebate: parseRebate('examRebate'),
    childrenRebate: parseChildrenRebate(),
  }

  const {
    basicRebate,
    childrenRebate,
    studentRebate,
    disabilityRebate,
    ztppRebate,
    giftRebate,
    preschoolRebate,
    spouseRebate,
    lifeInsuranceRebate,
    pensionInsuranceRebate,
    loanRebate,
    unionRebate,
    foreclosureRebate,
    examRebate,
  } = rebates
  const hasSomeRebate =
    Object.values(rebates).filter((rebate) => rebate !== null).length > 0

  return hasSomeRebate ? (
    <>
      {basicRebate && (
        <RebateRow
          icon={<Poplatnik />}
          label={t('rebate.basicRebate')}
          settlementRequestStatus={props.data.status}
          {...basicRebate}
        />
      )}

      {studentRebate && (
        <RebateRow
          icon={<Student />}
          label={t('rebate.studentRebate')}
          settlementRequestStatus={props.data.status}
          {...studentRebate}
        />
      )}
      {childrenRebate && (
        <RebateRow
          icon={<Deti />}
          label={t('rebate.childrenRebate')}
          settlementRequestStatus={props.data.status}
          {...childrenRebate}
        />
      )}
      {disabilityRebate && (
        <RebateRow
          icon={<Invalida />}
          label={t('rebate.disabilityRebate')}
          settlementRequestStatus={props.data.status}
          {...disabilityRebate}
        />
      )}
      {ztppRebate && (
        <RebateRow
          icon={<ZTPP />}
          label={t('rebate.ztppRebate')}
          settlementRequestStatus={props.data.status}
          {...ztppRebate}
        />
      )}
      {giftRebate && (
        <RebateRow
          icon={<Dary2 />}
          label={t('rebate.giftRebate')}
          settlementRequestStatus={props.data.status}
          {...giftRebate}
        />
      )}
      {preschoolRebate && (
        <RebateRow
          icon={<Skolkovne />}
          label={t('rebate.preschoolRebate')}
          settlementRequestStatus={props.data.status}
          {...preschoolRebate}
        />
      )}
      {spouseRebate && (
        <RebateRow
          icon={<Manzel />}
          label={t('rebate.spouseRebate')}
          settlementRequestStatus={props.data.status}
          {...spouseRebate}
        />
      )}
      {lifeInsuranceRebate && (
        <RebateRow
          icon={<ZivotniPojisteni />}
          label={t('rebate.lifeInsuranceRebate')}
          settlementRequestStatus={props.data.status}
          {...lifeInsuranceRebate}
        />
      )}
      {pensionInsuranceRebate && (
        <RebateRow
          icon={<PenzijniPojisteni />}
          label={t('rebate.pensionInsuranceRebate')}
          settlementRequestStatus={props.data.status}
          {...pensionInsuranceRebate}
        />
      )}
      {loanRebate && (
        <RebateRow
          icon={<Hypoteka />}
          label={t('rebate.loanRebate')}
          settlementRequestStatus={props.data.status}
          {...loanRebate}
        />
      )}
      {unionRebate && (
          <RebateRow
              icon={<Odbory />}
              label={t('rebate.unionRebate')}
              settlementRequestStatus={props.data.status}
              {...unionRebate}
          />
      )}
      {foreclosureRebate && (
          <RebateRow
              icon={<Exekuce />}
              label={t('rebate.foreclosureRebate')}
              settlementRequestStatus={props.data.status}
              {...foreclosureRebate}
          />
      )}
      {examRebate && (
        <RebateRow
          icon={<Vzdelani />}
          label={t('rebate.examRebate')}
          settlementRequestStatus={props.data.status}
          {...examRebate}
        />
      )}
    </>
  ) : (
    <Box textAlign="center">
      <Typography variant="h4">
        {t('taxRebates.noApplyingRebates', { context: user.data.gender })}
      </Typography>
    </Box>
  )
}

export default RebatesOverview
