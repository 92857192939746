import React from 'react'
import Box from '@material-ui/core/Box'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles(theme => {
  return {
    action: {
      display: 'flex',
      color: '#000000',
      marginRight: theme.spacing(2),
      fontFamily: 'sans-serif',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main,
      },
      '&:last-of-type': {
        marginRight: 0,
      },
      '& svg': {
        color: '#898989',
      },
      '&:hover svg': {
        color: theme.palette.primary.main,
      },
    },
  }
})

interface IProps {
  onClick: () => void
}

const MessageTopAction: React.FC<IProps> = props => {
  const classes = useStyles()
  const { children, onClick } = props

  return (
    <Box onClick={onClick} className={classes.action}>
      {children}
    </Box>
  )
}

export default MessageTopAction
