import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import * as KubikIcons from './KubikIcons'
import { Typography } from '@material-ui/core'
import { Box } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      minHeight: 80,
      width: 200,
      backgroundColor: '#b0ecc8',
    },
    control: {
      padding: theme.spacing(2),
    },
    iconWrapper: {
      position: 'relative',
      left: '50%',
      top: '50%',
      display: 'inline',
    },
  }),
)

let IconsKeys = Object.keys(KubikIcons)

export default function PreviewIcons() {
  const classes = useStyles()

  function renderIcons2(key: string) {
    if (key) {
      // @ts-ignore
      const Component = KubikIcons[key]
      return (
        <div>
          <Typography align="center" paragraph>
            {key}
          </Typography>
          <Box display="flex" justifyContent="center">
            <Component />
          </Box>
        </div>
      )
    } else {
      return ''
    }
  }

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={4}>
          {IconsKeys.map(value => {
            return (
              <Grid key={value} item>
                <Paper className={classes.paper}>{renderIcons2(value)}</Paper>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    </Grid>
  )
}
