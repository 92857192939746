import {ICustomerData, IUnitDeleteData, IUnitsData, NEW_CUSTOMER_UNITS, TUnit, UNIT_DELETE, UNITS,} from './gql'
import {createNewCustomer} from '../../../../generated/createNewCustomer'
import {superadminUnits} from '../../../../generated/superadminUnits'
import {makeStyles} from '@material-ui/core/styles'
import {Observer} from 'mobx-react'
//import { useMutation } from 'react-apollo-hooks'
import {useMutation} from 'react-apollo'
import {useTranslation} from 'react-i18next'
import Dialog from '../../../../components/Dialog'
import DialogActions from '../../../../components/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../../../../components/DialogTitle'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import SubmitButton from '../../../../components/form/SubmitButton'
import useForm from '../../../../hooks/useForm'
import {Typography} from '@material-ui/core'
import {Center, IconCircle} from '../../../../components/CommonBox'
import {OpravduSmazat} from '../../../../components/icons/KubikIcons'
import useUser from '../../../../hooks/useUser'

const useStyles = makeStyles((theme) => ({
  errorButton: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
}))

interface IUnitRemoveDialogProps {
  open: boolean
  unit: TUnit | null
  onClose: () => void
  customer:
    | ICustomerData['customer']
    | createNewCustomer['createNewCustomer']
    | undefined
    | null
}
const UnitRemoveDialog: React.FC<IUnitRemoveDialogProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { onClose, open, unit } = props
  const { user } = useUser()

  const isNewCustomer = user.isSuperadmin()

  const [deleteUnit] = useMutation<IUnitDeleteData>(UNIT_DELETE, {
    update: (store, { data }) => {
      if (!isNewCustomer) {
        const cache = store.readQuery<IUnitsData>({ query: UNITS })

        if (data && cache && Array.isArray(cache.units)) {
          cache.units = cache.units.filter((u) => u.id !== data.deleteUnit.id)
          store.writeQuery({ query: UNITS, data: cache })
        }
      } else {
        const cache = store.readQuery<superadminUnits>({
          query: NEW_CUSTOMER_UNITS,
          variables: {
            where: { customerId: (props.customer && props.customer.id) || '' },
          },
        })
        if (data && cache && Array.isArray(cache.superadminUnits)) {
          cache.superadminUnits = cache.superadminUnits.filter(
            (u) => u.id !== data.deleteUnit.id,
          )
        }
        store.writeQuery({
          query: NEW_CUSTOMER_UNITS,
          data: cache,
          variables: {
            where: { customerId: (props.customer && props.customer.id) || '' },
          },
        })
      }
    },
  })

  const { form } = useForm<TUnit>(
    { id: unit && unit.id },
    {},
    {
      submitWholeModel: true,
      variables: { where: { id: unit && unit.id } },
      async onSubmit(_, form) {
        try {
          const { data } = await deleteUnit({
            variables: form.options.variables,
          })
          if (data) {
            onClose()
          }
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" scroll="body">
      <DialogTitle form={form} onClose={onClose}>
        <Center mb={2}>
          <IconCircle fontSize="3rem">
            <OpravduSmazat fontSize="inherit" color="primary" />
          </IconCircle>
        </Center>
        {t('customerOptions.removeUnitDialogHeading')}
      </DialogTitle>

      <DialogContent>
        <Observer>
          {() => (
            <Grid container direction="column" spacing={1}>
              <Grid sm item>
                <Typography align="center">„{unit && unit.name}“</Typography>
              </Grid>
            </Grid>
          )}
        </Observer>
      </DialogContent>

      <DialogActions dividerProps={{ mt: 1 }}>
        <SubmitButton
          classes={{ button: classes.errorButton }}
          form={form}
          label={t('common.remove')}
        />
      </DialogActions>
    </Dialog>
  )
}

export default UnitRemoveDialog
