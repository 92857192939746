import {
  CREATE_UNIT,
  ICustomerData,
  IUnitCreateData,
  IUnitsData,
  NEW_CUSTOMER_CREATE_UNIT,
  NEW_CUSTOMER_UNITS,
  TCreateUnitVariables,
  UNITS,
} from './gql'
import {superadminCreateUnit, superadminCreateUnitVariables,} from '../../../../generated/superadminCreateUnit'
import {createNewCustomer} from '../../../../generated/createNewCustomer'
import {superadminUnits} from '../../../../generated/superadminUnits'
import {makeStyles, Theme, useTheme} from '@material-ui/core/styles'
import {Observer} from 'mobx-react'
//import { useMutation } from 'react-apollo-hooks'
import {useMutation} from 'react-apollo'
import {useTranslation} from 'react-i18next'
import Dialog from '../../../../components/Dialog'
import DialogActions from '../../../../components/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../../../../components/DialogTitle'
import Grid from '@material-ui/core/Grid'
import NativColorPicker from '../../../../components/form/NativColorPicker'
import React, {useRef} from 'react'
import SubmitButton from '../../../../components/form/SubmitButton'
import TextField from '../../../../components/form/TextField'
import useForm from '../../../../hooks/useForm'
import {Box} from '@material-ui/core'
import {PridatStredisko40} from '../../../../components/icons/KubikIcons'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import useUser from '../../../../hooks/useUser'

const useStyles = makeStyles((theme: Theme) => ({
  iconContainer: {
    fontSize: '3rem',
    marginBottom: theme.spacing(2),
  },
}))

interface IUnitCreateDialogProps {
  open: boolean
  onClose: () => void
  customer:
    | ICustomerData['customer']
    | createNewCustomer['createNewCustomer']
    | undefined
    | null
}
const UnitCreateDialog: React.FC<IUnitCreateDialogProps> = (props) => {
  const { t } = useTranslation()
  const { onClose, open } = props
  const theme = useTheme()
  const classes = useStyles()
  const { user } = useUser()

  const isNewCustomer = user.isSuperadmin()

  const [createUnit] = useMutation<IUnitCreateData, TCreateUnitVariables>(
    CREATE_UNIT,
    {
      update: (store, { data }) => {
        const cache = store.readQuery<IUnitsData>({ query: UNITS })
        data &&
          cache &&
          Array.isArray(cache.units) &&
          cache.units.push(data.createUnit)
        store.writeQuery({ query: UNITS, data: cache })
      },
    },
  )

  const [superadminCreateUnit] = useMutation<
    superadminCreateUnit,
    superadminCreateUnitVariables
  >(NEW_CUSTOMER_CREATE_UNIT, {
    update: (store, { data }) => {
      const cache = store.readQuery<superadminUnits>({
        query: NEW_CUSTOMER_UNITS,
        variables: {
          where: { customerId: (props.customer && props.customer.id) || '' },
        },
      })
      data &&
        data.superadminCreateUnit &&
        cache &&
        Array.isArray(cache.superadminUnits) &&
        cache.superadminUnits.push(data.superadminCreateUnit)
      store.writeQuery({
        query: NEW_CUSTOMER_UNITS,
        data: cache,
        variables: {
          where: { customerId: (props.customer && props.customer.id) || '' },
        },
      })
    },
  })

  const initData = useRef({ name: '', color: '#3388FF' }).current

  const { bind, form } = useForm<TCreateUnitVariables['data']>(
    initData,
    {
      name: {
        label: t('common.unitName'),
        help: t('common.unitNameHelp'),
        placeholder: t('common.unitName') + '...',
        rule: 'required',
      },
      color: {
        label: t('common.change'),
      },
    },
    {
      submitWholeModel: true,
      async onSubmit(formData, form) {
        try {
          let resultData
          if (!isNewCustomer) {
            const { data } = await createUnit({
              variables: { data: formData },
            })
            resultData = data
          } else {
            const { data: newCustomerData } = await superadminCreateUnit({
              variables: {
                data: {
                  ...formData,
                  customerId: (props.customer && props.customer.id) || '',
                },
              },
            })
            resultData = newCustomerData
          }
          if (resultData) {
            form.onSuccess(initData)
            onClose()
          }
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" scroll="body">
      <DialogTitle form={form} onClose={onClose}>
        <Box
          display="flex"
          justifyContent="center"
          className={classes.iconContainer}
        >
          <Step completed={false}>
            <StepLabel icon={<PridatStredisko40 fontSize="inherit" />}>
              {' '}
            </StepLabel>
          </Step>
        </Box>
        <Box display="flex" justifyContent="center">
          {t('customerOptions.addNewUnit')}
        </Box>
      </DialogTitle>

      <DialogContent>
        <Observer>
          {() => (
            <Grid container spacing={1}>
              <Grid xs={12} item>
                <TextField
                  fullWidth
                  gutterBottom
                  inputProps={{ maxLength: 20 }}
                  {...bind('name')}
                />
              </Grid>
              <Grid
                xs={12}
                item
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  fontFamily="fontFamily"
                  borderRadius={6}
                  px={2}
                  py={1}
                  bgcolor={form.getValue('color')}
                  color={theme.palette.getContrastText(form.getValue('color'))}
                >
                  {form.getValue('name') || t('common.unit')}
                </Box>

                <NativColorPicker {...bind('color')} />
              </Grid>
            </Grid>
          )}
        </Observer>
      </DialogContent>

      <DialogActions dividerProps={{ mt: 1 }}>
        <SubmitButton form={form} label={t('customerOptions.addUnit')} />
      </DialogActions>
    </Dialog>
  )
}

export default UnitCreateDialog
