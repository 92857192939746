import {gql} from 'apollo-boost'
import {NexusGenArgTypes, NexusGenFieldTypes} from 'kubik-server'
import {LONGTERMCARE_REBATE_FULL_FRAGMENT} from '../../../../../../fragments/LongTermCareRebate'
import {INVESTMENT_REBATE_FULL_FRAGMENT} from "../../../../../../fragments/InvestmentRebate";
import {PENSION_INSURANCE_REBATE_FULL_FRAGMENT} from "../../../../../../fragments/PensionInsuranceRebate";
import {LIFE_INSURANCE_REBATE_FULL_FRAGMENT} from "../../../../../../fragments/LifeInsuranceRebate";

export type TLongTermCareRebate = NexusGenFieldTypes['LongTermCareRebate']

export interface IOwnAnnualLongTermCareRebateData {
  user: {
    annualRebate: NexusGenFieldTypes['AnnualRebate'] & {
      longTermCareRebate: TLongTermCareRebate
      longTermCareRebates: TLongTermCareRebate[]
    }
  }
}
export const OWN_ANNUAL_LONGTERMCARE_REBATE = gql`
  query ownAnnualLongTermCareRebate {
    user {
      id
      annualRebate {
        id
        year
        longTermCareRebate {
          ...LongTermCareRebateFull
        }
        longTermCareRebates(orderBy: createdAt_DESC) {
          ...LongTermCareRebateFull
        }
        investmentRebate {
            ...InvestmentRebateFull
        }
        investmentRebates(orderBy: createdAt_DESC) {
            ...InvestmentRebateFull
        }
        pensionInsuranceRebate {
            ...PensionInsuranceRebateFull
        }
        pensionInsuranceRebates(orderBy: createdAt_DESC) {
            ...PensionInsuranceRebateFull
        }
        lifeInsuranceRebate {
            ...LifeInsuranceRebateFull
        }
        lifeInsuranceRebates(orderBy: createdAt_DESC) {
            ...LifeInsuranceRebateFull
        }
        limits {
            pensionShared {
                max
            }
        }
      }
    }
  }
  ${LONGTERMCARE_REBATE_FULL_FRAGMENT}
  ${INVESTMENT_REBATE_FULL_FRAGMENT}
  ${PENSION_INSURANCE_REBATE_FULL_FRAGMENT}
  ${LIFE_INSURANCE_REBATE_FULL_FRAGMENT}
`

export interface IUpdateOwnAnnualLongTermCareRebateData {
  updateOwnAnnualLongTermCareRebate: NexusGenFieldTypes['LongTermCareRebate']
}
export type TUpdateOwnAnnualLongTermCareRebateVariables = NexusGenArgTypes['Mutation']['updateOwnAnnualLongTermCareRebate']
export const UPDATE_OWN_ANNUAL_LONGTERMCARE_REBATE = gql`
  mutation updateOwnAnnualLongTermCareRebate($data: OwnLongTermCareRebateUpdateInput!) {
    updateOwnAnnualLongTermCareRebate(data: $data) {
      ...LongTermCareRebateFull
    }
  }
  ${LONGTERMCARE_REBATE_FULL_FRAGMENT}
`

export interface IResetOwnAnnualLongTermCareData {
  resetOwnAnnualLongTermCareRebate: TLongTermCareRebate
}
export const RESET_OWN_ANNUAL_LONGTERMCARE_REBATE = gql`
  mutation resetOwnAnnualLongTermCareRebate {
    resetOwnAnnualLongTermCareRebate {
      ...LongTermCareRebateFull
    }
  }
  ${LONGTERMCARE_REBATE_FULL_FRAGMENT}
`
