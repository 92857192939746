import React from "react"
import {
    settlementRequest_settlementRequest_annualRebate_prevEmployersSummaryInfo
} from "../../../../../../generated/settlementRequest";
import Box from "@material-ui/core/Box";
import i18next from "i18next";
import {useTranslation} from "react-i18next";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import MonthsRange from "../../../../../../shared/MonthsRange";

interface IProps {
    summaryInfo: settlementRequest_settlementRequest_annualRebate_prevEmployersSummaryInfo
}

const PreviousEmployersSummaryInfoOverview: React.FC<IProps> = (props) => {

    const {t}: { t: i18next.TFunction } = useTranslation()
    const renderResult = (result: boolean | null) => result? t('common.yes').toUpperCase() : t('common.no')
    const {summaryInfo} = props

    return (
        <Box fontFamily="inherit" padding={'2rem 0'} width={740}>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>{t('rebate.basicRebate')}</TableCell>
                        <TableCell>{renderResult(summaryInfo.usedBasicRebate)}</TableCell>
                        <TableCell>
                            {summaryInfo.usedBasicRebate ? (
                                <MonthsRange value={summaryInfo.usedBasicRebateInMonths} />
                            ) : null}
                        </TableCell>
                    </TableRow>

                    {/* <TableRow>
                        <TableCell>{t('rebate.studentRebate')}</TableCell>
                        <TableCell>{renderResult(summaryInfo.usedStudentRebate)}</TableCell>
                        <TableCell>
                            <MonthsRange value={summaryInfo.usedStudentRebateInMonths} />
                        </TableCell>
                    </TableRow> */}

                    <TableRow>
                        <TableCell >{t('rebate.childrenRebate')}</TableCell>
                        <TableCell>{renderResult(summaryInfo.usedChildrenRebate)}</TableCell>
                        <TableCell>
                            {summaryInfo.usedChildrenRebate && summaryInfo.usedChildren ? (
                                    <Box display="flex" flexWrap="wrap">
                                        {summaryInfo.usedChildren.map((childInfo, index) =>
                                            <Box padding={'0.5rem'} key={'childInfon' + index}>
                                                <small>{index + 1}. {t('common.child')}</small>
                                                <div>{t('common.ztpp')}: {renderResult(childInfo.ztpp || false)}</div>
                                                <MonthsRange value={childInfo.usedInMonths}/>
                                            </Box>
                                        )}
                                    </Box>
                            ) : null}
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>{t('rebate.disabilityRebate')}</TableCell>
                        <TableCell>{renderResult(summaryInfo.usedDisabilityRebate)}</TableCell>

                        <TableCell>
                            {summaryInfo.usedDisabilityRebate ? (
                                <MonthsRange value={summaryInfo.usedDisabilityRebateInMonths} />
                            ) : null}
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>{t('rebate.ztppRebate')}</TableCell>
                        <TableCell>{renderResult(summaryInfo.usedZtppRebate)}</TableCell>
                        <TableCell>
                        {summaryInfo.usedZtppRebate ? (
                                <MonthsRange value={summaryInfo.usedZtppRebateInMonths} />
                            ) : null}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Box>
    )
}


export default PreviousEmployersSummaryInfoOverview