import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Typography from '@material-ui/core/Typography'

import {Information} from "./Information"

import {MesicniSlevy} from '../../../../../../../components/icons/KubikIcons'
import {Divider, Green} from '../../../../../../../components/CommonBox'
import DialogTitle from '../../../../../../../components/DialogTitle'
import {User} from '../../../../../../../hooks/useUser'

interface MonthlyInfoDialogProps {
    isOpen: boolean;
    onClose: () => void;
    user: User;
}

const useStyles = makeStyles(theme => ({
    content: {
        padding: `0 ${theme.spacing(2)}px`,
        [theme.breakpoints.up("sm")]: {
            padding: `0 ${theme.spacing(8)}px`,
        }
    }
}))

export const MonthlyInfoDialog: React.FC<MonthlyInfoDialogProps> = ({ isOpen, onClose, user }) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            scroll="body"
            maxWidth="sm"
            open={isOpen}
            onClose={onClose}
            fullScreen={fullScreen}
            aria-labelledby="monthly-info-dialog-title"
            aria-describedby="monthly-info-dialog-description"
        >
            <DialogTitle id="monthly-info-dialog-title" onClose={onClose} titleVariant='h1'>
                {t('common.monthlyRebates')}

                <Box my={2}>
                    <MesicniSlevy />
                </Box>
            </DialogTitle>

            <DialogContent className={classes.content}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    {/*<IntroSideHint gutterBottom fullwidth variant='blue'>*/}
                    {/*    <SideHint />*/}
                    {/*</IntroSideHint>*/}

                    <Typography align="center" id="monthly-info-dialog-description">
                        <Trans i18nKey="monthlyRebate.title">
                            Tento formulář umožnuje uplatnit si <Green>měsíční slevy na dani</Green>,
                            které se promítají ve mzdě měsíčně. Formulář začnete vyplňovat kliknutím na tlačítko <Green>Spustit</Green>.
                        </Trans>
                    </Typography>

                    <Divider width="100px" my={4} />

                    <Typography align="center" variant="h2" style={{ marginBottom: 16 }}>
                        <Trans i18nKey="monthlyRebate.subheaderTitle">
                            Základní informace k formuláři
                        </Trans>
                    </Typography>
                    
                    <Information user={user} />
                </Box>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>{t('common.back')}</Button>
            </DialogActions>
        </Dialog>
    )
}
