import {makeStyles, styled, useTheme} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import classnames from 'classnames'

const useStyles = makeStyles(() => ({
  left: {
    paddingRight: 32,
  },
  right: {
    paddingLeft: 32,
  },
}))

const Label = styled(Typography)(() => ({
  borderBottom: '1px solid #D2D2D2',
  color: '#898989',
}))
const Percent = styled(Typography)(() => ({
  textAlign: 'right',
  color: '#CCCCCC',
  fontSize: '3rem',
  fontWeight: 700,
  lineHeight: 1,
}))
const Count = styled(Typography)(() => ({
  fontSize: '3rem',
  fontWeight: 700,
  lineHeight: 1,
}))

interface IProps {
  label: string
  count: number
  percent?: number
  left?: boolean
  right?: boolean
  classes?: {
    percent?: string
    count?: string
  }
}

const StatItem: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  return (
    <Grid
      container
      direction={compactScreen ? 'column' : 'row'}
      className={classnames({
        [classes.left]: props.left,
        [classes.right]: props.right,
      })}
    >
      <Grid item xs={12}>
        <Label variant="subtitle2">{props.label}</Label>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Count className={props.classes && props.classes.count}>
          {props.count}
        </Count>
      </Grid>
      <Grid item xs={12} sm={6}>
        {typeof props.percent === 'number' && (
          <Percent className={props.classes && props.classes.percent}>
            {props.percent}%
          </Percent>
        )}
      </Grid>
    </Grid>
  )
}

export default StatItem
