import {gql} from 'apollo-boost'
import {NexusGenArgTypes, NexusGenFieldTypes} from 'kubik-server'
import {TZtppRebate, ZTPP_REBATE_FULL_FRAGMENT,} from '../../../../../../fragments/ZtppRebate'

export interface IOwnMonthlyHistoryZtppRebateData {
  user: {
    monthlyHistory: NexusGenFieldTypes['MonthlyRebate'] & {
      ztppRebate: TZtppRebate
      ztppRebates: TZtppRebate[]
    }
  }
}
export const OWN_MONTHLY_HISTORY_DISABILITY_REBATE = gql`
  query ownMonthlyHistoryZtppRebate {
    user {
      id
      monthlyHistory {
        id
        year
        ztppRebate {
          ...ZtppRebateFull
        }
        ztppRebates {
          ...ZtppRebateFull
        }
      }
    }
  }
  ${ZTPP_REBATE_FULL_FRAGMENT}
`

export interface IUpdateOwnMonthlyHistoryZtppRebateData {
  updateOwnMonthlyHistoryZtppRebate: NexusGenFieldTypes['ZtppRebate']
}
export type TUpdateOwnMonthlyHistoryZtppRebateVariables = NexusGenArgTypes['Mutation']['updateOwnMonthlyHistoryZtppRebate']
export const UPDATE_OWN_MONTHLY_HISTORY_DISABILITY_REBATE = gql`
  mutation updateOwnMonthlyHistoryZtppRebate(
    $data: OwnZtppAnnualRebateUpdateInput!
  ) {
    updateOwnMonthlyHistoryZtppRebate(data: $data) {
      ...ZtppRebateFull
    }
  }
  ${ZTPP_REBATE_FULL_FRAGMENT}
`

export interface IResetOwnMonthlyHistoryZtppRebateData {
  resetOwnMonthlyHistoryZtppRebate: TZtppRebate
}
export const RESET_OWN_MONTHLY_HISTORY_ZTPP_REBATE = gql`
  mutation resetOwnMonthlyHistoryZtppRebate {
    resetOwnMonthlyHistoryZtppRebate {
      ...ZtppRebateFull
    }
  }
  ${ZTPP_REBATE_FULL_FRAGMENT}
`
