import {
  CREATE_REBATE_FILE_EXPORT_BULK,
  REBATE_FILE_EXPORT_BULK,
  TCreateRebateFileExportBulkData,
  TCreateRebateFileExportBulkVariables,
  TFile,
  TRebateFileExportBulkData,
} from './gql'
import {downloadFile} from '../../../../utils'
import {Grid} from '@material-ui/core'
import {makeStyles, Theme, useTheme} from '@material-ui/core/styles'
import {Observer} from 'mobx-react'
import {useMutation, useQuery} from 'react-apollo'
import {useTranslation} from 'react-i18next'
import {StahnoutSoubor} from '../../../../components/icons/KubikIcons'
import {Center, IconCircle} from '../../../../components/CommonBox'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '../../../../components/Dialog'
import DialogActions from '../../../../components/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../../../../components/DialogTitle'
import React, {useRef, useState} from 'react'
import Select from '../../../../components/form/Select'
import useForm from '../../../../hooks/useForm'
import useYearsList from '../../../../hooks/useYearsList'
import GraphQLErrorSnackbar from '../../../../components/GraphQLErrorSnackbar'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import classnames from 'classnames'

interface IStylesProps {
  compactScreen?: boolean
  isNotSmallScreen?: boolean
}

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) => {
  return {
    buttonProgress: {
      // color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },

    closeIconButton: {
      position: 'absolute',
      top: theme.spacing(0.5),
      right: theme.spacing(0.5),
      padding: theme.spacing(1),
      fontSize: '1rem',
    },
    responsiveButton: {
      minHeight: (props) => (props.compactScreen ? '49px' : undefined),
      minWidth: (props) => (props.compactScreen ? '100%' : undefined),
    },
  }
})
interface IProps {
  onStartPolling: () => void
  onStopPolling: () => void
}

export const EXPORT_POLL_INTERVAL = 8000

const BulkExportButtonDialog: React.FC<IProps> = (props, context) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const classes = useStyles({ compactScreen, isNotSmallScreen })

  const [file, setFile] = useState<TFile | null>(null)
  const [inProgress, setInProgress] = useState(false)
  const [progress, setProgress] = useState(0)
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)

  const yearsList = useYearsList()

  const { error, startPolling, stopPolling } = useQuery<
    TRebateFileExportBulkData
  >(REBATE_FILE_EXPORT_BULK, {
    pollInterval: EXPORT_POLL_INTERVAL,
    notifyOnNetworkStatusChange: true,
    onCompleted({ rebateFileExportBulk }) {
      if (rebateFileExportBulk && rebateFileExportBulk.file) {
        setFile(rebateFileExportBulk.file)
      } else {
        setFile(null)
      }

      if (
        rebateFileExportBulk &&
        rebateFileExportBulk.status === 'IN_PROGRESS'
      ) {
        setInProgress(true)
        setProgress(rebateFileExportBulk.progress || 0)
      } else {
        setInProgress(false)
        stopPolling()
        props.onStopPolling()
      }

      if (!rebateFileExportBulk) {
        stopPolling()
        props.onStopPolling()
      }
    },
  })

  const [createRebateFileExportBulk, { error: error2 }] = useMutation<
    TCreateRebateFileExportBulkData,
    TCreateRebateFileExportBulkVariables
  >(CREATE_REBATE_FILE_EXPORT_BULK)

  const { bind, form } = useForm<TCreateRebateFileExportBulkVariables>(
    useRef({}).current,
    {
      year: {
        label: t('archive.yearSelection'),
        placeholder: t('archive.selectYear') + '...',
        rule: 'required',
        list: yearsList,
      },
      rebateType: {
        label: t('archive.formType'),
        placeholder: t('archive.formType2') + '...',
        rule: 'required',
        list: [
          { id: 'MONTHLY', text: t('common.monthlyRebates') },
          { id: 'ANNUAL', text: t('common.annualRebates') },
          { id: 'ALL', text: t('common.all') },
        ],
      },
      dateOfEmployment: {
        label: t('employee.dateOfEmployment'),
        help: t('employee.dateOfEmploymentHelp'),
        placeholder: 'dd. mm. yyyy',
      },
    },
    {
      async onSubmit({ year, rebateType, dateOfEmployment }) {
        try {
          //setOpen(false)
          await createRebateFileExportBulk({
            variables: {
              year,
              dateOfEmployment,
              ...((rebateType === 'MONTHLY' || rebateType === 'ANNUAL') && {
                rebateType,
              }),
            },
          })
          setInProgress(true)
          setProgress(0)
          startPolling(EXPORT_POLL_INTERVAL)
          props.onStartPolling()

          form.onSuccess({})
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  const handleDownload = () => {
    if (file) {
      downloadFile(file)
      setFile(null)
    }
  }

  return (
    <>
      <GraphQLErrorSnackbar error={error || error2} />
      {file ? (
        <Button
          color="primary"
          variant="contained"
          onClick={handleDownload}
          className={classnames(classes.responsiveButton)}
        >
          {t('common.download')}
        </Button>
      ) : (
        <Box position="relative">
          <Button
            color="primary"
            variant="contained"
            disabled={inProgress}
            onClick={handleOpen}
            className={classnames(classes.responsiveButton)}
          >
            <StahnoutSoubor />
            <Box component="span" ml={1}>
              {t('archive.export2')}
              {inProgress && (progress === 100? ` (100%, vytváření souboru...)` : ` (${progress}%)`)}
            </Box>
          </Button>
          {inProgress && (
              <CircularProgress size={24} className={classes.buttonProgress}/>
          )}
        </Box>
      )}

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle onClose={handleClose}>
          <Center mb={2}>
            <IconCircle fontSize="3rem">
              <StahnoutSoubor fontSize="inherit" color="primary" />
            </IconCircle>
          </Center>

          {t('archive.export')}
        </DialogTitle>

        <DialogContent>
          <Observer>
            {() => {
              const rebateType = form.getValue('rebateType')
              return (
                <Grid container spacing={1}>
                  <Grid xs={12} item>
                    <Select
                      {...bind('year')}
                      fullWidth
                      gutterBottom
                      valueField="value"
                    />
                    <Select {...bind('rebateType')} fullWidth gutterBottom />

                    {/*{rebateType === 'MONTHLY' && (*/}
                    {/*  <DatePicker*/}
                    {/*    fullWidth*/}
                    {/*    autoOk*/}
                    {/*    {...bind('dateOfEmployment')}*/}
                    {/*  />*/}
                    {/*)}*/}
                  </Grid>
                </Grid>
              )
            }}
          </Observer>
        </DialogContent>

        <DialogActions dividerProps={{ mt: 2 }} gutterBottom>
          <Box display="flex" justifyContent="center">
            {file ? (
              <Button
                color="primary"
                variant="contained"
                onClick={handleDownload}
              >
                {t('common.download')}
              </Button>
            ) : (
              <Box position="relative">
                <Button
                  color="primary"
                  variant="contained"
                  disabled={inProgress}
                  onClick={form.submit}
                  size="large"
                >
                  <StahnoutSoubor />
                  <Box component="span" ml={1}>
                    {t('archive.export2')}
                    {inProgress && (progress === 100? ` (100%, vytváření souboru...)` : ` (${progress}%)`)}
                  </Box>
                </Button>
                {inProgress && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Box>
            )}
            {/* <Button color="primary" variant="contained" onClick={form.submit}>
              {t('archive.export2')}
            </Button> */}
          </Box>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default BulkExportButtonDialog
