import { styled } from '@material-ui/core/styles'
import { SSO_LOGIN } from '../gql'
import { ssoLogin, ssoLoginVariables } from '../../../generated/ssoLogin'
import { useMutation } from '@apollo/react-hooks'
import { useRouter } from '../../../hooks'
import { useTranslation, Trans } from 'react-i18next'
import AppState from '../../../components/AppState'
import Box from '@material-ui/core/Box'
import Divider from '../../../components/Divider'
import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import CircularProgress from "@material-ui/core/CircularProgress";
import BoxBackground from '../../../components/BoxBackground'
import prihlaseniLeft from '../../../assets/images/Ilustrace-mzdova-ucetni.svg'
import prihlaseniRight from '../../../assets/images/Ilustrace-zamestnanec.svg'
import SelectedUser from "../../../containers/SelectedUser";
import useAccount from "../../../hooks/useAccount";

/**
 * stránka pro vyhodnocení tzv. SSO loginu v rámci přihlášení přes Nugget aplikaci
 */

const Container = styled('div')(() => ({
  boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.15)',
  width: 483,
  padding: 6 * 8,
}))

const SSOLoginConfirm: React.FC = () => {
  const { t } = useTranslation()
  const { match } = useRouter<{ token: string }>()
  const { refetch: refetchAccount } = useAccount()

  const [login, { error, loading }] = useMutation<ssoLogin, ssoLoginVariables>(
    SSO_LOGIN,
    {
      variables: { token: match.params.token },
      onCompleted() {
        window.location.assign('/')
      },
    },
  )
  SelectedUser.getInstance().resetLocalStorage()

  useEffect(() => {
    login().then(() => {
      refetchAccount()
    })
  }, [login])

  return (
    <BoxBackground left={prihlaseniLeft} right={prihlaseniRight} topBackgroundColor={'#ffffff'} bottomBackgroundColor={'#f8f8f8'}>
      <Container>
        <AppState loading={loading} />
        <Typography align="center" variant="h1" gutterBottom>
          <CircularProgress />
        </Typography>

        {error && (
          <Typography gutterBottom align="center">
            {t('loginConfirm.codeInvalid')}
          </Typography>
        )}

        <Divider spacing={4} />
        <Box textAlign="center" fontFamily="fontFamily" mt={3} mb={2}>
          <Trans i18nKey="common.needHelp">
            Potřebujete pomoct?
            <a href="mailto:infobot@payminator.cz">Napište nám.</a>
          </Trans>
        </Box>
      </Container>
    </BoxBackground>
  )
}

export default SSOLoginConfirm
