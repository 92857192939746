import {gql} from 'apollo-boost'
import {NexusGenArgTypes, NexusGenFieldTypes} from 'kubik-server'
import {STUDENT_REBATE_FULL_FRAGMENT, TStudentRebate,} from '../../../../../../fragments/StudentRebate'

export interface IOwnMonthlyStudentRebateData {
  user: {
    monthlyRebate: NexusGenFieldTypes['MonthlyRebate'] & {
      studentRebate: TStudentRebate
      studentRebates: TStudentRebate[]
    }
  }
}
export const OWN_MONTHLY_STUDENT_REBATE_STUDENT = gql`
  query ownMonthlyStudentRebate {
    user {
      id
      monthlyRebate {
        id
        year
        studentRebate {
          ...StudentRebateFull
        }
        studentRebates {
          ...StudentRebateFull
        }
      }
    }
  }

  ${STUDENT_REBATE_FULL_FRAGMENT}
`

export type TUpdateOwnMonthlyStudentRebateVariables = NexusGenArgTypes['Mutation']['updateOwnMonthlyStudentRebate']
export interface IUpdateOwnMonthlyStudentRebateData {
  updateOwnMonthlyStudentRebate: TStudentRebate
}
export const UPDATE_OWN_MONTHLY_STUDENT_REBATE_STUDENT = gql`
  mutation updateOwnMonthlyStudentRebate(
    $data: OwnStudentMonthlyRebateUpdateInput!
  ) {
    updateOwnMonthlyStudentRebate(data: $data) {
      ...StudentRebateFull
    }
  }
  ${STUDENT_REBATE_FULL_FRAGMENT}
`

export interface IResetOwnMonthlyStudentRebateData {
  resetOwnMonthlyStudentRebate: TStudentRebate
}
export const RESET_OWN_MONTHLY_STUDENT_REBATE = gql`
  mutation resetOwnMonthlyStudentRebate {
    resetOwnMonthlyStudentRebate {
      ...StudentRebateFull
    }
  }
  ${STUDENT_REBATE_FULL_FRAGMENT}
`
