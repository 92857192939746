import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'

const PridatStredisko40: React.FC<Props> = ({
  viewBox = '0 0 44 40',
  title,
  ...props
}: Props) => {
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      {title && <title>{title}</title>}
      <g id="Vrstva_2" data-name="Vrstva 2">
        <g id="Vrstva_1-2" data-name="Vrstva 1">
          <path
            d="M42.24,36.47H38.67V8A2.68,2.68,0,0,0,36,5.33H32V2.67A2.66,2.66,0,0,0,29.33,0H14.67A2.66,2.66,0,0,0,12,2.67V5.33H8A2.68,2.68,0,0,0,5.33,8V36.47H1.76a1.77,1.77,0,0,0,0,3.53H42.24a1.77,1.77,0,0,0,0-3.53ZM15.33,3.33H28.67v2H15.33ZM8.67,8.67H35.33v27.8H30.67V33A2.67,2.67,0,0,0,28,30.32H16A2.67,2.67,0,0,0,13.33,33v3.48H8.67Zm19.66,27.8H23.17V32.7s0,0,0-.05H28a.34.34,0,0,1,.33.34Zm-7.5-3.77v3.77H15.67V33a.34.34,0,0,1,.33-.34h4.84S20.83,32.68,20.83,32.7Z"
          />
          <rect
            x="13.33"
            y="12.39"
            width="4"
            height="4"
            rx="1.33"
            ry="1.33"
          />
          <rect
            x="13.33"
            y="17.73"
            width="4"
            height="4"
            rx="1.33"
            ry="1.33"
          />
          <rect
            x="13.33"
            y="23.06"
            width="4"
            height="4"
            rx="1.33"
            ry="1.33"
          />
          <rect
            x="20"
            y="12.39"
            width="4"
            height="4"
            rx="1.33"
            ry="1.33"
          />
          <rect
            x="20"
            y="17.73"
            width="4"
            height="4"
            rx="1.33"
            ry="1.33"
          />
          <rect
            x="20"
            y="23.06"
            width="4"
            height="4"
            rx="1.33"
            ry="1.33"
          />
          <rect
            x="26.67"
            y="12.39"
            width="4"
            height="4"
            rx="1.33"
            ry="1.33"
          />
          <rect
            x="26.67"
            y="17.73"
            width="4"
            height="4"
            rx="1.33"
            ry="1.33"
          />
          <rect
            x="26.67"
            y="23.06"
            width="4"
            height="4"
            rx="1.33"
            ry="1.33"
          />
        </g>
      </g>
    </SvgIcon>
  )
}

export default PridatStredisko40
