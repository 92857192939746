import {DatatableStateContext} from '../containers/DatatableStateProvider'
import {useApolloClient} from 'react-apollo'
import {useContext} from 'react'

const useDatatableRefetch = (globalKey: string) => {
  const { state } = useContext(DatatableStateContext)
  const client = useApolloClient()

  const query = state.queries[globalKey]
  const variables = state.variables[globalKey]
  return () => {
    //debugger
    if (query && variables) {
      client.query({
        query,
        variables,
        fetchPolicy: 'network-only',
      })
    }
  }
}

export default useDatatableRefetch
