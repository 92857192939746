import {Green} from '../../../../components/CommonBox'
import {FINISH_OWN_ENTRY_FORM, IFinishOwnEntryFormData} from './gql'
import {makeStyles, Theme, useTheme} from '@material-ui/core/styles'
import {Observer} from 'mobx-react'
import {useMutation} from 'react-apollo-hooks'
import {Trans, useTranslation} from 'react-i18next'
import AppState from '../../../../components/AppState'
import Box from '@material-ui/core/Box'
import Divider from '../../../../components/Divider'
import ErrorSnackbar from '../../../../components/ErrorSnackbar'
import React, {useEffect, useState} from 'react'
import Typography from '@material-ui/core/Typography'
import useForm from '../../../../hooks/useForm'
import {useScrollWindowToCenter, useUser} from '../../../../hooks'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {waitFor} from '../../../../utils'
import TermsConditions from "../Profile/TermsConditions";
import bgImage from "../../../../assets/images/svg/Ilustrace-dokonceni-registrace.svg";
import FormControls from "../../../../components/form/FormControls";
import useAccount from "../../../../hooks/useAccount";
import Checkbox from "../../../../components/form/Checkbox";

interface IStylesProps {
  compactScreen?: boolean
  isNotSmallScreen?: boolean
}

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) => ({
  marginBottom4: {
    marginBottom: theme.spacing(4),
  },
  marginTop3: {
    marginTop: theme.spacing(3),
  },
  backBtnContanier: {
    textAlign: 'right',
    color: '#898989',
  },
  iconContainer: {
    fontSize: '12rem',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  order2: {
    order: (props) => (props.isNotSmallScreen ? undefined : 2),
  },
}))

interface IProps {
  prevStep: () => void
}

const width = 1080

const Completion: React.FC<IProps> = (props) => {
  useScrollWindowToCenter()
  const { t } = useTranslation()

  const theme = useTheme()
  const { user } = useUser()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const classes = useStyles({ compactScreen, isNotSmallScreen })
  const {refetch: refetchAccount} = useAccount()

  const [loading, setLoading] = useState(true)
  const [termsConditions, setTermsConditions] = React.useState<boolean>(false)
  const [termsConditionsAgreed, setTermsConditionsAgreed] = React.useState<boolean>(false)
  const agreementRequired = user.data.customer.requireCheckOfTerms

  const finishEntryForm = useMutation<IFinishOwnEntryFormData>(
    FINISH_OWN_ENTRY_FORM,
  )

  // na tuto stránku se routuje z ukončení formuláře, které vyvolává me dotaz
  // workarround: musíme tedy počkat než tento dotaz doběhne
  useEffect(() => {
    const timeout = async () => {
      await waitFor(2000)
      setLoading(false)
    }
    timeout()
  }, [])

  const { form } = useForm(
    {},
    {},
    {
      async onSubmit(_, form) {
        try {
          const { data } = await finishEntryForm()
          if (data && data.finishOwnEntryForm) {
            refetchAccount()
            form.onSuccess(data.finishOwnEntryForm)
          }
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  return (
    <Observer>
      {() => (
        <Box maxWidth="100%" width={width}>
          <AppState loading={form.state.loading || loading} />

          <Typography
            align="center"
            variant="h1"
            color="primary"
          >
            {t('entryform.completionHeading')}
          </Typography>

          <Box
            display="flex"
            justifyContent="center"
            className={classes.iconContainer}
          >
            <img src={bgImage} width={"280px"}/>
          </Box>

          <Typography
            gutterBottom
            align="center"
            className={classes.marginBottom4}
          >
            <Trans i18nKey="entryform.completionContent">
              Úspěšně jste prošli registrací a nyní Vás bude čekat procházení daňových formulářů v aplikaci.

              <Divider width={"20%"} spacing={3} />
              Tlačítkem <Green>Dokončit</Green> potvrdíte vyplněné údaje v registraci. Tyto údaje si budete moci kdykoliv později změnit v sekci <Green>Osobní údaje</Green>.

              <Divider width={"20%"} spacing={3} />
              Podmínky a informace o zpracování osobních údajů v aplikaci naleznete
              <Green
                onClick={(e) => {
                  e.preventDefault()
                  setTermsConditions(true)
                }}
                style={{ textDecoration: 'underline', cursor: "pointer" }}>zde</Green>.

            </Trans>
          </Typography>


          <TermsConditions
              open={termsConditions}
              onClose={() => setTermsConditions(false)}
          />
          {agreementRequired && <Box maxWidth={'60%'} style={{margin: '0 auto'}}>
            <Divider spacing={4} />
            <Checkbox value={termsConditionsAgreed} onChange={value => setTermsConditionsAgreed(!!value) } label={<Trans i18nKey="entryform.agreementCheck"></Trans>} />
          </Box>}

          <Divider spacing={4} />
          <FormControls
            onSubmit={form.submit}
            onBack={props.prevStep}
            disabled={loading || (agreementRequired && !termsConditionsAgreed)}
            submitContent={t('common.finishRegistration')}
          />

          <ErrorSnackbar
            errors={form.state.errors}
            onClose={() => (form.state.errors = [])}
          />
        </Box>
      )}
    </Observer>
  )
}

export default Completion
