import {Box} from '@material-ui/core'
import {IconCircle} from '../../../../../components/CommonBox'
import {Observer} from 'mobx-react'
import {useTranslation} from 'react-i18next'
import Button from '@material-ui/core/Button'
import Dialog from '../../../../../components/Dialog'
import DialogActions from '../../../../../components/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../../../../../components/DialogTitle'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import MonthYearPicker from '../../../../../components/form/MonthYearPicker'
import Select from '../../../../../components/form/Select'
// import MultiSelect from '../../../../../components/form/MultiSelect'
import React from 'react'
import Exportovat from '../../../../../components/icons/ExportovatIcona'
import useForm from '../../../../../hooks/useForm'
import useUser from '../../../../../hooks/useUser'

export interface IProps {
  open: boolean
  onClose: () => void,
  onFormSent: (formData: IDialogData) => void
}

interface IDialogData {
  period: string
  type: 'ANNUAL' | 'MONTHLY'
}

const ExportDialog: React.FC<IProps> = props => {
  const { open, onClose, onFormSent } = props
  const { user } = useUser()
  const { t } = useTranslation()

  const { bind, form } = useForm<IDialogData>(
    {
      period: moment()
        .subtract(1, 'month')
        .toISOString(),
      type: 'MONTHLY',

    },
    {
      period: {
        rule: 'required',
        label: t('companySettings.exportDialog.chooseMonth'),
      },
      type: {
        rule: 'required',
        label: t('companySettings.exportDialog.choosePeriod'),
        list: [
          { id: 'ANNUAL', text: t('common.annualRebates') },
          { id: 'MONTHLY', text: t('common.monthlyRebates') },
        ],
      },
    },
    {
      onSubmit(formData, form) {
        form.onSuccess(formData)
        onFormSent({...formData})
        onClose()
      },
      submitWholeModel: true,
    },
  )

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      scroll="body"
      transitionDuration={{
        exit: 60,
      }}
    >
      <DialogTitle onClose={onClose}>
        <Box display="flex" alignItems="center" flexDirection="column" mb={-2}>
          <IconCircle mb={2} width={100} height={100}>
            <Exportovat fontSize="inherit" />
          </IconCircle>
          <Box>{t('companySettings.exportDialog.heading')}</Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid xs={12} item>
            <Observer>
              {() => {
                const type = form.getValue('type')
                return (
                  <>
                    <Select {...bind('type')} fullWidth gutterBottom />
                    {type === 'MONTHLY' && (
                      <>
                        <MonthYearPicker
                          {...bind('period')}
                          maxDate={moment()
                            .endOf('month')
                            .toISOString()}
                          minDate={moment(
                            '01.01.2020',
                            'DD.MM.YYYY',
                          ).toISOString()}
                          fullWidth
                          gutterBottom
                          openTo="month"
                          views={['year', 'month']}
                        />
                      </>
                    )}
                  </>
                )
              }}
            </Observer>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions gutterBottom dividerProps={{ mt: 3 }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => form.submit()}
        >
          {t('common.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ExportDialog
