import {gql} from 'apollo-boost'
import {MONTHLY_TAX_QUESTIONNAIRE_WITH_HISTORY} from '../../../../../../fragments/TaxQuestionnaire'

export const OWN_MONTHLY_TAX_QUESTIONNAIRE = gql`
  query userWithMonthlyTaxQuestionnaire {
    user {
      id
      monthlyRebate {
        id
        year
        wizardStep
        allowSendAnythingOnce
        taxQuestionnaire {
          ...MonthlyTaxQuestionnaireWithHistory
        }
      }
    }
  }

  ${MONTHLY_TAX_QUESTIONNAIRE_WITH_HISTORY}
`

export const UPDATE_MONTHLY_TAX_QUESTIONNAIRE = gql`
  mutation updateMonthlyTaxQuestionnaire(
    $data: MonthlyTaxQuestionnaireUpdateInput!
  ) {
    updateMonthlyTaxQuestionnaire(data: $data) {
      id
      createByActualEmployer
      madeByAnotherEmployer
    }
  }
`
