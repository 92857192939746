import 'chartjs-plugin-piechart-outlabels'
import {Doughnut} from 'react-chartjs-2'
import {ANNUAL_STATISTICS, IStatisticsData, MONTHLY_STATISTICS} from './gql'
import {percent} from '../../../../utils'
import {makeStyles, styled, useTheme} from '@material-ui/core/styles'
import {UpperHint} from '../../../../components/CommonBox'
import {useQuery} from '@apollo/react-hooks'
import {useTranslation} from 'react-i18next'
import AppState from '../../../../components/AppState'
import Box from '@material-ui/core/Box'
import Fade from '../../../../components/Fade'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../components/GraphQLErrorSnackbar'
import Grid from '@material-ui/core/Grid'
import InsideLayout from '../../../../components/layouts/InsideLayout'
import moment from 'moment'
import OnlyPeriod from './dialogs/OnlyPeriod'
import React from 'react'
import ReportLink from './components/ReportLink'
import ReportLinkDialog from './components/ReportLinkDialog'
import ScrollToTop from '../../../../components/ScrollToTop'
import StatItem from './components/StatItem'
import Toggle, {ToggleItem} from '../../../../components/Toggle'
import Typography from '@material-ui/core/Typography'
import useUser from '../../../../hooks/useUser'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(() => ({
  colorfullCount: {
    color: '#4EBE4F',
  },
  chartContainer: {
    position: 'relative',
  },
  percentLeft: {
    textAlign: 'left',
  },
}))

export const List = styled('ul')(() => ({
  paddingLeft: 16,
  marginTop: 8,
  marginBottom: 8,
  '& > li': {
    marginTop: 4,
    marginBottom: 4,
  },
}))

const Paper = styled(Box)(() => ({
  boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.15)',
}))

interface TStat {
  backgroundColor: string
  data: number
  label: string
  hide?: boolean
}
const prepareChartData = (stats: TStat[]) => {
  const labels: string[] = []
  const dataset: { backgroundColor: string[]; data: number[] } = {
    backgroundColor: [],
    data: [],
  }
  const zeroStats: TStat[] = []

  for (const stat of stats) {
    if (!stat.hide) {
      if (stat.data > 0) {
        labels.push(`${stat.label} (${stat.data})`)
        dataset.backgroundColor.push(stat.backgroundColor)
        dataset.data.push(stat.data)
      } else {
        zeroStats.push(stat)
      }
    }
  }
  for (const stat of zeroStats) {
    labels.push(`${stat.label} (${stat.data})`)
    dataset.backgroundColor.push(stat.backgroundColor)
  }

  return { labels, datasets: [dataset] }
}

const Statistics: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useUser()
  const { yearOfAnnualRebates, yearOfMonthlyRebates } = user.data.customer
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)

  const afterAnnual = moment(user.data.customer.annualRebatesPeriodEnd)
    .add(1, 'month')
    .endOf('month')
    .isBefore() // konec období + celý další měsíc
  const [toggle, setToggle] = React.useState<'MONTHLY' | 'ANNUAL'>(
    afterAnnual ? 'MONTHLY' : 'ANNUAL',
  )

  // const [annualStatistics, { called, loading, data, error }] = useLazyQuery<
  //   IStatisticsData
  // >(ANNUAL_STATISTICS, {
  //   fetchPolicy: 'cache-and-network',
  // })

  const { loading: aLoading, data: aData, error: aError } = useQuery<
    IStatisticsData
  >(ANNUAL_STATISTICS, {
    skip: toggle !== 'ANNUAL',
    fetchPolicy: 'cache-and-network',
  })

  const { loading: mLoading, data: mData, error: mError } = useQuery<
    IStatisticsData
  >(MONTHLY_STATISTICS, {
    skip: toggle !== 'MONTHLY',
    fetchPolicy: 'cache-and-network',
  })

  const loading = toggle === 'ANNUAL' ? aLoading : mLoading
  const error = toggle === 'ANNUAL' ? aError : mError

  //const doNotLoadWorkarround = user.data.customer.hideDocumentsForEmployees

  const doNotLoadWorkarround = false

  const data = doNotLoadWorkarround ? [] : toggle === 'ANNUAL' ? aData : mData

  const getOnRebate = (target: string): number => {
    return get(data, `statistics.${toggle.toLowerCase()}Rebates.${target}`, 0)
  }

  const numberOfActiveEmployees = getOnRebate('numberOfActiveEmployees')
  const numberOfRegisteredEmployees = getOnRebate('numberOfRegisteredEmployees')

  const numberOfApprovedApplications = getOnRebate(
    'numberOfApprovedApplications',
  )
  const numberOfDeniedApplications = getOnRebate('numberOfDeniedApplications')
  const numberOfPendingApplications = getOnRebate('numberOfPendingApplications')
  const numberOfUnsentApplications = getOnRebate('numberOfUnsentApplications')
  const numberOfPozpApplications =
    toggle === 'ANNUAL'
      ? (get(
          aData,
          'statistics.annualRebates.numberOfPozpApplications',
          0,
        ) as number)
      : 0
  const numberOfMonthlyDontCreateApplications =
    toggle === 'MONTHLY'
      ? (get(
          mData,
          'statistics.monthlyRebates.numberOfMonthlyDontCreateApplications',
          0,
        ) as number)
      : 0

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <AppState loading={loading} />
      <GraphQLErrorSnackbar error={error} />

      {data && (
        <Fade>
          <Box
            width={1077}
            maxWidth="100%"
            ml="auto"
            mr="auto"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography variant="h1">{t('common.statistics')}</Typography>

            <Toggle value={toggle} onChange={setToggle} boxProps={{ mt: 2 }}>
              <ToggleItem value="ANNUAL">
                {t('common.annualRebates')} {yearOfAnnualRebates}
              </ToggleItem>
              <ToggleItem value="MONTHLY">
                {t('common.monthlyRebates')} {yearOfMonthlyRebates}
              </ToggleItem>
            </Toggle>

            <Box
              width={compactScreen ? '100%' : 900}
              mb={1}
              maxWidth="100%"
              className={classes.chartContainer}
            >
              <Doughnut
                options={{
                  responsive: true,
                  maintainAspectRatio: true,
                  rotation: -0.75 * Math.PI,
                  animation: {
                    duration: 0, // general animation time
                  },
                  layout: { padding: isNotSmallScreen ? 50 : 8 },
                  tooltips: {
                    enabled: false,
                  },
                  legend: {
                    position: 'right',
                    fontWeight: 'bold',
                    onClick: () => {},
                    labels: {
                      // This more specific font property overrides the global property
                      fontColor: 'black',
                      fontWeight: 'bold',
                      fontSize: isNotSmallScreen ? 16 : 12,
                      fontFamily: 'Montserrat,sans-serif',
                      // paddingLeft: 20
                    },
                  },
                  plugins: {
                    outlabels: {
                      display: isNotSmallScreen,
                      backgroundColor: '',
                      lineColor: 'white',
                      stretch: 8,
                      text: '%p',
                      padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                      },
                      color: ({
                        dataIndex,
                        dataset,
                      }: {
                        dataIndex: number
                        dataset: { backgroundColor: string[]; data: number[] }
                      }) => dataset.backgroundColor[dataIndex],
                      font: {
                        weight: 700,
                        family: 'Montserrat,sans-serif',
                        minSize: isNotSmallScreen ? 16 : 12,
                        maxSize: isNotSmallScreen ? 16 : 12,
                      },
                    },
                  },
                }}
                data={prepareChartData([
                  {
                    label: t('reports.approvedApplicationsChartLabel'),
                    backgroundColor: '#4EBE4F',
                    data: numberOfApprovedApplications,
                  },
                  {
                    label: t('reports.pozpChartLabel'),
                    backgroundColor: '#3A9CB1',
                    data: numberOfPozpApplications,
                    hide: toggle !== 'ANNUAL',
                  },
                  {
                    label: t('reports.monthlyDontCreateChartLabel'),
                    backgroundColor: '#3A9CB1',
                    data: numberOfMonthlyDontCreateApplications,
                    hide: toggle !== 'MONTHLY',
                  },
                  {
                    label: t('reports.deniedApplicationsChartLabel'),
                    backgroundColor: '#D33D3D',
                    data: numberOfDeniedApplications,
                  },
                  {
                    label: t('reports.pendingApplicationsChartLabel'),
                    backgroundColor: '#1F55A1',
                    data: numberOfPendingApplications,
                  },
                  {
                    label: t('reports.unsentApplicationsChartLabel'),
                    backgroundColor: '#FFB85B',
                    data: numberOfUnsentApplications,
                  },
                ])}
              />
            </Box>

            <Paper
              width={1070}
              mt={4}
              mb={6}
              py={compactScreen ? 1 : 6}
              px={compactScreen ? 1 : 20}
              maxWidth="100%"
            >
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Typography variant="h2">{t('common.statistics')}</Typography>
                </Grid>
                <Grid item xs={compactScreen ? 12 : 6}>
                  <StatItem
                    label={t('reports.numberOfActiveUsers')}
                    count={numberOfActiveEmployees}
                    left={!compactScreen || undefined}
                  />
                </Grid>
                <Grid item xs={compactScreen ? 12 : 6}>
                  <StatItem
                    label={t('reports.numberOfRegisteredUsers')}
                    count={numberOfRegisteredEmployees}
                    percent={percent(
                      numberOfActiveEmployees,
                      numberOfRegisteredEmployees,
                    )}
                    right={!compactScreen || undefined}
                    classes={{
                      ...(compactScreen && { percent: classes.percentLeft }),
                    }}
                  />
                </Grid>
                <Grid item xs={compactScreen ? 12 : 6}>
                  <StatItem
                    label={t('reports.numberOfUsersSubmittingRequest')}
                    count={numberOfActiveEmployees - numberOfUnsentApplications}
                    percent={percent(
                      numberOfActiveEmployees,
                      numberOfActiveEmployees - numberOfUnsentApplications,
                    )}
                    left={!compactScreen || undefined}
                    classes={{
                      ...(compactScreen && { percent: classes.percentLeft }),
                    }}
                  />
                </Grid>
                <Grid item xs={compactScreen ? 12 : 6}>
                  <StatItem
                    label={t('reports.numberOfUsersApprovedRequest')}
                    count={
                      numberOfApprovedApplications +
                      numberOfPozpApplications +
                      numberOfMonthlyDontCreateApplications
                    }
                    percent={percent(
                      numberOfActiveEmployees,
                      numberOfApprovedApplications +
                        numberOfPozpApplications +
                        numberOfMonthlyDontCreateApplications,
                    )}
                    right={!compactScreen || undefined}
                    classes={{
                      count: classes.colorfullCount,
                      ...(compactScreen && { percent: classes.percentLeft }),
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>

            <Box width={750} mt={2} maxWidth="100%">
              <Typography variant="h2">{t('common.reports')}</Typography>
              <Typography>{t('reports.subtitle')}</Typography>

              <UpperHint mt={4} display="inline-block">
                {t('common.annualRebates')}{' '}
                {user.data.customer.yearOfAnnualRebates}
              </UpperHint>
              <List>
                <ReportLink report="employeeWithoutAnnualRequest">
                  {t('reports.employeeWithoutAnnualRequest')}
                </ReportLink>
                <ReportLink report="employeeApplyingAnnualRequest">
                  {t('reports.employeeApplyingAnnualRequest')}
                </ReportLink>
                <ReportLink report="employeeWithConfirmedAnnualRequest">
                  {t('reports.employeeWithConfirmedAnnualRequest')}
                </ReportLink>
                <ReportLink report="employeeWithPozpRequest">
                  {t('reports.employeeWithPozpRequest')}
                </ReportLink>
                <ReportLink report="employeeWithDeniedAnnualRequest">
                  {t('reports.employeeWithDeniedAnnualRequest')}
                </ReportLink>
              </List>

              <UpperHint mt={2} display="inline-block">
                {t('common.monthlyRebates')}{' '}
                {user.data.customer.yearOfMonthlyRebates}
              </UpperHint>
              <List>
                <ReportLink report="employeeWithoutMonthlyRequest">
                  {t('reports.employeeWithoutMonthlyRequest')}
                </ReportLink>
                <ReportLink report="employeeApplyingMonthlyRequest">
                  {t('reports.employeeApplyingMonthlyRequest')}
                </ReportLink>
                <ReportLink report="employeeWithConfirmedMonthlyRequest">
                  {t('reports.employeeWithConfirmedMonthlyRequest')}
                </ReportLink>
                <ReportLink report="employeeWithMonthlyDontCreateRequest">
                  {t('reports.employeeWithMonthlyDontCreateRequest')}
                </ReportLink>
                <ReportLink report="employeeWithDeniedMonthlyRequest">
                  {t('reports.employeeWithDeniedMonthlyRequest')}
                </ReportLink>
              </List>

              <UpperHint mt={2} display="inline-block">
                {t('common.other')}
              </UpperHint>
              <List>
                <ReportLink report="employees">
                  {t('reports.employees')}
                </ReportLink>
                <ReportLink report="unregisteredEmployees">
                  {t('reports.unregisteredEmployees')}
                </ReportLink>
                <ReportLink report="nonResidents">
                  {t('reports.nonResidents')}
                </ReportLink>
                <ReportLink report="deniedResidents">
                  {t('reports.deniedResidents')}
                </ReportLink>
                <ReportLink report="monthlyRebatesDetails">
                  {t('reports.monthlyRebatesDetails')}
                </ReportLink>
                <ReportLinkDialog
                  report="monthlyRebatesEnding"
                  label={t('reports.monthlyRebatesEnding')}
                >
                  <OnlyPeriod />
                </ReportLinkDialog>
                <ReportLinkDialog
                  report="monthlyRebatesChildrenUnderEnding"
                  label={t('reports.monthlyRebatesChildrenUnderEnding')}
                >
                  <OnlyPeriod />
                </ReportLinkDialog>
              </List>
            </Box>
          </Box>
        </Fade>
      )}
    </InsideLayout>
  )
}

export default Statistics
