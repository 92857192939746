import {Caj, Kvetina} from '../../../../components/icons/KubikIcons'
import Box from '@material-ui/core/Box'
import React from 'react'
import {useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

interface IProps {
  showNotice: boolean
}

const FooterIlustration: React.FC<IProps> = ({ showNotice }) => {
  const theme = useTheme()
  const smDown = theme.breakpoints.down('sm')
  const smallScreen = useMediaQuery(smDown)

  return (
    <Box
      position={smallScreen ? undefined : 'absolute'}
      maxWidth="100%"
      width="calc(100% - 64px)"
      bottom={16}
      display="flex"
      flexDirection="column"
      zIndex={2}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        zIndex={2}
        alignItems="baseline"
      >
        <Kvetina style={{ fontSize: '8rem' }} />
        <Caj
          style={{
            marginRight: showNotice ? 216 : 25,
            fontSize: '6rem',
          }}
        />
      </Box>
      <Box
        border="1px solid #eee"
        style={{ backgroundColor: '#eee' }}
        borderRadius={8}
        width="100%"
        height={10}
        zIndex={1}
        marginTop={-0.5}
      />
    </Box>
  )
}

export default FooterIlustration
