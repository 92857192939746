import { makeStyles } from '@material-ui/core/styles'
import {Trans, useTranslation} from 'react-i18next'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import classnames from 'classnames'
import DocumentSelect from './DocumentSelect'
import FileList, { TGridItemProps } from './FileList'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import useUser from '../../hooks/useUser'
import {Bold, Green} from '../../components/CommonBox'
import HelpAdornment from './HelpAdornment'
import {Close} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  container: {
    border: '2px dashed #CCCCCC',
    borderRadius: theme.shape.borderRadius * 1.5,
    padding: theme.spacing(1, 1, 2, 1),
    textAlign: 'center',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    transition: theme.transitions.create(['border-color'], {
      duration: theme.transitions.duration.shorter,
    }),
  },
  errorBorder: {
    borderColor: theme.palette.error.main,
  },
  button: {
    textDecoration: 'underline',
  },
  buttonBusy: {
    textDecoration: 'none',
    cursor: 'default',
    color: theme.palette.error.main,
    '&:hover': { color: theme.palette.error.main },
  },
  fileInput: {
    zIndex: 100,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    cursor: 'pointer',
  },
  error: {
    color: theme.palette.error.main,
  },
}))

export interface IProps {
  value: any
  deletedValue?: any
  error?: string
  onRelationCreate: (data: any) => void
  onRelationConnect: (data: any) => void
  onRelationDelete: (value: string | number) => void
  onUndoRelationDelete: (value: string | number) => void
  className?: string
  maxSize?: number
  extensions?: string[]
  multiple?: boolean
  filelist?: boolean
  dense?: boolean
  disabled?: boolean
  preview?: boolean
  contentText?: string
  contentTextSub?: string
  valueField?: string
  itemGridProps?: TGridItemProps
  deleteButton?: boolean
  hideErrorText?: boolean
  showValidationUntouched?: boolean
  touched?: boolean
  onBlur: () => void
  onBusy?: () => void
  busy?: boolean
  isFileRelation?: boolean
  documentSelectWidth?: number
  label: string
  showLabel?: boolean
  help?: string
}

const Uploader: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    className,
    value,
    deletedValue,
    error,
    filelist = true,
    multiple = false,
    dense = false,
    disabled: _disabled = false,
    hideErrorText,
    showValidationUntouched,
    touched,
    onBlur,
    contentText,
    contentTextSub,
    onRelationCreate,
    onRelationConnect,
    onRelationDelete,
    onUndoRelationDelete,
    itemGridProps,
    extensions = [
      'gif',
      'png',
      'jpg',
      'jpeg',
      'bmp',
      'pdf',
      'xps',
      'oxps',
      'doc',
      'docx',
      'tif',
      'tiff',
    ],
    deleteButton,
    preview,
    onBusy,
    busy,
    isFileRelation = false,
    documentSelectWidth = 300,
    showLabel = false,
    label,
    help
  } = props
  const disabled = _disabled || busy

  const { user } = useUser()
  const [transferedFiles, setTransferedFiles] = useState<any[]>([])

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = e.target.files

      for (let index = 0; index < files.length; index++) {
        onRelationCreate(files.item(index))
      }
    }
  }

  const onClick = () => {
    setTimeout(() => {
      try {
        if (onBlur) {
          onBlur()
        }
      } catch (e) {}
    }, 800)
  }

  const onUploaderRelationDelete = (value: string | number) => {
    onRelationDelete(value)
    const filteredFiles = transferedFiles.filter((file) => file.id !== value)
    setTransferedFiles(filteredFiles)
  }

  const userDocuments = user.getDocumentsList()
  const useDocSelect = userDocuments.length > 0 && isFileRelation

  const getDocument = (docId: string) => {
    const doc = userDocuments.find((document) => document.id === docId)
    if (doc) {
      return doc
    }
    return null
  }

  const onDocumentSelect = (value: string) => {
    const document = getDocument(value)
    if (document) {
      // @ts-ignore  - určitě tam files jsou
      const file = document.files[0]
      onRelationConnect(file.id)
      if (!transferedFiles.includes((item: any) => item.id !== file.id)) {
        setTransferedFiles((prevState) => [...prevState, file])
      }
    }
  }

  const showError =
    (showValidationUntouched || touched) && !!error && !hideErrorText

  const id = Date.now().toString(32)

  return (
    <>
      {showLabel ? (
        <Bold display="flex" alignItems="center" mb={2}>
          {label}
          {help ? <HelpAdornment inline text={help} /> : null}
        </Bold>
      ) : null}
      <Grid container direction="row" spacing={0}>
        {!preview && (
          <Grid
            xs={12}
            item
            className={classnames(classes.container, className, {
              [classes.errorBorder]: showError,
            })}
          >
            <Button
              size="large"
              disabled={!busy && disabled}
              htmlFor={id}
              component="label"
              color="primary"
              className={busy && !onBusy ? classes.buttonBusy : classes.button}
              disableRipple={busy && !onBusy}
              disableFocusRipple={busy && !onBusy}
              onClick={() => {
                disabled && onBusy && onBusy()
              }}
            >
              {contentText || t('common.uploaderBtnText')}
            </Button>

            {!dense && (
              <Typography variant="body2" color="textSecondary">
                {contentTextSub || t('common.uploaderText')}
              </Typography>
            )}
            {!disabled && (
              <input
                onClick={onClick}
                // onBlur={() => onBlur()}
                accept={extensions.map((ext) => '.' + ext).join(', ')}
                className={classes.fileInput}
                value=""
                id={id}
                onChange={handleUpload}
                type="file"
                name="file"
                multiple={multiple}
              />
            )}
          </Grid>
        )}
        {showError && (
          <Grid item xs={12} hidden={preview}>
            <FormHelperText className={classes.error}>{error}</FormHelperText>
          </Grid>
        )}
        {useDocSelect && (
          <Box mt={4} maxWidth="100%" flexBasis="100%">
            <DocumentSelect
              onDocumentSelect={onDocumentSelect}
              width={documentSelectWidth}
            />
          </Box>
        )}
        {filelist && (value.length > 0) && (
          <Box mt={preview ? 0 : 2} width="100%">
            <FileList
              value={value.filter(
                (item: any) =>
                  !transferedFiles.includes((file: any) => file.id === item.id),
              )}
              disabled={disabled}
              onRelationDelete={onUploaderRelationDelete}
              onUndoRelationDelete={onUndoRelationDelete}
              deletedValue={deletedValue}
              itemGridProps={itemGridProps}
              deletable={deleteButton}
              transferedValue={transferedFiles}
            />
          </Box>
        )}
        {!filelist && (value.length > 0) && <div style={{margin: '0.5rem 0', display: 'flex', justifyContent: 'space-between', width: '100%'}}>
          <Green>
            <Trans i18nKey={'common.pickedFiles'}
                    tOptions={{filesCount: value.length}}>Vybráno <Green>5</Green> souborů.</Trans>
          </Green>
          <IconButton size="small" onClick={() => {
            for (let v of value) {
              onUploaderRelationDelete(v.id)
            }
          }}>
            <CloseIcon />
          </IconButton>
        </div> }
      </Grid>
    </>
  )
}

export default Uploader
