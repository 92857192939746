import DatePicker, {TMaybeDate,} from '../../../../../components/form/DatePicker'
import {Observer} from 'mobx-react'
import {Bold, SpaceBetween} from '../../../../../components/CommonBox'
import {useTranslation} from 'react-i18next'
import Box from '@material-ui/core/Box'
import React from 'react'

interface IProps {
  bind: (fieldPath: string) => any
  yearStart?: TMaybeDate
  yearEnd?: TMaybeDate
  confirmationValidityFrom?: TMaybeDate
  confirmationValidityTo?: TMaybeDate
}

const CloseMonthlyPeriodSection: React.FC<IProps> = props => {
  const { t } = useTranslation()
  const {
    bind,
    children,
    yearStart,
    yearEnd,
    confirmationValidityFrom,
    confirmationValidityTo,
  } = props

  return (
    <SpaceBetween maxWidth="100%" width={680} alignItems="flex-start" my={2}>
      <Bold lineHeight="49px">{children}</Bold>

      <Observer>
        {() => (
          <Box width={225}>
            <DatePicker
              minDates={[
                {
                  date: yearStart,
                  message: t('error.minDateMonthlyYearStart'),
                },
                {
                  date: confirmationValidityFrom,
                  message: t('error.minDatePrevConfirmedRebateValidFrom'),
                },
              ]}
              maxDates={[
                { date: yearEnd, message: t('error.maxDateMonthlyYearEnd') },
                {
                  date: confirmationValidityTo,
                  message: t('error.maxDatePrevConfirmedRebateValidTo'),
                },
              ]}
              hideLabel
              autoOk
              {...bind('closePeriodAt')}
            />
          </Box>
        )}
      </Observer>
    </SpaceBetween>
  )
}

export default CloseMonthlyPeriodSection
