import {gql} from 'apollo-boost'
import {NexusGenFieldTypes} from 'kubik-server'

export interface IStartMonthlyRebateWizardData {
  startMonthlyRebateWizard: Pick<
    NexusGenFieldTypes['MonthlyRebate'],
    | 'id'
    | 'year'
    | 'submitedAt'
    | 'wizardStartedAt'
    | 'wizardFinishedAt'
    | 'createdAt'
  >
}
export const START_MONTHLY_REBATE_WIZARD = gql`
  mutation startMonthlyRebateWizard {
    startMonthlyRebateWizard {
      id
      year
      submitedAt
      createdAt
      wizardStartedAt
      wizardFinishedAt
      wizardStep
      settlementRequest {
        id
        status
      }
      settlementRequests {
        id
        status
      }
      taxQuestionnaire {
        id
        status
      }
      basicRebate {
        id
        status
      }
      studentRebate {
        id
        status
      }
      disabilityRebate {
        id
        status
      }
      ztppRebate {
        id
        status
      }
      childrenRebate {
        id
        status
        children {
          id
          status
        }
        otherParentStatus
      }
    }
  }
`
