import {
  IOwnMonthlyHistoryBasicRebateData,
  IResetOwnMonthlyHistoryBasicRebateData,
  IUpdateOwnMonthlyHistoryBasicRebateData,
  OWN_MONTHLY_HISTORY_BASIC_REBATE,
  RESET_OWN_MONTHLY_HISTORY_BASIC_REBATE,
  TUpdateOwnMonthlyHistoryBasicRebateVariables,
  UPDATE_OWN_MONTHLY_HISTORY_BASIC_REBATE,
} from './gql'
import {Center, Divider, Green,} from '../../../../../../components/CommonBox'
import ChangeRebateButton, {ChangeRebateChoice,} from '../../../AnnualRebates/components/ChangeRebateButton'
import {getPrevRebates, getRebateStats} from '../../utils'
import {Observer} from 'mobx-react'
import {pickId, yearRange} from '../../../../../../utils'
import {TBasicRebate} from '../../../../../../fragments/BasicRebate'
import {useMutation, useQuery} from 'react-apollo'
import {Trans, useTranslation} from 'react-i18next'
import AppState from '../../../../../../components/AppState'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import BasicRebateOverview from '../../../AnnualRebates/components/BasicRebateOverview'
import BasicWithPeriodsFormSection from '../../../../../../shared/BasicWithPeriodsFormSection'
import Box from '@material-ui/core/Box'
import CancelRebateDialog from '../../../../../../components/CancelRebateDialog'
import CancelRebateSection from '../../../AnnualRebates/components/CancelRebateSection'
import Fade from '../../../../../../components/Fade'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import moment from 'moment'
import Poplatnik from '../../../../../../components/icons/Poplatnik'
import PrevRebate from '../../../AnnualRebates/components/PrevRebate'
import React from 'react'
import RebateActions from '../../../AnnualRebates/components/RebateActions'
import RebateLayout from '../../../../../../components/layouts/RebateLayout'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import useForm, {FormProvider} from '../../../../../../hooks/useForm'
import useRouter from '../../../../../../hooks/useRouter'
import useUser from '../../../../../../hooks/useUser'

const BasicRebate: React.FC = () => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { history } = useRouter()

  const [updateRebate, { loading: updateLoading, error: updateError }] =
    useMutation<
      IUpdateOwnMonthlyHistoryBasicRebateData,
      TUpdateOwnMonthlyHistoryBasicRebateVariables
    >(UPDATE_OWN_MONTHLY_HISTORY_BASIC_REBATE)

  const [resetRebate, { loading: resetLoading, error: resetError }] =
    useMutation<IResetOwnMonthlyHistoryBasicRebateData>(
      RESET_OWN_MONTHLY_HISTORY_BASIC_REBATE,
    )

  const { data, loading, error } = useQuery<IOwnMonthlyHistoryBasicRebateData>(
    OWN_MONTHLY_HISTORY_BASIC_REBATE,
    { fetchPolicy: 'network-only' },
  )
  const year: number = get(data, 'user.monthlyHistory.year')
  const formData = get(data, 'user.monthlyHistory.basicRebate') || {}
  const [yearStart, yearEnd] = yearRange(year)

  const translationYear = year === 2022 ? `_${year}` : ''

  const { bind, form } = useForm<
    TUpdateOwnMonthlyHistoryBasicRebateVariables['data']
  >(
    formData,
    {
      status: {
        label: t('monthlyBasicRebate.applyLabel'),
        help: t('monthlyBasicRebate.applyHelp'),
      },
      prevYearPeriods: {
        type: 'multi_relation',
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
      },
      affidavitFiles: {
        type: 'multi_relation',
        label: t('common.affidavit'),
        isFileRelation: true,
      },
    },
    {
      async onSubmit(data, form) {
        try {
          await updateRebate({
            variables: {
              data: {
                ...data,
                status: data.status || 'APPLY',
              },
            },
          })
          history.push(`/${user.data.id}/monthly-history`)
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  const prevRebates = getPrevRebates<TBasicRebate>(
    get(data, 'user.monthlyHistory.basicRebates'),
  )

  const {
    isFirstRequest,
    isPrevDenied,
    isPrevRemoved,
    showingCommentOfDenied,
    isCancelable,
    closePeriodTookEffect,
  } = getRebateStats<TBasicRebate>(
    get(data, 'user.monthlyHistory.basicRebates'),
  )

  const cleanRebate = async () => {
    if (formData) {
      const prevYearPeriods = formData.prevYearPeriods || []
      await updateRebate({
        variables: {
          data: {
            status: 'NEW',
            type: 'NEW',
            prevYearPeriods: {
              delete: prevYearPeriods.map(pickId),
            },
          },
        },
      })
    }
  }

  const onCancelRebate = async () => {
    if (formData) {
      const type = form.getValue('type')
      const alreadyRemoved = type === 'REMOVE'

      if (isPrevDenied && !alreadyRemoved) {
        await updateRebate({
          variables: {
            data: {
              status: 'APPLY',
              type: 'REMOVE',
            },
          },
        })
      } else {
        await cleanRebate()
      }
      history.push(`/${user.data.id}/monthly-history`)
    }
  }

  const handleAlter = async () => {
    await resetRebate()
    form.setField('type', 'ALTER')
    form.setField('status', 'APPLY')
  }
  const handleRemove = async () => {
    await cleanRebate()
    form.setField('type', 'REMOVE')
    form.setField('status', 'APPLY')
  }

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <AppState loading={loading || updateLoading || resetLoading} />
      <GraphQLErrorSnackbar error={error || updateError || resetError} />

      {data && (
        <Fade>
          <RebateLayout
            commentOfDenied={showingCommentOfDenied}
            sideHint={
              <Trans i18nKey={`monthlyBasicRebate.sideHint${translationYear}`}>
                Jako zaměstnanec máte automaticky právo uplatnit
                <Green>Základní slevu na poplatníka</Green> u jednoho
                zaměstnavatele (ten, u kterého se rozhodnete uplatňovat daňové
                slevy a podepsat
                <Box component="span" fontStyle="italic">
                  Prohlášení poplatníka daně z příjmu fyzických osob ze závislé
                  činnosti
                </Box>
                ). Roční výše slevy je až XXX Kč na dani (což činí XXXXX Kč
                měsíčně).
              </Trans>
            }
            upperHint={`${t('common.monthlyRebates')} ${year} (${String(
              t('common.history'),
            ).toLowerCase()})`}
            heading={t('monthlyBasicRebate.heading')}
            subHeading={<Trans i18nKey="monthlyBasicRebate.subHeading">Chcete-li uplatňovat Základní slevu na poplatníka, <Green>vyplňte datum uplatňování</Green></Trans>}
            icon={<Poplatnik fontSize="large" />}
          >
            {isCancelable && (
              <Box display="flex" justifyContent="center" mb={4}>
                <CancelRebateDialog onSubmit={onCancelRebate} />
              </Box>
            )}

            <Observer>
              {() => {
                const type = form.getValue('type')
                const status = form.getValue('status')
                const affidavitFiles = form.getValue('affidavitFiles')
                const hasAffidavit = affidavitFiles && affidavitFiles.length > 0

                if (type === 'REMOVE') {
                  return (
                    <CancelRebateSection
                      onUndo={() => form.setField('type', 'NEW')}
                    >
                      {t('rebateChanges.cancelBasic')}
                    </CancelRebateSection>
                  )
                }

                if (
                  isFirstRequest ||
                  isPrevDenied ||
                  isPrevRemoved ||
                  status === 'DENIED' ||
                  type === 'ALTER'
                ) {
                  return (
                    <FormProvider form={form}>
                      <BasicWithPeriodsFormSection
                        year={year}
                        bind={bind}
                        minDates={[
                          {
                            date: yearStart,
                            message: t('error.minDateMonthlyYearStart'),
                          },
                          ...(moment(user.data.dateOfEmployment).date() === 1 ||
                          hasAffidavit
                            ? [
                                {
                                  date: user.data.dateOfEmployment,
                                  message: t('error.minDateOfEmploment'),
                                },
                              ]
                            : [
                                {
                                  date: moment(user.data.dateOfEmployment)
                                    .add(1, 'month')
                                    .startOf('month'),
                                  message: t(
                                    'error.minDateOfEmploment_NextMonth',
                                  ),
                                },
                              ]),
                        ]}
                        maxDates={[
                          {
                            date: yearEnd,
                            message: t('error.maxDateMonthlyYearEnd'),
                          },
                        ]}
                      />
                    </FormProvider>
                  )
                }

                return (
                  <Center maxWidth="100%" width={680}>
                    <ChangeRebateButton>
                      <ChangeRebateChoice
                        variant="ALTER"
                        onClick={handleAlter}
                      />
                      <ChangeRebateChoice
                        variant="REMOVE"
                        onClick={handleRemove}
                      />
                    </ChangeRebateButton>
                  </Center>
                )
              }}
            </Observer>

            <Observer>
              {() => {
                const type = form.getValue('type')
                const status = form.getValue('status')
                const showFormSection =
                  isFirstRequest ||
                  isPrevDenied ||
                  isPrevRemoved ||
                  status === 'DENIED' ||
                  type === 'ALTER'
                return (
                  <Center maxWidth="100%" width={680} mt={3}>
                    <ArchiveChanges expand={!showFormSection}>
                      {prevRebates.map((rebate) => (
                        <PrevRebate
                          key={rebate.id}
                          settlementRequest={rebate.settlementRequest}
                          status={rebate.status}
                          commentOfDenied={rebate.commentOfDenied}
                        >
                          <BasicRebateOverview data={rebate} isHistory/>
                        </PrevRebate>
                      ))}
                    </ArchiveChanges>
                  </Center>
                )
              }}
            </Observer>

            <Divider my={6} />

            <Observer>
              {() => (
                <RebateActions
                  backTo={`/${user.data.id}/monthly-history`}
                  onSubmit={form.submit}
                  isDirty={form.state.isDirty}
                  formLoading={form.state.loading}
                />
              )}
            </Observer>
          </RebateLayout>
        </Fade>
      )}
    </InsideLayout>
  )
}

export default BasicRebate
