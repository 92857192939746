import Props from '../../../../icons/iconsType'
import React, { forwardRef } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Prazdne = forwardRef<SVGSVGElement, Props>(({ viewBox = '0 0 17.99 18', ...props }, ref) => (
  <SvgIcon viewBox={viewBox} {...props} ref={ref}>
    <>
      <defs>
        <style>.cls-MenuPrazdne-1{'{fill:#fff;}'}</style>
      </defs>
      <g id="Vrstva_2" data-name="Vrstva 2">
        <g id="Vrstva_1-2" data-name="Vrstva 1">
          <path
            className="cls-MenuPrazdne-1"
            d="M16.14,3.55l.49-.49a1.2,1.2,0,0,0-1.7-1.7l-.48.49a9,9,0,0,0-12.6,12.6l-.49.49a1.2,1.2,0,0,0,.85,2,1.24,1.24,0,0,0,.85-.35l.48-.49a9,9,0,0,0,12.6-12.6ZM2.4,9A6.59,6.59,0,0,1,12.73,3.56L3.56,12.74A6.49,6.49,0,0,1,2.4,9Zm11.26,4.67a6.6,6.6,0,0,1-8.4.77l9.17-9.18A6.61,6.61,0,0,1,13.66,13.67Z"
          />
        </g>
      </g>
    </>
  </SvgIcon>
))

Prazdne.displayName = 'Prazdne'

export default Prazdne
