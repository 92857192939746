import {gql} from 'apollo-boost'

export const CUSTOMERS = gql`
  query customersPage(
    $where: CustomerWhereInput
    $skip: Int
    $first: Int
    $orderBy: CustomerOrderByInput
    $type: CustomerTypeEnum
  ) {
    customers(
      where: $where
      first: $first
      skip: $skip
      orderBy: $orderBy
      type: $type
    ) {
      items {
        id
        name
        ico
        dic
        createdAt
        activationAt
        address
        numberOfActiveUsers
        blockedAt
        activationFormStep
        activationFormDoneAt
        yearOfAnnualRebates
        yearOfMonthlyRebates
        newYearSetting {
          id
          yearOfAnnualRebates
          yearOfMonthlyRebates
          annualRebatesPeriodStart
          annualRebatesPeriodEnd
          invitationPeriodStart
          invitationPeriodEnd
        }
      }
      total
    }
  }
`

export const CUSTOMER_DELETE_SUPERADMIN = gql`
  mutation customerDeleteSuperadmin($where: CustomerWhereUniqueInput!) {
    customerDeleteSuperadmin(where: $where) {
      id
      status
      metaArgs
    }
  }
`

export const CUSTOMER_BLOCK_SUPERADMIN = gql`
  mutation customerBlockSuperadmin(
    $where: CustomerWhereUniqueInput!
    $value: Boolean!
  ) {
    customerBlockSuperadmin(where: $where, value: $value) {
      id
      name
      blockedAt
    }
  }
`
export const DELETE_CUSTOMER_POOLING = gql`
  query deleteCustomerPooling($where: FileProcessingWhereInput!) {
    deleteCustomerPooling(where: $where) {
      id
      status
      result
    }
  }
`
