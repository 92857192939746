import * as React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Props from './iconsType'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  'bleel-1': { fill: '#ffccb8' },
  'bleel-2': { fill: '#eaa992' },
  'bleel-3': { fill: '#e6e7e8' },
  'bleel-4': { fill: '#d6d6d6' },
  'bleel-5': { fill: '#53a1e0' },
  'bleel-6': { fill: '#ff92c6' },
  'bleel-7': { fill: '#f2bba5' },
  'bleel-8': { fill: '#4fff30' },
  'bleel-9': { fill: '#fff' },
  'bleel-10': { fill: '#efdf6c' },
  'bleel-11': { fill: '#e0cb4c' },
  'bleel-12': { fill: '#86c2ea' },

  /* viz https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/SvgIcon/SvgIcon.js */
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(60),
  },
  fontSizeDefault: {
    fontSize: theme.typography.pxToRem(75),
  },
  fontSizeLarge: {
    fontSize: theme.typography.pxToRem(115),
  },
}))

const Pece: React.FC<Props> = ({
  viewBox = '0 0 66.08 75.64',
  fontSize,
  ...props
}: Props) => {
  const classes = useStyles()
  return (
    <SvgIcon
      viewBox={viewBox}
      className={classnames({
        [classes.fontSizeDefault]: fontSize === 'default' || !fontSize,
        [classes.fontSizeSmall]: fontSize === 'small',
        [classes.fontSizeLarge]: fontSize === 'large',
      })}
      fontSize={fontSize}
      {...props}
    >
      <>
        <title>Péče</title>
        <defs>
          <style>{".peces-1{fill:none;stroke:#b2b2b2;stroke-dasharray:0 3;stroke-linecap:round;stroke-miterlimit:10;}.peces-2{fill:#d32f2f;}.peces-3{fill:#ccc;}.peces-4{fill:#e6e7e8;}.peces-5{fill:#9d9d9c;}.peces-6{fill:#efdf6c;}.peces-7{fill:#ef8181;}"}</style>
        </defs>
          <g id="Vrstva_1-2">
            <path className="peces-2"
                  d="M48.51,31.14c-.74,4.07-3.82,7.42-6.99,10.31-3.11,2.84-6.5,5.56-8.48,9.15-1.98-3.59-5.37-6.32-8.48-9.15-3.17-2.9-6.25-6.24-6.99-10.31-.49-2.67.17-5.58,2.08-7.64,1.91-2.05,5.14-3.09,7.92-2.23,2.59.8,5.47,4.01,5.47,4.01,0,0,2.88-3.22,5.47-4.01,2.78-.85,6.01.18,7.92,2.23,1.91,2.05,2.56,4.96,2.08,7.64Z"/>
            <path className="peces-4"
                  d="M0,7.43h0v9.22c0,25.31,11.5,45.69,33.04,58.99h0c21.54-13.29,33.04-33.68,33.04-58.99V7.43h0C44.04-2.48,22.04-2.48,0,7.43Z"/>
            <path className="peces-3"
                  d="M33.04,4.03c10.59,0,19.27,2.03,29.01,6.05v6.57c0,22.03-9.24,41.19-29.01,54.21V4.03Z"/>
            <path className="peces-7"
                  d="M33.04,4.03c10.59,0,19.27,2.03,29.01,6.05v6.57c0,22.03-9.24,41.19-29.01,54.21C13.27,57.84,4.03,38.68,4.03,16.65v-6.57c9.75-4.02,18.42-6.05,29.01-6.05M33.04,0C21.22,0,11.02,2.48,0,7.43H0v9.22C0,41.96,11.5,62.35,33.04,75.64h0c21.54-13.29,33.04-33.68,33.04-58.99V7.43C55.06,2.48,44.85,0,33.04,0h0Z"/>
            <path className="peces-3"
                  d="M5.64,17.85v-6.36c9.75-3.89,18.42-5.85,29.01-5.85,10.01,0,18.13,1.78,27.4,5.26v-.81c-9.75-4.02-18.42-6.05-29.01-6.05S13.77,6.06,4.03,10.08v6.57c0,22.03,9.24,41.19,29.01,54.21.42-.27.81-.57,1.21-.85C14.74,57.43,5.64,39.01,5.64,17.85Z"/>
            <path className="peces-5" d="M33.04,69.19v1.68c.42-.27.81-.57,1.21-.85-.42-.27-.81-.56-1.21-.83Z"/>
            <path className="peces-5"
                  d="M33.04,4.03v1.65c.54-.01,1.07-.04,1.61-.04,10.01,0,18.13,1.78,27.4,5.26v-.81c-9.75-4.02-18.42-6.05-29.01-6.05Z"/>
            <path className="peces-1"
                  d="M33.04,8.2c8.67,0,15.78,1.73,23.76,5.16v5.6c0,18.76-7.57,35.09-23.76,46.18-16.19-11.09-23.76-27.42-23.76-46.18v-5.6c7.98-3.42,15.09-5.16,23.76-5.16"/>
            <path className="peces-6"
                  d="M40.71,1.16c0,2.25-1.82,4.07-4.07,4.07,2.25,0,4.07,1.82,4.07,4.07,0-2.25,1.82-4.07,4.07-4.07-2.25,0-4.07-1.82-4.07-4.07Z"/>
            <path className="peces-6"
                  d="M47.54,52.39c0,2.25-1.82,4.07-4.07,4.07,2.25,0,4.07,1.82,4.07,4.07,0-2.25,1.82-4.07,4.07-4.07-2.25,0-4.07-1.82-4.07-4.07Z"/>
            <path className="peces-6"
                  d="M5.92,26.61c0,2.25-1.82,4.07-4.07,4.07,2.25,0,4.07,1.82,4.07,4.07,0-2.25,1.82-4.07,4.07-4.07-2.25,0-4.07-1.82-4.07-4.07Z"/>
            <path className="peces-5"
                  d="M50.02,31.9c-.74,4.07-3.82,7.42-6.99,10.31-3.11,2.84-6.5,5.56-8.48,9.15-1.98-3.59-5.37-6.32-8.48-9.15-3.17-2.9-6.25-6.24-6.99-10.31-.49-2.67.17-5.58,2.08-7.64,1.91-2.05,5.14-3.09,7.92-2.23,2.59.8,5.47,4.01,5.47,4.01,0,0,2.88-3.22,5.47-4.01,2.78-.85,6.01.18,7.92,2.23,1.91,2.05,2.56,4.96,2.08,7.64Z"/>
            <path className="peces-2"
                  d="M48.51,31.14c-.74,4.07-3.82,7.42-6.99,10.31-3.11,2.84-6.5,5.56-8.48,9.15-1.98-3.59-5.37-6.32-8.48-9.15-3.17-2.9-6.25-6.24-6.99-10.31-.49-2.67.17-5.58,2.08-7.64,1.91-2.05,5.14-3.09,7.92-2.23,2.59.8,5.47,4.01,5.47,4.01,0,0,2.88-3.22,5.47-4.01,2.78-.85,6.01.18,7.92,2.23,1.91,2.05,2.56,4.96,2.08,7.64Z"/>
          </g>
      </>
    </SvgIcon>
  )
}

export default Pece
