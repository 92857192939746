import {
  CUSTOMER_UPDATE,
  CUSTOMER_UPDATE_SUPERADMIN,
  ICustomerUpdateData,
  ICustomerUpdateVariables,
  NEW_CUSTOMER_ROLES,
  NEW_CUSTOMER_UNITS,
} from '../CustomerActivation/gql'
import {CUSTOMER_UNITS_ROLES, ICustomerData, ICustomerVariables, SUPERADMIN_CUSTOMER,} from './gql'
import {Role} from '../../../../generated/globalTypes'
import {
  customerUpdateSuperadmin,
  customerUpdateSuperadminVariables,
} from '../../../../generated/customerUpdateSuperadmin'
import {superadminCustomer, superadminCustomerVariables,} from '../../../../generated/superadminCustomer'
import {superadminUnits, superadminUnitsVariables,} from '../../../../generated/superadminUnits'
import {
  superadminUsers,
  superadminUsers_superadminUsers,
  superadminUsersVariables,
} from '../../../../generated/superadminUsers'
import {NastaveniSpolecnosti2} from '../../../../components/icons/KubikIcons'
import {Observer} from 'mobx-react'
import {Bold, SpaceBetween} from '../../../../components/CommonBox'
import {useMutation, useQuery} from 'react-apollo'
import {useTranslation} from 'react-i18next'
import {RouteComponentProps, withRouter} from 'react-router-dom'
import AppState from '../../../../components/AppState'
import Box from '@material-ui/core/Box'
import DatePicker from '../../../../components/form/DatePicker'
import Divider from '../../../../components/Divider'
import FormControls from '../../../../components/form/FormControls'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../components/GraphQLErrorSnackbar'
import InsideLayout from '../../../../components/layouts/InsideLayout'
import moment from 'moment'
import React from 'react'
import Roles from '../CustomerActivation/Roles'
import ScrollToTop from '../../../../components/ScrollToTop'
import Snackbars from '../../../../components/form/Snackbars'
import Switch from '../../../../components/form/Switch'
import TextField from '../../../../components/form/TextField'
import Typography from '@material-ui/core/Typography'
import Units from '../CustomerActivation/Units'
import useForm from '../../../../hooks/useForm'
import useRouter from '../../../../hooks/useRouter'
import useSnackbar from '../../../../hooks/useSnackbar'
import useUser from '../../../../hooks/useUser'
import Select from '../../../../components/form/Select'
import {useExportTypeEnum} from '../../../../hooks'

type TRole = Omit<superadminUsers_superadminUsers, '__typename'>

const Layout: React.FC<{ isSuperadmin: boolean; children: React.ReactNode }> = (
  props,
) => {
  const { isSuperadmin, children } = props
  return (
    <>
      {isSuperadmin ? (
        <div>{children}</div>
      ) : (
        <InsideLayout sidebar>{children}</InsideLayout>
      )}
    </>
  )
}

const EditData: React.FC<RouteComponentProps<{
  userId: string
  customerId?: string
}>> = (props) => {
  const { t } = useTranslation()
  const { history } = useRouter()
  const { user } = useUser()
  const { addMessage } = useSnackbar()

  const customerId = props.match.params.customerId

  const isSuperadmin = user.isSuperadmin()

  const {
    data: customerData,
    loading: customerLoading,
    error: customerError,
  } = useQuery<ICustomerData, ICustomerVariables>(CUSTOMER_UNITS_ROLES, {
    variables: {
      roles: [
        'ACCOUNTANT',
        'IMPLEMENTATOR',
        'VIEWER',
        ...(!user.isKeeper() ? ['KEEPER' as 'KEEPER'] : []),
      ],
    },
    skip: isSuperadmin,
  })

  const {
    data: superadminData,
    loading: superadminLoading,
    error: superadminError,
  } = useQuery<superadminCustomer, superadminCustomerVariables>(
    SUPERADMIN_CUSTOMER,
    {
      variables: {
        where: { id: customerId! },
      },
      skip: !isSuperadmin,
      fetchPolicy: 'network-only',
    },
  )

  const { data: rolesData } = useQuery<
    superadminUsers,
    superadminUsersVariables
  >(NEW_CUSTOMER_ROLES, {
    variables: {
      where: {
        customerId: customerId!,
        role_not: Role.EMPLOYEE,
      },
    },
    skip: !isSuperadmin,
    fetchPolicy: 'network-only',
  })

  const { data: unitsData } = useQuery<
    superadminUnits,
    superadminUnitsVariables
  >(NEW_CUSTOMER_UNITS, {
    variables: {
      where: { customerId: customerId! },
    },
    skip: !isSuperadmin,
    fetchPolicy: 'network-only',
  })

  const data = customerData || superadminData
  const loading = customerLoading || superadminLoading
  const error = customerError || superadminError

  const divideByUnits = get(data, 'customer.divideByUnits')
  const units = get(data, 'units') || (unitsData && unitsData.superadminUnits)
  const customer = get(data, 'customer')
  const roles = get(data, 'roles') || (rolesData && rolesData.superadminUsers)
  const ssoSetting =
    get(data, 'customer.ssoClientId') &&
    get(data, 'customer.ssoTenantId') &&
    get(data, 'customer.ssoScopes')

  const [customerUpdate] = useMutation<
    ICustomerUpdateData,
    ICustomerUpdateVariables
  >(CUSTOMER_UPDATE, {
    onCompleted() {
      addMessage(t('common.change', { context: 'SUCCESS' }))
    },
  })

  const [customerUpdateSuperadmin] = useMutation<
    customerUpdateSuperadmin,
    customerUpdateSuperadminVariables
  >(CUSTOMER_UPDATE_SUPERADMIN, {
    onCompleted() {
      addMessage(t('common.change', { context: 'SUCCESS' }))
    },
  })

  const { bind, form } = useForm<
    | (ICustomerUpdateVariables['data'] & { ssoSetting: boolean })
    | (customerUpdateSuperadminVariables['data'] & { ssoSetting: boolean })
  >(
    { ...customer, ssoSetting } || {},
    {
      activationAt: {
        label: t('customerActivation.activationAt'),
        help: t('customerActivation.activationAtHelp'),
        placeholder: 'dd. mm. yyyy',
        rule: 'required|date',
      },
      activationAnnualtax: {
        help: t('customerActivation.annualtaxHelp'),
      },

      divideByUnits: {
        help: t('customerActivation.divideByUnitsHelp'),
      },
      sharedMessages: {
        help: t('customerActivation.sharedMessagesHelp'),
      },
      allowFilesUpload: {
        help: t('customerActivation.allowFilesUploadHelp'),
      },
      annualRebatesPeriodStart: {
        label: t('customerActivation.annualRebatesPeriodStart'),
        help: t('customerActivation.annualRebatesPeriodStartHelp'),
        placeholder: 'dd. mm. yyyy',
        rule: () => (isSuperadmin ? 'required|date' : ''),
      },
      annualRebatesPeriodEnd: {
        label: t('customerActivation.annualRebatesPeriodEnd'),
        help: t('customerActivation.annualRebatesPeriodEndHelp'),
        placeholder: 'dd. mm. yyyy',
        rule: () => (isSuperadmin ? 'required|date' : ''),
      },
      annualRebatesPeriodExtraEnd: {
        label: t('customerActivation.annualRebatesPeriodExtraEnd'),
        help: t('customerActivation.annualRebatesPeriodExtraEndHelp'),
        placeholder: 'dd. mm. yyyy',
      },
      ssoSetting: {
        help: t('customerActivation.ssoSettingHelp'),
        omitOnSubmit: true,
      },
      ssoClientId: {
        label: t('customerActivation.ssoClientId'),
        placeholder: t('customerActivation.ssoClientId'),
        rule: (data) => (Boolean(data.ssoSetting) ? 'required' : ''),
      },
      ssoTenantId: {
        label: t('customerActivation.ssoTenantId'),
        placeholder: t('customerActivation.ssoTenantId'),
        rule: (data) => (Boolean(data.ssoSetting) ? 'required' : ''),
      },
      ssoScopes: {
        label: t('customerActivation.ssoScopes'),
        placeholder: t('customerActivation.ssoScopes'),
        rule: (data) => (Boolean(data.ssoSetting) ? 'required' : ''),
      },


      allowOpenID: {
        label: t('openID.allowOpenID'),
      },
      openIDUrl: {
        label: t('openID.openIDUrl'),
        placeholder: t('openID.openIDUrl'),
        rule: (data) => (Boolean(data.allowOpenID) ? 'required' : ''),
      },
      openIDClientId: {
        label: t('openID.openIDClientId'),
        placeholder: t('openID.openIDClientId'),
        rule: (data) => (Boolean(data.allowOpenID) ? 'required' : ''),
      },
      openIDClientSecret: {
        label: t('openID.openIDClientSecret'),
        placeholder: t('openID.openIDClientSecret'),
        rule: (data) => (Boolean(data.allowOpenID) ? 'required' : ''),
      },

      annualRebatesExportType: {
        label: t('customerActivation.annualRebatesExportType'),
        list: useExportTypeEnum().list,
        rule: 'required',
      },
      allowDocumentsForViewer: {
        label: t('customerActivation.allowDocumentsForViewer'),
        help: t('customerActivation.allowDocumentsForViewerHelp'),
      },
      dontRequireBasicRebateAffidavitFiles: {
        label: t('customerActivation.dontRequireBasicRebateAffidavitFiles'),
        help: t('customerActivation.dontRequireBasicRebateAffidavitFilesHelp'),
      },
      allowApplyCurrentMonth: {
        label: t('customerActivation.allowApplyCurrentMonth'),
        help: t('customerActivation.allowApplyCurrentMonthHelp'),
      },
      allowMessages: {
        label: t('customerActivation.allowMessages'),
        help: t('customerActivation.allowMessagesHelp'),
      },
      allowMessagesForViewer: {
        label: t('customerActivation.allowMessagesForViewer'),
        help: t('customerActivation.allowMessagesForViewerHelp'),
      },

      allowPayslipsMultiupload: {
        label: t('customerActivation.allowPayslipsMultiupload'),
        help: t('customerActivation.allowPayslipsMultiupload'),
      },
      requireCheckOfTerms: {
        label: t('customerActivation.requireCheckOfTerms'),
        help: t('customerActivation.requireCheckOfTerms'),
      },

    },
    {
      async onSubmit(formData, form) {
        try {
          if (!isSuperadmin) {
            const { data } = await customerUpdate({
              variables: { data: formData },
            })
            if (data && data.customerUpdate) {
              form.onSuccess(data.customerUpdate)
              history.push(`/${user.data.id}/company-settings`, {
                activationAt: formData.activationAt,
              })
            }
          } else {
            const { data: superadminData } = await customerUpdateSuperadmin({
              variables: {
                where: {
                  id: form.getValue('id'),
                },
                data: formData as customerUpdateSuperadminVariables['data'],
              },
            })
            if (superadminData && superadminData.customerUpdateSuperadmin) {
              form.onSuccess(superadminData.customerUpdateSuperadmin)
              history.push(`/${user.data.id}/superadmin/customers`)
            }
          }
        } catch (err) {
          form.onFail(err)
        }
      },
      onFieldChange(fieldPath, value, form, error) {
        if (fieldPath === 'ssoSetting' && !error) {
          if (value === false) {
            form.setField('ssoClientId', null)
            form.setField('ssoTenantId', null)
            form.setField('ssoScopes', null)
          }
        }
      },
    },
  )

  return (
    <Layout isSuperadmin={isSuperadmin}>
      <ScrollToTop />
      <Observer>
        {() => <AppState loading={form.state.loading || loading} />}
      </Observer>
      <GraphQLErrorSnackbar error={error} />
      <Box
        maxWidth="100%"
        width={1077}
        ml="auto"
        mr="auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography variant="h1" align="center">
          {t('companySettings.subHeading2')}
        </Typography>
        {isSuperadmin && customer && (
          <Typography variant="h3" align="center">
            {customer.name}
          </Typography>
        )}

        <Box display="flex" justifyContent="center" my={4}>
          <NastaveniSpolecnosti2 fontSize="large" />
        </Box>

        <Box maxWidth="100%" width={753}>
          <SpaceBetween my={6}>
            <Bold display="inline">{t('customerActivation.activationAt')}</Bold>

            <Box maxWidth="100%" width={220}>
              <Observer>
                {() => {
                  const initDate = form.initials.activationAt
                  const disabled =
                    initDate && moment(initDate).isSameOrBefore(moment(), 'day')
                  return (
                    <DatePicker
                      minDate={moment().add(1, 'day')}
                      hideLabel
                      autoOk
                      {...bind('activationAt')}
                      disabled={disabled}
                    />
                  )
                }}
              </Observer>
            </Box>
          </SpaceBetween>

          <Box my={6}>
            <Observer>
              {() => (
                <Switch
                  {...bind('activationAnnualtax')}
                  label={`${t(
                    'common.annualRebates',
                  )} (${new Date().getFullYear() - 1})`}
                />
              )}
            </Observer>
          </Box>

          {isSuperadmin && form.getValue('activationAnnualtax') === true && (
            <>
              <Box my={6}>
                <Observer>
                  {() => {
                    const initDate = form.initials.activationAt
                    const initDateMoment = moment(initDate)
                    const disabled = false
                    return (
                      <DatePicker
                        minDate={initDateMoment.startOf('year')}
                        inlineLabel={t(
                          'customerActivation.annualRebatesPeriodStart',
                        )}
                        autoOk
                        {...bind('annualRebatesPeriodStart')}
                        disabled={disabled}
                      />
                    )
                  }}
                </Observer>
              </Box>

              <Box my={6}>
                <Observer>
                  {() => {
                    const initDate = form.initials.activationAt
                    const initDateMoment = moment(initDate)
                    const disabled = false
                    return (
                      <DatePicker
                        minDate={initDateMoment.startOf('year')}
                        inlineLabel={t(
                          'customerActivation.annualRebatesPeriodEnd',
                        )}
                        autoOk
                        {...bind('annualRebatesPeriodEnd')}
                        disabled={disabled}
                      />
                    )
                  }}
                </Observer>
              </Box>

              <Box my={6}>
                <Observer>
                  {() => {
                    const initDate = form.initials.activationAt
                    const initDateMoment = moment(initDate)
                    const disabled = false
                    return (
                      <DatePicker
                        minDate={initDateMoment.startOf('year')}
                        hideLabel
                        inlineLabel={t(
                          'customerActivation.annualRebatesPeriodExtraEnd',
                        )}
                        autoOk
                        {...bind('annualRebatesPeriodExtraEnd')}
                        disabled={disabled}
                      />
                    )
                  }}
                </Observer>
              </Box>
            </>
          )}

          <Box my={6}>
            <Observer>
              {() => {
                const initDate = form.initials.activationAt
                const disabled =
                  initDate && moment(initDate).isSameOrBefore(moment(), 'day')

                return (
                  <Switch
                    {...bind('divideByUnits')}
                    label={t('customerActivation.divideByUnits')}
                    disabled={disabled}
                  />
                )
              }}
            </Observer>
          </Box>

          <Box my={6}>
            <Observer>{() => <Switch {...bind('allowMessages')} />}</Observer>
          </Box>

          <Box my={6}>
            <Observer>
              {() => <Switch {...bind('allowMessagesForViewer')} />}
            </Observer>
          </Box>


          <Box my={6}>
            <Observer>
              {() => {
                return (
                  <Switch
                    {...bind('sharedMessages')}
                    label={t('customerActivation.sharedMessages')}
                  />
                )
              }}
            </Observer>
          </Box>

          <Box my={6}>
            <Observer>
              {() => {
                return (
                  <Switch
                    {...bind('allowDocumentsForViewer')}
                    label={t('customerActivation.allowDocumentsForViewer')}
                  />
                )
              }}
            </Observer>
          </Box>

          <Box my={6}>
            <Observer>
              {() => {
                return (
                  <Switch
                    {...bind('allowFilesUpload')}
                    label={t('customerActivation.allowFilesUpload')}
                  />
                )
              }}
            </Observer>
          </Box>

          <Box my={6}>
            <Observer>
              {() => {
                return (
                  <Switch
                    {...bind('dontRequireBasicRebateAffidavitFiles')}
                    label={t(
                      'customerActivation.dontRequireBasicRebateAffidavitFiles',
                    )}
                  />
                )
              }}
            </Observer>
          </Box>

          <Box my={6}>
            <Observer>
              {() => {
                return (
                  <Switch
                    {...bind('allowApplyCurrentMonth')}
                    label={t('customerActivation.allowApplyCurrentMonth')}
                  />
                )
              }}
            </Observer>
          </Box>

          <Box my={6}>
            <>
              <Observer>
                {() => {
                  const isSSO = form.getValue('ssoSetting')

                  return (
                    <>
                      <Switch
                        {...bind('ssoSetting')}
                        label={t('customerActivation.ssoSetting')}
                      />
                      {isSSO && (
                        <Box mt={3}>
                          <TextField
                            gutterBottom
                            inline
                            {...bind('ssoClientId')}
                          />
                          <TextField
                            gutterBottom
                            inline
                            {...bind('ssoTenantId')}
                          />
                          <TextField
                            gutterBottom
                            inline
                            {...bind('ssoScopes')}
                          />
                        </Box>
                      )}
                    </>
                  )
                }}
              </Observer>
            </>
          </Box>


          <Box my={6}>
            <>
              <Observer>
                {() => {
                  const isOpenId = form.getValue('allowOpenID')

                  return (
                    <>
                      <Switch
                        {...bind('allowOpenID')}
                      />
                      {isOpenId && (
                        <Box mt={3}>
                          <TextField
                            gutterBottom
                            inline
                            {...bind('openIDUrl')}
                          />
                          <TextField
                            gutterBottom
                            inline
                            {...bind('openIDClientId')}
                          />
                          <TextField
                            gutterBottom
                            inline
                            {...bind('openIDClientSecret')}
                          />
                        </Box>
                      )}
                    </>
                  )
                }}
              </Observer>
            </>
          </Box>

          <Box my={6}>
            <Observer>
              {() => {
                return (
                  <Select
                    gutterBottom
                    inline
                    {...bind('annualRebatesExportType')}
                  />
                )
              }}
            </Observer>
          </Box>

          {user.data.role === 'SUPERADMIN' && <>
            <Divider spacing={6}/>
            <Box my={6}>
              <Observer>
                {() => <Switch {...bind('allowPayslipsMultiupload')} />}
              </Observer>
            </Box>

            <Box my={6}>
              <Observer>
                {() => <Switch {...bind('requireCheckOfTerms')} />}
              </Observer>
            </Box>
          </>}
          </Box>

        {divideByUnits && units && (
          <Box maxWidth="100%" width={753}>
            <Divider spacing={4} />
            <Units units={units} customer={customer} />
          </Box>
        )}



        <Divider spacing={6} />

        {customer && roles && (
          <Box maxWidth="100%" width={753}>
            <Roles customer={customer} roles={roles as TRole[]} blockRole />
          </Box>
        )}

        <Divider spacing={4} />

        <FormControls
          onSubmit={form.submit}
          onBack={() =>
            isSuperadmin
              ? props.history.push(`/${user.data.id}/superadmin/customers`)
              : props.history.push(
                  `/${props.match.params.userId}/company-settings`,
                )
          }
          submitContent={t('common.saveChanges')}
        />

        <Snackbars form={form} />
      </Box>
    </Layout>
  )
}

export default withRouter(EditData)
