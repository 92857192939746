import {gql} from 'apollo-boost'
import {NexusGenArgTypes, NexusGenFieldTypes} from 'kubik-server'
import {PENSION_INSURANCE_REBATE_FULL_FRAGMENT} from '../../../../../../fragments/PensionInsuranceRebate'
import {LONGTERMCARE_REBATE_FULL_FRAGMENT} from "../../../../../../fragments/LongTermCareRebate";
import {INVESTMENT_REBATE_FULL_FRAGMENT} from "../../../../../../fragments/InvestmentRebate";
import {LIFE_INSURANCE_REBATE_FULL_FRAGMENT} from "../../../../../../fragments/LifeInsuranceRebate";

export type TPensionInsuranceRebate = NexusGenFieldTypes['PensionInsuranceRebate']

export interface IOwnAnnualPensionInsuranceRebateData {
  user: {
    annualRebate: NexusGenFieldTypes['AnnualRebate'] & {
      pensionInsuranceRebate: TPensionInsuranceRebate
      pensionInsuranceRebates: TPensionInsuranceRebate[]
    }
  }
}
export const OWN_ANNUAL_PENSION_INSURANCE_REBATE = gql`
  query ownAnnualPensionInsuranceRebate {
    user {
      id
      annualRebate {
        id
        year
        pensionInsuranceRebate {
            ...PensionInsuranceRebateFull
        }
        pensionInsuranceRebates(orderBy: createdAt_DESC) {
            ...PensionInsuranceRebateFull
        }
        investmentRebate {
            ...InvestmentRebateFull
        }
        investmentRebates(orderBy: createdAt_DESC) {
            ...InvestmentRebateFull
        }
        lifeInsuranceRebate {
            ...LifeInsuranceRebateFull
        }
        lifeInsuranceRebates(orderBy: createdAt_DESC) {
            ...LifeInsuranceRebateFull
        }
        longTermCareRebate {
            ...LongTermCareRebateFull
        }
        longTermCareRebates(orderBy: createdAt_DESC) {
            ...LongTermCareRebateFull
        }
        limits {
            pensionShared {
                max
            }
        }
      }
    }
  }
  ${PENSION_INSURANCE_REBATE_FULL_FRAGMENT}
  ${LONGTERMCARE_REBATE_FULL_FRAGMENT}
  ${INVESTMENT_REBATE_FULL_FRAGMENT}
  ${LIFE_INSURANCE_REBATE_FULL_FRAGMENT}
`

export interface IUpdateOwnAnnualPensionInsuranceRebateData {
  updateOwnAnnualPensionInsuranceRebate: NexusGenFieldTypes['PensionInsuranceRebate']
}
export type TUpdateOwnAnnualPensionInsuranceRebateVariables = NexusGenArgTypes['Mutation']['updateOwnAnnualPensionInsuranceRebate']
export const UPDATE_OWN_ANNUAL_PENSION_INSURANCE_REBATE = gql`
  mutation updateOwnAnnualPensionInsuranceRebate(
    $data: OwnPensionInsuranceRebateUpdateInput!
  ) {
    updateOwnAnnualPensionInsuranceRebate(data: $data) {
      ...PensionInsuranceRebateFull
    }
  }
  ${PENSION_INSURANCE_REBATE_FULL_FRAGMENT}
`

export interface IResetOwnAnnualPensionInsuranceData {
  resetOwnAnnualPensionInsuranceRebate: TPensionInsuranceRebate
}
export const RESET_OWN_ANNUAL_PENSION_INSURANCE_REBATE = gql`
  mutation resetOwnAnnualPensionInsuranceRebate {
    resetOwnAnnualPensionInsuranceRebate {
      ...PensionInsuranceRebateFull
    }
  }
  ${PENSION_INSURANCE_REBATE_FULL_FRAGMENT}
`
