import React, {useState} from 'react'
import {TFile} from "../../Archive/gql";
import {useMutation, useQuery} from "react-apollo";
import {
  CREATE_REBATES_EXPORT,
  REBATES_EXPORT,
  TCreateRebateFileExportVariables,
  TCreateRebatesExportData,
  TRebatesExportData
} from "../gql";
import ExportDialog from "./ExportDialog";
import {Center} from "../../../../../components/CommonBox";
import IconButton from "@material-ui/core/IconButton";
import {Exportovat} from "../../../../../components/icons/KubikIcons";
import {makeStyles, styled} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import {downloadFile} from "../../../../../utils";
import CircularProgress from "@material-ui/core/CircularProgress";

export interface IProps {
}

const ExportButton = styled(Button)(() => ({
  backgroundColor: '#00A2E3',
  '&:hover': {
    backgroundColor: '#02ACF0',
  },
}))



const useStyles = makeStyles((theme) => ({
  pridatIconContainer: {
    fontSize: '0.9rem',
    marginRight: theme.spacing(1),
  },
}))

const RebatesExport: React.FC<IProps> = props => {

  const classes = useStyles()
  const { t } = useTranslation()
  const [exportDialogOpen, setExportDialogOpen] = React.useState(false)
  const [exportInProgress, setExportInProgress] = useState(false)
  const [exportFile, setExportFile] = useState<TFile | null>(null)

  const { error, startPolling, stopPolling } = useQuery<
    TRebatesExportData
  >(REBATES_EXPORT, {
    pollInterval: 4000,
    notifyOnNetworkStatusChange: true,
    onCompleted({ rebatesExport }) {
      if (rebatesExport && rebatesExport.file && rebatesExport.file.id) {
        setExportFile(rebatesExport.file)
        if (exportInProgress) { // download when was still running
          downloadFile(rebatesExport.file)
        }
      } else {
        setExportFile(null)
      }

      if (
        rebatesExport &&
        rebatesExport.status === 'IN_PROGRESS'
      ) {
        setExportInProgress(true)
      } else {
        setExportInProgress(false)
        stopPolling()
      }
    },
  })
  const [createRebatesExport, { error: error2 }] = useMutation<
    TCreateRebatesExportData,
    TCreateRebateFileExportVariables
  >(CREATE_REBATES_EXPORT)

  return (<>
    <Center mt={2}>
      {!exportInProgress && <ExportButton
        onClick={() => setExportDialogOpen(true)}
        variant="contained"
        color="primary"
      >
        {/* <span className={classes.pridatIconContainer}>
                    <StahnoutArchiv fontSize="inherit" />
                  </span> */}
        <IconButton
          className={classes.pridatIconContainer}
          size="small"
          color="inherit"
        >
          <Exportovat/>
        </IconButton>
        {t('companySettings.exportButton')}
      </ExportButton>}
      {exportInProgress && <CircularProgress />}

      {!exportInProgress && !!exportFile && <div style={{padding: '1rem'}}><Button
        color="primary"
        variant="contained"
        onClick={() => {
          downloadFile(exportFile)
        }}
      >
        {t('common.download')}
      </Button></div>}
    </Center>

    <ExportDialog
      onClose={() => setExportDialogOpen(false)}
      onFormSent={async (formData) => {
        await createRebatesExport({
          variables: {
            ...formData
          },
        })
        setExportInProgress(true)
        startPolling(2000)
      }}
      open={exportDialogOpen}
    />
  </>)
}

export default RebatesExport
