import {InlineContainer, InlineLabelContainer,} from '../../../../../../components/form/InlineFormContainer'
import {styled, Theme} from '@material-ui/core/styles'
import {Bold} from '../../../../../../components/CommonBox'
import {useTranslation} from 'react-i18next'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import React from 'react'
import TextField from '../../../../../../components/form/TextField'
import HelpAdornment from '../../../../../../components/form/HelpAdornment'
import {makeStyles} from '@material-ui/styles'

const useStyles = makeStyles<Theme>((theme) => ({
  addButton: {
    "& svg": {
      marginRight: "0.25rem"
    }
  },
  input: {
    [theme.breakpoints.down('sm')]: {
      paddingRight: 110,
    },
  }
}))

const Wrapper = styled('div')(({ theme }) => ({
  '&:first-child': {
    paddingTop: 0,
  },
  '&:not(:first-child)': {
    paddingTop: 12,
  },
  // [theme.breakpoints.down('sm')]: {
  //   paddingRight: 45,
  // },
}))
const InputContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%'
}))
const IconsWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  height: '100%',
  left: 'calc(100% + 8px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    right: 8,
    left: 'unset',
  },
}))

interface IProps {
  error?: string
  fullWidth?: boolean
  onBlur: () => void
  onListSet: (value: string[]) => void
  touched?: boolean
  value?: string[]
  help?: string
}

const Addresses: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const { onListSet, error, touched, help } = props
  let value = props.value || []
  if (value.length === 0) {
    value = ['']
  }

  const handleAdd = () => {
    onListSet([...value, ''])
  }
  const handleRemove = (idx: number) => () => {
    const temp = [...value]
    temp.splice(idx, 1)
    onListSet(temp)
  }
  return (
    <>
      {value.map((v, idx, arr) => {
        return (
          <Wrapper key={idx} id="address-input-wrapper">
            <InlineContainer>
              <InlineLabelContainer>
                <Bold component="label">
                  {idx === 0
                    ? t('annualLoanRebate.addressesDescription', { context: "2023" })
                    : t('annualLoanRebate.addressesDescription3')}
                </Bold>
              </InlineLabelContainer>

              <InputContainer id="address-input-container">
                <TextField
                  inputProps={{
                    className: styles.input
                  }}
                  error={error}
                  touched={touched}
                  label=""
                  value={v}
                  fullWidth
                  placeholder={t('annualLoanRebate.addressesLabel') + '...'}
                  onChange={(text) => {
                    const temp = [...value]
                    temp[idx] = text as string
                    onListSet(temp)
                  }}
                />
                <IconsWrapper>
                  {idx !== 0 ? (
                    <IconButton size="small" onClick={handleRemove(idx)}>
                      <CloseIcon />
                    </IconButton>
                  ) : <HelpAdornment inline text={help} />}
                  {idx === arr.length - 1 && (
                    <Button
                      variant="text"
                      color="primary"
                      size="small"
                      onClick={handleAdd}
                      className={styles.addButton}
                    >
                      <AddCircleIcon />
                      {t('common.add')}
                    </Button>
                  )}
                </IconsWrapper>
              </InputContainer>
            </InlineContainer>
          </Wrapper>
        )
      })}
    </>
  )
}

export default Addresses
