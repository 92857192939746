import {
  Archivovat,
  IkonaZprava,
  IkonaZpravaBlue,
  Kos,
  Odeslat,
  Odpovedet,
  Smazat1,
} from '../../../../components/icons/KubikIcons'
import {MESSAGES, TMessage, TMessagesData, TMessagesVariables} from './gql'
import {makeStyles, Theme, useTheme} from '@material-ui/core/styles'
import {useTranslation} from 'react-i18next'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Datatable, {DatatableAction} from '../../../../components/Datatable'
import moment from 'moment'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import useUser from '../../../../hooks/useUser'
import {IActions} from './components/Message'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import classnames from 'classnames'
import ErrorButton from '../../../../components/ErrorButton'

interface IStylesProps {
  compactScreen?: boolean
  isNotSmallScreen?: boolean
}

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) => {
  return {
    actionIcon: {
      padding: theme.spacing(0.8),
      fontSize: '1rem',
      color: '#8a8a8a',
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
    outlinedButton: {
      borderRadius: '28px !important',
      backgroundColor: 'transparent',
      fontWeight: 'bold',
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      borderWidth: '2px !important',
      '&:hover': {
        color: '#FFFFFF',
        backgroundColor: '#4EBE4E',
      },
    },
    zpravaIlustraceIcon: {
      marginRight: theme.spacing(1),
      fontSize: '1.5rem',
    },
    responsiveButton: {
      minHeight: (props) => (props.compactScreen ? '49px' : undefined),
      minWidth: (props) => (props.compactScreen ? '100%' : undefined),
    },
    gridGap: {
      gridGap: (props) => (props.compactScreen ? theme.spacing(2) : 0),
    },
  }
})

interface IProps {
  globalKey: string
  type: TMessagesVariables['type']
  onRowClick: (data: TMessage) => void
  actions: IActions
}

const Table: React.FC<IProps> = (props) => {
  const { globalKey: GLOBAL_UNIQUE_KEY, type, onRowClick, actions } = props
  const { t } = useTranslation()
  const { user } = useUser()

  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const classes = useStyles({ compactScreen, isNotSmallScreen })

  return (
    <Datatable<TMessagesData['messages']['items'], TMessagesVariables>
      globalKey={GLOBAL_UNIQUE_KEY}
      query={{
        query: MESSAGES,
        dataField: 'messages',
        options: {
          fetchPolicy: 'cache-and-network',
        },
      }}
      searchFields={[
        ...(type === 'ARCHIVED'
          ? ['from.search_contains', 'to.search_contains']
          : []),
        ...(type === 'RECEIVED' ? ['from.search_contains'] : []),
        ...(type === 'SENT' ? ['to.search_contains'] : []),
        ...(type === 'TRASH'
          ? ['from.search_contains', 'to.search_contains']
          : []),
      ]}
      onRowClick={onRowClick}
      columns={[
        {
          key: 'user',
          label: t('message.message'),
          noWrap: true,
          render(data) {
            let fullname: string
            switch (type) {
              case 'RECEIVED':
                fullname = data.from.fullname
                break
              case 'SENT':
                fullname = data.to.fullname
                break
              default:
                if (data.from.id === user.data.id) fullname = data.to.fullname
                else fullname = data.from.fullname
                break
            }
            return (
              <Typography variant="h4" className={classes.message}>
                {data.from.id === user.data.id ? (
                  <IkonaZprava
                    fontSize="inherit"
                    className={classes.zpravaIlustraceIcon}
                  />
                ) : (
                  <IkonaZpravaBlue
                    fontSize="inherit"
                    className={classes.zpravaIlustraceIcon}
                  />
                )}
                {fullname}
              </Typography>
            )
          },
        },
        {
          key: 'subject',
          field: 'subject',
          label: t('message.subject'),
          ellipsis: true,
        },
        {
          key: 'createdAt',
          label: t('message.time'),
          shrink: true,
          render(data) {
            return moment(data.createdAt).format('DD.M.YYYY, HH:mm')
          },
        },
        {
          key: 'actions',
          shrink: true,
          render(data) {
            return (
              <Box display="flex" alignItems="normal">
                <DatatableAction
                  tooltip={t('message.respond')}
                  onClick={() => actions.new({ data, action: 'RESPOND' })}
                  className={classes.actionIcon}
                >
                  <Odpovedet fontSize="inherit" />
                </DatatableAction>
                {type !== 'ARCHIVED' && (
                  <DatatableAction
                    tooltip={t('message.archive')}
                    onClick={() => actions.archive(data)}
                    className={classes.actionIcon}
                  >
                    <Archivovat fontSize="inherit" />
                  </DatatableAction>
                )}
                <DatatableAction
                  tooltip={t('message.delete')}
                  onClick={() => actions.trash(data)}
                  className={classes.actionIcon}
                >
                  <Smazat1 fontSize="inherit" />
                </DatatableAction>
              </Box>
            )
          },
        },
      ]}
      orderByList={[
        // TBD další řazení (v různých tabech atd)
        { value: 'subject_ASC', text: t('message.subject_ASC') },
        { value: 'subject_DESC', text: t('message.subject_DESC') },
        { value: 'createdAt_ASC', text: t('common.createdAt_ASC') },
        { value: 'createdAt_DESC', text: t('common.createdAt_DESC') },
        { value: 'user_firstname_ASC', text: t('common.firstname_ASC') },
        { value: 'user_firstname_DESC', text: t('common.firstname_DESC') },
        { value: 'user_lastname_ASC', text: t('common.lastname_ASC') },
        { value: 'user_lastname_DESC', text: t('common.lastname_DESC') },
      ]}
      renderToolbar={(elems) => (
        <Box
          display="flex"
          flexDirection={compactScreen ? 'column' : 'row'}
          justifyContent="space-between"
          mb={2}
          className={classnames(classes.gridGap)}
          id="toolbar-wrapper"
        >
          <Box
            display="flex"
            flexDirection={compactScreen ? 'column' : 'row'}
            maxWidth="100%"
            className={classnames(classes.gridGap)}
            id="toolbar-container"
          >
            {/* <Box maxWidth="100%"> */}
            <Button
              color="primary"
              variant="outlined"
              onClick={() => actions.new()}
              className={classnames(
                classes.outlinedButton,
                classes.responsiveButton,
              )}
            >
              {t('message.new')}
              &nbsp;
              <Odeslat fontSize="inherit" color="inherit" />
            </Button>
            {/* </Box> */}
            <Box
              display="flex"
              maxWidth="100%"
              minWidth={200}
              mx={compactScreen ? undefined : 2}
            >
              {elems.orderByElement}
            </Box>
            {type === 'TRASH' && (
              <ErrorButton size="medium">
                {t('message.emptybin')}&nbsp;
                <Kos fontSize="inherit" />
              </ErrorButton>
            )}
          </Box>
          <Box maxWidth="100%" width={compactScreen ? '600' : 200}>
            {elems.searchElement}
          </Box>
        </Box>
      )}
    />
  )
}

export default Table
