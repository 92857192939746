
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import { Observer } from 'mobx-react'
import { useLazyQuery } from '@apollo/react-hooks'
import { useTranslation, Trans } from 'react-i18next'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import React, { useState } from 'react'
import TextField from '../../../components/form/TextField'
import Typography from '@material-ui/core/Typography'
import useForm from '../../../hooks/useForm'
import Divider from '../../../components/Divider'
import ErrorSnackbar from '../../../components/ErrorSnackbar'
import Fade from '@material-ui/core/Fade'
import { Zamecek } from '../../../components/icons/KubikIcons'
import logoPng from '../../../assets/logo.png'
import AppState from '../../../components/AppState'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import BoxBackground from '../../../components/BoxBackground'
import prihlaseniLeft from '../../../assets/images/Ilustrace-mzdova-ucetni.svg'
import prihlaseniRight from '../../../assets/images/Ilustrace-zamestnanec.svg'
import {Error, Green} from "../../../components/CommonBox";
import {NexusGenArgTypes, NexusGenFieldTypes} from "kubik-server";
import {useMutation} from "react-apollo";
import {gql} from 'apollo-boost'
import CircularProgress from "@material-ui/core/CircularProgress";
import {CheckCircle} from "@material-ui/icons";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

interface IStylesProps {
  compactScreen?: boolean
  isNotSmallScreen?: boolean
}

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) => ({
  container: {
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.15)',
    zIndex: 1000,
    width: '100%',
    backgroundColor: '#f8f8f8',
  },
  backgroundFooter: {
    display: (props) => (props.compactScreen ? 'none' : undefined),
    background: '#f8f8f8',
    width: '100%',
    height: 90,
  },
  logo: {
    marginBottom: theme.spacing(3),
  },
  backgroundGray: {
    backgroundColor: '#f8f8f8',
  },
}))

export const OPENID_STATE = 'openIDState'
export const OPENID_NONCE = 'openIDNonce'
export const OPENID_CUSTOMER = 'openIDCustomer'

interface Props extends RouteComponentProps {}

const LoginOpenID: React.FC<Props> = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const classes = useStyles({ compactScreen, isNotSmallScreen })
  const [openIDErrors, setOPENIDErrors] = useState<string[]>([])
  const [redirectingTo, setRedirectingTo] = useState<{ href: string, customerName: string } | null>(null)


  const [requestOpenIdLogin, { loading }] = useMutation<{requestOpenIdLogin: NexusGenFieldTypes['RequestOpenIdLoginPayload'] }, NexusGenArgTypes['Mutation']['requestOpenIdLogin']>(gql`
      mutation requestOpenIdLogin($email: String!) {
          requestOpenIdLogin(email: $email) {
              redirectUrl
              errors
              customerId
              customerName
          }
      }
  `, {
    onCompleted(data) {
    },
    onError(data) {
      setOPENIDErrors([data.message])
    }
  })

  const { bind, form } = useForm<NexusGenArgTypes['Mutation']['requestOpenIdLogin']>(
    {
      email: '',
    },
    {
      email: {
        label: t('common.email'),
        placeholder: t('common.emailPlaceholder'),
        rule: 'required',
      },
    },
    {
      async onSubmit(data, form) {
        setOPENIDErrors([])
        const result = await requestOpenIdLogin({variables: data})
        const resData = result.data && result.data.requestOpenIdLogin
        if (!resData) {
          setOPENIDErrors(['Something went wrong'])
          return
        }
        if ((resData.errors && resData.errors.length) || !resData.redirectUrl) {
          setOPENIDErrors(resData.errors as string[])
          return
        }
        let redirectTo = resData.redirectUrl
        let state = crypto.randomUUID().substring(0, 20)
        localStorage.setItem(OPENID_STATE, state)
        let nonce = crypto.randomUUID().substring(0, 20)
        localStorage.setItem(OPENID_NONCE, nonce)
        localStorage.setItem(OPENID_CUSTOMER, resData.customerId || '')
        redirectTo += `&state=${state}&nonce=${nonce}`
        setRedirectingTo({
          href: encodeURI(redirectTo),
          customerName: resData.customerName || ''
        })
        setTimeout(() => {
          window.location.assign(redirectTo)
        }, 3000)

      },
    },
  )

  return (
    <Observer>
      {() => (
        <>
          <BoxBackground left={prihlaseniLeft} right={prihlaseniRight} topBackgroundColor={'#ffffff'} bottomBackgroundColor={'#f8f8f8'}>
            <Box
              display="flex"
              justifyContent="center"
              flex-wrap="nowrap"
              flexDirection="row"
            >
              <Box p={6} className={classes.container}>

                <Box display="flex" justifyContent="center">
                  <img
                    alt=""
                    className={classes.logo}
                    src={logoPng}
                    width={250}
                    height={48}
                  />
                </Box>

                <Typography align="center" variant="h1" gutterBottom>
                  {t('login.heading')}
                </Typography>

                <Box textAlign="center" fontFamily="fontFamily" mb={4} style={{display: 'flex', alignItems: 'center'}}>
                  {!loading && !redirectingTo &&
                    <div><Trans i18nKey="login.subHeadingSSO" tOptions={{context: 'OPENID'}}>
                      1<Green>Payminatora</Green>3
                    </Trans></div>}
                  {loading && <div style={{width: '100%', textAlign: 'center'}}><CircularProgress size={24}/></div>}
                    {!!redirectingTo && <div style={{width: '100%', textAlign: 'center'}}>
                    <CircularProgress size={24}/><br />
                    <Trans i18nKey={'openID.youNowWillBeRedirectedTo'}>Nyní vás přesměrujeme na &nbsp;</Trans><br />
                    <a href={redirectingTo.href} title={redirectingTo.customerName}>
                      {redirectingTo.customerName}
                    </a>
                  </div>}
                </Box>



                <TextField fullWidth gutterBottom {...bind('email')} />

                {!!openIDErrors && !!openIDErrors.length && <>{openIDErrors.map(errCode => <Error>{t('openID.' + errCode)}</Error>)}</>}

                <Box display="flex" justifyContent="center">
                  <Button
                    onClick={form.submit}
                    size="large"
                    color="primary"
                    variant="contained"
                  >
                    {t('common.signin')}
                    <span
                      style={{
                        fontSize: '0.75rem',
                        marginLeft: '8px',
                        marginTop: '3px',
                      }}
                    >
                      <Zamecek fontSize="inherit" />
                    </span>
                  </Button>
                </Box>

                <Divider spacing={4} />

                <Box textAlign="center" fontFamily="fontFamily">
                  <Trans i18nKey="common.needHelp">
                    Potřebujete pomoct?
                    <a href="mailto:helpdesk@payminator.cz">Napište nám.</a>
                  </Trans>
                </Box>
              </Box>
            </Box>
          </BoxBackground>
        </>
      )}
    </Observer>
  )
}

export default withRouter(LoginOpenID)
