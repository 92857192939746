import {gql} from 'apollo-boost'
import {NexusGenArgTypes} from 'kubik-server'

export interface IUpdateOwnLoginData {
  updateOwnLogin: {
    email: string
  }
}
export type TUpdateOwnLoginVariables = NexusGenArgTypes['Mutation']['updateOwnLogin']
export const UPDATE_OWN_LOGIN = gql`
  mutation updateOwnLogin($data: UpdateOwnLoginInput!) {
    updateOwnLogin(data: $data) {
      id
      email
      users {
        id
        email
      }
    }
  }
`
