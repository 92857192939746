import {ICustomerData, TUnit} from './gql'
import {createNewCustomer} from '../../../../generated/createNewCustomer'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import {useTranslation} from 'react-i18next'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import UnitCreateDialog from './UnitCreate'
import UnitUpdateDialog from './UnitUpdate'
import UnitRemoveDialog from './UnitRemove'
import {Box} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  marginBetween: {
    '& > *': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
}))

interface IProps {
  units: TUnit[]
  customer:
    | ICustomerData['customer']
    | createNewCustomer['createNewCustomer']
    | undefined
    | null
}

const Units: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()

  const [createDialogOpen, setCreateDialogOpen] = React.useState(false)
  const handleCreateDialogOpen = () => setCreateDialogOpen(true)
  const handleCreateDialogClose = () => setCreateDialogOpen(false)

  const [removeUnit, setRemoveDialogOpen] = React.useState<TUnit | null>(null)
  const factoryHandleRemoveDialogOpen = (unit: TUnit) => () =>
    setRemoveDialogOpen(unit)
  const handleRemoveDialogClose = () => setRemoveDialogOpen(null)

  const [updateUnit, setUpdateDialogOpen] = React.useState<TUnit | null>(null)
  const factoryHandleUpdateDialogOpen = (unit: TUnit) => () =>
    setUpdateDialogOpen(unit)
  const handleUpdateDialogClose = () => setUpdateDialogOpen(null)

  return (
    <Grid direction="column" item container spacing={2}>
      {props.units.map((unit) => (
        <Grid item key={unit.id} container justifyContent="space-between">
          <Box
            fontFamily="fontFamily"
            borderRadius={6}
            px={2}
            py={1}
            bgcolor={unit.color}
            color={theme.palette.getContrastText(unit.color)}
          >
            {unit.name}
          </Box>

          <div className={classes.marginBetween}>
            <Button onClick={factoryHandleUpdateDialogOpen(unit)}>
              {t('common.edit').toLowerCase()}
              <EditIcon className={classes.marginLeft} />
            </Button>
            <Button onClick={factoryHandleRemoveDialogOpen(unit)}>
              {t('common.remove').toLowerCase()}
              <CloseIcon className={classes.marginLeft} />
            </Button>
          </div>
        </Grid>
      ))}
      <Grid item>
        <Button variant="outlined" onClick={handleCreateDialogOpen} fullWidth>
          <AddIcon />
          {t('customerOptions.addUnit')}
        </Button>
      </Grid>

      <UnitCreateDialog
        open={createDialogOpen}
        onClose={handleCreateDialogClose}
        customer={props.customer}
      />

      <UnitUpdateDialog
        open={Boolean(updateUnit)}
        unit={updateUnit}
        onClose={handleUpdateDialogClose}
        customer={props.customer}
      />

      <UnitRemoveDialog
        open={Boolean(removeUnit)}
        unit={removeUnit}
        onClose={handleRemoveDialogClose}
        customer={props.customer}
      />
    </Grid>
  )
}

export default Units
