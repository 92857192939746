import { IUserContext } from '../../../../containers/User'
import { NexusGenEnums } from 'kubik-server'
import { useRouter, useUser } from '../../../../hooks'
import { matchPath } from 'react-router-dom'

type TUser = IUserContext['user']

type TAnnualRebate = TUser['data']['annualRebate']
type TMonthlyRebate = TUser['data']['monthlyRebate']
type TMonthlyHistory = TUser['data']['monthlyHistory']

interface IRebateStatus {
  state: TRebateState
  allowed: boolean
  done: boolean
}

export type TRebateState =
  | 'CONFIRMED'
  | 'DENIED'
  | 'INPROGRESS'
  | 'APPLIED'
  | 'NEW'

export const rebateState = (
  rebate: TAnnualRebate | TMonthlyRebate | TMonthlyHistory,
  rebateStatus: NexusGenEnums['RebateStatus'],
  isWizzardFinished: boolean,
): TRebateState => {
  if (rebate) {
    if (rebate.settlementRequest) {
      switch (rebate.settlementRequest.status) {
        case 'CONFIRMED':
          return rebateStatus === 'APPLY' ? 'INPROGRESS' : 'CONFIRMED'
        case 'DENIED':
          return rebateStatus === 'APPLY' ? 'INPROGRESS' : 'DENIED'
        case 'APPLY':
          return 'APPLIED'
        default:
          console.log('----- rebateState:', rebate.settlementRequest.status) // TBD debug --- odstranit
      }
    }

    if (isWizzardFinished) {
      return 'APPLIED'
    }

    if (
      rebateStatus === 'APPLY' ||
      (!!rebate.wizardStartedAt && !!!rebate.wizardFinishedAt) ||
      !rebate.settlementRequest
    ) {
      return 'INPROGRESS'
    }
  }
  return 'NEW'
}

export const useRebateStates = (): {
  annualRebate: IRebateStatus
  monthlyRebate: IRebateStatus
  monthlyHistory: IRebateStatus
} => {
  const { user } = useUser()
  const { location } = useRouter()

  const annualWizzardFinishedPath = Boolean(
    matchPath(location.pathname, '/:userId/annual-rebates/finish-wizard'),
  )
  const monthlyWizzardFinishedPath = Boolean(
    matchPath(location.pathname, '/:userId/monthly-rebates/finish-wizard'),
  )
  const historyWizzardFinishedPath = Boolean(
    matchPath(location.pathname, '/:userId/monthly-history/finish-wizard'),
  )

  const {
    data: {
      allowAnnual,
      annualRebate,
      blockedAnnualOverride,
      monthlyRebate,
      monthlyHistory,
    },
    rebateStatus,
  } = user

  const annualDone = allowAnnual
    ? Boolean(annualRebate && annualRebate.settlementRequest)
    : true
  const monthlyDone = Boolean(monthlyRebate && monthlyRebate.settlementRequest)
  const historyDone = Boolean(
    monthlyHistory && monthlyHistory.settlementRequest,
  )

  let {
    annualRebate: { status: annualStatus },
    monthlyRebate: { status: monthlyStatus },
    monthlyHistory: { status: historyStatus },
  } = rebateStatus()

  return {
    annualRebate: {
      state: rebateState(annualRebate, annualStatus, annualWizzardFinishedPath),
      allowed: Boolean(allowAnnual),
      done: annualDone,
    },
    monthlyRebate: {
      state: rebateState(
        monthlyRebate,
        monthlyStatus,
        monthlyWizzardFinishedPath,
      ),
      allowed: true && !blockedAnnualOverride,
      done: monthlyDone,
    },
    monthlyHistory: {
      state: rebateState(
        monthlyHistory,
        historyStatus,
        historyWizzardFinishedPath,
      ),
      allowed: true,
      done: historyDone,
    },
  }
}
