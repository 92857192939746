import {Redirect, Route, Switch} from 'react-router-dom'
import {useUser} from '../../../../hooks'
import React from 'react'
import Root from '../../../../shared/Welcome'
import SelectRebate from './pages/SelectRebate'
import WelcomeToMonthlyHistory from './pages/WelcomeToMonthlyHistory'
import GuidePage from "../GuidePage";
// import MonthlyRebate from './pages/MonthlyRebate'

const Welcome = () => {
  const { user } = useUser()

  if (user.data.monthlyHistoryRequired) {
    return <WelcomeToMonthlyHistory />
  }

  return (
    <Switch>
      <Route path="/:userId/welcome/select-rebate" component={SelectRebate} />
      {/* <Route path="/:userId/welcome/monthly-rebate" component={MonthlyRebate} /> */}
        <Route path="/:userId/welcome" component={Root} />
        <Route path="/:userId/guide" component={GuidePage} />
      <Redirect to="/:userId/welcome" />
    </Switch>
  )
}

export default Welcome
